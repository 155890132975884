import { useState, useEffect } from 'react';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
export default useWindowSize;

// whenever question number changes, the timer resets
export function useTimer(questionNumber, gameSpeed) {
  const [timer, setTimer] = useState(gameSpeed);

  useEffect(() => {
    if (timer === 0) {
      return null;
    }
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setTimer(gameSpeed);
  }, [questionNumber, gameSpeed]);
  return timer;
}
