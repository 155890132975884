import React, { useState } from 'react';
import { Button } from '@springrole/trivia-games';
import history from 'createHistory';
import { useGetVirtualCoffeeAnalyticsQuery } from 'app/services/home';
import classNames from 'classnames';
import SetupVirtualCoffeeModal from 'components/SetupVirtualCoffeeModal';
import styles from './styles.module.scss';

const VirtualCoffeeCard = () => {
  const [openVcModal, setOpenVcModal] = useState(false);
  const { data: virtualCoffee = { usersMatched: 0, channels: [], lastMatch: 0 } } =
    useGetVirtualCoffeeAnalyticsQuery();

  const getChannelsText = () => {
    let channelText = virtualCoffee.channels
      .slice(0, 3)
      // isPrivate field will not be there in case of teams
      .map((channel) => `${channel?.isPrivate ? '🔒' : '#'}${channel.name}`)
      .join(', ');
    if (virtualCoffee.channels.length > 3) {
      channelText += ` and ${virtualCoffee.channels.length - 3} more`;
    }
    return channelText;
  };

  const handleButtonClick = () => {
    setOpenVcModal(true);
  };

  const titleText = virtualCoffee.lastMatch ? (
    <>
      <span className={styles.highlightText}>{virtualCoffee.usersMatched} teammates</span> were
      matched in last round
    </>
  ) : (
    <>
      <span className={styles.highlightText}>{virtualCoffee.usersMatched} teammates</span> will be
      matched
    </>
  );

  return virtualCoffee.channels.length > 0 ? (
    <div className={classNames(styles.cardWraper, styles.vcCard)}>
      <div className={classNames(styles.cardSubTitle, styles.subTitleNonEmptyState)}>
        VirtualCoffee is enabled in&nbsp;
        <span className={styles.highlightText}>
          <b>{getChannelsText()}</b>
        </span>
        .
      </div>
      <div className={classNames(styles.cardTitle, styles.titleNonEmptyState)}>{titleText}</div>
      <Button
        className={styles.commonButtonStyles}
        onClick={() => history.push('/analytics/virtual-coffee')}
      >
        View VirtualCoffee insights
      </Button>
    </div>
  ) : (
    <div className={classNames(styles.cardWraper, styles.vcCard)}>
      <div className={styles.cardTitle}>
        Build team bonding over <span className={styles.highlightText}>VirtualCoffee</span>
      </div>
      <div className={styles.cardSubTitle}>
        Introduce teammates across departments with icebreakers.
      </div>
      <Button className={styles.commonButtonStyles} onClick={handleButtonClick}>
        Set up VirtualCoffee
      </Button>
      {openVcModal && (
        <SetupVirtualCoffeeModal open={openVcModal} handleCancel={() => setOpenVcModal(false)} />
      )}
    </div>
  );
};

export default VirtualCoffeeCard;
