import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 8 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.10356 1.60636C7.76555 1.01287 6.71739 0.0731818 6.0554 0.716126L1.20077 5.01891C0.924939 5.2662 0.924939 5.71131 1.20077 5.9586L6.0554 10.3108C6.71739 10.9043 7.76555 9.96464 7.10356 9.37115L2.80059 5.51348L7.10356 1.60636Z'
      fill={fill}
    />
  </svg>
);

ArrowLeft.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

ArrowLeft.defaultProps = {
  fill: '#8E86BF',
  width: 8,
  height: 16
};

export default ArrowLeft;
