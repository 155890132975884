import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RestrictedAccess from 'components/RestrictedAccess';
import Layout from 'components/Layout';
import { Section, Loading } from '@springrole/trivia-games';
import Button from 'components/Button';
import { ReactComponent as Bulb } from 'assets/images/bulb.svg';
import { ReactComponent as Participant } from 'assets/images/participant.svg';
import { useGetpollsQuery, pollsResultsApi } from 'app/services/poll-results';
import { ReactComponent as EmptyIcon } from 'assets/images/empty-state.svg';
import dayjs from 'dayjs';
import PollInfo from './PollInfo';
import styles from './styles.module.scss';

const PollResults = () => {
  const userDetails = useSelector((state) => state.user);
  const [polls, setPolls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();
  const listReference = useRef(null);

  const id = new URLSearchParams(window.location.search).get('id');
  const [selectedPollId, setSelectedPollId] = useState(id);

  const {
    data: pollsData = { hasMore: false, polls: {} },
    isFetching,
    isLoading
  } = useGetpollsQuery(currentPage, { skip: !userDetails.user?.isAdmin });

  const handlePollSelect = (gameId) => {
    setSelectedPollId(gameId);
    if (gameId) {
      window.history.pushState(null, '', `/custom-poll-analytics?id=${gameId}`);
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  };

  useEffect(() => {
    handlePollSelect(id);
  }, [id]);

  useEffect(() => {
    if (!isFetching && pollsData.polls.unpopularOpinionList) {
      setPolls((prevPolls) => [...prevPolls, ...pollsData.polls.unpopularOpinionList]);
      setHasMore(pollsData.hasMore);
    }
  }, [pollsData]);

  const getMore = () => {
    dispatch(pollsResultsApi.endpoints.getpolls.initiate(currentPage + 1));
    setCurrentPage((prevValue) => prevValue + 1);
  };

  const goToTop = () => {
    if (listReference && listReference.current) {
      listReference.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  if (isLoading || isFetching || userDetails.loading) {
    return (
      <Layout name='Custom Poll Analytics'>
        <Loading height={100} width={100} />
      </Layout>
    );
  }

  if (
    (!userDetails.loading && !isLoading && !userDetails.user.isAdmin) ||
    ['BASIC', 'STANDARD'].includes(userDetails.plan)
  ) {
    return (
      <Layout name='Custom Poll Analytics'>
        <div className={styles.pollResultsWrapper}>
          <div className={styles.heading}>
            <Bulb />
            Custom Poll Analytics
          </div>
          <Section>
            <RestrictedAccess
              message={
                !userDetails.user.isAdmin
                  ? 'Caution! This page is cordoned off'
                  : 'This page is not accessible due to your current payment plan'
              }
              adminRestriction={!userDetails.user.isAdmin}
            />
          </Section>
        </div>
      </Layout>
    );
  }
  return (
    <Layout name='Custom Poll Analytics'>
      <div className={styles.pollResultsWrapper}>
        <div className={styles.pollResultsContent}>
          {!selectedPollId ? (
            <>
              <div className={styles.heading}>
                <Bulb />
                Custom Poll Analytics
              </div>
              <div className={styles.pollsWrapper} ref={listReference}>
                {pollsData && pollsData.polls.total > 0 && (
                  <span className={styles.recentPolls}> Recent Polls</span>
                )}
                {pollsData &&
                  (polls.length > 0 ? (
                    polls.map((poll, index) => (
                      <div
                        key={poll.questionId}
                        className={styles.pollItem}
                        onClick={() => handlePollSelect(poll?.gameId)}
                      >
                        <div className={styles.pollItemContent}>
                          <div className={styles.pollIndex}>
                            {`0${pollsData.polls.total - index}`.slice(-2)}
                          </div>
                          <div className={styles.pollDetails}>
                            <div className={styles.opinion}>{poll.opinion}</div>
                            <div className={styles.organizerInfo}>
                              Organized by <span>{poll.host}</span> in{' '}
                              <span>{poll.channelname}</span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.pollStats}>
                          <div className={styles.participantsCount}>
                            <Participant />
                            {poll.participants.length}
                            <div>Participants</div>
                          </div>
                          <div className={styles.pollTiming}>
                            <div>{dayjs(poll.startTime).format('HH:mm A')}</div>
                            <span>{dayjs(poll.startTime).format('DD-MMM-YYYY')}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.noPolls} data-testid='empty-icon'>
                      <EmptyIcon />
                      <div className={styles.text}>
                        You haven&apos;t organized any custom polls yet...
                      </div>
                    </div>
                  ))}
                {!hasMore && pollsData.polls.total > 5 && (
                  <Button className={styles.loadMore} secondary onClick={goToTop}>
                    Go to top
                  </Button>
                )}
                {hasMore && (
                  <Button className={styles.loadMore} secondary onClick={getMore}>
                    Load More
                  </Button>
                )}
              </div>
            </>
          ) : (
            <PollInfo id={selectedPollId} handlePollSelect={handlePollSelect} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PollResults;
