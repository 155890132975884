import Button from 'components/Button';
import PropTypes from 'prop-types';
import Tag from 'components/Tag';
import React from 'react';
import ContentLoader from 'react-content-loader';
import colors from 'utils/stylesheet/colors';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Game = ({ description, criteria, rules, image, name, loading, onStart }) =>
  loading ? (
    <div className={classNames(styles.gameCardContainer, styles.loading)}>
      <ContentLoader
        title='Coming soon'
        width={150}
        height={130}
        backgroundColor={colors.grey}
        foregroundColor={colors.dark_blue}
        speed={3}
        style={{ marginRight: '1rem' }}
      >
        <rect x={0} y={0} rx={4} width={130} height={130} />
      </ContentLoader>
      <div className={styles.rightLoading}>
        <div className={styles.heading}>
          <div className={styles.text}>Coming soon...</div>
          <ContentLoader
            title='Coming soon'
            height={20}
            width='100%'
            backgroundColor={colors.grey}
            foregroundColor={colors.dark_blue}
            speed={3}
          >
            <rect x='40%' y={0} rx={10} width='15%' height={20} />
            <rect x='57%' y={0} rx={10} width='30%' height={20} />
            <rect x='90%' y={0} rx={10} width='10%' height={20} />
          </ContentLoader>
        </div>
        <div className={styles.description}>
          <ContentLoader
            title='Coming soon'
            height={40}
            width='100%'
            backgroundColor={colors.grey}
            foregroundColor={colors.dark_blue}
            speed={3}
          >
            <rect x={0} y={0} width='100%' height={9} rx={4} />
            <rect x={0} y={13} width='100%' height={9} rx={4} />
            <rect x={0} y={26} width='100%' height={9} rx={4} />
          </ContentLoader>
        </div>
        <ContentLoader
          title='Coming soon'
          className={styles.loadingButton}
          height={50}
          width={100}
          backgroundColor={colors.purple}
          foregroundColor={colors.purplish}
          speed={3}
        >
          <rect x={0} y={0} width={100} height={50} rx={24} />
        </ContentLoader>
      </div>
    </div>
  ) : (
    <div className={styles.gameCardContainer}>
      <div className={styles.descriptionWrapper}>
        <div className={styles.leftSide}>{image}</div>
        <div className={styles.rightSide}>
          <div className={styles.heading}>
            <div className={styles.name}>{name}</div>
            <div className={styles.criteria}>
              {criteria.map((c, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className={styles.tagWrapper} data-testid='criteria-tag'>
                  <Tag type='grey' text={c} />
                  {i !== criteria.length - 1 && <div className={styles.dot} />}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.description}>{description}</div>
          <div className={styles.subtitle}>Rules</div>
          <div className={styles.description}>{rules}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button onClick={onStart}>Start</Button>
      </div>
    </div>
  );

Game.propTypes = {
  description: PropTypes.string,
  criteria: PropTypes.arrayOf(PropTypes.string),
  rules: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  image: PropTypes.oneOfType([PropTypes.element]),
  name: PropTypes.string,
  loading: PropTypes.bool,
  onStart: PropTypes.func
};

Game.defaultProps = {
  description: '',
  criteria: [],
  rules: null,
  image: null,
  name: '',
  onStart: () => null,
  loading: false
};

export default Game;
