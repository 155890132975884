import { isRejectedWithValue } from '@reduxjs/toolkit';
import resetStore from 'app/actions/resetStore';
import localStorage from 'utils/localStorage';
import { toast } from 'react-toastify';

let isSessionExpired = false;

const authenticateResponse =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      isRejectedWithValue(action) &&
      action?.meta?.rejectedWithValue &&
      [401].includes(action?.meta?.baseQueryMeta?.response?.status)
    ) {
      toast.info('Session expired!', { toastId: 'session-expired' });
      dispatch(resetStore);
      localStorage.clearStorage();
      if (!isSessionExpired) {
        window.location.href = '/login';
        isSessionExpired = true;
      }
    }
    return next(action);
  };

export default authenticateResponse;
