import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 8 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.525593 1.60636C-0.136402 1.01287 0.911757 0.0731818 1.57375 0.716126L6.42838 5.01891C6.70421 5.2662 6.70421 5.71131 6.42838 5.9586L1.57375 10.3108C0.911757 10.9043 -0.136402 9.96464 0.525593 9.37115L4.82856 5.51348L0.525593 1.60636Z'
      fill={fill}
    />
  </svg>
);

ArrowRight.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

ArrowRight.defaultProps = {
  fill: '#8E86BF',
  width: 8,
  height: 16
};

export default ArrowRight;
