import { Grid } from '@mantine/core';
import { Link as LinkTag } from '@springrole/trivia-games';
import React from 'react';
import { ReactComponent as Edit } from 'assets/images/pencil.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as Empty } from 'assets/images/empty-state.svg';
import momentTz from 'moment-timezone';
import localStorage from 'utils/localStorage';
import CustomTooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const ManageSchedules = (props) => {
  const {
    handleAddNew,
    schedules,
    handleEditSchedule,
    handleDeleteSchedule,
    findChannelPrivateOrPublic
  } = props;
  const showHash = localStorage.getPlatform() === 'slack';
  const resolveTeamName = (schedule) => (schedule.teamName ? `${schedule.teamName} - ` : '');
  return (
    <div className={styles.manageScheduleContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.label}>Upcoming schedule</div>
        <LinkTag onClick={handleAddNew} style={{ textAlign: 'right' }}>
          + Create new schedule
        </LinkTag>
      </div>
      <div className={styles.schedulesListContainer}>
        <Grid className={styles.tableHeader}>
          <Grid.Col span={2}>Time</Grid.Col>
          <Grid.Col span={3}>Date</Grid.Col>
          <Grid.Col span={2}>Channel</Grid.Col>
          <Grid.Col span={4}>Timezone</Grid.Col>
          <Grid item xs={1} />
        </Grid>
        <div className={styles.schedulesList}>
          {schedules.length > 0 ? (
            schedules.map((schedule) => (
              <Grid className={styles.tableRow} key={schedule.scheduleId}>
                <Grid.Col span={2} className={styles.cell}>
                  {momentTz.tz(schedule.time, schedule.timezone).format('hh:mm a')}
                </Grid.Col>
                <Grid.Col span={3} className={styles.cell}>
                  {momentTz.tz(schedule.time, schedule.timezone).format('ddd, DD MMM YYYY')}
                </Grid.Col>
                <Grid.Col span={2} className={styles.cell}>
                  {showHash
                    ? `${findChannelPrivateOrPublic(schedule.channel)}
                    ${schedule.channelName || schedule.channel}`
                    : `${resolveTeamName(schedule)}${schedule.channelName || schedule.channel}`}
                </Grid.Col>
                <Grid.Col span={4} className={styles.cell} style={{ wordBreak: 'break-all' }}>
                  {schedule.timezone}
                </Grid.Col>
                <Grid.Col
                  span={1}
                  className={styles.cell}
                  style={{
                    justifyContent: showHash || schedule.teamName ? 'space-between' : 'flex-end'
                  }}
                >
                  {(showHash || schedule.teamName) && (
                    <CustomTooltip label='Edit Schedule'>
                      <Edit onClick={() => handleEditSchedule(schedule)} />
                    </CustomTooltip>
                  )}
                  <CustomTooltip label='Delete Schedule'>
                    <Delete onClick={() => handleDeleteSchedule(schedule)} />
                  </CustomTooltip>
                </Grid.Col>
              </Grid>
            ))
          ) : (
            <div className={styles.noSchedules}>
              <Empty height={100} width={100} />
              <div className='text'>No schedules found</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ManageSchedules.propTypes = {
  handleAddNew: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string.isRequired,
      channelName: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      teamId: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      timezone: PropTypes.string.isRequired
    })
  ).isRequired,
  findChannelPrivateOrPublic: PropTypes.func.isRequired,
  handleEditSchedule: PropTypes.func.isRequired,
  handleDeleteSchedule: PropTypes.func.isRequired
};

export default ManageSchedules;
