import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import {
  getPageData,
  saveLeaderboardData,
  setAllowedToView,
  setInvalidWorkspace
} from 'app/features/leaderboardSlice';
import localStorage from 'utils/localStorage';

const baseUrl = process.env.REACT_APP_TRIVIA_WEB_API;

export const leaderboardApi = createApi({
  reducerPath: 'leaderboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (header) => {
      header.set('authorization', localStorage.getToken());
      return header;
    }
  }),
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    // GET /leaderboard endpoint
    getLeaderboard: builder.query({
      query: (params) => ({
        url: params?.workspaceId ? `/public/leaderboard/${params.workspaceId}` : '/leaderboard',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveLeaderboardData(response));
            dispatch(getPageData(1));
          } else if (!response.ok && response.message === 'Workspace on BASIC plan') {
            dispatch(setAllowedToView());
          } else if (params.workspaceId) {
            dispatch(setInvalidWorkspace());
          } else {
            toast.error('Failed to get leaderboard data');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to fetch leaderboard');
          }
          return Promise.resolve();
        }
      }
    })
  })
});

export const { useGetLeaderboardQuery } = leaderboardApi;
