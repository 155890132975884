import React, { useEffect } from 'react';
import { Button } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import ArrowLeft from 'assets/images/steplicenses/ArrowLeft';
import TextField from 'components/TextField';
import classNames from 'classnames';
import styles from './styles.module.scss';

const BuyOrRemove = (props) => {
  const {
    buttonClick,
    setButtonClick,
    value,
    setValue,
    getTotalAmount,
    getLicenseValue,
    handleLicenseQuantity,
    loading,
    teamsSubscription,
    licensesRemaining,
    teamMataesUnableToPlay,
    checkOverview
  } = props;
  const { currency = '$', termUnit = 'P1M' } = teamsSubscription.currentPlanPricing;

  useEffect(() => {
    setValue(0);
  }, []);

  return (
    <div className={styles.manageLicenseContainer}>
      {checkOverview === 1 ? (
        <div className={styles.overviewBuyLicensesText}>
          {buttonClick === 'buy' ? 'Buy more' : 'Remove'} Licenses
        </div>
      ) : (
        <div onClick={() => setButtonClick(false)} className={styles.buyLicensesArrowText}>
          <ArrowLeft fill='#fff' width={20} height={20} />
          <div className={styles.buyLicensesText}>
            {buttonClick === 'buy' ? 'Buy' : 'Remove'} Licenses
          </div>
        </div>
      )}

      <div className={styles.manageLicenseInfo}>
        <div className={styles.questionText}>
          How many Licenses do you want to {buttonClick === 'buy' ? 'buy' : 'delete'}?
        </div>
        <div className={styles.licenseValue}>1 Licence = {`${currency} ${getLicenseValue()}`}</div>

        <div className={styles.textFieldgridContainer}>
          <TextField
            className={styles.textField}
            onChange={(e) => setValue(Number(e.target.value))}
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} // type=number doesnt restrict the string e from getting inputtted, this logic restricts all those characters form being inputted
            type='number'
            placeholder='Enter a number'
          />
          {checkOverview === 1 ? (
            <div className={styles.txt}>
              {teamMataesUnableToPlay} teammates could not play in last 20 games and you have{' '}
              {licensesRemaining} unassigned Licenses already
            </div>
          ) : null}
        </div>

        {value < 0 && (
          <div className={styles.validationText}>
            Yikes, Math is hard but you have to enter a digit greater than 0. 🤯
          </div>
        )}
      </div>

      <div className={styles.totalContainer}>
        <div className={styles.totalText}>Total:</div>
        <div className={styles.totalValue}>
          {buttonClick === 'buy' ? value : `-${value}`} x {`${currency} ${getLicenseValue()}`}{' '}
          =&nbsp;
          <b>
            {buttonClick === 'remove' && value > 0 && '-'}
            {`${currency} ${Number(value * getLicenseValue()).toFixed(2)}`}
          </b>
        </div>
      </div>
      <div className={styles.noteText}>
        Note: Your {termUnit === 'P1M' ? 'monthly' : 'yearly'} subscription is now&nbsp;
        {`${currency} ${getTotalAmount() < 0 ? 0 : getTotalAmount()}`}.
      </div>

      <div
        className={classNames(styles.buttonContainer, {
          [styles.customButtonContainer]: buttonClick
        })}
      >
        <Button
          data-testid='div'
          onClick={() => setButtonClick(false)}
          style={{
            margin: 0,
            marginRight: '10px',
            backgroundColor: 'transparent',
            textTransform: 'unset'
          }}
        >
          Go back
        </Button>
        <Button
          onClick={handleLicenseQuantity}
          disable={value < 0 || loading}
          style={{ margin: '0px' }}
        >
          {buttonClick === 'buy' ? 'Buy' : 'Confirm'}
        </Button>
      </div>
    </div>
  );
};

BuyOrRemove.propTypes = {
  buttonClick: PropTypes.string.isRequired,
  setButtonClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getTotalAmount: PropTypes.func.isRequired,
  getLicenseValue: PropTypes.func.isRequired,
  handleLicenseQuantity: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  teamsSubscription: PropTypes.shape({
    currentPlanPricing: PropTypes.objectOf(
      PropTypes.shape({
        currency: PropTypes.string,
        price: PropTypes.number,
        termDescription: PropTypes.string,
        termUnit: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  licensesRemaining: PropTypes.number.isRequired,
  teamMataesUnableToPlay: PropTypes.number.isRequired,
  checkOverview: PropTypes.number.isRequired
};

export default BuyOrRemove;
