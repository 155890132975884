import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';

const Charts = (props) => {
  const { type, labels, datasets, options, width, height, setSelectedDataPoint } = props;
  const chartRef = useRef();
  const data = {
    labels,
    datasets
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { index } = element[0];

    setSelectedDataPoint(data.labels[index]);
  };

  const onClick = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    printElementAtEvent(getElementAtEvent(chart, event));
  };

  if (type === 'bar') {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  } else if (type === 'line') {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  }

  const customOptions = {
    ...options
  };

  if (type === 'bar') {
    return <Bar options={customOptions} data={data} width={width} height={height} />;
  }

  // if type is line
  return (
    <Line
      ref={chartRef}
      options={customOptions}
      data={data}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

Charts.propTypes = {
  type: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.shape({}).isRequired,
  setSelectedDataPoint: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number
};

Charts.defaultProps = {
  width: 5,
  height: 400,
  setSelectedDataPoint: () => {}
};

export default Charts;
