import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allUsers: [],
  licenseCount: null,
  buttonType: null,
  hasMore: null,
  pageCount: null,
  totalUsers: null,
  loading: true
};

export const monetizationSlice = createSlice({
  name: 'monetization',
  initialState,
  reducers: {
    getAllUsers: (state, action) => {
      state.allUsers = action.payload.users;
      state.hasMore = action.payload.hasMore;
      state.pageCount = action.payload.pageCount;
      state.loading = false;
    },
    updateLicenses: (state, action) => {
      const temp = [...state.allUsers];

      const updatedUsers = temp.map((user) => {
        const exists = action.payload.licensedUsers.find((item) => user.id === item.id);

        if (exists) {
          user.licensed = exists.licensed;
        } else {
          user.licensed = false;
        }

        return user;
      });

      state.allUsers = updatedUsers;
      state.licenseCount = action.payload.licensedUsers.length;
    },
    updateButtonType: (state, action) => {
      state.buttonType = action.payload;
    }
  }
});

export const { updateButtonType, updateLicenses, getAllUsers } = monetizationSlice.actions;

export default monetizationSlice.reducer;
