import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  responses: [],
  currentPage: [],
  workspaceName: '',
  invalidWorkspace: null,
  allowedToView: true,
  loading: true
};

export const KnowYourTeamSlice = createSlice({
  name: 'KnowYourTeam',
  initialState,
  reducers: {
    saveKYTData: (state, action) => {
      state.responses = action.payload.data;
      state.workspaceName = action.payload.workspaceName;
      state.invalidWorkspace = false;
      state.loading = false;
    },
    getPageData: (state, action) => {
      const page = action.payload;
      const { responses } = state;
      if (responses.length === 0) {
        state.currentPage = [];
      }
      const currentPage = _.slice(responses, (page - 1) * 10, page * 10);
      state.currentPage = currentPage;
    },
    setInvalidWorkspace: (state) => {
      state.invalidWorkspace = true;
    },
    setAllowedToView: (state) => {
      state.allowedToView = false;
    },
    setKYTLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { saveKYTData, getPageData, setInvalidWorkspace, setAllowedToView, setKYTLoading } =
  KnowYourTeamSlice.actions;

export default KnowYourTeamSlice.reducer;
