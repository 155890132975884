/* eslint-disable global-require */
const reviews = [
  {
    id: 1,
    quote: '“Trivia unites the team, managers, fostering enjoyable connections."',
    content:
      'The team likes it, they bond with each other better. Games are enjoyed by everyone, the team, the managers, and you get to know so much about each other.',
    person: 'Gabriella Antivilo',
    designation: 'Human Resources Coordinator at Kongregate',
    image: require('assets/images/billing/testimonials/gabriella-antivilio.jpeg')
  },
  {
    id: 2,
    quote: '“Trivia elevates engagement, culture; 4 pm quiz unites everyone."',
    content:
      'Employee engagement and culture have seen a boost since we’ve started using Trivia.    Everyone frees up their calendar for the 4 pm quiz!',
    person: 'Simone Williams',
    designation: 'Office & Purchasing Manager, Pranda Group',
    image: require('assets/images/billing/testimonials/simone-williams.jpeg')
  },
  {
    id: 3,
    quote: "“Trivia: Remote teamwork's perfect bond; its absence would deeply disappoint.”",
    content:
      'Trivia is the perfect employee engagement app for remote organizations like us. It helps us bond and engage better as a team. If we don’t have Trivia anymore, our people will be really sad.',
    person: 'Melanie Wagner',
    designation: "Manager, People's Operation, Hatch",
    image: require('assets/images/billing/testimonials/melanie-wagner.jpeg')
  },
  {
    id: 4,
    quote: "“Trivia's joy unites remote team, fostering connections and delightful bonding.”",
    content:
      'It’s heartwarming to see everyone participate and have so much fun. We geek about stuff, try to outscore each other, and have a blast. Trivia has helped our remote team stay connected and bond beautifully.',
    person: 'Savannah Collins',
    designation: 'TA & Employee Engagement Manager, Noticed',
    image: require('assets/images/billing/testimonials/savannah-collins.jpeg')
  },
  {
    id: 5,
    quote: '“Trivia fills remote voids; games, camaraderie, and joy all-in-one package.”',
    content:
      'Trivia is so much fun! Being a remote organization we miss the casual coffee breaks, talking to colleagues about what’s new - and just popping over to a teammate’s office to chat. With Trivia, we can play games, try to outscore each other and have all along.',
    person: 'Amanda Omelchuk',
    designation: 'Philanthropy Associate, Parkinson Canada',
    image: require('assets/images/billing/testimonials/amanda-omelchuk.jpeg')
  }
];

export default reviews;
