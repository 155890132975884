import React from 'react';
import PropTypes from 'prop-types';
import localStorage from 'utils/localStorage';
import ErrorTape from 'assets/images/errortape.svg';
import history from 'createHistory';
import styles from './styles.module.scss';

const getErrorMessage = (cause) => {
  switch (cause) {
    case 'no-app':
      return `You don't have access to this page`;

    case 'expired-session':
      return 'Your session has expired.';

    case 'consent_required':
      return 'Please grant the required permissions before using Trivia';

    default:
      return 'Caution! This Page is Cordoned Off';
  }
};

const ErrorPage = ({ location, notFound }) => {
  const [, cause] = location.search.slice(1).split('=');
  if (cause === 'expired-session') {
    localStorage.clearStorage();
  }
  return (
    <div className={styles.errorContainer}>
      <div className={styles.image}>
        <img src={ErrorTape} alt='error' />
      </div>
      <div className={styles.heading}>
        {notFound ? 'The page you are looking for does not exist' : getErrorMessage(cause)}
      </div>
      {!notFound && cause === 'user' && (
        <div className={styles.subtitle}>
          Only admins from your organisation can access this site.
        </div>
      )}
      <div
        className={styles.button}
        data-testid='error-container__button'
        onClick={() =>
          notFound ? history.replace('/') : window.open(process.env.REACT_APP_HOME_PAGE, '_self')
        }
      >
        Return to homepage
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  notFound: PropTypes.bool
};
ErrorPage.defaultProps = {
  notFound: false
};

export default ErrorPage;
