import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/green-save.svg';
import styles from './styles.module.scss';

const InlineStatus = ({ status, message, align }) => {
  switch (status) {
    case 'error':
      return (
        <div className={classNames(styles.inlineError, align)}>
          <ErrorIcon />
          {message}
        </div>
      );
    case 'success':
      return (
        <div className={classNames(styles.inlineSuccess, align)}>
          <SuccessIcon />
          {message}
        </div>
      );
    default:
      return null;
  }
};

InlineStatus.propTypes = {
  status: PropTypes.oneOf(['error', 'success']).isRequired,
  message: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['center', 'right'])
};

InlineStatus.defaultProps = {
  align: 'center'
};

export default InlineStatus;
