const gameFormatForTeams = [
  {
    value: 'imageQuiz',
    label: 'Image Quizzes'
  },
  {
    value: 'kyt',
    label: 'Know Your Team Quiz'
  },
  {
    value: 'trivia',
    label: 'Trivia Quizzes'
  },
  {
    value: 'selfPacedQuiz',
    label: 'Self-paced Quizzes'
  },
  {
    value: 'unpopularOpinion',
    label: 'UnPopular Opinions'
  },
  {
    value: 'waterCooler',
    label: 'Water Cooler'
  },
  {
    value: 'trueOrFalse',
    label: 'True or False'
  },
  {
    value: 'gotcha',
    label: 'Gotcha!'
  }
];

export default gameFormatForTeams;
