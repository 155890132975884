import { Radio, Group } from '@mantine/core';
import TextField from 'components/TextField';
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const CustomRadio = (props) => (
  <Radio variant='outline' classNames={{ label: styles.radioLabel }} {...props} />
);

class Question extends React.Component {
  constructor(props) {
    super(props);
    const { question, option1, option2, option3, option4, option5, correct } = this.props;
    this.state = {
      question: question || '',
      option1: option1 || '',
      option2: option2 || '',
      option3: option3 || '',
      option4: option4 || '',
      option5: option5 || '',
      correct: correct || '1'
    };
  }

  handleChange = (event) => {
    const { onChange } = this.props;

    if (event.target) {
      const { name, value } = event.target;
      this.setState({ [name]: value });
      onChange(name, value);
    }
    return null;
  };

  render() {
    const { question, option1, option2, option3, option4, option5, correct } = this.state;
    const { showFifth } = this.props;
    return (
      <div className={styles.addQuestionContainer}>
        <div className={styles.question}>
          <div className={styles.label}>Question</div>
          <TextField
            multiline='on'
            value={question}
            onChange={this.handleChange}
            name='question'
            placeholder='Enter the question'
          />
        </div>
        <div className={styles.options}>
          <div className={styles.label}>Options</div>
          <TextField
            value={option1}
            onChange={this.handleChange}
            name='option1'
            placeholder='Enter first option'
          />
          <TextField
            value={option2}
            onChange={this.handleChange}
            name='option2'
            placeholder='Enter second option'
          />
          <TextField
            value={option3}
            onChange={this.handleChange}
            name='option3'
            placeholder='Enter third option (optional)'
          />
          <TextField
            value={option4}
            onChange={this.handleChange}
            name='option4'
            placeholder='Enter fourth option (optional)'
          />
          {showFifth && (
            <TextField
              value={option5}
              onChange={this.handleChange}
              name='option5'
              placeholder='Enter fifth option (optional)'
            />
          )}
        </div>
        <div className={styles.correctOption}>
          <div className={styles.label}>Choose the correct option</div>
          <Radio.Group name='correct' orientation='vertical' value={correct}>
            <Group onChange={this.handleChange}>
              <CustomRadio value='1' label='Option 1' />
              <CustomRadio value='2' label='Option 2' />
              <CustomRadio value='3' label='Option 3' />
              <CustomRadio value='4' label='Option 4' />
              {showFifth && <CustomRadio value='5' label='Option 5' />}
            </Group>
          </Radio.Group>
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string,
  option1: PropTypes.string,
  option2: PropTypes.string,
  option3: PropTypes.string,
  option4: PropTypes.string,
  option5: PropTypes.string,
  correct: PropTypes.string,
  showFifth: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Question.defaultProps = {
  question: '',
  option1: '',
  option2: '',
  option3: '',
  option4: '',
  option5: '',
  correct: '',
  showFifth: false
};

export default Question;
