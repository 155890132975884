import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/MantineTable';
import { Button, Search, Section } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import useWindowSize from 'utils/hooks';
import { connect } from 'react-redux';
import { daysToNaturalLanguage } from 'data/days';
import localStorage from 'utils/localStorage';
import { maybePluralize } from 'utils/utilities';
import MobileAutomationRow from './MobileAutomationRow';
import Form from '../Modals/Form';
import styles from './styles.module.scss';
import Delete from '../Modals/Delete';

const ManageAutomations = (props) => {
  const {
    automations,
    channels,
    selectedDropdownValues,
    handleChange,
    loading,
    handleAutomationModalOpen,
    formValues,
    showAutomationModals,
    setShowAutomationModals,
    isAdmin
  } = props;
  const platform = localStorage.getPlatform();
  const { width } = useWindowSize();

  const automationTableFormat = automations?.map((automation) => {
    const { channel, team } = automation;
    const data = {
      id: { value: automation.id, display: false }, // display false will ensure not to display id in table
      game: automation?.types?.join(', '),
      channel: channel?.isPrivate ? `🔒${channel.name}` : `#${channel.name}`,
      frequency: daysToNaturalLanguage(automation.frequency),
      time: automation.time
    };
    if (platform === 'teams') {
      if (automation.channel?.isGroupChat) {
        data.channel = `Group chat -> ${channel.name}`;
      } else {
        data.channel = `${team.name} -> ${channel.name}`;
      }
    }
    return data;
  });

  return (
    <Section className={styles.container}>
      <div className={styles.topHeader}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>
            {automations.length} {maybePluralize(automations.length, 'Automation')}
          </div>

          {width < 1025 && isAdmin && (
            <Button
              style={{
                fontSize: '12px',
                fontWeight: '500',
                minWidth: width < 350 ? '8rem' : '10rem',
                padding: width < 350 ? '0.4rem 0.8rem' : '0.6rem 1rem',
                margin: 0
              }}
              onClick={() =>
                setShowAutomationModals((prevValues) => ({ ...prevValues, add: true }))
              }
            >
              Add New Automation
            </Button>
          )}
        </div>

        <div className={styles.actions}>
          <div className={styles.select}>
            <CustomSelect
              options={channels}
              value={selectedDropdownValues.channel}
              setValue={(value) => handleChange('channel', value)}
              disabled={loading}
            />
          </div>

          <div className={styles.search}>
            <Search
              placeholder='Search by Game, channel'
              size='tiny'
              value={selectedDropdownValues.search}
              handleChange={(value) => handleChange('search', value)}
              customStyles={{ padding: '0px', width: '100%' }}
              disabled={loading}
            />
          </div>

          {width > 1025 && isAdmin && (
            <Button
              style={{
                fontSize: '12px',
                fontWeight: '500',
                minWidth: '10rem',
                padding: '1.2rem 1rem',
                margin: 0
              }}
              onClick={() =>
                setShowAutomationModals((prevValues) => ({ ...prevValues, add: true }))
              }
            >
              Add New Automation
            </Button>
          )}
        </div>
      </div>

      <div className={styles.tableContainer}>
        {width > 480 ? (
          <Table
            columns={
              platform === 'teams'
                ? ['GAME', 'CONVERSATION', 'FREQUENCY', 'TIME']
                : ['GAME', 'CHANNEL', 'FREQUENCY', 'TIME']
            }
            elements={automationTableFormat}
            handleAutomationModalOpen={handleAutomationModalOpen}
            loading={loading}
            isAdmin={isAdmin}
          />
        ) : (
          <MobileAutomationRow
            automation={automationTableFormat}
            handleAutomationModalOpen={handleAutomationModalOpen}
          />
        )}
      </div>
      {showAutomationModals.add && (
        <Form
          open={showAutomationModals.add}
          handleCancel={() =>
            setShowAutomationModals((prevValues) => ({ ...prevValues, add: false }))
          }
        />
      )}
      {showAutomationModals.edit && (
        <Form
          open={showAutomationModals.edit}
          edit
          formValues={formValues}
          handleCancel={() =>
            setShowAutomationModals((prevValues) => ({ ...prevValues, edit: false }))
          }
        />
      )}
      {showAutomationModals.delete && (
        <Delete
          open={showAutomationModals.delete}
          automationId={formValues.id}
          handleCancel={() =>
            setShowAutomationModals((prevValues) => ({ ...prevValues, delete: false }))
          }
        />
      )}
    </Section>
  );
};

ManageAutomations.propTypes = {
  automations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      channel: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        isPrivate: PropTypes.bool
      }),
      team: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ),
      frequency: PropTypes.arrayOf(PropTypes.string),
      time: PropTypes.string
    })
  ).isRequired,
  handleAutomationModalOpen: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    id: PropTypes.string,
    gameTypes: PropTypes.arrayOf(String),
    channel: PropTypes.shape({
      id: PropTypes.string,
      isPrivate: PropTypes.bool,
      name: PropTypes.string,
      users: PropTypes.arrayOf(PropTypes.string),
      usersCount: PropTypes.number
    }),
    frequency: PropTypes.arrayOf(String),
    time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    timezone: PropTypes.string,
    category: PropTypes.arrayOf(String),
    difficulty: PropTypes.string
  }).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedDropdownValues: PropTypes.shape({
    channel: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setShowAutomationModals: PropTypes.func.isRequired,
  showAutomationModals: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    edit: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired
};

const mapStateToProps = ({ user }) => ({ isAdmin: user.user?.isAdmin });

export default connect(mapStateToProps)(ManageAutomations);
