import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  leaderboard: [],
  currentPage: [],
  workspaceName: '',
  invalidWorkspace: null,
  allowedToView: true,
  loading: true
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    saveLeaderboardData: (state, action) => {
      state.leaderboard = action.payload.data;
      state.workspaceName = action.payload.workspaceName;
      state.invalidWorkspace = false;
      state.loading = false;
    },
    getPageData: (state, action) => {
      const page = action.payload;
      const { leaderboard } = state;
      if (leaderboard.length === 0) {
        state.currentPage = [];
      }
      const currentPage = _.slice(leaderboard, (page - 1) * 10, page * 10);
      state.currentPage = currentPage;
    },
    setInvalidWorkspace: (state) => {
      state.invalidWorkspace = true;
    },
    setAllowedToView: (state) => {
      state.allowedToView = false;
    },
    setLeaderboardLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const {
  saveLeaderboardData,
  getPageData,
  setInvalidWorkspace,
  setAllowedToView,
  setLeaderboardLoading
} = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
