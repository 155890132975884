import React from 'react';
import PInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.scss';
import momentTz from 'moment-timezone';
import timezones, { toTzValue } from 'data/timezones';
import PropTypes from 'prop-types';

const getCountryCodeById = (id) => {
  const timezone = timezones.find((tz) => tz.value === id);
  return timezone?.countryCode.toLowerCase();
};

const PhoneInput = ({ value, savePhone, validate, disabled }) => {
  const timeZoneId = toTzValue(momentTz.tz.guess());
  const countryCode = getCountryCodeById(timeZoneId);

  return (
    <PInput
      country={countryCode}
      value={value}
      onChange={(phone, country) => {
        const formatLength = country.format.match(/\./g).length;
        savePhone(phone, formatLength);
      }}
      jumpCursorToEnd
      onBlur={(e) => {
        const num = e.target.value.match(/\d/g);
        validate(num ? num.join('') : '');
      }}
      preferredCountries={['in', 'us']}
      dropdownClass='dropdown'
      disabled={disabled}
    />
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  savePhone: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default PhoneInput;
