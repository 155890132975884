import React from 'react';
import { useGetEngagementInsightsQuery } from 'app/services/analytics';
import { Section, Loading } from '@springrole/trivia-games';
import LineChartImg from 'assets/images/Analytics/lineChartComingSoon.png';
import Charts from 'components/Charts';
import useWindowSize from 'utils/hooks';
import EmptyStateFooter from 'components/EmptyStateFooter';
import classNames from 'classnames';
import localStorage from 'utils/localStorage';
import styles from './styles.module.scss';

const GamesEngagement = () => {
  const { data: engagementInsights = [], isFetching: engagementLoading } =
    useGetEngagementInsightsQuery();
  const { width } = useWindowSize();
  const platform = localStorage.getPlatform();
  const labels = engagementInsights.map((item) => item.mark);
  const data = engagementInsights.map((item) => item.engagementRate);

  const handleClick = () => {
    if (platform === 'teams') {
      return window.open(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`,
        '_blank'
      );
    }

    return window.open(
      `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=gamesPage&key=${localStorage.getToken()}`,
      '_blank'
    );
  };

  return (
    <Section
      className={classNames(styles.container, {
        [styles.emptyState]: engagementInsights.length <= 0
      })}
    >
      <div
        className={classNames(styles.title, {
          [styles.emptyStateTitle]: engagementInsights.length <= 0
        })}
      >
        Games engagement rate
      </div>

      {engagementLoading && <Loading />}

      {!engagementLoading &&
        (engagementInsights.length <= 0 ? (
          <>
            <div className={styles.comingSoonContainer}>
              <img className={styles.lineChartImg} src={LineChartImg} alt='' />
            </div>

            <EmptyStateFooter
              content='Visualize trends in engagement rate when you start playing Trivia Games'
              action='Start a game'
              handleClick={handleClick}
            />
          </>
        ) : (
          <div>
            <Charts
              type='line'
              labels={width < 425 ? labels.slice(-5) : labels}
              datasets={[
                {
                  data: width < 425 ? data.slice(-5) : data,
                  backgroundColor: '#E1DCFF'
                }
              ]}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    xAlign: 'center',
                    yAlign: 'bottom',
                    backgroundColor: '#FFFFFF',
                    bodyColor: '#000',
                    displayColors: false,
                    callbacks: {
                      label(context) {
                        let label = context.label || '';

                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += `${context.parsed.y}%`;
                        }
                        return label;
                      },
                      title: () => null
                    }
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: true,
                      color: '#A192F5',
                      drawTicks: false
                    },
                    border: {
                      dash: [10, 10],
                      display: false
                    },
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.8)',
                      padding: width < 425 ? 10 : 20
                    }
                  },
                  y: {
                    grid: {
                      color: '#A192F5',
                      drawTicks: true
                    },
                    border: {
                      dash: [10, 10],
                      display: false
                    },
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.8)',
                      padding: width < 425 ? 5 : 20,
                      stepSize: 25,
                      callback: (value) => `${value}%`
                    },
                    min: 0,
                    max: 100
                  }
                },
                elements: {
                  line: { borderColor: ' #A192F5' }
                },
                maintainAspectRatio: false
              }}
            />
          </div>
        ))}
    </Section>
  );
};

export default GamesEngagement;
