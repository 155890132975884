import React from 'react';
import { ReactComponent as TickIcon } from 'assets/images/home/tickIcon.svg';

export const timespanOptions = [
  { label: 'Last 3 months', value: '3' },
  { label: 'Last 6 months', value: '6' },
  { label: 'Last 12 months', value: '12' },
  { label: 'All Time', value: 'all' }
];

export const difficultiesOptions = [
  {
    label: (
      <>
        <TickIcon /> Easy
      </>
    ),
    value: 'Easy'
  },
  {
    label: (
      <>
        <TickIcon /> Medium
      </>
    ),
    value: 'Medium'
  },
  {
    label: (
      <>
        <TickIcon /> Hard
      </>
    ),
    value: 'Hard'
  }
];

export const virtualCoffeeFrequencyOptions = [
  { label: 'Every two weeks', value: '2' },
  { label: 'Every four weeks', value: '4' },
  { label: 'Once a quarter', value: '12' }
];
