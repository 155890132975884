import React from 'react';
import { ReactComponent as Pictionary } from 'assets/images/painter.svg';

const games = (gamesLeft) => {
  const criteria = ['Players: 2+', '2-5 mins per game'];
  if (gamesLeft) {
    criteria.push(`${gamesLeft} games left`);
  }
  return [
    {
      id: 0,
      name: 'Pictionary',
      description: 'Start a game of Pictionary and invite your team to play with you.',
      rules: (
        <>
          <p>
            If it&apos;s your turn to draw, you will have to choose a word from three options. In
            the next two minutes, you need to draw a picture that depicts the chosen word.
          </p>
          <p>
            If someone else is drawing, your goal is to guess the word being drawn. The quickest
            player to guess the word correctly wins!
          </p>
        </>
      ),
      criteria,
      image: <Pictionary />
    }
  ];
};

export default games;
