import React from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from 'components/Tooltip';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Tag = ({ type, text, style, tooltip }) =>
  tooltip ? (
    <CustomTooltip label={text}>
      <div data-testid='TagText' className={classNames(styles.tag, styles[type])} style={style}>
        {text}
      </div>
    </CustomTooltip>
  ) : (
    <div data-testid='TagText' className={classNames(styles.tag, styles[type])} style={style}>
      {text}
    </div>
  );

Tag.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  tooltip: PropTypes.bool
};

Tag.defaultProps = {
  style: {},
  type: 'pink',
  tooltip: true
};

export default Tag;
