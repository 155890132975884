import React from 'react';
import localStorage from 'utils/localStorage';
import classNames from 'classnames';
import KnowYourTeam from './KnowYourTeam';
import VirtualCoffee from './VirtualCoffee';
import styles from './styles.module.scss';

const GameCards = () => {
  const platform = localStorage.getPlatform();

  return (
    <div
      className={classNames(styles.cardsContainer, { [styles.teamsGrid]: platform === 'teams' })}
    >
      {platform === 'slack' && <VirtualCoffee />}
      <KnowYourTeam />
    </div>
  );
};

export default GameCards;
