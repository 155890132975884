import React from 'react';
import { Button } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import { ReactComponent as Trophy } from 'assets/images/leaderboard/trophy.svg';
import localStorage from 'utils/localStorage';
import styles from './styles.module.scss';

const EmptyLeaderboardState = (props) => {
  const { teamId } = props;
  const platform = localStorage.getPlatform();
  const handleClick = () => {
    if (platform === 'teams') {
      return window.open(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`,
        '_blank'
      );
    }

    return window.open(
      `slack://app?team=${teamId}&id=${process.env.REACT_APP_SLACK_APP_ID}&tab=home`,
      '_blank'
    );
  };

  return (
    <div className={styles.modalWrapper} data-testid='empty-leaderboard-wrapper'>
      <div className={styles.modalContent} data-testid='empty-leaderboard-content'>
        <Trophy />
        <div className={styles.text}>
          Play Games with your teammates & see how you rank <br /> up against them here!
        </div>
        <Button onClick={handleClick} style={{ borderRadius: '8px' }}>
          Play Now
        </Button>
      </div>
    </div>
  );
};

EmptyLeaderboardState.propTypes = { teamId: PropTypes.string };
EmptyLeaderboardState.defaultProps = { teamId: '' };
export default EmptyLeaderboardState;
