/* eslint-disable jsx-a11y/label-has-associated-control */
import TextField from 'components/TextField';
import React from 'react';
import InlineStatus from 'components/InlineStatus';
import CustomSelect from 'components/Select/customSelect';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const Create = (props) => {
  const selectClassNames = {
    root: styles.customSelectRoot,
    input: styles.customSelectInput,
    item: styles.customSelectItem,
    option: styles.customSelectOption
  };
  const { name, handleChange, disabled, error, questionsCount, isPaidUser } = props;
  const options = ['5', '10'];
  if (isPaidUser) {
    options.push('15');
  }
  const modifiedOptions = options.map((value) => ({ label: value, value }));

  return (
    <div className={styles.createQuizModalContent}>
      <div className={styles.nameContainer}>
        <label htmlFor='quizName'>Name of the quiz</label>
        <TextField
          placeholder='Quiz name (Maximum of 25 characters)'
          value={name}
          name='quizName'
          onChange={({ target: { value } }) => handleChange('name', value)}
          disabled={disabled}
        />
        {error && <InlineStatus status='error' message={error} />}
      </div>
      <div className={styles.questionsCountContainer}>
        <label htmlFor='questions-count'>Questions</label>
        <CustomSelect
          value={questionsCount}
          setValue={(value) => handleChange('questionsCount', value)}
          options={modifiedOptions}
          disabled={disabled}
          classNames={selectClassNames}
        />
      </div>
    </div>
  );
};

Create.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  questionsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isPaidUser: PropTypes.bool
};

Create.defaultProps = {
  error: '',
  disabled: false,
  handleChange: null,
  isPaidUser: false
};

export default Create;
