import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@springrole/trivia-games';
import { ReactComponent as MicIcon } from 'assets/images/home/micIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import { ReactComponent as AlarmIcon } from 'assets/images/home/alarmIcon.svg';
import { ReactComponent as ListIcon } from 'assets/images/home/listIcon.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/home/peopleIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/home/arrowRight.svg';
import CustomSelect from 'components/Select/customSelect';
import history from 'createHistory';
import localStorage from 'utils/localStorage';
import classNames from 'classnames';
import styles from './styles.module.scss';

const GamesOverview = (props) => {
  const {
    loading,
    overview,
    channelOptions,
    dateRangeOptions,
    withDiveDeeperFooter,
    selectedDropdownValues,
    handleDropdownValuesChange
  } = props;
  const platform = localStorage.getPlatform();
  const tooltipText =
    platform === 'slack'
      ? 'This is the % of teammates playing Trivia Games among all members of slack workspace'
      : 'This is the % of teammates playing Trivia Games among all members of your Microsoft Teams organization';
  const analyticsData = [
    {
      icon: <MicIcon className={styles.icon} />,
      value: (
        <>
          {overview.engagementRate}%
          <Tooltip multiline width={220} label={tooltipText} position='top'>
            <InfoIcon />
          </Tooltip>
        </>
      ),
      label: 'Engagement rate'
    },
    {
      icon: <AlarmIcon className={styles.icon} />,
      value: overview.gamesPlayed,
      label: 'Games played'
    },
    {
      icon: <ListIcon className={styles.icon} />,
      value: <>{overview.weeklyEngagement} mins</>,
      label: 'Weekly engagement'
    },
    {
      icon: <PeopleIcon className={styles.icon} />,
      value: overview.uniquePlayers,
      label: 'Unique Players'
    }
  ];

  const handleClick = () => {
    if (platform === 'teams') {
      return window.open(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`,
        '_blank'
      );
    }

    return window.open(
      `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=gamesPage&key=${localStorage.getToken()}`,
      '_blank'
    );
  };

  return (
    <div
      className={classNames(styles.overviewWrapper, {
        [styles.paddingBottom]: !withDiveDeeperFooter && overview.gamesPlayed > 0
      })}
    >
      <div className={styles.overviewHeader}>
        <div className={styles.title}>Games overview</div>
        <div className={styles.dropdowns}>
          <CustomSelect
            options={channelOptions}
            value={selectedDropdownValues.channel}
            setValue={(value) => handleDropdownValuesChange('channel', value)}
            disabled={loading}
          />
          <CustomSelect
            options={dateRangeOptions}
            value={selectedDropdownValues.timespan}
            setValue={(value) => handleDropdownValuesChange('timespan', value)}
            disabled={loading}
          />
        </div>
      </div>
      {/* Analytics component */}
      <div className={styles.gamesAnalytics}>
        {analyticsData.map((analtics) => (
          <div className={styles.gameDetailsWrapper} key={analtics.label}>
            {analtics.icon}
            <div className={styles.analytcsWrapper}>
              <div className={styles.analyticsValue}>{analtics.value}</div>
              <div className={styles.analyticsLabel}>{analtics.label}</div>
            </div>
          </div>
        ))}
      </div>
      {/* Footer component based on the games played value */}
      {overview.gamesPlayed > 0 ? (
        <div
          className={classNames(styles.overviewFooter, {
            [styles.displayNone]: !withDiveDeeperFooter
          })}
        >
          <div className={styles.diveDeeperButton} onClick={() => history.push('/analytics')}>
            Dive Deeper <ArrowIcon />
          </div>
        </div>
      ) : (
        <div className={styles.emptyStateFooter}>
          <div className={styles.footerDescription}>
            Play Trivia Games & analyze your team&apos;s engagement here
          </div>
          <div className={styles.playGamesButton} onClick={handleClick}>
            Play Games <ArrowIcon />
          </div>
        </div>
      )}
    </div>
  );
};

GamesOverview.propTypes = {
  channelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedDropdownValues: PropTypes.shape({
    timespan: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired
  }).isRequired,
  handleDropdownValuesChange: PropTypes.func.isRequired,
  overview: PropTypes.shape({
    engagementRate: PropTypes.number.isRequired,
    gamesPlayed: PropTypes.number.isRequired,
    weeklyEngagement: PropTypes.number.isRequired,
    uniquePlayers: PropTypes.number.isRequired
  }).isRequired,
  withDiveDeeperFooter: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default GamesOverview;
