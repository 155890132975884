import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@springrole/trivia-games';
import { ReactComponent as KytImage } from 'assets/images/components/kytImage.svg';
import styles from './styles.module.scss';

const EnableKnowYourTeamModal = (props) => {
  const { open, loading, handleCancel, handleClick } = props;

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      loading={loading}
      title='Enable Know Your Team'
      confirmText=''
      cancelText=''
      size='xl'
      showCancelButton={false}
    >
      <>
        <div className={styles.cardWrapper}>
          <ul className={styles.kytPointsWrapper}>
            <li>
              Introductions are old, boring and hard to do, aren&apos;t they? That&apos;s when Know
              Your Team (KYT) comes to the rescue!
            </li>
            <li>
              When you enable KYT, Trivia collects responses from your teammates about their
              interests & favourites.
            </li>
            <li>It then launches KYT Quizzes with questions based on everyone&apos;s responses.</li>
            <li>So, introductions made fun & automated with one-click.</li>
          </ul>
          <KytImage className={styles.kytImage} />
        </div>
        <div className={styles.automationContentWrapper}>
          <p>To enable Know Your Team, add an Automation in a channel with your teammates.</p>
          <Button onClick={handleClick}>Add Automation</Button>
        </div>
      </>
    </Modal>
  );
};

EnableKnowYourTeamModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired
};

EnableKnowYourTeamModal.defaultProps = {
  loading: false
};

export default EnableKnowYourTeamModal;
