import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Hand } from 'assets/images/hand.svg';
import { ReactComponent as Star } from 'assets/images/star.svg';
import { ReactComponent as Tick } from 'assets/images/tick.svg';
import { useSubmitFeedbackMutation } from 'app/services/virtual-coffee';
import history from 'createHistory';
import Button from 'components/Button';
import TextField from 'components/TextField';
import styles from './Feedback.module.scss';

const Feedback = ({ isConnectionLost, onRejoin }) => {
  const [hoverValue, setHoverValue] = useState(0);
  const [currentStarValue, setCurrentStarValue] = useState(0);
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [submitFeedback] = useSubmitFeedbackMutation();
  const stars = Array(5).fill(0);

  const handleStarClick = (value) => {
    setCurrentStarValue(value);
    submitFeedback({ rating: value });
  };

  const handleMouseOver = (value) => {
    setHoverValue(value);
  };

  const handleMouseLeave = () => {
    setHoverValue(0);
  };

  const onFeedbackDescriptionChange = (e) => {
    setFeedbackDescription(e.target.value);
  };

  const onSubmitFeedbackClicked = () => {
    setIsFeedbackSubmitted(true);
    submitFeedback({ feedback: feedbackDescription });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          {!isConnectionLost && <Hand />}

          <div className={styles.headingText}>
            {isConnectionLost ? (
              <>
                Your VirtualCoffee ended because the <br /> connection was lost
              </>
            ) : (
              'You left your VirtualCoffee cafe'
            )}
          </div>

          {isConnectionLost && (
            <div className={styles.connectivityText}>
              Please check your network connection and try again.
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button className={styles.rejoinButton} onClick={onRejoin}>
              Rejoin
            </Button>
            <Button className={styles.exploreButton} onClick={() => history.push('/')}>
              Explore more
            </Button>
          </div>
        </div>

        {!isFeedbackSubmitted && (
          <div className={styles.exprienceContainer}>
            <div className={styles.exprienceText}>How was your VirtualCoffee experience?</div>

            <div className={styles.starsContentWrapper}>
              <div className={styles.starsContainer}>
                {stars.map((ele, index) => (
                  <Star
                    onClick={() => handleStarClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    fill={(hoverValue || currentStarValue) > index ? '#FADB14' : '#E8E8E8'}
                    className={styles.star}
                    data-testid={`star-${index}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                ))}
              </div>

              <div className={styles.starsTextContainer}>
                <div>Very bad</div>
                <div>Very good</div>
              </div>
            </div>

            {currentStarValue > 0 && (
              <>
                <TextField
                  name='feedbackDescription'
                  onChange={(e) => onFeedbackDescriptionChange(e)}
                  value={feedbackDescription}
                  multiline='on'
                  placeholder='Tell us more! What went well? What should we do better? (optional)'
                  style={{ width: '95%', height: '80px' }}
                />

                <Button onClick={onSubmitFeedbackClicked} className={styles.submitButton}>
                  Submit feedback
                </Button>
              </>
            )}
          </div>
        )}

        {isFeedbackSubmitted && (
          <div className={styles.thanksContainer}>
            <Tick className={styles.tick} fill='#52C41A' />
            Thanks for your feedback!
          </div>
        )}
      </div>
    </div>
  );
};

Feedback.propTypes = {
  isConnectionLost: PropTypes.bool,
  onRejoin: PropTypes.func
};
Feedback.defaultProps = {
  isConnectionLost: false,
  onRejoin: () => null
};

export default Feedback;
