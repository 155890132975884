import React from 'react';
import Avatar from 'assets/images/avatar.svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import { maybePluralize } from 'utils/utilities';
import DotSeparator from 'components/DotSeparator';
import Tag from 'components/Tag';
import { Button } from '@springrole/trivia-games';
import { Menu } from '@mantine/core';
// import { ReactComponent as ChatIcon } from 'assets/images/chat.svg';
import { ReactComponent as FaqIcon } from 'assets/images/faq.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import TriviaLogo from 'assets/images/logo.svg';
import CustomTooltip from 'components/Tooltip';
import moment from 'moment';
import classNames from 'classnames';
import history from 'createHistory';
import { upperFirst } from 'lodash';
import { ReactComponent as DownArrow } from 'assets/images/down.svg';
import HamburgerMenu from './HamburgerMenu';
import styles from './styles.module.scss';

const handleOpenSettings = () => {
  history.push('/settings');
};

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuShown: false,
      anchorel: null
      // zohoWidgetShown: false
    };
  }

  render() {
    const {
      tabs,
      logout,
      team,
      photo,
      fullname,
      isPublic,
      loading,
      plan,
      freeTrial,
      daysLeft,
      name,
      days
    } = this.props;
    const { anchorel, menuShown /* , zohoWidgetShown */ } = this.state;

    return (
      <div className={classNames(styles.pageHeader, { [styles.public]: isPublic })}>
        {!isPublic && (
          <div className={styles.menu} data-testid='menu-checkbox-r'>
            <label className={styles.menuIcon} htmlFor='menu'>
              <input
                className={styles.menuBtn}
                type='checkbox'
                id='menu'
                checked={menuShown}
                onChange={({ target: { checked } }) => {
                  this.setState({ menuShown: checked });
                }}
              />
              <span className={styles.icon} />
            </label>
          </div>
        )}
        <div className={styles.titleWrapper}>
          <div className={styles.companyWrapper}>
            <div className={styles.company}>{team}</div>
            <DotSeparator size='large' />
            <Tag
              type='plan'
              text={upperFirst(plan.toLowerCase()) || '...'}
              style={{
                fontSize: '11px',
                lineHeight: '16px',
                fontWeight: '500',
                fontFamily: 'Poppins',
                color: '#2A00A2',
                letterSpacing: '0.75px',
                padding: '0.25rem 1rem',
                maxWidth: '150px'
              }}
            />
            {!['STANDARD', 'PRO', 'ENTERPRISE'].includes(plan) && !loading && freeTrial && (
              <div className={styles.planInfo}>
                <strong>{daysLeft}</strong> {maybePluralize(daysLeft, days ? 'day' : 'hour')}
                left
              </div>
            )}
            {(plan === 'BASIC' || freeTrial) && (
              <Button
                onClick={() => history.push('/billing')}
                style={{
                  margin: 0,
                  marginRight: '10px',
                  backgroundColor: 'transparent',
                  textTransform: 'unset',
                  fontSize: '0.75rem',
                  padding: '0.3rem 0.8rem',
                  minWidth: 'fit-content'
                }}
              >
                Upgrade now
              </Button>
            )}
          </div>
          <div className={styles.userWrapper}>
            <div className={styles.actions}>
              {/* <CustomTooltip label='Contact us'> */}
              {/*  <div */}
              {/*    data-testid='tooltip' */}
              {/*    onClick={() => { */}
              {/*      if (zohoWidgetShown) { */}
              {/*        window.$zoho?.salesiq?.floatwindow?.visible('hide'); */}
              {/*        this.setState({ zohoWidgetShown: false }); */}
              {/*      } else { */}
              {/*        window.$zoho?.salesiq?.floatwindow?.visible('show'); */}
              {/*        this.setState({ zohoWidgetShown: true }); */}
              {/*      } */}
              {/*      this.setState(({ zohoWidgetShown: widgetShown }) => ({ */}
              {/*        zohoWidgetShown: !widgetShown */}
              {/*      })); */}
              {/*    }} */}
              {/*  > */}
              {/*    <ChatIcon /> */}
              {/*  </div> */}
              {/* </CustomTooltip> */}
              <CustomTooltip label='Knowledge Base'>
                <div
                  data-testid='support-link'
                  onClick={() =>
                    window.open('https://support.springworks.in/portal/en/kb/trivia', '_blank')
                  }
                >
                  <FaqIcon />
                </div>
              </CustomTooltip>
              <CustomTooltip label='Settings'>
                <div data-testid='tooltip'>
                  <Link to='/settings'>
                    <SettingsIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
            <div className={styles.user}>
              <Menu
                anchorel={anchorel}
                position='bottom-start'
                onClose={() => this.setState({ anchorel: null })}
                classNames={{
                  root: styles.customMenuRoot,
                  list: styles.customMenuList,
                  item: styles.customMenuItem,
                  itemLabel: styles.customMenuItemLabel,
                  label: styles.customMenuLabel
                }}
              >
                <Menu.Target>
                  <Button secondary='true' className={styles.menuButton}>
                    <img
                      src={photo || Avatar}
                      className={styles.avatar}
                      alt={fullname}
                      height={40}
                      width={40}
                    />
                    <span>{fullname}</span>
                    <div className={classNames(styles.arrow, styles.down)} />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown className={styles.MenuDropdown}>
                  <Menu.Item
                    className={styles.avatarWrapper}
                    onClick={() => this.setState({ anchorel: null })}
                    rightSection={<DownArrow style={{ transform: 'rotate(180deg)' }} />}
                    icon={<img src={photo || Avatar} className={styles.avatar} alt={fullname} />}
                  >
                    <span>{fullname}</span>
                  </Menu.Item>
                  <Menu.Item onClick={logout}>Logout</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
          </div>
        </div>
        <div className={styles.pageHeader}>
          <div className={styles.logo}>
            <img src={TriviaLogo} alt='trivia' />
          </div>
          <div className={styles.text}>{name}</div>
        </div>
        <HamburgerMenu
          open={menuShown}
          handleClose={() => this.setState({ menuShown: false })}
          onLogout={logout}
          onSettings={handleOpenSettings}
          tabs={tabs.map((tab) => (
            <Link
              to={tab.path}
              key={tab.id}
              onClick={() => {
                this.setState({ menuShown: false });
              }}
            >
              <div id={`mobile-${tab.id}`}>
                <span>{tab.name}</span>
                {tab.restricted && !loading && <Lock />}
              </div>
            </Link>
          ))}
          userInfo={
            <>
              {photo ? (
                <img
                  src={photo}
                  className={styles.hbmenuAvatar}
                  alt={fullname}
                  height={40}
                  width={40}
                />
              ) : (
                <img src={Avatar} alt='user' height={40} width={40} />
              )}
              <span className={styles.hbmenuName}>{fullname}</span>
            </>
          }
        />
      </div>
    );
  }
}

Header.propTypes = {
  name: PropTypes.string.isRequired,
  daysLeft: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  logout: PropTypes.func.isRequired,
  team: PropTypes.string,
  photo: PropTypes.string,
  fullname: PropTypes.string.isRequired,
  isPublic: PropTypes.bool,
  loading: PropTypes.bool,
  freeTrial: PropTypes.bool,
  plan: PropTypes.string,
  basicPlanDetails: PropTypes.shape({
    instantGamesLeft: PropTypes.number,
    customGamesLeft: PropTypes.number,
    nextRenewOn: PropTypes.string,
    vcPairingsLeft: PropTypes.number
  }).isRequired,
  days: PropTypes.bool.isRequired
};
Header.defaultProps = {
  tabs: [],
  loading: false,
  isPublic: false,
  photo: '',
  team: 'Trivia',
  plan: '',
  freeTrial: false
};

const mapStateToProps = ({
  user: { team, user, loading, plan, freeTrial, trialExpiresAt, basicPlanDetails }
}) => {
  let days = true;
  let trialDaysLeft = moment(trialExpiresAt).diff(moment(), 'days', false);
  if (trialDaysLeft < 1) {
    trialDaysLeft = moment(trialExpiresAt).diff(moment(), 'hours', false);
    days = false;
  }
  trialDaysLeft = trialDaysLeft <= 0 ? 0 : trialDaysLeft;
  return {
    team: team?.name || 'Loading...',
    fullname: user?.fullname?.trim().split(' ')[0] || 'User',
    photo: user?.image,
    loading,
    plan: freeTrial ? 'Pro (Free Trial)' : plan,
    daysLeft: freeTrial ? trialDaysLeft : 0,
    basicPlanDetails: plan === 'BASIC' ? basicPlanDetails : {},
    freeTrial,
    days
  };
};

export default connect(mapStateToProps)(Header);
