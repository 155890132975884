/* eslint-disable react/no-array-index-key */
import { Accordion, Badge } from '@mantine/core';
import React, { useState } from 'react';
import { ReactComponent as CorrectOption } from 'assets/images/correct-option.svg';
import { ReactComponent as Empty } from 'assets/images/empty-state.svg';
import colors from 'utils/stylesheet/colors';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const QuestionResult = ({ options, count, number, question }) => {
  const [expanded, setExpanded] = useState(-1);

  const handleChange = (value) => {
    setExpanded((prevExpanded) => (prevExpanded === value ? -1 : value));
  };

  const correctAnswer = options.findIndex((option) => option.correct);
  const noUserText = count === 0 ? 'Nobody answered the question' : 'Nobody chose this option';
  const getOptionAlphabet = (ind) => {
    switch (ind) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      default:
        return 'unknown';
    }
  };

  return (
    <div className={styles.questionResultWrapper}>
      <div className={styles.question}>
        <div className={styles.slNum}>{number}</div>
        <div className={styles.text}>{question}</div>
      </div>
      <Accordion
        classNames={{
          root: styles.AccordionRoot,
          item: styles.AccordionItem,
          control: styles.AccordionControl,
          chevron: styles.AccordionChevron,
          label: styles.AccordionLabel,
          panel: styles.AccordionPanel
        }}
        onChange={handleChange}
      >
        {options.map((option, ind) => (
          <div key={ind} className={styles.optionWrapper}>
            <div className={styles.alphabet}>{getOptionAlphabet(ind)}</div>
            <div className='accorWrapper'>
              <Accordion.Item
                key={ind}
                value={String(ind)}
                expanded={(ind === expanded).toString()}
              >
                <Accordion.Control className={styles.Control}>
                  <div className={styles.accordiann}>
                    <div className={styles.accordianWrapper}>
                      <div className={styles.accordianTop}>
                        <div className={styles.option}>
                          <div className={styles.percent}>
                            {Number.isInteger(option.percentage)
                              ? option.percentage
                              : option.percentage.toFixed(2)}
                            %
                          </div>
                          <div className={styles.optionText}>{option.value}</div>
                          {option.correct && <CorrectOption />}
                        </div>
                      </div>
                      <div>
                        <Badge color={colors.lilac} className={styles.badgeWrapper}>
                          {option.pcount} people
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Accordion.Control>
                <Accordion.Panel className={styles.expanded}>
                  <div className={styles.userNames}>
                    {option.answeredUsers.length > 0 ? (
                      option.answeredUsers.map((user) => (
                        <div className={styles.answeredUserName} key={user.name} value={user.name}>
                          {user.name}
                        </div>
                      ))
                    ) : (
                      <div className={styles.noUsers}>
                        <Empty />
                        <div>{noUserText}</div>
                      </div>
                    )}
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </div>
          </div>
        ))}
      </Accordion>
      <div className={styles.footer}>
        <p>
          <CorrectOption />
          <span>{getOptionAlphabet(correctAnswer)} - is the correct answer</span>
        </p>
        <div>{count === 0 ? 'No participation' : `${count} people answered`}</div>
      </div>
    </div>
  );
};
QuestionResult.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      answeredUsers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      ).isRequired,
      correct: PropTypes.bool.isRequired,
      pcount: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  count: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired
};

export default QuestionResult;
