/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Layout from 'components/Layout';
import Section from 'components/Section';
import { Button, Loading } from '@springrole/trivia-games';
import classNames from 'classnames';
import RestrictedAccess from 'components/RestrictedAccess';
import { useLocation } from 'react-router-dom';
import {
  useOpenCustomerPortalMutation,
  useGetPlanQuery,
  useOpenCheckoutSessionMutation,
  useUpdateChannelListMutation
} from 'app/services/payment';
import {
  useGetDropdownChannelsOptionsQuery,
  useGetCalculateUniqueUsersCountQuery
} from 'app/services/channels';
import { useSelector } from 'react-redux';
import { useGetKpiDataQuery, useGetAnalyticsOverviewQuery, initialState } from 'app/services/home';
import useWindowSize from 'utils/hooks';
import localStorage from 'utils/localStorage';
import OverviewSection from './OverviewSection';
import styles from './styles.module.scss';
import LicenseSteps from './LicenseSteps';
import ManageLicense from './LicenseSteps/ManageLicense';
import CancelSubscriptionModal from './LicenseSteps/CancelSubscriptionModal';
import AwardSection from './AwardSection/AwardSection';
import CompanySection from './CompanySection/CompanySection';
import ComparePlan from './ComparePlan';
import FAQSection from './FAQSection/FAQSection';
import ReviewSection from './ReviewsSection/ReviewSection';

const buckets = [
  [1, 25],
  [26, 50],
  [51, 100],
  [101, 250],
  [251, 499]
];
const marks = [
  { value: 0 },
  { value: 20 },
  { value: 40 },
  { value: 60 },
  { value: 80 },
  { value: 100 }
];

const getActiveIndex = (activeUsers) =>
  buckets.findIndex((a) => activeUsers >= a[0] && activeUsers <= a[1]);

const Payment = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateChannelListLoading, setUpdateChannelListLoading] = useState(false);
  const [btnPlan, setBtnPlan] = useState(null);

  const platform = localStorage.getPlatform();
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false);
  const [openManageLicenseModal, setOpenManageLicenseModal] = useState(false);
  const [isStandardProModalOpen, setIsStandardProModalOpen] = useState(false);

  const toggleStandardProModal = () => {
    setIsStandardProModalOpen(!isStandardProModalOpen);
  };
  const [activeUsers, setActiveUser] = useState(0);
  const [openCustomerPortal] = useOpenCustomerPortalMutation();
  const [openCheckoutSession] = useOpenCheckoutSessionMutation();
  const [updateChannelList] = useUpdateChannelListMutation();
  const {
    data: planResponse = { plan: '', planPrices: { TERM: 'month', STANDARD: 1, PRO: 2 } },
    isFetching: planLoading
  } = useGetPlanQuery();

  const { data: overviewData = initialState.overview } = useGetAnalyticsOverviewQuery({
    timespan: '3'
  });

  const { data: dropDownChannelsData = [], isFetching: drowDownDataLoading } =
    useGetDropdownChannelsOptionsQuery(undefined, {
      skip: !planResponse?.channelBasedBilling || !platform || platform === 'teams'
    });
  const {
    data: calculateUniqueUsersCountResponse = {
      uniqueUserIds: []
    },
    isFetching: calculatingUniqueUsersCount
  } = useGetCalculateUniqueUsersCountQuery(
    {
      selectedChannels
    },
    {
      skip: !planResponse?.channelBasedBilling || selectedChannels?.length === 0
    }
  );
  const { isFetching: kpiDataLoading, data: statsData = { participantLeftOut: [] } } =
    useGetKpiDataQuery(undefined, {
      skip: planResponse?.plan === 'PRO'
    });
  const userData = useSelector((state) => state.user);
  const bannerRef = useRef(null);
  const [assignLicenses, setAssignLicenses] = useState(false);
  const location = useLocation();
  const { width } = useWindowSize();
  const params = new URLSearchParams(location.search).get('getPro');
  const { plan, usersCount, tenspot, freeTrial, user } = userData;
  const { teamsSubscription, hasStripeSubscription, customerInfo, channelBasedBilling } =
    planResponse;
  const isTenSpot = tenspot === true;
  const usersList = useSelector((state) => state.monetization.allUsers);
  const unassignedUsers = usersList.filter((item) => !item.licensed);
  const isFreeTrial = freeTrial || false;
  const licenseCount = useSelector((state) => state.monetization.licenseCount);
  const licenseRemaining =
    Number(teamsSubscription?.quantity) - Number(licenseCount ?? teamsSubscription?.licensedCount);
  const choose = isFreeTrial || plan === 'BASIC';
  const isMonetizedBasicOrFreeTrial = platform === 'teams' && choose;

  const handlePlanSelection = async (_plan, name, email) => {
    let args;
    if (platform === 'slack') {
      args = { plan: _plan ?? undefined, name: name ?? undefined, email: email ?? undefined };
    } else {
      args = { plan: _plan };
    }
    openCheckoutSession({ ...args, selectedChannels });
  };

  useEffect(() => {
    if (planResponse?.triviaEnabledChannels?.length > 0) {
      setSelectedChannels(planResponse?.triviaEnabledChannels);
    }
  }, [planResponse?.triviaEnabledChannels]);

  useEffect(() => {
    if (usersCount) {
      setActiveUser(usersCount);
    }
  }, [usersCount]);

  const handleBuySubscription = (type) => {
    if (type === 'buy') {
      return window.open(process.env.REACT_APP_TEAMS_APP_SOURCE_URL);
    }
    if (type === 'manage') {
      return setOpenManageLicenseModal(true);
    }
    return setOpenCancelSubscriptionModal(true);
  };

  useEffect(() => {
    setActiveIndex(getActiveIndex(activeUsers));
    if (activeIndex || activeIndex === 0) {
      // eslint-disable-next-line no-param-reassign
      marks.forEach((mark) => delete mark.label); // deleting previous assinged labels
      const currentRange = buckets[activeIndex];
      if (currentRange) {
        marks[
          activeIndex
        ].label = `Your usage indicates ${currentRange[0]}-${currentRange[1]} Active Users`;
      }
    }
  }, [activeUsers, activeIndex]);

  useEffect(() => {
    if (params) {
      handlePlanSelection('PRO');
    }
  }, [params]);

  const formatDropdownChannelData = (channelsData) =>
    channelsData.map((channel) => ({
      label: `${channel?.isPrivate ? '🔒' : '#'}${channel?.name}`,
      value: channel.id
    }));

  const formatDisplayChannels = (channelList, targetIds) =>
    channelList
      .filter((channel) => targetIds?.includes(channel.id))
      .map((channel) => ({
        label: `${channel?.isPrivate ? '🔒' : '#'}${channel?.name}`,
        value: channel.id
      }));
  const [billingInfo, setBillingInfo] = useState({
    name: customerInfo?.name,
    email: customerInfo?.email
  });
  const [open, setOpen] = useState(false);
  const handleStripeRedirect = (selectedPlan) => {
    if (hasStripeSubscription) {
      openCustomerPortal();
    } else {
      handlePlanSelection(
        selectedPlan,
        billingInfo.name !== customerInfo?.name ? billingInfo.name : null,
        billingInfo.email !== customerInfo?.email ? billingInfo.email : null
      );
    }
  };

  const handleButtonClick = (selectedPlan) => {
    if (isMonetizedBasicOrFreeTrial) {
      return window.open(process.env.REACT_APP_TEAMS_APP_SOURCE_URL, '_blank');
    }

    if ((freeTrial || plan.toUpperCase() === 'BASIC') && platform === 'slack') {
      setOpen(true);
      setBtnPlan(selectedPlan);
    } else {
      handleStripeRedirect(selectedPlan);
    }
    return null;
  };

  const handleManageChannelClick = async () => {
    const { data } = await updateChannelList({ selectedChannels });
    setSelectedChannels(data?.updatedChannelList);
    setActiveUser(data?.activeUsers || 0);
    setUpdateChannelListLoading(false);
    setIsModalOpen(!isModalOpen);
  };

  if (userData.loading || kpiDataLoading || planLoading || drowDownDataLoading) {
    return (
      <Layout name='Billing'>
        <Loading height={150} width={150} />
      </Layout>
    );
  }
  if (isTenSpot) {
    return (
      <Layout name='Billing'>
        <Section>
          <RestrictedAccess
            message='Caution! This page is cordoned off'
            adminRestriction={false}
            isTenSpot={isTenSpot}
          />
        </Section>
      </Layout>
    );
  }

  if (
    platform === 'teams' &&
    teamsSubscription &&
    teamsSubscription?.status === 'Subscribed' &&
    !user.isAdmin
  ) {
    return (
      <Layout name='Billing'>
        <Section>
          <RestrictedAccess
            message='To manage Billing, you need to have admin privileges.'
            subtitle='Reach out to your admins and ask them to assign admin privileges to you.'
            adminRestriction
          />
        </Section>
      </Layout>
    );
  }
  return (
    <Layout name='Billing'>
      <div className={styles.billingLayoutWrapper}>
        <div
          className={classNames(styles.currentPlanSection, {
            [styles.customCurrentPlanSection]: ['NoSubscription', 'Unsubscribed'].includes(
              teamsSubscription?.status
            )
          })}
        >
          {openCancelSubscriptionModal && (
            <CancelSubscriptionModal
              teamsSubscription={teamsSubscription}
              openCancelSubscriptionModal={openCancelSubscriptionModal}
              setOpenCancelSubscriptionModal={setOpenCancelSubscriptionModal}
              plan={plan}
            />
          )}

          {openManageLicenseModal && (
            <ManageLicense
              // TODO: Pending
              teamsSubscription={teamsSubscription}
              handleCancelClick={() => setOpenManageLicenseModal(false)}
              licensesAssigned={Number(licenseCount ?? teamsSubscription?.licensedCount)}
              licensesRemaining={licenseRemaining}
              buttonType='Buy Licenses'
              checkOverview={0}
            />
          )}
          <div className={styles.sectionHeading}>
            {width > 420 && (
              <div>
                💳
                <span className={styles.heading}>Billing</span>
              </div>
            )}
            <div className={styles.headerButton}>
              {teamsSubscription &&
                teamsSubscription.status !== 'Suspended' &&
                !isMonetizedBasicOrFreeTrial &&
                plan !== 'BASIC' && (
                  <>
                    <Button
                      onClick={() => handleBuySubscription('manage')}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      Manage Licenses
                    </Button>
                    <Button
                      onClick={() => handleBuySubscription('cancel')}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      Cancel Subscription
                    </Button>
                  </>
                )}
            </div>

            {!teamsSubscription && plan !== 'BASIC' && !freeTrial && (
              <div className={styles.managingButtons}>
                <Button
                  onClick={() => {
                    openCustomerPortal();
                  }}
                  className={styles.slackManagePlanButton}
                >
                  Manage Plan
                </Button>
              </div>
            )}
          </div>
        </div>
        {platform === 'teams' && plan !== 'BASIC' && !isFreeTrial ? null : (
          <OverviewSection
            platform={platform}
            gamesPlayed={overviewData.gamesPlayed}
            isModalOpen={isStandardProModalOpen}
            setIsModalOpen={toggleStandardProModal}
            setUpdateChannelListLoading={setUpdateChannelListLoading}
            updateChannelListLoading={updateChannelListLoading}
            handleBuySubscription={handleBuySubscription}
            planResponse={planResponse}
            teamsSubscription={teamsSubscription}
            userData={userData}
            channels={formatDisplayChannels(dropDownChannelsData, selectedChannels)}
            assignLicenses={assignLicenses}
            handleManageChannelClick={handleManageChannelClick}
            channelBasedBilling={channelBasedBilling}
            setAssignLicenses={setAssignLicenses}
            activeUsers={activeUsers}
            uniqueUsersCount={calculateUniqueUsersCountResponse.uniqueUserIds?.length}
            selectedChannels={selectedChannels}
            usersCount={activeUsers}
            participantLeftOut={statsData.participantLeftOut}
            setSelectedChannels={setSelectedChannels}
            unassignedUsers={unassignedUsers}
            dropDownChannelOptions={formatDropdownChannelData(dropDownChannelsData)}
            isCalculatingUniqueUsersCount={calculatingUniqueUsersCount}
            toggleStandardProModal={toggleStandardProModal}
            openCustomerPortal={openCustomerPortal}
          />
        )}

        {plan !== 'BASIC' &&
          !freeTrial &&
          teamsSubscription &&
          teamsSubscription?.status === 'Subscribed' && (
            <LicenseSteps
              assignLicenses={assignLicenses}
              handleClick={() => setOpenManageLicenseModal(false)}
              setAssignLicenses={setAssignLicenses}
              teamsSubscription={teamsSubscription}
            />
          )}

        {(isMonetizedBasicOrFreeTrial || !teamsSubscription) && (
          <div className={styles.enterpriseBanner} ref={bannerRef}>
            <ComparePlan
              isMonetizedBasicOrFreeTrial={isMonetizedBasicOrFreeTrial}
              handleStripeRedirect={handleStripeRedirect}
              hasStripeSubscription={hasStripeSubscription}
              handleBuySubscription={handleBuySubscription}
              billingInfo={billingInfo}
              btnPlan={btnPlan}
              setBillingInfo={setBillingInfo}
              handleButtonClick={handleButtonClick}
              open={open}
              setOpen={setOpen}
              username={customerInfo?.name}
              useremail={customerInfo?.email}
              channelBasedBilling={channelBasedBilling}
              planResponse={planResponse}
              platform={platform}
              freeTrial={isFreeTrial}
              activeUsers={activeUsers}
              openCustomerPortal={openCustomerPortal}
              dropDownChannelOptions={formatDropdownChannelData(dropDownChannelsData)}
              setSelectedChannels={setSelectedChannels}
              selectedChannels={selectedChannels}
              uniqueUsersCount={calculateUniqueUsersCountResponse.uniqueUserIds?.length}
              isCalculatingUniqueUsersCount={calculatingUniqueUsersCount}
            />
            <AwardSection />
            <ReviewSection />
            <CompanySection />
            <FAQSection platform={platform} channelBasedBilling={channelBasedBilling} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Payment;
