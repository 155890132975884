import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';
import styles from './styles.module.scss';

const MantineCheckBox = (props) => {
  const { checked, handleChange, label, ...Props } = props;
  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      label={label}
      classNames={{
        input: styles.input
      }}
      {...Props}
    />
  );
};

MantineCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string
};

MantineCheckBox.defaultProps = {
  handleChange: () => {},
  label: ''
};

export default MantineCheckBox;
