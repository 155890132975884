import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import {
  saveKYTData,
  getPageData,
  setAllowedToView,
  setInvalidWorkspace,
  setKYTLoading
} from 'app/features/KnowYourTeamSlice';
import localStorage from 'utils/localStorage';

const platform = localStorage.getPlatform();
const baseUrl =
  platform === 'slack'
    ? process.env.REACT_APP_TRIVIA_SLACK_API
    : process.env.REACT_APP_TRIVIA_TEAMS_API;

export const KnowYourTeamApi = createApi({
  reducerPath: 'KnowYourTeamApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (header) => {
      header.set('authorization', localStorage.getToken());
      return header;
    }
  }),
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getKnowYourTeam: builder.query({
      query: (params) => ({
        url: params?.workspaceId
          ? `/know-your-team/responses/${params.workspaceId}`
          : '/know-your-team/responses',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveKYTData(response));
            dispatch(getPageData(1));
          } else if (!response.ok && response.message === 'Workspace on BASIC plan') {
            dispatch(setAllowedToView());
          } else {
            dispatch(setInvalidWorkspace());
            toast.error('Failed to get Know Your Team data');
          }
          dispatch(setKYTLoading(false));
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to fetch Know Your Team responses');
          }
          return Promise.resolve();
        }
      }
    })
  })
});

export const { useGetKnowYourTeamQuery } = KnowYourTeamApi;
