import { toast } from 'react-toastify';
import api from './api';

export const initialState = { channels: [], virtualCoffeeChannels: [], dropDownChannelsData: [] };

export const channelsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET channels endpoint
    getChannels: builder.query({
      query: (params) => ({
        url: '/channels',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.channels;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch channels info');
        }
        return initialState.channels;
      }
    }),

    getVirtualCoffeeChannels: builder.query({
      query: () => ({
        url: '/virtualcoffee/channels'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.virtualCoffeeChannels;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch virtial coffee channels');
        }
        return initialState.virtualCoffeeChannels;
      }
    }),

    // GET /channels/channel-details
    getDropdownChannelsOptions: builder.query({
      query: () => ({
        url: '/channels/channel-details'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.dropDownChannelsData;
      },
      transformErrorResponse: (response) => {
        if (![401, 404].includes(response.status)) {
          toast.error('Failed to fetch drop down channel data');
        }
        return initialState.dropDownChannelsData;
      }
    }),

    // GET /channels/calculate-unique-users-count
    getCalculateUniqueUsersCount: builder.query({
      query: (params) => ({
        url: '/channels/calculate-unique-users-count',
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return {};
      },
      transformErrorResponse: (response) => {
        if (![401, 404].includes(response.status)) {
          toast.error('Failed to calculate unique users count');
        }
        return {};
      }
    })
  })
});

export const {
  useGetChannelsQuery,
  useGetVirtualCoffeeChannelsQuery,
  useGetDropdownChannelsOptionsQuery,
  useGetCalculateUniqueUsersCountQuery
} = channelsApi;
