import React from 'react';
import { Pagination as MantinePagination } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Pagination = (props) => {
  const { activePage, setPage, total, siblings = 1 } = props;
  return (
    <MantinePagination
      classNames={{ item: styles.item }}
      page={activePage}
      siblings={siblings}
      onChange={setPage}
      total={total}
    />
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  siblings: PropTypes.number
};

Pagination.defaultProps = {
  siblings: 1
};

export default Pagination;
