import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TriviaSpringworks } from 'assets/VirtualCoffee/trivia-logo-large.svg';
import { ReactComponent as HotCoffee } from 'assets/VirtualCoffee/hot-coffee-small.svg';
import { Button } from '@springrole/trivia-games';
import { Menu } from '@mantine/core';
import Tag from 'components/Tag';
import { connect } from 'react-redux';
import { useLogoutMutation } from 'app/services/user';
import Avatar from 'assets/images/avatar.svg';
import DotSeparator from 'components/DotSeparator';
import styles from './Header.module.scss';

const Header = (props) => {
  const { team, photo, fullname, plan, onCall } = props;
  const [showMenu, setShownMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutUser] = useLogoutMutation();

  return (
    <div className={styles.pageHeader} data-testid='header'>
      {onCall ? (
        <div className={styles.virtualCoffeeWrapper}>
          <TriviaSpringworks className={styles.triviaIcon} />
          <div className={styles.virtualCoffee}>
            <HotCoffee className={styles.hotCoffee} />
            <div className={styles.text}>VirtualCoffee</div>
          </div>
        </div>
      ) : (
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <TriviaSpringworks />
            <div className={styles.companyWrapper}>
              <div className={styles.company}>{team}</div>
              <DotSeparator size='large' />
              <Tag
                type='plan'
                text={plan || '...'}
                style={{
                  fontSize: '11px',
                  lineHeight: '16px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#2A00A2',
                  letterSpacing: '0.75px',
                  padding: '0.25rem 1rem',
                  maxWidth: '150px'
                }}
              />
            </div>
          </div>
          <div className={styles.userWrapper} data-testid='user-wrapper'>
            <Menu className={styles.user} onClick={() => setShownMenu(!showMenu)}>
              <Menu.Target>
                <Button
                  onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  style={{ textTransform: 'none' }}
                >
                  <img
                    src={photo || Avatar}
                    className={styles.avatar}
                    alt={fullname}
                    height={40}
                    width={40}
                  />
                  <span>{fullname}</span>
                  <div className={`${styles.arrow} ${styles.down}`} />
                </Button>
              </Menu.Target>

              <Menu.Dropdown
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={styles.styledMenu}
              >
                <Menu.Item className={styles.avatarWrapper}>
                  <img src={photo || Avatar} className={styles.avatar} alt={fullname} />
                  <span>{fullname}</span>
                  <div className={`${styles.arrow} ${styles.up}`} />
                </Menu.Item>
                <Menu.Item onClick={logoutUser}>Logout</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  team: PropTypes.string,
  photo: PropTypes.string,
  fullname: PropTypes.string.isRequired,
  plan: PropTypes.string,
  onCall: PropTypes.bool.isRequired
};

Header.defaultProps = {
  photo: '',
  team: 'Trivia',
  plan: 'Pro (Free Trial)'
};

const mapStateToProps = ({ user: { team, user, plan } }) => {
  const name = user?.fullname?.trim().split(' ')[0] || 'User';
  return {
    team: team?.name || 'Loading...',
    fullname: name,
    photo: user?.image,
    plan: plan || 'Pro (Free Trial)'
  };
};

export default connect(mapStateToProps)(Header);
