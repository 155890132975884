import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@springrole/trivia-games';
import LimitReached from 'components/LimitReached';
import { ReactComponent as Coffee } from 'assets/images/home/coffee.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const VcLimits = (props) => {
  const { leftOut } = props;
  return (
    <Section
      title="You're reaching VirtualCoffee limits"
      className={styles.container}
      headerClass={styles.headerWrapper}
    >
      <div className={styles.coffeeImage}>
        <Coffee data-testid='coffee-svg' />
      </div>

      <LimitReached
        message={
          <>
            <b>
              {leftOut} {maybePluralize(leftOut, 'teammate')}
            </b>{' '}
            were not included in the last <b>20 rounds</b> of VirtualCoffee due to plan limits.
            Upgrade now to improve team engagement
          </>
        }
      />
    </Section>
  );
};

VcLimits.propTypes = {
  leftOut: PropTypes.number.isRequired
};

export default VcLimits;
