import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Section = (props) => {
  const { type, size, className, style, title, onAction, actionText, noBorder, children } = props;

  return (
    <div
      className={classNames(styles.sectionWrapper, styles[type], styles[size], className)}
      style={style}
      data-testid='Section-id'
    >
      {title ? (
        <div className={styles.sectionTitleWrapper}>
          <div className={styles.sectionTitle}>{title}</div>
          {onAction && (
            <div className={styles.sectionAction} onClick={onAction}>
              {actionText}
            </div>
          )}
        </div>
      ) : (
        <div
          className={classNames({
            [styles.sectionTitleWrapperNoBorder]: noBorder,
            [styles.sectionTitleWrapper]: !noBorder
          })}
        >
          {onAction && (
            <div className={styles.sectionAction} onClick={onAction}>
              {actionText}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

Section.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['fixed', 'overflow']),
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onAction: PropTypes.func,
  style: PropTypes.shape(),
  className: PropTypes.string,
  noBorder: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

Section.defaultProps = {
  size: 'medium',
  type: 'overflow',
  style: {},
  onAction: null,
  className: null,
  actionText: '',
  noBorder: false
};

export default Section;
