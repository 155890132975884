import React, { useState, useEffect } from 'react';
import { Link as LinkTag } from '@springrole/trivia-games';
import Tag from 'components/Tag';
import localStorage from 'utils/localStorage';
import { serializeSlackChannels } from 'utils/serialization';
import { ReactComponent as Clock } from 'assets/images/clock.svg';
import { ReactComponent as Edit } from 'assets/images/pencil.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import timezones from 'data/timezones';
import CustomTooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import AccessRestrictedModal from 'components/AccessRestrictedModal';
import { useGetChannelsQuery } from 'app/services/channels';
import { useGetTeamsQuery } from 'app/services/teams';
import {
  useAddQuestionMutation,
  customQuizApi,
  useCompleteQuizMutation,
  useEditQuestionMutation,
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useUpdateScheduleMutation
} from 'app/services/custom-quiz';
import QuizModal from './QuizModal';
import { Complete, Question, ManageSchedules, Schedule } from './modal-content';
import getQuestionData from './getQuestionData';
import styles from './styles.module.scss';

const defaultTimezone = 'Asia/Kolkata';
const types = {
  manage: 'manage-schedules',
  complete: 'complete-quiz',
  newSchedule: 'new-schedule'
};

const Quiz = (props) => {
  const dispatch = useDispatch();
  const {
    isPaidUser,
    name,
    onDelete,
    hasSchedules,
    schedules,
    incomplete,
    creator,
    id,
    isAllowedToStart,
    contentLoading,
    teamId
  } = props;
  const [formData, setFormData] = useState({
    type: 'new-quiz',
    open: false,
    loading: false,
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    correct: '0',
    num: 1,
    edit: false,
    date: moment().toDate(),
    time: moment().add(15, 'minutes').toDate(),
    selectedChannel: null,
    selectedTeam: null,
    selectedTimezone: defaultTimezone,
    editSchedule: false,
    selectedSchedule: null
  });

  const [questions, setQuestions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const platform = localStorage.getPlatform();
  const { data: channels = [], isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: !platform || platform === 'teams'
  });
  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery(undefined, {
    skip: !platform || platform === 'slack'
  });
  const findChannelPrivateOrPublic = (channelId) => {
    const selectedChannel = channels.find((channel) => channel.id === channelId);
    return selectedChannel?.isPrivate ? '🔒' : '#';
  };
  useEffect(() => {
    if (platform === 'slack') {
      setChannelOptions(
        serializeSlackChannels(channels)?.map((channel) => ({
          name: channel.label,
          id: channel.value
        }))
      );
    } else {
      setChannelOptions(teams.filter((team) => !team.isGroupChat));
    }
  }, [channelLoading, teamsLoading]);

  const [addQuestion] = useAddQuestionMutation();
  const [completeQuiz] = useCompleteQuizMutation();
  const [editQuestion] = useEditQuestionMutation();
  const [createSchedule] = useCreateScheduleMutation();
  const [deleteSchedule] = useDeleteScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();

  useEffect(() => {
    const time = moment().add(5 - (moment().minute() % 5), 'minutes');
    setFormData((form) => ({ ...form, time }));
  }, []);

  const onConfirmCreation = async () => {
    if (questions.every((q) => q.question !== 'null')) {
      const response = await completeQuiz({ quizId: id }).unwrap();

      if (response.ok) {
        toast.success('Successfully created custom quiz.');
        setFormData((form) => ({ ...form, type: 'new-quiz', open: false }));
      }
    } else {
      toast.error('Please add all questions.');
    }
  };

  const onSaveSchedule = async () => {
    const {
      selectedChannel,
      selectedTimezone,
      editSchedule,
      selectedSchedule,
      date,
      time,
      selectedTeam
    } = formData;
    if (!selectedChannel || !selectedTimezone) {
      toast.error('Please enter all details.');
      return;
    }
    if (moment(date).isBefore(moment(), 'day')) {
      toast.error('Please enter future date');
      return;
    }
    if (!moment(date).isAfter(moment(), 'day') && moment(time).isBefore(moment())) {
      toast.error('Please enter a future time.');
      return;
    }
    const getChannelLabelById = (Channels, selectedChanne) => {
      const channel = Channels.find((Channel) => Channel.id === selectedChanne);
      return channel?.name;
    };
    const scheduleData = {
      catName: name,
      channel: selectedChannel?.id || selectedChannel,
      date: moment(date).format('YYYY-MM-DD'),
      time: moment(time).format('hh:mm a'),
      timezone: selectedTimezone,
      channelName: selectedChannel.name || getChannelLabelById(channels, selectedChannel),
      platform: localStorage.getPlatform(),
      teamId: platform !== 'slack' ? selectedTeam : selectedTeam?.id ?? teamId.id ?? teamId,
      teamName: getChannelLabelById(teams, selectedTeam)
    };
    try {
      let resp;
      if (editSchedule) {
        resp = await updateSchedule({
          ...scheduleData,
          scheduleId: selectedSchedule?.scheduleId
        }).unwrap();
      } else {
        resp = await createSchedule(scheduleData).unwrap();
      }
      if (resp.ok) {
        setFormData((form) => ({
          ...form,
          type: types.manage,
          date: moment().toDate(),
          time: moment().add(5, 'minutes').toDate(),
          selectedChannel: null,
          selectedTimezone: defaultTimezone,
          selectedSchedule: null,
          selectedTeam: null,
          editSchedule: false
        }));
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const addNewQuestion = async () => {
    const { edit, num } = formData;
    const data = getQuestionData(questions, formData);
    if (
      data.question === 'null' ||
      _.isEmpty(data.question?.trim()) ||
      _.isEmpty(data.option1?.trim()) ||
      _.isEmpty(data.option2?.trim())
    ) {
      toast.error(
        'Please add question and all answers before saving and make sure none of them are empty white spaces1.'
      );
    } else if (_.uniq(data.optionsArray).length !== data.optionsArray.length) {
      toast.error('One or more options are duplicate. Please try giving different option values.');
    } else if (_.isEmpty(data.option3) && data.correct === '3') {
      toast.error('Option 3 Cannot be empty if that is the correct option.');
    } else if (
      (data.correct === '4' && _.isEmpty(data.option4)) ||
      (_.isEmpty(data.option3) && !_.isEmpty(data.option4))
    ) {
      if (_.isEmpty(data.option3)) {
        toast.error('Please provide all the preceeding options first!');
      } else {
        toast.error('Option 4 Cannot be empty if that is the correct option.');
      }
    } else if (
      (data.correct === '5' && _.isEmpty(data.option5)) ||
      (!_.isEmpty(data.option5) && (_.isEmpty(data.option3) || _.isEmpty(data.option4)))
    ) {
      if (_.isEmpty(data.option3) || _.isEmpty(data.option4)) {
        toast.error('Please provide all the preceeding options first!');
      } else {
        toast.error('Option 5 Cannot be empty if that is the correct option.');
      }
    } else {
      const answers = [
        { value: data.option1.trim(), correct: data.correct === '1' },
        { value: data.option2.trim(), correct: data.correct === '2' }
      ];

      if (data.option3 && !_.isEmpty(data.option3.trim())) {
        answers.push({ value: data.option3.trim(), correct: data.correct === '3' });
      }
      if (data.option4 && !_.isEmpty(data.option4.trim())) {
        answers.push({ value: data.option4.trim(), correct: data.correct === '4' });
      }
      if (data.option5 && !_.isEmpty(data.option5.trim())) {
        answers.push({ value: data.option5.trim(), correct: data.correct === '5' });
      }

      let response;
      if (edit) {
        response = await editQuestion({
          question: data.question.trim(),
          answers,
          id: data.questionId,
          confirmedQuiz: true
        }).unwrap();
      } else {
        response = await addQuestion({
          question: data.question.trim(),
          answers,
          num,
          id,
          complete: false
        }).unwrap();
      }
      if (response?.ok) {
        const questionsTemp = [...questions];
        questionsTemp[num - 1] = {
          ...questionsTemp[num - 1],
          question: data.question.trim(),
          answers
        };

        setQuestions(questionsTemp);
      }
      setFormData((form) => ({
        ...form,
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: '',
        correct: '0',
        type: edit ? 'edit' : types.complete
      }));
    }
  };

  const handleChange = (field, value) => {
    if (field === 'selectedTeam' && value === null) {
      setFormData((form) => ({ ...form, selectedChannel: null }));
    } else if (field === 'time') {
      const formattedTime = moment(value, 'HH:mm');
      setFormData((form) => ({ ...form, [field]: formattedTime }));
    } else {
      setFormData((form) => ({ ...form, [field]: value }));
    }
  };

  const getModalContent = () => {
    const {
      type,
      loading,
      edit,
      editSchedule,
      date,
      time,
      selectedChannel,
      selectedTeam,
      selectedTimezone
    } = formData;

    if (type === 'delete') {
      return {
        title: `Delete ${name}`,
        children: 'Are you sure you want to go ahead and delete the quiz?',
        confirmText: 'Delete',
        onConfirm: onDelete,
        size: 'sm',
        showActions: true,
        dangerAction: true,
        contentLoading
      };
    }
    if (type === types.complete) {
      return {
        title: name,
        children: (
          <Complete
            name={name}
            questions={questions}
            questionsCount={questions.length}
            onQuestionClick={(num) => setFormData((form) => ({ ...form, type: 'question', num }))}
          />
        ),
        showActions: true,
        confirmText: 'Submit',
        onConfirm: onConfirmCreation,
        size: 'sm',
        contentLoading: loading
      };
    }
    if (type === 'question') {
      const data = getQuestionData(questions, formData);
      return {
        title: name,
        showActions: true,
        confirmText: 'Save',
        onConfirm: addNewQuestion,
        onCancel: () =>
          setFormData((form) => ({
            ...form,
            question: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: '',
            correct: '0',
            type: edit ? 'edit' : types.complete
          })),

        size: 'md',
        children: (
          <Question
            question={data.question}
            option1={data.option1}
            option2={data.option2}
            option3={data.option3}
            option4={data.option4}
            option5={data.option5}
            correct={data.correct}
            showFifth={isPaidUser || !_.isEmpty(data.option5)}
            onChange={(qName, value) => setFormData((form) => ({ ...form, [qName]: value }))}
          />
        )
      };
    }
    if (type === 'edit') {
      return {
        title: name,
        children: (
          <Complete
            name={name}
            questions={questions}
            questionsCount={questions.length}
            onQuestionClick={(num) => setFormData((form) => ({ ...form, type: 'question', num }))}
          />
        ),
        showActions: !edit,
        size: 'md',
        contentLoading: loading,
        onCancel: () =>
          setFormData((form) => ({ ...form, type: 'new-quiz', open: false, edit: false }))
      };
    }

    if (type === types.newSchedule) {
      return {
        title: `${editSchedule ? 'Edit' : 'Create'} schedule for ${name}`,
        size: 'sm',
        showActions: true,
        confirmText: 'Save',
        onConfirm: onSaveSchedule,
        children: (
          <Schedule
            hasNewSchedules={!hasSchedules}
            edit={editSchedule}
            date={date}
            time={time}
            handleChange={handleChange}
            selectedChannel={selectedChannel}
            selectedTeam={selectedTeam}
            selectedTimezone={selectedTimezone}
            channels={channelOptions}
            goBack={() =>
              setFormData((form) => ({
                ...form,
                type: types.manage,
                date: moment().toDate(),
                time: moment().add(15, 'minutes').toDate(),
                selectedChannel: null,
                selectedTimezone: defaultTimezone,
                selectedSchedule: null,
                selectedTeam: null,
                editSchedule: false
              }))
            }
          />
        )
      };
    }
    if (type === types.manage) {
      return {
        title: `${name}`,
        size: 'sm',
        showActions: false,
        showConfirm: false,
        showCancelButton: false,
        children: (
          <ManageSchedules
            handleAddNew={() => setFormData((form) => ({ ...form, type: types.newSchedule }))}
            schedules={schedules}
            findChannelPrivateOrPublic={findChannelPrivateOrPublic}
            handleEditSchedule={(schedule) => {
              setFormData((form) => ({
                ...form,
                type: types.newSchedule,
                editSchedule: true,
                selectedSchedule: schedule,
                date: momentTz.tz(schedule.time, schedule.timezone),
                time: momentTz.tz(schedule.time, schedule.timezone),
                selectedChannel: { id: schedule.channel, name: schedule.channelName },
                selectedTeam: { id: schedule.teamId, name: schedule.teamName },
                selectedTimezone: timezones.find((tz) => tz.name === schedule.timezone)?.name
              }));
            }}
            handleDeleteSchedule={async (schedule) => {
              try {
                const response = await deleteSchedule({
                  scheduleId: schedule.scheduleId,
                  catName: name
                }).unwrap();
                if (response.ok) {
                  toast.success('Schedule deleted successfully.');
                }
              } catch (e) {
                toast.error(e.data.message);
              }
            }}
          />
        )
      };
    }
    return null;
  };

  const handleCompleteQuiz = async (quizId) => {
    const response = await dispatch(
      customQuizApi.endpoints.getIncompleteQuiz.initiate({ id: quizId }, { forceRefetch: true })
    );
    setQuestions(response.data);
    setFormData((form) => ({ ...form, type: types.complete, open: true, loading: false }));
  };

  const handleSchedule = (first) => {
    if (channelOptions?.length === 0) {
      toast.error('Please add Trivia to atleast one team/channel to schedule the custom quiz.');
    } else {
      setFormData((form) => ({
        ...form,
        type: first ? types.newSchedule : types.manage,
        open: true
      }));
    }
  };

  const showHash = localStorage.getPlatform() === 'slack';
  const time =
    hasSchedules && momentTz.tz(schedules[0].time, schedules[0].timezone).format('hh:mm a');
  const date =
    hasSchedules &&
    momentTz.tz(schedules[0].time, schedules[0].timezone).format('ddd, DD MMM YYYY');
  const channel = hasSchedules && (schedules[0].channelName || schedules[0].channel);
  const team = hasSchedules && schedules[0].teamName;
  const { open } = formData;

  return (
    <>
      <div className={styles.quizContainer}>
        <div className={styles.left}>
          <div className={styles.quizName}>
            {name} {incomplete && <Tag type='red' text='Incomplete' />}
          </div>
          <div className={styles.createdBy}>
            Created by <span>{creator}</span>{' '}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            {incomplete && (
              <LinkTag
                style={{ margin: 0, fontSize: '12px', lineHeight: '15px' }}
                onClick={() => handleCompleteQuiz(id, name)}
              >
                Complete Quiz
              </LinkTag>
            )}
            {!incomplete && !hasSchedules && (
              <LinkTag
                style={{ margin: 0, fontSize: '12px', lineHeight: '15px' }}
                onClick={() => handleSchedule(true)}
              >
                Create Schedule
              </LinkTag>
            )}
            {!incomplete && hasSchedules && (
              <CustomTooltip label='Manage Schedules'>
                <Clock className={styles.action} onClick={() => handleSchedule(false)} />
              </CustomTooltip>
            )}
            {!incomplete && (
              <CustomTooltip label='Edit Quiz'>
                <Edit
                  className={styles.action}
                  onClick={async () => {
                    const response = await dispatch(
                      customQuizApi.endpoints.getQuiz.initiate({ name, id }, { forceRefetch: true })
                    );
                    if (response?.data?.ok) {
                      setQuestions(response.data.questions);
                      setFormData((form) => ({ ...form, type: 'edit', open: true, edit: true }));
                    }
                  }}
                />
              </CustomTooltip>
            )}
            <CustomTooltip label='Delete Quiz'>
              <Delete
                className={styles.action}
                onClick={() => setFormData((form) => ({ ...form, type: 'delete', open: true }))}
              />
            </CustomTooltip>
          </div>
          {hasSchedules && (
            <div className={styles.bottom}>
              <div className={styles.dateTime}>
                <div className={styles.label}>Upcoming schedule</div>
                <div style={{ display: 'inline' }}>
                  <span className={styles.time}>{time}</span>
                  <span className={styles.date}>{date}</span>
                </div>
              </div>
              <div className={styles.channel}>
                <span className={styles.label}>Channel</span>
                {showHash && findChannelPrivateOrPublic(schedules[0].channel)}
                {!showHash && team && `${team} - `}
                {channel}
              </div>
            </div>
          )}
        </div>
      </div>
      {open &&
        (isAllowedToStart || localStorage.getPlatform() === 'teams' ? (
          <QuizModal
            open={open}
            onCancel={() => {
              setFormData((form) => ({
                ...form,
                type: 'new-quiz',
                open: false,
                date: moment().toDate(),
                time: moment().add(15, 'minutes').toDate(),
                selectedChannel: null,
                selectedTimezone: defaultTimezone,
                selectedSchedule: null,
                selectedTeam: null,
                editSchedule: false
              }));
            }}
            {...getModalContent()}
          />
        ) : (
          <AccessRestrictedModal
            open={open}
            automationRestriction={false}
            onCancel={() => setFormData((form) => ({ ...form, open: false }))}
          />
        ))}
    </>
  );
};

Quiz.propTypes = {
  AddQuestion: PropTypes.func,
  id: PropTypes.string.isRequired,
  onQuestionAdded: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      value: PropTypes.string
    })
  ),
  CompleteQuiz: PropTypes.func,
  onCancel: PropTypes.func,
  ResetQuizData: PropTypes.func,
  type: PropTypes.string,
  catName: PropTypes.string,
  qCount: PropTypes.number,
  contentLoading: PropTypes.bool,
  onQuestionClick: PropTypes.func,
  isPaidUser: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  name: PropTypes.string.isRequired,
  ModifySchedule: PropTypes.func,
  SaveSchedule: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  hasSchedules: PropTypes.bool.isRequired,
  DeleteSchedule: PropTypes.func,
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  incomplete: PropTypes.bool.isRequired,
  creator: PropTypes.string.isRequired,
  EditQuestion: PropTypes.func,
  teamId: PropTypes.string,
  isAllowedToStart: PropTypes.bool
};

Quiz.defaultProps = {
  type: 'new-quiz',
  catName: '',
  qCount: 0,
  contentLoading: false,
  teamId: '',
  AddQuestion: () => null,
  onQuestionAdded: () => null,
  questions: [],
  CompleteQuiz: () => null,
  onCancel: () => null,
  ResetQuizData: () => null,
  onQuestionClick: () => null,
  onConfirm: () => null,
  ModifySchedule: () => null,
  SaveSchedule: () => null,
  DeleteSchedule: () => null,
  EditQuestion: () => null,
  isAllowedToStart: false
};

const mapStateToProps = ({ user }) => ({
  teamId: user?.team?.id,
  isAllowedToStart: user.user?.isAllowedToStart
});

export default connect(mapStateToProps)(Quiz);
