import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { difficultiesOptions } from 'data/dropdown';
import momentTz from 'moment-timezone';
import moment from 'moment';
import days from 'data/days';
import gameFormats, { selectGameOptions } from 'data/formats';
import localStorage from 'utils/localStorage';
import { serializeSlackChannels, serializeTeamsChannels } from 'utils/serialization';
import { timezonesForMantineComponent as timezones, toTzValue, toTzString } from 'data/timezones';
import { useGetChannelsQuery } from 'app/services/channels';
import { useGetTeamsQuery } from 'app/services/teams';
import { useSelector } from 'react-redux';
import AccessRestrictedModal from 'components/AccessRestrictedModal';
import { useAddAutomationMutation, useUpdateAutomationMutation } from 'app/services/home';
import gameFormatForTeams from 'data/format-teams';
import { useGetCategoriesQuery } from 'app/services/categories';
import Modal from './Form';

const AutomationModal = ({ open, edit, isKytForm, formValues, handleCancel }) => {
  const [channelOptions, setChannelOptions] = useState([]);
  const platform = localStorage.getPlatform();
  const isAllowedToHandleAutomations = useSelector((state) => state.user.user.isAllowedToStart);
  const { data: channels = [], isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: !platform || platform === 'teams'
  });
  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery(undefined, {
    skip: !platform || platform === 'slack'
  });

  const types = () => {
    if (formValues?.types?.includes('Surprise Me!')) {
      return ['random'];
    }
    if (platform === 'slack') {
      return gameFormats
        .filter((format) => formValues?.types?.includes(format.label))
        .map((format) => format.value);
    }
    return gameFormatForTeams
      .filter((format) => formValues?.types?.includes(format.label))
      .map((format) => format.value);
  };

  useEffect(() => {
    if (platform === 'slack') {
      setChannelOptions(serializeSlackChannels(channels));
    } else {
      setChannelOptions(serializeTeamsChannels(teams));
    }
  }, [channelLoading, teamsLoading]);

  const { data: categoriesData = [] } = useGetCategoriesQuery();
  const [addAutomation, { isLoading: isAdding }] = useAddAutomationMutation();
  const [updateAutomation, { isLoading: isUpdating }] = useUpdateAutomationMutation();

  const [formData, setFormData] = useState({
    id: edit ? formValues.id : null,
    gameTypes: (edit && types()) || (isKytForm ? ['kyt'] : ['random']), // default set to surprise me
    channel: edit ? formValues.channel.id : channelOptions[0]?.value ?? null, // default set to first channel in the channels array and null when trivia is not added in any channel
    frequency: edit ? formValues.frequency : ['Monday', 'Wednesday', 'Friday'], // default set to monday, wednesday and friday
    time: edit
      ? moment(formValues.time, 'h:mm a').toDate()
      : moment('4:30 pm', 'hh:mm a')
          .add(5 * Math.floor(Math.random() * 13), 'minutes')
          .toDate(), // default time is between 4:30 pm and 5:30 pm
    timezone: edit ? toTzValue(formValues.timezone) : toTzValue(momentTz.tz.guess()), // default set to user's browser timezone
    category: edit ? formValues?.category?.map((cat) => cat.id) : null,
    difficulty: edit ? formValues?.difficulty : null,
    team: edit ? formValues?.team : channelOptions[0]?.teamId ?? null
  });
  const [errorState, setErrorState] = useState({
    category: ''
  });

  useEffect(() => {
    setFormData((prevValues) => ({
      ...prevValues,
      channel: edit ? formValues.channel.id : channelOptions[0]?.value ?? null,
      team: edit ? formValues?.team : channelOptions[0]?.teamId ?? null
    }));
  }, [channelOptions]);

  const handleChange = (field, value) => {
    if (field === 'category' && value.length > 5) {
      setErrorState((prevValues) => ({
        ...prevValues,
        category: 'You can not select more than 5 categories'
      }));
      return;
    }
    if (field === 'category' && value.length <= 5) {
      setErrorState((prevValues) => ({ ...prevValues, category: '' }));
    }
    setFormData((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!['selfPacedQuiz', 'trivia'].some((type) => formData.gameTypes.includes(type))) {
      delete formData.category;
      delete formData.difficulty;
    }
    const data = { ...formData };
    if (!data.id) {
      delete data.id;
    }
    if (!data.category) {
      delete data.category;
    }
    if (!data.difficulty) {
      delete data.difficulty;
    }
    if (!data.team) {
      delete data.team;
    }
    if (data.timezone) {
      data.timezone = toTzString(data.timezone);
    }
    if (data.time) {
      data.time = moment(data.time).format('h:mm a');
    }
    const selectedChannel = channelOptions.find((channel) => channel.value === formData.channel);
    if (data.channel) {
      data.channel = { name: selectedChannel.label.replace(/#|🔒/, ''), id: selectedChannel.value };
    }
    if (platform === 'teams' && selectedChannel?.group !== 'Group chats') {
      data.team = { name: selectedChannel?.group, id: selectedChannel?.teamId };
    }
    let response;
    if (edit) {
      response = await updateAutomation({ ...data, id: data.id });
    } else {
      response = await addAutomation(data);
    }
    if (!response.error) {
      handleCancel();
    }
  };

  if (platform === 'slack' && !isAllowedToHandleAutomations) {
    return <AccessRestrictedModal open={open} onCancel={handleCancel} />;
  }
  return (
    <Modal
      open={open}
      edit={edit}
      channels={channelOptions}
      categories={categoriesData.map((category) => ({
        label: category.name,
        value: category.id
      }))}
      isKytForm={isKytForm}
      timezones={timezones}
      difficulties={difficultiesOptions}
      days={days}
      gameFormats={platform === 'slack' ? gameFormats : gameFormatForTeams}
      selectGameOptions={selectGameOptions}
      formData={formData}
      errorState={errorState}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      disableConfirm={isAdding || isUpdating}
    />
  );
};

AutomationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  isKytForm: PropTypes.bool,
  formValues: PropTypes.shape({
    id: PropTypes.string,
    gameType: PropTypes.string,
    channel: PropTypes.shape({
      id: PropTypes.string,
      isPrivate: PropTypes.bool,
      name: PropTypes.string,
      users: PropTypes.arrayOf(PropTypes.string),
      usersCount: PropTypes.number
    }),
    frequency: PropTypes.arrayOf(String),
    time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    timezone: PropTypes.string,
    category: PropTypes.arrayOf(String),
    difficulty: PropTypes.string,
    types: PropTypes.arrayOf(String),
    team: PropTypes.shape({ name: PropTypes.string.isRequired, id: PropTypes.string.isRequired })
  }),
  handleCancel: PropTypes.func.isRequired
};

AutomationModal.defaultProps = {
  edit: false,
  formValues: {},
  isKytForm: false
};

export default AutomationModal;
