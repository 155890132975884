import React from 'react';
import PropTypes from 'prop-types';
import Charts from 'components/Charts';
import { Loading, Section } from '@springrole/trivia-games';
import { useGetTrendsQuery, useGetAnalyticsOverviewQuery } from 'app/services/home';
import LimitReached from 'components/LimitReached';
import useWindowSize from 'utils/hooks';
import EmptyState from 'assets/images/home/chart-emptystate.png';
import EmptyStateFooter from 'components/EmptyStateFooter';
import classNames from 'classnames';
import localStorage from 'utils/localStorage';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const Trend = ({ type }) => {
  const { width } = useWindowSize();
  const platform = localStorage.getPlatform();
  const { data: trend, isFetching: trendLoading } = useGetTrendsQuery();
  const { data: overview, isFetching: overviewLoading } = useGetAnalyticsOverviewQuery();
  let allowedPlayers = [];
  let restrictedPlayers = [];
  let gamesPlayed = 0;
  let noRestrictedPlayers = [];
  let maxStepSize = 5;
  let scales = {};

  if (trend && !trendLoading) {
    allowedPlayers =
      width < 650
        ? trend.players.map((item) => item.allowed).slice(-6)
        : trend.players.map((item) => item.allowed);
    restrictedPlayers =
      width < 650
        ? trend.players.map((item) => item.restricted).slice(-6)
        : trend.players.map((item) => item.restricted);
    noRestrictedPlayers = trend.players.every((item) => item.restricted === 0);
    maxStepSize = Math.round((Math.max(...allowedPlayers) + Math.max(...restrictedPlayers)) / 5);
    scales = {
      x: {
        stacked: type === 'restricted',
        grid: {
          display: false,
          color: '#A192F5',
          drawTicks: false
        },
        border: {
          display: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)'
        },
        title: {
          display: true,
          text: 'GAME',
          color: '#FFFFFF',
          font: { weight: '700', size: 14 },
          padding: {
            top: 10
          }
        }
      },
      y: {
        stacked: type === 'restricted',
        grid: {
          color: '#A192F5',
          drawTicks: false
        },
        border: {
          dash: [10, 10],
          display: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
          precision: 0, // ensures no decimal points
          stepSize: type === 'restricted' && noRestrictedPlayers ? trend.limit : maxStepSize
        },
        title: {
          display: true,
          text: 'PLAYERS',
          color: '#FFFFFF',
          font: { weight: '700', size: 14 },
          padding: {
            bottom: 10
          }
        }
      }
    };
  }
  if (overview && !overviewLoading) {
    gamesPlayed = overview.gamesPlayed;
  }

  const datasets =
    type === 'restricted'
      ? [
          {
            label: 'Allowed players',
            data: allowedPlayers,
            backgroundColor: '#a192f5'
          },
          {
            label: 'Restricted players',
            data: restrictedPlayers,
            backgroundColor: '#CA4949'
          }
        ]
      : [
          {
            label: 'Allowed players',
            data: allowedPlayers,
            backgroundColor: '#a192f5'
          }
        ];

  const handleClick = () => {
    if (platform === 'teams') {
      return window.open(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`,
        '_blank'
      );
    }

    return window.open(
      `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=gamesPage&key=${localStorage.getToken()}`,
      '_blank'
    );
  };

  return (
    <Section
      title={type === 'restricted' ? 'Restricted players trend' : 'Engagement trends'}
      className={classNames(styles.wrapper, { [styles.emptyStateWrapper]: !gamesPlayed })}
      headerClass={styles.header}
    >
      {trendLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          {!gamesPlayed ? (
            <img className={styles.emptyStateImage} src={EmptyState} alt='' />
          ) : (
            <>
              {type === 'restricted' && trend.leftOut > 0 && (
                <LimitReached
                  message={
                    <>
                      <b>
                        {trend.leftOut} {maybePluralize(trend.leftOut, 'teammate')}
                      </b>{' '}
                      couldn&apos;t play in the past <b>20 Games</b>
                      {platform === 'teams'
                        ? `as they did not have Licenses. Buy and assign more Licenses to improve team engagement`
                        : `due to plan limits. Upgrade now
                      to improve team engagement.`}
                    </>
                  }
                  homeTrend
                />
              )}

              <div>
                <Charts
                  type='bar'
                  labels={
                    width < 650
                      ? ['1', '2', '3', '4', '5', '6']
                      : [
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20'
                        ]
                  }
                  datasets={datasets}
                  options={{
                    interaction: {
                      mode: 'index'
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                        display: type !== 'engagement',
                        labels: {
                          color: '#F7FAFC'
                        }
                      },
                      tooltip: {
                        xAlign: 'center',
                        yAlign: 'bottom',
                        backgroundColor: '#FFFFFF',
                        bodyColor: '#000',
                        displayColors: type === 'restricted',
                        titleColor: '#000',
                        callbacks: {
                          label(context) {
                            let label;

                            if (type === 'restricted') {
                              label = context.dataset.label;
                            } else {
                              label = 'Played';
                            }

                            if (label) {
                              label += ': ';
                            }
                            if (context.parsed.y !== null) {
                              label += `${context.parsed.y}`;
                            }
                            return label;
                          },
                          title: () => null
                        }
                      }
                    },
                    scales,
                    barThickness: 20,
                    maintainAspectRatio: false
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}

      {!gamesPlayed && (
        <EmptyStateFooter
          content='Visualize trends in engagement rate when you start playing Trivia Games'
          action='Start a game'
          handleClick={handleClick}
        />
      )}
    </Section>
  );
};

Trend.propTypes = {
  type: PropTypes.string.isRequired
};

export default Trend;
