import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ReactComponent as Cracker } from 'assets/images/cracker.svg';
import Avatar from 'assets/images/avatar.svg';
import { Grid } from '@mantine/core';
import classNames from 'classnames';

const GameCenterTags = ({ showPlayed, isPastActivity }) => (
  <div className='user-tags'>
    <div className='myrank-tag'>Your rank</div>
    <div className='right'>
      {showPlayed && (
        <div className='played-tag'>{isPastActivity ? 'Correct answers' : 'Played'}</div>
      )}
      <div className='score-tag'>
        <span className='hide'>Your</span> Score
      </div>
    </div>
  </div>
);

GameCenterTags.propTypes = {
  showPlayed: PropTypes.bool,
  isPastActivity: PropTypes.bool
};

GameCenterTags.defaultProps = {
  showPlayed: true,
  isPastActivity: false
};

const resultCopy = (isResult, rank) => {
  if (rank !== 1) {
    return <div>Congratulations, you are on the podium.</div>;
  }
  return <div>Congratulations, {isResult ? 'you came in first.' : "you're currently first."}`</div>;
};
const LeaderboardUserRank = ({ userData, isPastActivity, ...props }) => {
  const { rank, photo, name, data, error } = userData;
  const { gameCenter, isResult } = props;
  let message = '';
  const tag = gameCenter ? (
    <GameCenterTags showPlayed={props.showPlayed} isPastActivity={isPastActivity} />
  ) : (
    <div className='myrank-tag'>Your rank</div>
  );

  if (error) {
    return (
      <div className={classNames('myRankWrapper', { gameCenter })}>
        {tag}
        <div className='myrank-content'>
          <div className='photo-text-wrapper'>{error}</div>
        </div>
      </div>
    );
  }

  switch (rank) {
    // eslint-disable-next-line
    case 1:
    case 2:
    case 3:
      if (parseFloat(data?.score).toFixed(2) > 0) {
        message = (
          <div data-testid='myrank-text' className='myrank-text'>
            <Cracker className='cracker' />
            <div>{resultCopy(isResult, rank)}</div>
            <Cracker className='cracker' />
          </div>
        );
        break;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      message = (
        <div className='rank-details'>
          <Grid justify='space-around'>
            <Grid.Col
              span={1}
              className='row-cell'
              style={{
                justifyContent: 'flex-start',
                marginLeft: 5
              }}
            >
              {rank}
            </Grid.Col>
            <Grid.Col
              span={6}
              className='row-cell'
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                marginLeft: 14
              }}
            >
              <div className='user-phone-name'>
                <img
                  src={photo || Avatar || `https://ui-avatars.com/api/?name=${encodeURI(name)}`}
                  alt='user'
                />
                <div>{name || `My name`}</div>
              </div>
            </Grid.Col>
            <Grid.Col
              span={2}
              className='row-cell played'
              style={{
                textAlign: 'center'
              }}
            >
              {data?.played}
            </Grid.Col>
            <Grid.Col
              span={2}
              className='row-cell'
              style={{
                textAlign: 'center'
              }}
            >
              {parseFloat(data?.score).toFixed(2)}
            </Grid.Col>
          </Grid>
        </div>
      );
  }
  return (
    <div className={`myRankWrapper ${gameCenter && 'gameCenter'}`}>
      {gameCenter ? (
        <GameCenterTags showPlayed={props.showPlayed} isPastActivity={isPastActivity} />
      ) : (
        <div className='myrank-tag'>Your rank</div>
      )}
      <div className='myrank-content'>
        <div className='photo-text-wrapper'>{message}</div>
      </div>
    </div>
  );
};

LeaderboardUserRank.propTypes = {
  userData: PropTypes.shape().isRequired,
  gameCenter: PropTypes.bool,
  isResult: PropTypes.bool,
  showPlayed: PropTypes.bool,
  isPastActivity: PropTypes.bool
};

LeaderboardUserRank.defaultProps = {
  gameCenter: false,
  isResult: false,
  showPlayed: true,
  isPastActivity: false
};

export default LeaderboardUserRank;
