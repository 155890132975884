import React, { useState } from 'react';
import { Modal, Button } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateButtonType } from 'app/features/monetizationSlice';
import { maybePluralize } from 'utils/utilities';
import classNames from 'classnames';
import {
  useUpdateLicensesQuantityMutation,
  useCancelSubscriptionMutation
} from 'app/services/monetization';
import styles from './styles.module.scss';
import BuyOrRemove from './BuyOrRemove';

const ManageLicenseDetails = (
  confirmRemoveState,
  processing,
  removeRecentlyAssigned,
  licensesRemaining,
  value,
  setConfirmRemoveState,
  handleLicenseQuantity,
  handleCancelSubscription,
  licensesAssigned,
  buttonClick,
  handleClick,
  setButtonClick,
  setValue,
  getTotalAmount,
  getLicenseValue,
  plan,
  loading,
  teamsSubscription,
  teamMataesUnableToPlay,
  checkOverview
) => {
  if (confirmRemoveState || processing) {
    if (confirmRemoveState) {
      return (
        <>
          {removeRecentlyAssigned ? (
            <div className={styles.unsubscribeText}>
              You have {licensesRemaining} unassigned&nbsp;
              {maybePluralize(licensesRemaining, 'License')}. To delete {value}&nbsp;
              {maybePluralize(value, 'License')}, ({Math.abs(licensesRemaining - value)})
              random&nbsp;
              {maybePluralize(Math.abs(licensesRemaining - value), 'user')} will be unassigned&nbsp;
              {maybePluralize(Math.abs(licensesRemaining - value), 'License')}. If you want to
              select whose {maybePluralize(Math.abs(licensesRemaining - value), 'License')}
              should be unassigned, go back & unassign first.
            </div>
          ) : (
            <div className={styles.unsubscribeText}>
              You are removing all your Licenses. This will cancel your subscription. Would you like
              to continue?
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => setConfirmRemoveState(false)}
              style={{
                margin: 0,
                marginRight: '10px',
                backgroundColor: 'transparent',
                textTransform: 'unset'
              }}
            >
              Go back
            </Button>
            <Button
              onClick={() =>
                removeRecentlyAssigned ? handleLicenseQuantity() : handleCancelSubscription()
              }
              style={{ margin: '0px', backgroundColor: 'transparent' }}
            >
              Confirm
            </Button>
          </div>
        </>
      );
    }

    return (
      <div className={styles.heading}>
        Your request is being processed. This might take up to 10 minutes.
      </div>
    );
  }

  return (
    <>
      <div className={styles.licenseInfo}>
        <div className={styles.info}>
          <div className={styles.licenseNumber}>{licensesAssigned}</div>
          <div className={styles.text}>
            {checkOverview === 1 ? 'Total' : null} Licenses assigned
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.licenseNumber}>{licensesRemaining}</div>
          <div className={styles.text}>
            {checkOverview === 1 ? 'Unassigned Licences' : 'Licenses available'}
          </div>
        </div>
      </div>

      {teamsSubscription?.updateOperationExists && (
        <div className={styles.text}>
          You can manage Licenses after your previous request is processed. This might take up to 10
          minutes.
        </div>
      )}

      {!buttonClick && (
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => handleClick('buy')}
            disable={teamsSubscription?.updateOperationExists}
            style={{
              margin: 0,
              marginRight: '10px',
              backgroundColor: 'transparent',
              textTransform: 'unset'
            }}
          >
            Buy more
          </Button>
          <Button
            disable={teamsSubscription?.updateOperationExists}
            onClick={() => handleClick('remove')}
            style={{ margin: '0px', backgroundColor: 'transparent' }}
          >
            Remove
          </Button>
        </div>
      )}

      {buttonClick && (
        <>
          <hr className={styles.line} />
          <BuyOrRemove
            buttonClick={buttonClick}
            setButtonClick={setButtonClick}
            value={value}
            setValue={setValue}
            getTotalAmount={getTotalAmount}
            getLicenseValue={getLicenseValue}
            handleLicenseQuantity={handleLicenseQuantity}
            plan={plan}
            loading={loading}
            teamsSubscription={teamsSubscription}
            licensesRemaining={licensesRemaining}
            teamMataesUnableToPlay={teamMataesUnableToPlay}
            checkOverview={checkOverview}
          />
        </>
      )}
    </>
  );
};

const ManageLicense = ({
  licensesAssigned,
  licensesRemaining,
  handleCancelClick,
  buttonType,
  teamsSubscription,
  teamMataesUnableToPlay,
  checkOverview
}) => {
  const dispatch = useDispatch();
  const [updateLicensesQuantity] = useUpdateLicensesQuantityMutation();
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const plan = useSelector((state) => state.user.plan);
  const [buttonClick, setButtonClick] = useState(buttonType === 'Buy Licenses' ? 'buy' : null);
  const [value, setValue] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmRemoveState, setConfirmRemoveState] = useState(false);
  const removeRecentlyAssigned =
    value > licensesRemaining && value < teamsSubscription?.quantity && buttonClick === 'remove';

  const handleClick = (type) => {
    setButtonClick(type);
  };

  const handleLicenseQuantity = async () => {
    setLoading(true);
    if (buttonClick === 'remove') {
      if (value > Number(teamsSubscription?.quantity)) {
        setLoading(false);
        toast.error(
          `You cannot remove more Licenses than you have bought (${teamsSubscription?.quantity}).`
        );
        return;
      }

      if (
        !confirmRemoveState &&
        (value === Number(teamsSubscription?.quantity) || removeRecentlyAssigned)
      ) {
        setLoading(false);
        setConfirmRemoveState(true);
        return;
      }
    }

    const data = await updateLicensesQuantity({
      subscriptionId: teamsSubscription?.subscriptionId,
      quantity:
        buttonClick === 'buy'
          ? Number(teamsSubscription?.quantity) + Number(value)
          : Number(teamsSubscription?.quantity) - Number(value)
    }).unwrap();

    setLoading(false);

    if (data.ok) {
      if (confirmRemoveState) {
        setConfirmRemoveState(false);
      }
      setProcessing(true);
    }
  };

  const getLicenseValue = () => teamsSubscription?.currentPlanPricing?.price || 2;

  const getTotalAmount = () => {
    if (buttonClick === 'buy') {
      return (
        Number(value * getLicenseValue()) + Number(teamsSubscription.quantity * getLicenseValue())
      ).toFixed(2);
    }

    return (
      Number(teamsSubscription.quantity * getLicenseValue()) - Number(value * getLicenseValue())
    ).toFixed(2);
  };

  const handleCancelSubscription = async () => {
    const data = await cancelSubscription({
      subscriptionId: teamsSubscription?.subscriptionId
    }).unwrap();

    if (data.ok) {
      setConfirmRemoveState(false);
      setProcessing(true);
    }
  };

  return (
    <Modal
      title={confirmRemoveState && removeRecentlyAssigned ? 'Unassign Licenses' : 'Manage Licenses'}
      open={['manage licenses', 'buy licenses'].includes(buttonType?.toLowerCase())}
      onCancel={() => {
        handleCancelClick();
        dispatch(updateButtonType(null));
      }}
      showConfirm={false}
    >
      <div className={classNames(styles.container, { [styles.centerContainer]: processing })}>
        {ManageLicenseDetails(
          confirmRemoveState,
          processing,
          removeRecentlyAssigned,
          licensesRemaining,
          value,
          setConfirmRemoveState,
          handleLicenseQuantity,
          handleCancelSubscription,
          licensesAssigned,
          buttonClick,
          handleClick,
          setButtonClick,
          setValue,
          getTotalAmount,
          getLicenseValue,
          plan,
          loading,
          teamsSubscription,
          teamMataesUnableToPlay,
          checkOverview
        )}
      </div>
    </Modal>
  );
};

ManageLicense.propTypes = {
  licensesAssigned: PropTypes.number.isRequired,
  licensesRemaining: PropTypes.number.isRequired,
  buttonType: PropTypes.string.isRequired,
  teamsSubscription: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    currentPlanPricing: PropTypes.objectOf(
      PropTypes.shape({
        currency: PropTypes.string,
        price: PropTypes.number,
        termDescription: PropTypes.string,
        termUnit: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  teamMataesUnableToPlay: PropTypes.number.isRequired,
  checkOverview: PropTypes.number.isRequired,
  handleCancelClick: PropTypes.func.isRequired
};

export default ManageLicense;
