import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  virtualCoffeeOverview: {
    lastMatch: null,
    nextMatch: null,
    usersMatched: 0,
    virtualcoffees: 0
  },
  virtualCoffeeInsights: [],
  engagementInsights: []
};

export const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET /analytics/virtualcoffee/overview endpoint
    getVcAnalyticsOverview: builder.query({
      query: (params) => ({
        url: '/analytics/virtualcoffee/overview',
        params
      }),
      // transformResponse is used to transform the response data to the format that is required by the reducer/component
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.virtualCoffeeOverview;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch virtial coffee overview analytics');
        }
        return initialState.virtualCoffeeOverview;
      }
    }),

    // GET /analytics/virtualcoffee/insights
    getVirtualCoffeeInsights: builder.query({
      query: (params) => ({
        url: '/analytics/virtualcoffee/insights',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.insights;
        }
        toast.error(response.message);
        return initialState.virtualCoffeeInsights;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch virtial coffee insights');
        }
        return initialState.virtualCoffeeInsights;
      }
    }),

    // GET /analytics/engagement/insights endpoint
    getEngagementInsights: builder.query({
      query: () => ({
        url: '/analytics/engagement/insights'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.insights;
        }
        toast.error(response.message);
        return initialState.engagementInsights;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch engagement insights');
        }
        return initialState.engagementInsights;
      }
    })
  })
});

export const {
  useGetVcAnalyticsOverviewQuery,
  useGetVirtualCoffeeInsightsQuery,
  useGetEngagementInsightsQuery
} = analyticsApi;
