import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { HMSRoomProvider } from '@100mslive/hms-video-react';
import store from 'app/store';
import makeServer from './mirage';
import Routes from './routes';
import { ZohoContextProvider } from './utils/zohoContext';

if (process.env.REACT_APP_USE_MOCK === 'true') {
  makeServer({ environment: 'localhost' });
}

const fallbackCheck = ({ error, componentStack }) => (
  <>
    <div>Something went wrong! Please reload.</div>
    <div>Error: {error.toString()}</div>
    <div>{componentStack}</div>
  </>
);

const App = () => (
  <Sentry.ErrorBoundary fallback={fallbackCheck}>
    <Provider store={store}>
      <HMSRoomProvider>
        <ZohoContextProvider>
          <Routes />
        </ZohoContextProvider>
      </HMSRoomProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;
