import React from 'react';
import classNames from 'classnames';
import { useGetPlanQuery } from 'app/services/payment';
import localStorage from 'utils/localStorage';
import PlayGameCard from './PlayGameCard';
import AutomationCard from './AutomationCard';
import KytCard from './KytCard';
import VirtualCoffeeCard from './VirtualCoffeeCard';
import styles from './styles.module.scss';

const GameCards = () => {
  const platform = localStorage.getPlatform();
  const { data: planDetails = { plan: '' } } = useGetPlanQuery();

  return (
    <div className={classNames(styles.cardsContainer)}>
      {platform === 'slack' && <PlayGameCard planDetails={planDetails} />}
      <AutomationCard planDetails={planDetails} />
      <KytCard planDetails={planDetails} />
      {platform === 'slack' && <VirtualCoffeeCard planDetails={planDetails} />}
    </div>
  );
};

export default GameCards;
