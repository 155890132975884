const vcOverview1 = {
  ok: true,
  data: {
    lastMatch: { date: new Date(), channel: { name: 'general', id: 'C4545454', isPrivate: false } },
    nextMatch: { date: new Date(), channel: { name: 'general', id: 'C4545454', isPrivate: false } },
    usersMatched: 145, // total users matched across all VC channels
    virtualcoffees: 1
  }
};

const vcOverview2 = {
  ok: true,
  data: {
    lastMatch: {
      date: new Date(),
      channel: { name: 'random', id: 'C123123123', isPrivate: false }
    },
    nextMatch: { date: new Date(), channel: { name: 'general', id: 'C4545454', isPrivate: false } },
    usersMatched: 123123,
    virtualcoffees: [
      {
        id: 'vc_id',
        channel: { id: 'C123123121', name: 'fun', isPrivate: false },
        usersMatched: 168,
        lastMatch: new Date(),
        nextMatch: new Date()
      },
      {
        id: 'vc_id',
        channel: { id: 'C123123122', name: 'general', isPrivate: false },
        usersMatched: 138,
        lastMatch: new Date(),
        nextMatch: new Date()
      },
      {
        id: 'vc_id',
        channel: { id: 'C123123122', name: 'general', isPrivate: false },
        usersMatched: 122,
        lastMatch: new Date(),
        nextMatch: new Date()
      },
      {
        id: 'vc_id',
        channel: { id: 'C123123123', name: 'office', isPrivate: false },
        usersMatched: 138,
        lastMatch: new Date(),
        nextMatch: new Date()
      }
    ]
  }
};

export const getVirtualCoffeeOverview = (schema, request) => {
  const { timespan, channel } = request.queryParams;
  // for allTime
  if (!timespan && !channel) {
    return vcOverview1;
  }
  if ((timespan && timespan === '3') || channel) {
    return vcOverview1;
  }
  return vcOverview2;
};

export const virtualcoffeeInsights = {
  ok: true,
  insights: [
    {
      eventDate: new Date('2022-08-25'),
      rate: 100,
      matches: [
        {
          users: ['Avinash', 'Devesh', 'Karan'],
          messages: 20
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 83
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 70
        },
        {
          users: ['Avinash', 'Devesh', 'Karan'],
          messages: 24
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 20
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 34
        },
        {
          users: ['Avinash', 'Devesh', 'Karan'],
          messages: 37
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 44
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 48
        },
        {
          users: ['Avinash', 'Devesh', 'Karan'],
          messages: 12
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 30
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 14
        }
      ]
    },
    {
      eventDate: new Date('2022-08-26'),
      rate: 70,
      matches: [
        {
          users: ['random', 'employee', 'name3'],
          messages: 50
        },
        {
          users: ['abcd', 'name1', 'name2'],
          messages: 9
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 50
        }
      ]
    },
    {
      eventDate: new Date('2022-08-27'),
      rate: 50,
      matches: [
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 30
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 39
        },
        {
          users: ['Avinash', 'Devesh', 'Karan'],
          messages: 30
        }
      ]
    },
    {
      eventDate: new Date('2022-08-28'),
      rate: 40,
      matches: [
        {
          users: ['name1', 'name2', 'name3'],
          messages: 40
        },
        {
          users: ['Anuj', 'Sandeep', 'Suvang'],
          messages: 56
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 40
        }
      ]
    },
    {
      eventDate: new Date('2022-08-29'),
      rate: 80,
      matches: [
        {
          users: ['employee1', 'employee2', 'employee3'],
          messages: 27
        },
        {
          users: ['name1', 'name2', 'name3'],
          messages: 14
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 27
        }
      ]
    },
    {
      eventDate: new Date('2022-08-30'),
      rate: 90,
      matches: [
        {
          users: ['aaaaaaa', 'bbbbbbb', 'cccccc'],
          messages: 36
        },
        {
          users: ['employee1', 'employee2', 'employee3'],
          messages: 84
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 36
        }
      ]
    },
    {
      eventDate: new Date('2022-08-31'),
      rate: 30,
      matches: [
        {
          users: ['name4', 'name5', 'name6'],
          messages: 60
        },
        {
          users: ['name7', 'name8', 'name9'],
          messages: 54
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 60
        }
      ]
    },
    {
      eventDate: new Date('2022-09-01'),
      rate: 10,
      matches: [
        {
          users: ['name10', 'name11', 'name12'],
          messages: 28
        },
        {
          users: ['name13', 'name14', 'name15'],
          messages: 38
        },
        {
          users: ['Kartik', 'Wasim', 'Chetan'],
          messages: 22
        }
      ]
    }
  ]
};

export const virtualCoffeeChannels = {
  ok: true,
  data: [
    { id: 'C1231231', name: 'random', isPrivate: false },
    { id: 'C23423423', name: 'social', isPrivate: false }
  ]
};

export const getAdmins = {
  ok: true,
  admins: [
    {
      userId: 'U03GKB79FFD',
      email: 'anuj1999kapoor@gmail.com',
      name: 'Anuj Kapoor',
      photo: 'https://avatars.slack-edge.com/2022-05-23/3588472663232_e5ef96c872f36e51cdd5_32.jpg',
      settings: {
        transactionalNudge: true,
        promotionalNudge: true
      }
    }
  ],
  members: [
    {
      id: 'U02H67NAQCF',
      name: 'Himani Chaudhary',
      photo: 'https://avatars.slack-edge.com/2021-10-13/2584294178983_7f2a9fb9a953f11af73a_32.png'
    },
    {
      id: 'U02HDUZ6WK0',
      name: 'anuj.kapoor',
      photo:
        'https://secure.gravatar.com/avatar/e58207bea6278158d341d38a1d46c2b3.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0016-32.png'
    },
    {
      id: 'U02HLUA4VBM',
      name: 'Ayus Das',
      photo:
        'https://secure.gravatar.com/avatar/9c162b58474c63a739b1eeb3004b5a59.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0020-32.png'
    },
    {
      id: 'U02HZG92WUR',
      name: 'Bhumika setia',
      photo: 'https://avatars.slack-edge.com/2021-10-14/2591817216087_bee3137ad36bed926029_32.jpg'
    },
    {
      id: 'U03347E7UE7',
      name: 'Jaan Nishar',
      photo: 'https://avatars.slack-edge.com/2022-02-15/3106258605299_32088d73078d5d2041b0_32.jpg'
    },
    {
      id: 'U03GKB79FFD',
      name: 'Anuj Kapoor',
      photo: 'https://avatars.slack-edge.com/2022-05-23/3588472663232_e5ef96c872f36e51cdd5_32.jpg'
    },
    {
      id: 'U03GWHV8ABT',
      name: 'Suvang Samal',
      photo: 'https://avatars.slack-edge.com/2022-05-23/3564341419060_e56ed069fabf7b96c7db_32.png'
    },
    {
      id: 'U03PDR2LYBF',
      name: 'Anuj2',
      photo:
        'https://secure.gravatar.com/avatar/90aabb556be4e5855e8873f7c7f7e0d6.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0021-32.png'
    },
    {
      id: 'U03Q6A8SSE4',
      name: 'Anuj1',
      photo:
        'https://secure.gravatar.com/avatar/15c8c489b1440a3918405029c5c4c016.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0013-32.png'
    },
    {
      id: 'U047HEBRTQ9',
      name: 'wasim',
      photo:
        'https://secure.gravatar.com/avatar/f509d97d3c088bf845a9c11266b8a9bb.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0001-32.png'
    }
  ]
};

export const engagementInsights = {
  ok: true,
  insights: [
    { mark: 'Week 1', engagementRate: 20 },
    { mark: 'Week 2', engagementRate: 70 },
    { mark: 'Week 3', engagementRate: 80 },
    { mark: 'Week 4', engagementRate: 100 },
    { mark: 'Week 5', engagementRate: 40 },
    { mark: 'Week 6', engagementRate: 10 },
    { mark: 'Week 7', engagementRate: 37 },
    { mark: 'Week 8', engagementRate: 29 },
    { mark: 'Week 9', engagementRate: 74 },
    { mark: 'Week 10', engagementRate: 60 },
    { mark: 'Week 11', engagementRate: 55 },
    { mark: 'Week 12', engagementRate: 92 },
    { mark: 'Week 13', engagementRate: 19 },
    { mark: 'Week 14', engagementRate: 36 }
  ]
};
