import { Dialog } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseMenuIcon } from 'assets/images/menu-close.svg';
import Branding from 'assets/images/trivia-branding.svg';
import styles from './styles.module.scss';

const HamburgerMenu = ({ open, handleClose, userInfo, tabs, onLogout, onSettings }) => (
  <Dialog
    opened={open}
    onClose={handleClose}
    className={styles.hamburgerMenuModal}
    radius={0}
    position={{ top: -15, left: 0 }}
  >
    <div className={styles.appBar}>
      <div className={styles.toolBar}>
        <CloseMenuIcon className={styles.closeMenuIcon} onClick={handleClose} />
      </div>
    </div>
    <div className={styles.hamburgerMenuWrapper}>
      <div className={styles.userInfo}>{userInfo}</div>
      <div className={styles.tabsLinks}>{tabs}</div>
      <div className={styles.logoutButton}>
        <div className={styles.logout} onClick={onLogout}>
          Logout
        </div>
        <div className={styles.help} onClick={onSettings}>
          Settings
        </div>
      </div>
      <div className={styles.brandingMobile}>
        <img src={Branding} alt='trivia' />
        <a href='https://springworks.in/trivia'>Having trouble? Get help</a>
      </div>
    </div>
  </Dialog>
);

HamburgerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  onLogout: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired
};

HamburgerMenu.defaultProps = {
  tabs: [{ label: '' }],
  userInfo: {
    name: ''
  }
};

export default HamburgerMenu;
