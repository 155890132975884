import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InstallToWorkspace } from 'assets/images/install.svg';
import { ReactComponent as AddToSlack } from 'assets/images/addToSlack.svg';
import AddToTeams from 'assets/images/addToTeams.svg';
import styles from './styles.module.scss';

const ContainerView = ({ platform }) => {
  switch (platform) {
    case 'slack':
      return (
        <div
          className={styles.addToSlack}
          onClick={() => window.open(process.env.REACT_APP_SLACK_INSTALL, '_self')}
        >
          <AddToSlack role='img' title='Add To Slack' />
        </div>
      );

    case 'teams':
      return (
        <div
          className={styles.addToSlack}
          onClick={() => window.open(process.env.REACT_APP_TEAMS_INSTALL, '_self')}
        >
          <img src={AddToTeams} alt='Add To Teams' />
        </div>
      );

    default:
      return (
        <div className={styles.wrapper}>
          <div
            className={styles.slackWrapper}
            onClick={() => window.open(process.env.REACT_APP_SLACK_INSTALL, '_self')}
          >
            <AddToSlack role='img' title='Add To Slack' />
          </div>
          <div
            className='install-container__wrapper--teams'
            onClick={() => window.open(process.env.REACT_APP_TEAMS_INSTALL, '_self')}
          >
            <img src={AddToTeams} alt='Add To Teams' />
          </div>
        </div>
      );
  }
};

const Install = (props) => {
  const {
    location: { search }
  } = props;
  const params = new URLSearchParams(search);
  let platform = params.get('platform');
  if (!platform) {
    platform = 'all';
  }
  return (
    <div className={styles.installContainer}>
      <div className={styles.image}>
        <InstallToWorkspace />
      </div>
      <div data-testid='install' className={styles.header}>
        You need to install Trivia to your workspace to start
      </div>
      <ContainerView platform={platform} />
    </div>
  );
};

Install.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape({})).isRequired
};
ContainerView.propTypes = {
  platform: PropTypes.string.isRequired
};

export default Install;
