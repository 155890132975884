import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const containerSizes = {
  small: styles.smallContainer,
  tiny: styles.tinyContainer,
  square: styles.squareContainer
};

const Search = ({ size, fullWidth, value, handleChange, placeholder, className }) => (
  <div
    className={classNames(styles.searchContainer, containerSizes[size], className)}
    style={{ width: fullWidth ? '100%' : '50%' }}
  >
    <SearchIcon />
    <input
      autoComplete='off'
      className={size}
      type='text'
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  </div>
);

Search.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'default', 'square']),
  className: PropTypes.string
};

Search.defaultProps = {
  size: 'default',
  fullWidth: false,
  className: null
};

export default Search;
