const getQuestionData = (questions, state) => {
  const {
    question: questionState,
    num,
    correct: correctState,
    option1: option1State,
    option2: option2State,
    option3: option3State,
    option4: option4State,
    option5: option5State
  } = state;
  const { question, answers, _id: questionId } = questions[num - 1];
  const [option1, option2, option3, option4, option5] = [3, 4, 5].includes(answers.length)
    ? answers.map((ans) => ans.value)
    : Array(5).fill('');
  let correct = correctState;
  if (correctState === '0') {
    correct = [3, 4, 5].includes(answers.length)
      ? String(answers.findIndex((ans) => ans.correct) + 1)
      : '1';
  }
  const data = {
    question: question === 'null' ? '' : question,
    option1,
    option2,
    option3,
    option4,
    option5,
    correct,
    questionId,
    optionsArray: [option1.toLowerCase(), option2.toLowerCase()]
  };
  if (questionState !== '') {
    data.question = questionState;
  }
  if (option1State !== '') {
    data.option1 = option1State;
  }
  if (option2State !== '') {
    data.option2 = option2State;
  }
  if (option3State !== '') {
    data.option3 = option3State;
  }
  if (option4State !== '') {
    data.option4 = option4State;
  }
  if (option5State !== '') {
    data.option5 = option5State;
  }
  data.optionsArray = [data.option1.toLowerCase(), data.option2.toLowerCase()];
  if (data.option3) {
    data.optionsArray.push(data.option3.toLowerCase());
  }
  if (data.option4) {
    data.optionsArray.push(data.option4.toLowerCase());
  }
  if (data.option5) {
    data.optionsArray.push(data.option5.toLowerCase());
  }
  return data;
};

export default getQuestionData;
