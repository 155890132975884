import React from 'react';
import { ReactComponent as FirstPlace } from 'assets/images/gold-medal.svg';
import { ReactComponent as SecondPlace } from 'assets/images/silver-medal.svg';
import { ReactComponent as ThirdPlace } from 'assets/images/bronze-medal.svg';
import { proTiers, standardTiers } from 'data/plans';
import { cloneDeep } from 'lodash';

export const getPuzzleTag = (tag) => (tag === 'Puzzle' ? 'pink' : 'blue');

export const rankClassName = {
  1: 'first',
  2: 'second',
  3: 'third'
};

export const getMedal = {
  1: <FirstPlace />,
  2: <SecondPlace />,
  3: <ThirdPlace />
};

export const getMedalWithRank = (rank, score) => {
  if (score === 0) {
    return rank;
  }
  switch (rank) {
    case 1:
      return (
        <>
          <FirstPlace />
          {rank}
        </>
      );
    case 2:
      return (
        <>
          <SecondPlace />
          {rank}
        </>
      );
    case 3:
      return (
        <>
          <ThirdPlace />
          {rank}
        </>
      );

    default:
      return rank;
  }
};

/**
 * Function for getting singular or plural form of verb
 *
 * @example
 * maybePluralize(1, 'user')  ==> user
 * maybePluralize(5, 'user')  ==> users
 * maybePluralize(3, 'fox', [''],'es') ==> foxes
 * maybePluralize(4, 'teammate', ['is','are'])  ==> teamates are
 * maybePluralize(1, 'teammate', ['is','are'])  ==> teamate is

 */

const getSuffix = (count, suffix) => (count !== 1 ? suffix : '');

export const maybePluralize = (count, noun, auxillaryVerb = [''], suffix = 's') =>
  `${noun}${getSuffix(count, suffix)} ${
    auxillaryVerb.length === 2 && count !== 1 ? auxillaryVerb[1] : auxillaryVerb[0]
  }`;

export const getSortedStandings = (standings) =>
  cloneDeep(standings)
    .sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      if (a.score === b.score) {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
      return 0;
    })
    .map((p, i) => ({ ...p, rank: i + 1 }));

export const removeEmojis = (string) => {
  const eReg = /(:[^:\s\r\n]+:)/g;
  if (string.indexOf(':') !== -1) {
    return string.replace(eReg, '');
  }
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return string.replace(regex, '');
};

// format - date month year (eg:-02 January 2023)
export const convertDate = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
  return formattedDate;
};

/**
 * Method to capitalize String
 * @param {string} string - string to be capitalizes
 * @returns capitalized string
 */
export const capitalizeFirstLetter = (string) => {
  if (string == null) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getListAsString = (arr, limit) => {
  const _arr = cloneDeep(arr);
  if (limit && _arr.length > limit) {
    _arr.push(`${_arr.splice(limit).length} more`);
  }
  return _arr.join(', ').replace(/,([^,]*)$/, ' and$1');
};

/**
 * Rounds a number to 2 decimal places.
 *
 * @param {number} num - The number to round. Can be an integer or a decimal.
 * @returns {number} - The number rounded to 2 decimal places.
 */

const roundOffNumber = (num) => Math.round(num * 100) / 100;

/**
 * Method to calculate prices.
 *
 * @param {number} quantity - number of uniqueUsers/activeUsers.
 * @param {string} plan - plan
 * @param {string} span - The time span , monthly/yearly
 * @returns {Object<totalCost: number, totalCostWithoutDiscount: number, savings: number, tierDetails: Array<Object>>} - The calculated price.
 */

const calculateTieredPricing = (quantity, plan, span) => {
  const baseQuantity = plan === 'PRO' ? 25 : 29;
  const tiers = plan === 'PRO' ? proTiers : standardTiers;
  const isYearly = span !== 'month';

  // Define flat prices for yearly and monthly plans
  const flatPriceYearly = plan === 'PRO' ? 479.0 : 279.0;
  const flatPriceMonthly = plan === 'PRO' ? 49.0 : 29.0;
  const flatPrice = isYearly ? flatPriceYearly : flatPriceMonthly;

  let totalCost = 0.0;
  let totalCostWithoutDiscount = 0.0;

  const tierDetails = [];

  tiers.some((tier, index) => {
    const unitPrice = isYearly ? tier.unitPriceYearly : tier.unitPriceMonthly;

    if (quantity >= tier.min) {
      const applicableQty = Math.min(quantity, tier.max) - tier.min + 1;

      let tierCost = roundOffNumber(applicableQty * unitPrice);
      let tierCostWithoutDiscount;

      // Handle the first tier separately for the without discount cost
      if (index === 0) {
        tierCostWithoutDiscount = roundOffNumber(plan === 'PRO' ? 12 * 2 * 25 : 12 * 29);
        tierCost = roundOffNumber(flatPrice);
      } else {
        tierCostWithoutDiscount = roundOffNumber(applicableQty * unitPrice);
      }

      // If quantity is within the base quantity, add the flat amount if any
      if (applicableQty <= baseQuantity && index !== 0) {
        tierCost += roundOffNumber(tier.flatAmount || 0);
      }

      totalCost += tierCost;
      totalCostWithoutDiscount += tierCostWithoutDiscount;

      tierDetails.push({
        tierName: `${tier.min}-${tier.max}`,
        applicableQty,
        unitPrice: roundOffNumber(unitPrice),
        tierCost,
        tierCostWithoutDiscount
      });

      if (quantity <= tier.max) {
        return true;
      }
    }
    return false;
  });

  const savings = roundOffNumber(totalCostWithoutDiscount - totalCost);

  return {
    totalCost: roundOffNumber(totalCost),
    totalCostWithoutDiscount: roundOffNumber(totalCostWithoutDiscount),
    savings,
    tierDetails
  };
};

export default calculateTieredPricing;

export const featureLaunchDate = { taleTrail: '2024-06-15' };
