/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  MultiSelectDropdown,
  TimePicker,
  SegmmentedControl
} from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import localStorage from 'utils/localStorage';
import styles from './styles.module.scss';

const Form = (props) => {
  const {
    open,
    edit,
    categories,
    timezones,
    difficulties,
    days,
    channels,
    isKytForm,
    gameFormats,
    selectGameOptions,
    formData,
    handleSubmit,
    handleCancel,
    handleChange,
    errorState,
    disableConfirm
  } = props;
  const platform = localStorage.getPlatform();
  const [selectGameType, setSelectGameType] = useState(
    formData?.gameTypes?.includes('random') ? 'random' : 'selectAFormat'
  );
  const [selectGameFormat, setSelectGameFormat] = useState(
    isKytForm ? ['kyt'] : edit ? formData?.gameTypes : ['trivia']
  );

  const handleGameTypeChange = (value) => {
    setSelectGameType(value);
    if (value === 'random') {
      handleChange('gameTypes', ['random']);
    } else {
      handleChange('gameTypes', selectGameFormat);
    }
  };

  const handleGameFormatChange = (value) => {
    setSelectGameFormat(value);
    if (selectGameType !== 'random') {
      handleChange('gameTypes', value);
    }
  };

  const getChannelPlaceholder = () => {
    if (platform === 'slack') {
      return channels.length ? 'Select a channel' : 'No channels are integrated with Trivia.';
    }

    return channels.length ? 'Select a conversation' : `Trivia is not a member of any team.`;
  };

  const getNoChannelIntegratedNote = () => {
    if (platform === 'slack') {
      return (
        <span className={styles.noChannelIntegratedNote}>
          You can only view integrated channels here. To integrate Trivia with a channel, submit{' '}
          <b>/invite @Trivia</b> on that channel.
        </span>
      );
    }

    return (
      <span className={styles.noChannelIntegratedNote}>
        To view teams here, add Trivia to one from MS Teams.
      </span>
    );
  };

  const getKYTIntegratedNote = () => (
    <span className={styles.noChannelIntegratedNote}>
      For KYT to run ,At least 5 questions should have been answered and also there must be 3 unique
      players who have responded.
    </span>
  );

  return (
    <Modal
      open={open}
      title={edit ? 'Edit Automation' : 'Add New Automation'}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      confirmText={edit ? 'Update' : 'Add'}
      disableConfirmButton={disableConfirm}
    >
      <div className={styles.formContainer}>
        <CustomSelect
          label='SELECT A GAME'
          value={selectGameType}
          options={selectGameOptions}
          setValue={handleGameTypeChange}
        />
        {selectGameType !== 'random' && (
          <>
            <MultiSelectDropdown
              label='FORMATS'
              value={selectGameFormat.includes('random') ? ['trivia'] : selectGameFormat}
              data={gameFormats}
              onChange={handleGameFormatChange}
              styles={{ rightSection: { pointerEvents: 'none' } }}
            />
            {selectGameFormat.includes('kyt') && getKYTIntegratedNote()}
          </>
        )}
        <CustomSelect
          label={platform === 'slack' ? 'CHANNEL' : 'CONVERSATION'}
          value={formData.channel ?? ''}
          options={channels}
          setValue={(value) => handleChange('channel', value)}
          placeholder={getChannelPlaceholder()}
        />
        {!channels.length && getNoChannelIntegratedNote()}
        <MultiSelectDropdown
          label='SELECT DAYS'
          value={formData.frequency}
          data={days}
          onChange={(value) => handleChange('frequency', value)}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
        <div className={styles.dateAndTimezoneContainer}>
          <TimePicker
            label='SELECT TIME'
            value={formData.time}
            onChange={(value) => handleChange('time', value)}
          />
          <CustomSelect
            label='SELECT TIMEZONE'
            value={formData.timezone}
            options={timezones}
            setValue={(value) => handleChange('timezone', value)}
          />
        </div>
        {(formData?.gameTypes?.some((quizType) => ['selfPacedQuiz', 'trivia'].includes(quizType)) ||
          (selectGameType !== 'random' && selectGameFormat.includes('random'))) && (
          <>
            <div className={styles.categoryDropdownWrapper}>
              <div className={styles.label}>QUIZ CATEGORIES (optional)</div>
              <div className={styles.subLabel}>You can select upto 5 categories</div>
              <MultiSelectDropdown
                value={formData.category}
                data={categories}
                onChange={(value) => handleChange('category', value)}
                error={errorState.category}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
            </div>
            <div className={styles.segmentWrapper}>
              <div className={styles.label}>QUIZ DIFFICULTY (optional)</div>
              <SegmmentedControl
                data={difficulties}
                value={formData.difficulty}
                onChange={(value) => handleChange('difficulty', value)}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

Form.propTypes = {
  edit: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      group: PropTypes.string
    })
  ).isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      length: PropTypes.func
    })
  ).isRequired,
  gameFormats: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  selectGameOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  difficulties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.element,
      value: PropTypes.string
    })
  ).isRequired,
  formData: PropTypes.shape({
    gameTypes: PropTypes.arrayOf(String),
    channel: PropTypes.string,
    frequency: PropTypes.arrayOf(String),
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    timezone: PropTypes.string,
    category: PropTypes.arrayOf(String),
    difficulty: PropTypes.string
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorState: PropTypes.shape({
    category: PropTypes.string.isRequired
  }).isRequired,
  isKytForm: PropTypes.bool
};

Form.defaultProps = {
  isKytForm: false
};
export default Form;
