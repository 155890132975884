import { Button } from '@springrole/trivia-games';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const TeamsFooter = (props) => {
  const { footerDetails, onClick } = props;
  return (
    <div className={styles.container} data-testid='teams-footer-container'>
      <div className={styles.leftSection}>
        <div className={styles.freeTrialText}>{footerDetails.heading}</div>
        <div className={styles.accessTriviaText}>{footerDetails.description}</div>
      </div>

      <Button
        onClick={onClick}
        dark='true'
        className={styles.getSubscriptionBtn}
        style={{ padding: '10px 32px' }}
        data-testid='teams-footer-button'
      >
        {footerDetails.buttonText}
      </Button>
    </div>
  );
};

TeamsFooter.propTypes = {
  footerDetails: PropTypes.objectOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired
};

export default TeamsFooter;
