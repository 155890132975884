import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import localStorage from 'utils/localStorage';
import resetCache from 'app/actions/resetStore';
import { saveUserInfo, updateUserInfo } from 'app/features/userSlice';
import api from './api';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET /user endpoint
    getUser: builder.query({
      query: () => ({
        url: '/user'
      }),
      provideTags: ['User'],
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveUserInfo(response.info));
          } else {
            toast.error('Failed to fetch information');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to fetch user info');
          }
          return Promise.resolve();
        }
      }
    }),

    // POST /user endpoint
    updateUser: builder.mutation({
      query: (formData) => ({
        url: '/user',
        method: 'POST',
        body: formData
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getUser', undefined, (draft) => {
                const cache = current(draft);
                const { info } = cache;
                const { user } = info;
                // when alternateEmail is present make emailKey as alternateEmail otherwise as email
                const emailKey =
                  (user?.alternateEmail && 'alternateEmail') ?? (user?.email && 'email');
                const updatedUser = {
                  ...user,
                  [emailKey]: Data.email,
                  phone: response.data.phone
                };
                const updatedInfo = {
                  ...info,
                  user: updatedUser
                };
                return { ...cache, info: updatedInfo };
              })
            );
            dispatch(updateUserInfo(Data));
            toast.success('Successfully added contact information');
          } else {
            toast.error('Failed to add contact information');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update user details');
          }
          return Promise.resolve();
        }
      }
    }),

    // POST /logout endpoint
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            localStorage.clearStorage();
            window.location.href = '/login';
            dispatch(resetCache);
          } else {
            toast.error('Failed to logout, please try again');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to logout');
          }
          return Promise.resolve();
        }
      }
    })
  })
});

export const { useGetUserQuery, useUpdateUserMutation, useLogoutMutation } = userApi;
