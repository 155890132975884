import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mantine/core';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Row = ({ columns, className }) => (
  <div className={classNames(styles.rowContainer, className)}>
    <Grid>
      {columns.map((col, ind) => (
        <Grid.Col
          className={styles.rowCell}
          span={col.size || 3}
          // eslint-disable-next-line react/no-array-index-key
          key={ind}
          style={{
            textAlign: col.center ? 'center' : 'initial',
            justifyContent: col.center ? 'center' : 'flex-start',
            fontWeight: col.bold ? '600' : '500'
          }}
        >
          {col.label}
        </Grid.Col>
      ))}
    </Grid>
  </div>
);

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  className: PropTypes.string
};

Row.defaultProps = {
  className: null
};

export default Row;
