import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGetAdminsQuery } from 'app/services/settings';
import Modal from './AccessRestrictedModal';

const AccessRestrictedModal = (props) => {
  const { open, onCancel, automationRestriction } = props;
  const { data: adminsData = { admins: [] }, isLoading: loading } = useGetAdminsQuery(true);

  return (
    <Modal
      open={open}
      admins={adminsData.admins}
      loading={loading}
      handleCancel={onCancel}
      automationRestriction={automationRestriction}
    />
  );
};

AccessRestrictedModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  automationRestriction: PropTypes.bool
};

AccessRestrictedModal.defaultProps = { open: false, automationRestriction: true };

const mapStateToProps = ({ user }) => ({
  isAllowedToStart: user.user?.isAllowedToStart
});

export default connect(mapStateToProps)(AccessRestrictedModal);
