import React, { useEffect } from 'react';
import { ReactComponent as MicOn } from 'assets/VirtualCoffee/microphone-on.svg';
import { ReactComponent as MicOff } from 'assets/VirtualCoffee/microphone-off.svg';
import { ReactComponent as CameraOn } from 'assets/VirtualCoffee/camera-on.svg';
import { ReactComponent as CameraOff } from 'assets/VirtualCoffee/camera-off.svg';
import { ReactComponent as Phone } from 'assets/VirtualCoffee/phone.svg';
import { ReactComponent as Setting } from 'assets/VirtualCoffee/setting.svg';
import { ReactComponent as CloseIcon } from 'assets/VirtualCoffee/close-black.svg';
import { VirtualCoffeeTooltip } from 'components/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './CallControls.module.scss';

const CallControls = ({
  onCall,
  isMuted,
  isLocalVideoEnabled,
  toggleVideo,
  toggleAudio,
  notification,
  setNotification,
  isConnectionLost,
  leaveRoom,
  openSettings
}) => {
  useEffect(() => {
    setNotification(notification);

    const timer = setTimeout(() => {
      setNotification('');
    }, 5000);
    return () => clearTimeout(timer);
  }, [notification]);

  return (
    <div className={styles.componentWrapper}>
      {notification !== '' && (
        <div className={styles.messageWrapper} data-testid='notification'>
          <div className={styles.message}>{notification}</div>
          {!isConnectionLost && (
            <div className={styles.closeIcon}>
              <CloseIcon onClick={() => setNotification('')} data-testid='closeIcon' />
            </div>
          )}
        </div>
      )}
      <div className={classNames(styles.callControlsWrapper, { [styles.fixed]: onCall })}>
        <div
          className={isMuted ? [styles.red] : [styles.circle]}
          onClick={() => toggleAudio(!isMuted)}
          data-testid='audioControl'
        >
          <VirtualCoffeeTooltip label={`Turn ${isMuted ? 'on' : 'off'} microphone`}>
            {isMuted ? <MicOff data-testid='micIcon' /> : <MicOn data-testid='micIcon' />}
          </VirtualCoffeeTooltip>
        </div>
        <div
          className={isLocalVideoEnabled ? [styles.circle] : [styles.red]}
          onClick={() => toggleVideo(!isLocalVideoEnabled)}
          data-testid='videoControl'
        >
          <VirtualCoffeeTooltip label={`Turn ${isLocalVideoEnabled ? 'on' : 'off'} camera`}>
            {isLocalVideoEnabled ? (
              <CameraOn data-testid='cameraIcon' />
            ) : (
              <CameraOff data-testid='cameraIcon' />
            )}
          </VirtualCoffeeTooltip>
        </div>
        {!isConnectionLost && (
          <div className={styles.circle} onClick={openSettings} data-testid='settingsButton'>
            <VirtualCoffeeTooltip label='Settings'>
              <Setting />
            </VirtualCoffeeTooltip>
          </div>
        )}
        {onCall && (
          <div className={styles.phone} onClick={leaveRoom} data-testid='leaveRoomButton'>
            <Phone />
          </div>
        )}
      </div>
    </div>
  );
};

CallControls.propTypes = {
  onCall: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  isLocalVideoEnabled: PropTypes.bool.isRequired,
  isConnectionLost: PropTypes.bool,
  notification: PropTypes.string,
  toggleVideo: PropTypes.func,
  toggleAudio: PropTypes.func,
  leaveRoom: PropTypes.func,
  openSettings: PropTypes.func,
  setNotification: PropTypes.func
};
CallControls.defaultProps = {
  notification: '',
  isConnectionLost: false,
  toggleVideo: () => null,
  toggleAudio: () => null,
  leaveRoom: () => null,
  openSettings: () => null,
  setNotification: () => null
};

export default CallControls;
