import React from 'react';
import Layout from 'components/Layout';
import history from 'createHistory';
import { ReactComponent as BackIcon } from 'assets/images/home/backIcon.svg';
import ManageAutomations from './ManageAutomations';
import styles from '../styles.module.scss';

const Automations = () => (
  <Layout name='Home' className={styles.homeContentWrapper}>
    <div className={styles.goBackButton} onClick={() => history.push('/')}>
      <BackIcon /> Go back
    </div>
    <ManageAutomations />
  </Layout>
);

export default Automations;
