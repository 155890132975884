import _ from 'lodash';
import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  quizzes: {
    hasMore: false,
    data: []
  },
  quizInfo: {
    gameId: '',
    questionsList: [],
    usersList: {}
  }
};

export const customQuizResultsApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /custom-quiz/list
    getCustomQuizzes: build.query({
      query: (page) => ({
        url: '/custom-quiz/list',
        params: { page, limit: 5 }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return { quizzes: response.data, hasMore: response.hasMore };
        }
        toast.error(response.message);
        return initialState.quizzes;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch custom quizzes');
        }
        return initialState.quizzes;
      }
    }),

    // GET /custom-quiz/info
    getCustomQuizInfo: build.query({
      query: (gameId) => ({
        url: '/custom-quiz/info',
        params: { gameId }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return _.omit(response, 'ok');
        }
        toast.error(response.message);
        return initialState.quizInfo;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch custom quiz info');
        }
        return initialState.quizInfo;
      }
    })
  })
});

export const { useGetCustomQuizzesQuery, useGetCustomQuizInfoQuery } = customQuizResultsApi;
