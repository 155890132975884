import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import Section from 'components/Section/section';
import PropTypes from 'prop-types';
import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import { ReactComponent as LeaderboardLayout } from 'assets/images/leaderboard/leaderboard.svg';
import RestrictedAccess from 'components/RestrictedAccess';
import * as microsoftTeams from '@microsoft/teams-js';
import { useSelector, connect } from 'react-redux';
import { useGetAnalyticsOverviewQuery } from 'app/services/home';
import Snackbar from 'components/Snackbar';
import { identify, track } from 'utils/segment';
import EmptyLeaderboardState from 'components/EmptyLeaderboardState';
import { Loading } from '@springrole/trivia-games';
import Standings from './Standings';
import styles from './styles.module.scss';

let overview;
let isLoading = true;
const Leaderboard = (props) => {
  const { isPublic, taskModule, workspace, team } = props;

  if (!isPublic) {
    const { data, isFetching } = useGetAnalyticsOverviewQuery();
    if (!isFetching) {
      overview = data;
      isLoading = isFetching;
    }
  }

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.appInitialization.notifySuccess();
  }, []);

  const userDetails = useSelector((state) => state.user);
  const neverUsedTrivia = !overview?.gamesPlayed;
  const allowedToView =
    userDetails.freeTrial || (!userDetails.freeTrial && userDetails.plan !== 'BASIC');

  if (!userDetails.loading) {
    const { _id, loggedUsersCount = 0 } = userDetails;

    if (_id) {
      identify(_id, { 'Unique Logged In Users': loggedUsersCount });
      track('Visit Dashboard', {
        distinct_id: _id,
        Page: 'Leaderboard',
        Date: new Date(),
        'Unique Logged In Users': loggedUsersCount
      });
    }
  }

  return (
    <Layout name='Leaderboard' isPublic={isPublic}>
      <div className={styles.heading}>
        <CrownIcon /> <span>&nbsp;{team}&apos;s Trivia Leaderboard</span>
      </div>
      {isLoading && <Loading />}
      {isPublic && !taskModule && (
        <Snackbar
          text='You need to login to view your rankings'
          actionText='Login now'
          type='info'
          performAction={() => {
            window.location.href = `${
              window.location.pathname.includes('/tabs') ? '/tabs' : ''
            }/login`;
          }}
        />
      )}
      {!allowedToView ? (
        <Section className={styles.standingsSection} style={{ marginBottom: '5rem' }}>
          <RestrictedAccess withoutButton={isPublic} />
        </Section>
      ) : (
        <Section
          title=''
          noBorder
          style={{
            marginTop: 0,
            flexGrow: 1,
            marginBottom: '5rem',
            padding: neverUsedTrivia && !isPublic && '0',
            margin: neverUsedTrivia && !isPublic && '0'
          }}
        >
          {overview && neverUsedTrivia && !isPublic && (
            <div className={styles.neverUsed}>
              <EmptyLeaderboardState
                plan={userDetails.plan}
                teamId={userDetails?.user?.workspace}
              />
              <LeaderboardLayout width='100%' height='100%' />
            </div>
          )}

          {(!neverUsedTrivia || isPublic) && (
            <div className={styles.standingsWrapper}>
              <div className={styles.header}>
                <Standings isPublic={isPublic} workspace={workspace} />
              </div>
            </div>
          )}
        </Section>
      )}
    </Layout>
  );
};

Leaderboard.propTypes = {
  workspace: PropTypes.string,
  isPublic: PropTypes.bool,
  taskModule: PropTypes.bool,
  team: PropTypes.string.isRequired
};

Leaderboard.defaultProps = {
  workspace: '',
  isPublic: false,
  taskModule: false
};

const mapStateToProps = ({ user: { team } }) => ({
  team: team?.name || ''
});

export default connect(mapStateToProps)(Leaderboard);
