import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mantine/core';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as DownArrow } from 'assets/images/down.svg';
import styles from './styles.module.scss';

const AutoComplete = ({
  options: _options,
  placeholder,
  onSelect,
  searchable,
  clearable,
  value: _value,
  disabled,
  className,
  defaultSearchValue,
  bordered,
  defaultValue,
  ...Props
}) => {
  const [search, setSearch] = useState(_value || '');

  return (
    <Select
      data={_options}
      placeholder={placeholder}
      searchable={searchable}
      disabled={disabled}
      maxDropdownHeight={200}
      clearable={clearable}
      defaultSearchValue={defaultSearchValue}
      value={search}
      onChange={(value) => {
        onSelect(value);
        setSearch(value || '');
      }}
      classNames={{
        wrapper: styles.customWrapper,
        input: styles.input,
        root: styles.root,
        dropdown: styles.dropdown,
        options: styles.options,
        option: styles.option,
        item: styles.item
      }}
      {...Props}
      defaultValue={defaultValue}
      rightSection={bordered ? <SearchIcon data-testid='down-arrow-purple' /> : <DownArrow />}
    />
  );
};

AutoComplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      photo: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      value: PropTypes.number
    })
  ]),
  bordered: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultSearchValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  searchable: PropTypes.bool.isRequired,
  clearable: PropTypes.bool.isRequired,
  className: PropTypes.shape({}),
  disabled: PropTypes.bool
};

AutoComplete.defaultProps = {
  value: null,
  disabled: false,
  className: {},
  bordered: false,
  defaultValue: '',
  defaultSearchValue: {}
};

export default AutoComplete;
