import styled from 'styled-components';
import colors from '../../utils/stylesheet/colors';

const onHoverDark = `background-color: ${colors.purple}; color: ${colors.white};`;
const onDisableButton = `opacity:1; pointer-events: none; color: ${colors.white}; background-color: ${colors.disable_grey}; border: 1px solid ${colors.disable_grey};`;
const Button = styled.button`
  user-select: none;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-family: Poppins, serif;
  font-size: ${(props) => (props.small ? '0.75rem' : '0.875rem')};
  font-weight: ${(props) => {
    if (props.secondary) {
      if (props.dark) {
        return 'bold';
      }
      return 'normal';
    }
    return 'bold';
  }};
  line-height: 1.3125rem;
  color: ${(props) => {
    if (props.outline) {
      return colors.red;
    }
    if (props.secondary) {
      if (props.dark) {
        return colors.dark_blue;
      }
      return colors.purple;
    }
    return colors.white;
  }};
  background-color: ${(props) => {
    if (props.outline) {
      return 'transparent';
    }
    if (props.secondary) {
      return 'transparent';
    }
    if (props.danger) {
      return colors.red;
    }
    if (props.dark) {
      return colors.dark_blue;
    }
    return colors.purple;
  }};
  border: 1px solid
    ${(props) => {
      if (!props.danger) {
        if (props.dark) {
          return colors.dark_blue;
        }
        return colors.purple;
      }
      return colors.red;
    }};
  outline: none;
  cursor: pointer;
  padding: ${(props) => (props.small ? '0.25rem 0' : '0.75rem 1rem')};
  border-radius: 100px;
  border-radius: ${(props) => {
    if (props.gameCenter) {
      return '8px';
    }
    return '100px';
  }};
  text-transform: capitalize;
  :active {
    transform: translateY(-1px);
  }
  ${(props) => (!props.noMargin ? 'margin: 4px;' : '')}
  min-width: 8rem;
  ${(props) => (props.disable ? onDisableButton : '')}
  &:hover {
    ${(props) => {
      if (props.secondary) {
        if (!props.dark) {
          return onHoverDark;
        }
        return '';
      }
      return '';
    }}
`;

export default Button;
