import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDeleteAutomationMutation } from 'app/services/home';
import localStorage from 'utils/localStorage';
import AccessRestrictedModal from 'components/AccessRestrictedModal';
import Modal from './Delete';

const DeleteAutomation = (props) => {
  const { open, automationId, handleCancel } = props;
  const platform = localStorage.getPlatform();
  const isAllowedToHandleAutomations = useSelector((state) => state.user.user.isAllowedToStart);
  const [deleteAutomation, { isLoading: isUpdating }] = useDeleteAutomationMutation();

  const handleConfirm = async () => {
    await deleteAutomation(automationId);
    handleCancel();
  };

  if (platform === 'slack' && !isAllowedToHandleAutomations) {
    return <AccessRestrictedModal open={open} onCancel={handleCancel} />;
  }

  return (
    <Modal
      open={open}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      disableConfirm={isUpdating}
    />
  );
};

DeleteAutomation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  automationId: PropTypes.string.isRequired
};

export default DeleteAutomation;
