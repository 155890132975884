import React from 'react';
import PropTypes from 'prop-types';
import { DatePickerInput as DateRangePicker } from '@mantine/dates';
import styles from './styles.module.scss';

const DatePicker = (props) => {
  const { placeholder, onChange, date, hidden, close } = props;

  return (
    <DateRangePicker
      type='range'
      allowSingleDateInRange
      placeholder={placeholder}
      classNames={{
        wrapper: styles.wrapper,
        input: styles.hiddenInputWrapper,
        calendar: styles.dropdownWrapper,
        calendarHeaderLevel: styles.calendarHeaderLevel,
        calendarHeaderControl: styles.calendarControls,
        outside: styles.outsideItem,
        day: styles.dayItem,
        weekend: styles.weekendItem,
        pickerControl: styles.dayItem,
        icon: styles.dropDownIcon,
        rightSection: styles.rangePickerRightSection
      }}
      value={date}
      onChange={(val) => onChange(val)}
      data-testid='mantine-testid'
      id='mantine'
      size='md'
      withCellSpacing={false}
      popoverProps={{ opened: hidden, closeOnClickOutside: true, onClose: close }}
    />
  );
};

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  date: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.instanceOf(Date))
  ]),
  hidden: PropTypes.bool,
  close: PropTypes.func
};

DatePicker.defaultProps = {
  placeholder: 'Date Range',
  date: '',
  onChange: () => null,
  hidden: false,
  close: () => null
};

export default DatePicker;
