/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import faq from 'data/faq';
import { Section } from '@springrole/trivia-games';
import { ReactComponent as DownArrow } from 'assets/images/down.svg';
import styles from './styles.module.scss';

const FAQSection = (props) => {
  const { platform, channelBasedBilling } = props;
  const questions = faq[platform];
  const [expandedItem, setExpandedItem] = useState(-1);

  const toggleItem = (index) => {
    if (index === expandedItem) {
      setExpandedItem(-1);
    } else {
      setExpandedItem(index);
    }
  };

  return (
    <Section>
      <div className={styles.FAQSectionWrapper}>
        <div className={styles.FAQHeaderWrapper}>
          <div className={styles.haveMoreLink}>
            Have more questions?
            <span className={styles.link}>
              {/* 
            TODO: need to update the link
            */}
              <a
                href='https://calendly.com/trivia-1/trivia-demo/?month=2023-02'
                target='blank'
                rel='noreferrer'
              >
                {' '}
                Ask us directly.
              </a>
            </span>
          </div>
          <div className={styles.subHeading}>Frequently Asked Questions</div>
        </div>
        <div className={styles.questionsWrapper}>
          {questions.map((faqItem, index) => (
            <div
              key={faqItem.id}
              className={`${styles.faqItems} ${expandedItem === index ? styles.openedAnswer : ''}`}
            >
              <div className={styles.faqQuestion}>
                <div className={styles.question}>
                  {platform === 'slack' && channelBasedBilling && index === 1
                    ? faqItem.QuestionChannelBased
                    : faqItem.Question}
                </div>
                <div
                  className={`${styles.toggleButton} ${
                    expandedItem === index ? styles.open : styles.closed
                  }`}
                  onClick={() => toggleItem(index)}
                >
                  <span>
                    <DownArrow stroke='white' />
                  </span>
                </div>
              </div>
              <div className={styles.answer}>
                {expandedItem === index && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        channelBasedBilling && index === 1
                          ? faqItem.AnswerChannelBased
                          : faqItem.Answer
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

FAQSection.propTypes = {
  channelBasedBilling: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired
};

export default FAQSection;
