import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PlanCardTooltip } from 'components/Tooltip';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import { Modal, MultiSelectDropdown, Button } from '@springrole/trivia-games';
import { useGetAdminsQuery } from 'app/services/settings';
import calculateTieredPricing, { getListAsString } from 'utils/utilities';
import styles from './styles.module.scss';

const StandardProOverviewSection = (props) => {
  const {
    plan,
    term,
    channelBasedBilling,
    handleClick,
    isModalOpen,
    uniqueUsersCount,
    usersCount,
    channels,
    selectedChannels,
    hasChanges,
    setHasChanges,
    setSelectedChannels,
    dropDownChannelOptions,
    handleClickInChannelDropdown,
    handleManageChannelClick,
    isCalculatingUniqueUsersCount,
    updateChannelListLoading,
    setUpdateChannelListLoading,
    toggleStandardProModal,
    openCustomerPortal
  } = props;
  const [originalSelectedChannels, setOriginalSelectedChannels] = useState(selectedChannels);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const userDetails = useSelector((state) => state.user);
  const { data: adminsData = { admins: [] } } = useGetAdminsQuery(true, {
    skip: userDetails?.isAdmin
  });
  const billingUsersCount = channelBasedBilling ? uniqueUsersCount : usersCount;
  const NextMonthEstimateCosts = calculateTieredPricing(billingUsersCount, plan, term);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      setHasChanges(false);
      setOriginalSelectedChannels(selectedChannels);
    }
  }, [isModalOpen]);

  useEffect(() => {
    const userCountChanged = uniqueUsersCount !== usersCount;
    setHasChanges(userCountChanged);
  }, [uniqueUsersCount, usersCount]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setPopupVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTogglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div className={styles.standardProOverviewWrapper} ref={wrapperRef}>
      <div
        className={`${styles.commonOverview} ${
          !channelBasedBilling && styles.nonChannelBasedOverview
        }`}
      >
        <div className={styles.overviewPlan}>
          <div className={styles.planDetails}>
            <div className={styles.heading}>Plan Details</div>
            <div className={styles.manageButton}>
              {(plan !== 'PRO' || term !== 'year') && (
                <Button
                  onClick={() => {
                    openCustomerPortal();
                  }}
                >
                  Upgrade
                </Button>
              )}
              <div className={styles.threeDots} onClick={handleTogglePopup}>
                &#x2022;&#x2022;&#x2022;
              </div>
              {isPopupVisible && (
                <div className={styles.popup}>
                  <div className={styles.closeButton} onClick={handleTogglePopup}>
                    <Button
                      secondary
                      onClick={() => {
                        openCustomerPortal();
                      }}
                    >
                      Cancel Plan
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.planDetailBillingSection}>
            <div>
              {plan} (
              <span className={styles.basicMontlyStyles}>
                {term.charAt(0).toUpperCase() + term.slice(1)}ly
              </span>
              )<div className={styles.currentPlan}>Current plan</div>
            </div>
            <div>
              ${NextMonthEstimateCosts.totalCost}{' '}
              <div className={styles.currentPlan}>Next billing Estimate</div>
            </div>
          </div>
        </div>
        <div className={`${styles.overviewPlan} ${styles.channelsAndUsers}`}>
          <div className={styles.planDetails}>
            <div className={styles.heading}>
              {channelBasedBilling ? `Channels & Users` : `User's Details`}
            </div>
            {channelBasedBilling && (
              <div onClick={toggleStandardProModal} className={styles.manageChannels}>
                Manage Channels
              </div>
            )}
          </div>
          <div className={styles.channelAndUserInfo}>
            {channelBasedBilling && (
              <div>
                {channels.slice(0, 2).map((channel, index) => (
                  <span key={channel.label} className={styles.channel}>
                    {channel.label}
                    {index === 0 && channels.length > 1
                      ? ', '
                      : index === 0 && channels.length <= 1 && ''}
                    {index === 1 && channels.length > 2 && ' and '}
                  </span>
                ))}
                {channels.length > 2 && (
                  <span className={styles.channel}>{channels.length - 2} more</span>
                )}{' '}
                <span>
                  <PlanCardTooltip
                    label={` Trivia enabled channels , only unique\nusers in these channels will be billed `}
                  >
                    <InfoIcon />
                  </PlanCardTooltip>
                </span>
                <div className={styles.currentPlan}>Channels Enabled </div>
              </div>
            )}
            <div>
              {usersCount}{' '}
              <span>
                <PlanCardTooltip
                  label={
                    channelBasedBilling
                      ? `Unique users are the individuals of the channels where Trivia \nis enabled.Billing is per user means it's charged once, regardless \nof their participationin multiple Trivia-enabled channels.`
                      : `Any user who plays a game or uses other\nTrivia features are called Trivia Users.`
                  }
                >
                  <InfoIcon />
                </PlanCardTooltip>
              </span>
              <div className={styles.currentPlan}>
                {!channelBasedBilling ? 'Active users' : 'Unique users'}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onCancel={() => {
            handleClick();
            setUpdateChannelListLoading(false);
            setSelectedChannels(originalSelectedChannels);
          }}
          onConfirm={() => {
            if (userDetails.isAdmin) {
              setUpdateChannelListLoading(true);
              handleManageChannelClick();
            }
          }}
          title='Manage channels'
          cancelText='Go Back'
          confirmText='Update'
          loading={
            isCalculatingUniqueUsersCount || (updateChannelListLoading && userDetails.isAdmin)
          }
          showConfirm={userDetails.isAdmin}
        >
          <div className={styles.manageChannelsModal}>
            {userDetails.isAdmin ? (
              <>
                <div className={styles.txt}>CHANNELS TO ENABLE</div>
                <MultiSelectDropdown
                  placeholder='search or choose channels'
                  value={selectedChannels}
                  data={dropDownChannelOptions}
                  onChange={(selectedChannel) => {
                    handleClickInChannelDropdown(selectedChannel);
                  }}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                />
                <div className={styles.note}>
                  Note: Only unique users from the above selected channels will be billed and
                  allowed to play Trivia
                </div>
                <div className={styles.modalUniqueUsers}>
                  👥 Total Unique users- <span>{uniqueUsersCount}</span>
                </div>
                {hasChanges && (
                  <div className={styles.attentionMessage}>
                    Attention: You have {usersCount - uniqueUsersCount < 0 ? 'added' : 'removed'} a
                    new channel and your Total unique user list has been
                    {usersCount - uniqueUsersCount < 0 ? ' increased' : ' decreased'} by{' '}
                    <span>{Math.abs(usersCount - uniqueUsersCount)} users</span>. Your next billing
                    will reflect these changes once you update.
                  </div>
                )}
              </>
            ) : (
              <div className={styles.attentionMessage}>
                You cannot add/remove trivia from any channel. Please contact your trivia admins.
                <br />
                Admins:{' '}
                {getListAsString(
                  adminsData?.admins?.map((admin) => admin.name),
                  3
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

StandardProOverviewSection.propTypes = {
  plan: PropTypes.string.isRequired,
  channelBasedBilling: PropTypes.bool.isRequired,
  term: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  uniqueUsersCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasChanges: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  setSelectedChannels: PropTypes.func.isRequired,
  dropDownChannelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  handleClickInChannelDropdown: PropTypes.func.isRequired,
  handleManageChannelClick: PropTypes.func.isRequired,
  isCalculatingUniqueUsersCount: PropTypes.bool.isRequired,
  updateChannelListLoading: PropTypes.bool.isRequired,
  setUpdateChannelListLoading: PropTypes.func.isRequired,
  toggleStandardProModal: PropTypes.bool.isRequired,
  openCustomerPortal: PropTypes.func.isRequired
};

export default StandardProOverviewSection;
