import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/Layout';
import { ReactComponent as Bulb } from 'assets/images/bulb.svg';
import { Section, Loading } from '@springrole/trivia-games';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCustomQuizzesQuery, customQuizResultsApi } from 'app/services/custom-quiz-results';
import RestrictedAccess from 'components/RestrictedAccess';
import { identify, track } from 'utils/segment';
import QuizzesList from './QuizzesList';
import Result from './Result';
import styles from './styles.module.scss';

export const CustomQuizzes = () => {
  const userDetails = useSelector((state) => state.user);
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzes, setQuizzes] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const listReference = useRef(null);
  const dispatch = useDispatch();

  const {
    data: customQuizzes = { hasMore: false },
    isFetching,
    isLoading
  } = useGetCustomQuizzesQuery(currentPage, { skip: !userDetails.user?.isAdmin });

  useEffect(() => {
    if (!isFetching && customQuizzes.quizzes) {
      setQuizzes((prevQuizzes) => [...prevQuizzes, ...customQuizzes.quizzes]);
      setHasMore(customQuizzes.hasMore);
    }
  }, [customQuizzes]);

  function handleQuizSelect(quizId) {
    setId(quizId);
    if (quizId) {
      window.history.pushState(null, '', `/custom-quiz-results?id=${quizId}`);
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  }

  const quizId = new URLSearchParams(window.location.search).get('id');
  useEffect(() => {
    handleQuizSelect(quizId);
  }, [quizId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gameId = params.get('id');
    if (gameId && userDetails.user.isAdmin) {
      handleQuizSelect(gameId);
    }
  }, []);

  function getMore() {
    dispatch(customQuizResultsApi.endpoints.getCustomQuizzes.initiate(currentPage + 1));
    setCurrentPage((prevValue) => prevValue + 1);
  }

  function goToTop() {
    if (listReference) {
      listReference.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  return !id ? (
    <>
      <div className={styles.header}>
        <Bulb />
        Custom Quiz Result
      </div>
      <Section className={styles.customQuizResultsListContainer}>
        {isLoading ? (
          <Loading height={100} width={100} />
        ) : (
          <QuizzesList
            noQuizzes={quizzes.length === 0}
            recentQuiz={quizzes?.[0] ?? {}}
            handleSelect={(value) => handleQuizSelect(value)}
            length={quizzes.length}
            listReference={listReference}
            quizzes={quizzes.slice(1)}
            getMore={() => getMore()}
            hasMore={hasMore}
            currentPage={currentPage}
            goToTop={() => goToTop()}
          />
        )}
      </Section>
    </>
  ) : (
    <Result id={id} onBack={() => handleQuizSelect(null)} />
  );
};

const CustomQuizResults = () => {
  const userDetails = useSelector((state) => state.user);

  if (userDetails.loading) {
    return (
      <Layout name='Custom Quiz Results'>
        <Loading height={100} width={100} />
      </Layout>
    );
  }

  if (!userDetails.user.isAdmin || ['BASIC', 'STANDARD'].includes(userDetails.plan)) {
    return (
      <Layout name='Custom Quiz Results'>
        <div className={styles.header}>
          <Bulb />
          Custom Quiz Results
        </div>
        <Section>
          <RestrictedAccess
            message={
              !userDetails.user.isAdmin
                ? 'Caution! This page is cordoned off'
                : 'This page is not accessible due to your current payment plan'
            }
            adminRestriction={!userDetails.user.isAdmin}
          />
        </Section>
      </Layout>
    );
  }

  if (!userDetails.loading) {
    const { _id, loggedUsersCount } = userDetails;
    if (_id) {
      identify(_id, { 'Unique Logged In Users': loggedUsersCount });
      track('Visit Dashboard', {
        distinct_id: _id,
        Page: 'CustomQuiz Results',
        Date: new Date(),
        'Unique Logged In Users': loggedUsersCount
      });
    }
  }

  return (
    <Layout name='Custom Quiz Results'>
      <CustomQuizzes />
    </Layout>
  );
};

export default CustomQuizResults;
