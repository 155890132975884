import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import localStorage from 'utils/localStorage';

const baseUrl = process.env.REACT_APP_TRIVIA_WEB_API;

const initialState = {
  categories: {
    categories: [],
    specialCategories: []
  }
};

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (header) => {
      header.set('authorization', localStorage.getToken());
      return header;
    }
  }),
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    // GET /categories endpoint
    getCategories: builder.query({
      query: () => ({
        url: '/categories'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return [...response.categories.specialCategories, ...response.categories.categories];
        }
        toast.error(response.message);
        return initialState.categories;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch categories');
        }
        return initialState.categories;
      }
    })
  })
});

export const { useGetCategoriesQuery } = categoriesApi;
