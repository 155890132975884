import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  quiz: [],
  quizzes: [],
  createQuiz: {
    categoryname: '',
    count: '',
    created_at: '',
    questions: [],
    teamId: '',
    trigger_id: '',
    updateAt: '',
    userid: '',
    username: '',
    warning: false,
    workspace: '',
    _id: ''
  },
  schedule: {
    scheduleId: '',
    time: ''
  }
};

export const customQuizApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /custom-quiz/all
    getQuizzes: build.query({
      query: () => ({
        url: '/custom-quiz/all'
      }),
      providesTags: ['Quizzes'],
      transformResponse: (response) => {
        if (response.ok) {
          return response.quizzes;
        }
        toast.error(response.message);
        return initialState.quizzes;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch Quizzes');
        }
        return initialState.quizzes;
      }
    }),

    // GET /custom-quiz/incomplete
    getIncompleteQuiz: build.query({
      query: (params) => ({
        url: '/custom-quiz/incomplete',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.questions;
        }
        toast.error(response.message);
        return initialState.quizzes;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch Incomplete Quizzes');
        }
        return initialState.quizzes;
      }
    }),

    // POST /custom-quiz
    createQuiz: build.mutation({
      query: (body) => ({
        url: '/custom-quiz',
        method: 'POST',
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getQuizzes', undefined, (draft) => {
                draft.push({
                  catName: response.quiz.categoryname,
                  schedules: [],
                  incomplete: true,
                  createdBy: response.quiz.username,
                  id: response.quiz._id
                });
              })
            );
            toast.success('Quiz created successfully');
          } else {
            toast.error('Failed to create quiz');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to create custom quiz');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /custom-quiz
    addQuestion: build.mutation({
      query: (body) => ({
        url: '/custom-quiz',
        method: 'PATCH',
        body
      }),
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error(response.data.message);
        }
        return response.data;
      }
    }),

    // PATCH /custom-quiz/confirm
    completeQuiz: build.mutation({
      query: (body) => ({
        url: '/custom-quiz/confirm',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Quizzes'],
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error(response.data.message);
        }
        return response.data;
      }
    }),

    // GET /custom-quiz
    getQuiz: build.query({
      query: (params) => ({
        url: '/custom-quiz',
        params
      }),
      providesTags: ['Quiz']
    }),

    // DELETE /custom-quiz
    deleteQuiz: build.mutation({
      query: (params) => ({
        url: '/custom-quiz',
        method: 'DELETE',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getQuizzes', undefined, (draft) => {
                const customQuizzes = current(draft);
                return customQuizzes.filter((quiz) => quiz.id !== params.id);
              })
            );
            toast.success('Custom Quiz deleted successfully');
          } else {
            toast.error('Failed to delete quiz');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to delete custom quiz');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /custom-quiz/question
    editQuestion: build.mutation({
      query: (body) => ({
        url: '/custom-quiz/question',
        method: 'PATCH',
        body
      }),
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error(response.data.message);
        }
        return response.data;
      }
    }),

    // POST /custom-quiz/schedule
    createSchedule: build.mutation({
      query: (body) => ({
        url: '/custom-quiz/schedule',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Quizzes']
    }),

    // PATCH /custom-quiz/schedule
    updateSchedule: build.mutation({
      query: (body) => ({
        url: '/custom-quiz/schedule',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Quizzes']
    }),

    // DELETE /custom-quiz/schedule
    deleteSchedule: build.mutation({
      query: (params) => ({
        url: '/custom-quiz/schedule',
        method: 'DELETE',
        params
      }),
      invalidatesTags: ['Quizzes']
    })
  })
});

export const {
  useGetQuizzesQuery,
  useGetIncompleteQuizQuery,
  useCreateQuizMutation,
  useAddQuestionMutation,
  useCompleteQuizMutation,
  useGetQuizQuery,
  useDeleteQuizMutation,
  useEditQuestionMutation,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation
} = customQuizApi;
