import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HotCoffee } from 'assets/images/hot-coffee.svg';
import Button from 'components/Button';
import styles from './JoinNowAction.module.scss';

const JoinNowAction = ({ loading, partner, joinRoom }) => {
  const none = '';
  return (
    <div className={styles.contentWrapper}>
      <HotCoffee className={styles.hotCoffee} />
      {loading ? (
        <div className={styles.bodyWrapper}>
          <div className={styles.text}>Brewing a hot cup of coffee for you...</div>
          <div className={styles.message}>You’ll be able to join in just a moment</div>
          <div className={styles.load} />
        </div>
      ) : (
        <div className={styles.bodyWrapper}>
          <div className={styles.text}>Your VirtualCoffee is ready</div>
          {partner.name === '' ? (
            <div className={styles.message}>No one else is here</div>
          ) : (
            <div className={styles.partnersDetail}>
              <div className={styles.circle}>
                <img
                  className={styles.image}
                  src={
                    partner.image ||
                    `https://ui-avatars.com/api/?background=774EB6&bold=true&color=F2F2F2&length=1&name=${partner.name}`
                  }
                  alt={partner.name}
                />
              </div>
              <div className={styles.message}>{partner.name} has joined</div>
            </div>
          )}
          <Button className={styles.button} onClick={joinRoom}>
            Join now
          </Button>
        </div>
      )}
      {none}
    </div>
  );
};

JoinNowAction.propTypes = {
  loading: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string
  }),
  joinRoom: PropTypes.func
};

JoinNowAction.defaultProps = {
  partner: {
    name: ''
  },
  joinRoom: () => null
};

export default JoinNowAction;
