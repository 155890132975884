import React, { useEffect, useState } from 'react';
import { useUpdateUserMutation } from 'app/services/user';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ContactView from './ContactView';

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    email: '',
    phone: '',
    validlength: 0,
    invalidEmail: false,
    emailTouched: false,
    invalidPhone: false,
    phoneTouched: false,
    saved: false
  });
  const userDetails = useSelector((state) => state.user);
  useEffect(() => {
    // When alternateEmail is present use value of alternateEmail otherwise use value of email
    const emailVal = userDetails.user?.alternateEmail ?? userDetails.user?.email;
    setContactInfo((prevContactInfo) => ({
      ...prevContactInfo,
      email: emailVal ?? '',
      phone: userDetails?.user?.phone ?? ''
    }));
  }, [userDetails]);
  const [updateUser, { isLoading: isUserUpdating, error: userError }] = useUpdateUserMutation();

  const handleSave = () => {
    const info = {};
    const { email, emailTouched, phoneTouched, invalidPhone } = contactInfo;
    let { phone } = contactInfo;
    // To remove any '+' character in phone string
    phone = phone.replace('+', '');

    if (email && email !== '' && emailPattern.test(email)) {
      info.email = email;
    } else if (email === '' && emailTouched) {
      info.email = '';
    }

    if (!invalidPhone && phone && phone !== '' && contactInfo.validlength) {
      info.phone = `+${phone}`;
    } else if (phone === '' && phoneTouched) {
      info.phone = '';
    }
    if (!isEmpty(info) && emailPattern.test(email) && !invalidPhone) {
      setContactInfo((prevContact) => ({ ...prevContact, saved: true }));
      updateUser({ email: info.email, phone: info.phone });
      if (!isUserUpdating) {
        setTimeout(() => {
          setContactInfo((prevContact) => ({
            ...prevContact,
            saved: false,
            invalidEmail: false,
            invalidPhone: false,
            emailTouched: false,
            phoneTouched: false
          }));
        }, 3000);
      }
    }
  };

  const handleEmailChange = ({ target: { value } }) => {
    setContactInfo((prevContact) => ({
      ...prevContact,
      email: value,
      emailTouched: true
    }));
  };

  const isEmailValid = ({ target: { value } }) => {
    if (value !== '') {
      setContactInfo((prevContact) => ({
        ...prevContact,
        invalidEmail: !emailPattern.test(value)
      }));
    }
  };

  const savePhone = (phone, validlength) => {
    setContactInfo((prevContact) => ({
      ...prevContact,
      phone,
      validlength,
      phoneTouched: true
    }));
  };

  const validatePhone = (phone) => {
    if (phone.length > 1) {
      setContactInfo((prevContact) => ({
        ...prevContact,
        invalidPhone: phone.length !== contactInfo.validlength
      }));
    }
  };

  return (
    <ContactView
      handleSave={handleSave}
      handleEmailChange={handleEmailChange}
      isEmailValid={isEmailValid}
      savePhone={savePhone}
      validatePhone={validatePhone}
      contactInfo={contactInfo}
      isUserUpdating={isUserUpdating}
      userErrorStatus={userError?.Status}
    />
  );
};

export default Contact;
