import React, { useEffect, useState } from 'react';
import { Loading, Pictionary } from '@springrole/trivia-games';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from 'createHistory';
import localStorage from 'utils/localStorage';
import Layout from 'components/Layout';
import { generateInviteCode, verifyInviteCode } from 'app/services/game-zone';

const PictionaryPage = (props) => {
  const currentUser = useSelector((state) => state.user);
  const loadingUser = useSelector((state) => state.user.loading);
  const { user } = currentUser;
  const { userId, fullname, image } = user;
  const location = useLocation();
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!token) {
      setToken(localStorage.getToken());
    }
  }, [token]);

  useEffect(() => {
    document.title = 'Pictionary | Trivia';
  }, []);

  if (loadingUser) {
    return (
      <Layout game name='Game Zone'>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout game name='Game Zone'>
      {token && (
        <Pictionary
          {...props}
          userId={userId}
          name={fullname}
          image={
            image ??
            `https://ui-avatars.com/api/?background=random&name=${fullname}&length=1&rounded=true&format=png`
          }
          loadingUser={loadingUser}
          GenerateInviteCode={generateInviteCode}
          VerifyInviteCode={verifyInviteCode}
          router={{
            push: (arg) => {
              if (typeof arg === 'string') {
                history.push(arg);
              } else if (typeof arg === 'object') {
                const params = new URLSearchParams(arg);
                history.push(location.pathname, {
                  state: { inviteCode: params.get('inviteCode') }
                });
              }
            }
          }}
          token={token}
          socket_server_key={process.env.REACT_APP_SOCKET_SERVER}
          platform='dashboard'
        />
      )}
    </Layout>
  );
};

export default PictionaryPage;
