import getPrice from './pricing.tier';

const UnlimitedGames = 'Unlimited games';
const UnlimitedTrivia = 'Unlimited Trivia quizzes';
const UnlimitedUPO = 'Unlimited Unpopular Opinions';
const UnlimitedGotcha = 'Unlimited games of Gotcha!';
const UnlimitedWordPuzzles = 'Unlimited Word Puzzles';
const ScheduleGames = 'Schedule games';
const Leaderboard = 'Trivia Leaderboard';
const CustomQuiz = 'Custom quizzes';

const plans = [
  {
    key: 'basic',
    plan: 'Basic',
    price: 'Free',
    features: ['5 games each / month'],
    subTitle: 'No player limit',
    total: 0,
    minUsers: 1,
    maxUsers: -1,
    featuresList: [
      '5 Trivia quizzes',
      '5 games of Gotcha!',
      '5 Unpopular Opinions',
      '5 Word Puzzles'
    ]
  },
  {
    key: 'standard',
    plan: 'Standard',
    price: '$29',
    features: [UnlimitedGames, 'Scheduling'],
    subTitle: '',
    total: 29,
    minUsers: 1,
    maxUsers: 100,
    featuresList: [
      UnlimitedTrivia,
      UnlimitedGotcha,
      UnlimitedUPO,
      UnlimitedWordPuzzles,
      ScheduleGames,
      Leaderboard
    ]
  },
  {
    key: 'pro',
    plan: 'Pro',
    price: '$49',
    features: [UnlimitedGames, CustomQuiz, 'Scheduling'],
    subTitle: '',
    total: 49,
    minUsers: 101,
    maxUsers: 250,
    featuresList: [
      UnlimitedTrivia,
      UnlimitedGotcha,
      UnlimitedUPO,
      UnlimitedWordPuzzles,
      ScheduleGames,
      CustomQuiz,
      Leaderboard
    ]
  },
  {
    key: 'ltd',
    plan: 'Lifetime Deal',
    price: 'Lifetime Deal',
    subText: '',
    features: [UnlimitedGames, 'Scheduling'],
    subTitle: '',
    featuresList: [
      UnlimitedTrivia,
      UnlimitedGotcha,
      UnlimitedUPO,
      UnlimitedWordPuzzles,
      ScheduleGames
    ]
  },
  {
    key: 'enterprise',
    plan: 'Enterprise',
    price: 'TBD',
    features: [UnlimitedGames, CustomQuiz, 'Scheduling'],
    subTitle: '',
    total: 0,
    minUsers: -1,
    maxUsers: -1,
    featuresList: [
      UnlimitedTrivia,
      UnlimitedGotcha,
      UnlimitedUPO,
      UnlimitedWordPuzzles,
      ScheduleGames,
      CustomQuiz,
      Leaderboard
    ]
  }
];

// for table
const pricingFeatures = (platform) => [
  {
    plan: 'Basic',
    features: [
      'Unlimited Instant Games',
      'No Customizable Quizzes',
      '3 Participants per game',
      'Unlimited VirtualCoffee channels',
      `${platform === 'teams' ? 'Unlimited' : '3 pairings'} VirtualCoffee pairings`,
      'No Organization-wide Leaderboard',
      'No Notification controls',
      ...(platform === 'slack' ? ['No Secret Santa'] : []),
      ...(platform === 'slack' ? ['No Calendar Integration for VirtualCoffee'] : []),
      ...(platform === 'slack' ? [' Tale Trail game for 30 days.'] : [])
    ]
  },
  {
    plan: 'Standard',
    features: [
      'Unlimited Instant Games',
      'No Customizable Quizzes',
      'Unlimited Participants per game',
      'Unlimited VirtualCoffee channels',
      `${platform === 'teams' ? 'Unlimited' : '25 pairings'} VirtualCoffee pairings`,
      'Organization-wide Leaderboard',
      'Notification controls',
      ...(platform === 'slack' ? ['Secret Santa'] : []),
      ...(platform === 'slack' ? ['No Calendar Integration for VirtualCoffee'] : []),
      ...(platform === 'slack' ? [' Tale Trail game for 30 days.'] : [])
    ]
  },
  {
    plan: 'Pro',
    features: [
      'Unlimited Instant Games',
      'Unlimited Customizable Quizzes',
      'Unlimited Participants per game',
      'Unlimited VirtualCoffee channels',
      'Unlimited VirtualCoffee pairings',
      'Organization-wide Leaderboard',
      'Notification controls',
      ...(platform === 'slack' ? ['Secret Santa'] : []),
      ...(platform === 'slack' ? ['Calendar Integration for VirtualCoffee'] : []),
      ...(platform === 'slack' ? ['Unlimited Tale Trail'] : [])
    ]
  }
];

const getFeatures = (plan, platform) =>
  pricingFeatures(platform).filter((feature) => plan === feature.plan && feature.features)[0];

const getPlans = (paidOnly, activeUsers, platform) => {
  let retPlans = plans.filter((plan) => !['ltd', 'enterprise'].includes(plan.key));
  if (paidOnly) {
    retPlans = retPlans.filter((plan) => !['basic', 'enterprise'].includes(plan.key));
  }
  retPlans = retPlans.map((plan) => {
    const { price, total } = getPrice(plan.key, activeUsers);
    const allFeatures = getFeatures(plan.plan, platform).features;
    return { ...plan, price, total, allFeatures };
  });
  return retPlans;
};

export const getEnterpriseCard = () => plans.find((plan) => plan.key === 'enterprise');

export const getLtdCard = (planName) => {
  const plan = plans.find((p) => p.key === 'ltd');
  const planUsers = `REACT_APP_MAX_USERS_FOR_${planName}`;
  switch (planName) {
    case 'LTD-49':
    case 'LTD-59':
      plan.featuresList = [
        UnlimitedTrivia,
        UnlimitedGotcha,
        UnlimitedUPO,
        UnlimitedWordPuzzles,
        ScheduleGames,
        'Workspace Leaderboard'
      ];
      plan.features = [UnlimitedGames, 'Scheduling'];
      plan.subText = 'STANDARD';
      break;
    case 'LTD-97':
    case 'LTD-177':
      plan.featuresList = [
        UnlimitedTrivia,
        UnlimitedGotcha,
        UnlimitedUPO,
        UnlimitedWordPuzzles,
        ScheduleGames,
        'Workspace Leaderboard',
        CustomQuiz
      ];
      plan.features = [UnlimitedGames, 'Scheduling', CustomQuiz];
      plan.subText = 'PRO';
      break;
    default:
      plan.featuresList = [UnlimitedGames, ScheduleGames];
  }
  plan.plan = planName;
  plan.subTitle = `For organisations below ${process.env[planUsers]} people`;
  return plan;
};

export const proTiers = [
  { min: 1, max: 25, unitPriceYearly: 0.0, unitPriceMonthly: 0.0, flatAmount: 479.0 },
  { min: 26, max: 50, unitPriceYearly: 19.2, unitPriceMonthly: 2.0, flatAmount: 0.0 },
  { min: 51, max: 100, unitPriceYearly: 15.36, unitPriceMonthly: 1.6, flatAmount: 0.0 },
  { min: 101, max: 200, unitPriceYearly: 13.44, unitPriceMonthly: 1.4, flatAmount: 0.0 },
  { min: 201, max: 250, unitPriceYearly: 11.52, unitPriceMonthly: 1.2, flatAmount: 0.0 },
  { min: 251, max: Infinity, unitPriceYearly: 9.6, unitPriceMonthly: 1.0, flatAmount: 0.0 }
];

export const standardTiers = [
  { min: 1, max: 29, unitPriceYearly: 0.0, unitPriceMonthly: 0.0, flatAmount: 279.0 },
  { min: 30, max: 50, unitPriceYearly: 9.6, unitPriceMonthly: 1.0, flatAmount: 0.0 },
  { min: 51, max: 100, unitPriceYearly: 7.68, unitPriceMonthly: 0.8, flatAmount: 0.0 },
  { min: 101, max: 200, unitPriceYearly: 6.72, unitPriceMonthly: 0.7, flatAmount: 0.0 },
  { min: 201, max: 250, unitPriceYearly: 5.76, unitPriceMonthly: 0.6, flatAmount: 0.0 },
  { min: 251, max: Infinity, unitPriceYearly: 4.8, unitPriceMonthly: 0.5, flatAmount: 0.0 }
];

export default getPlans;
