import React from 'react';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const teamsFooterDetails = (type, callback, teamsSubscription) => {
  switch (type) {
    case 'buy':
      return {
        heading: '⚠️ Your free trial is over :(',
        description: (
          <>
            To access Unlimited Trivia fun.., bring your teammates together and see <br /> them
            bond,buy a subscription now!
          </>
        ),
        buttonText: 'Buy a subscription',
        buttonAction: callback
      };

    case 'assign':
      return {
        heading: 'Activate Trivia for your teammates',
        description: (
          <>
            You have bought {teamsSubscription?.quantity}{' '}
            {maybePluralize(teamsSubscription?.quantity, 'License')}. To activate Trivia for your
            teammates, assign {maybePluralize(teamsSubscription?.quantity, 'License')} to them.
          </>
        ),
        buttonText: 'Assign Licenses',
        buttonAction: callback
      };

    case 'request':
      return {
        heading: (
          <>
            <Lock className={styles.locked} /> The dashboard is restricted to Licensed users.
          </>
        ),
        description: <>To view dashboard data, request your admins to assign a License to you.</>,
        buttonText: 'Request License',
        buttonAction: callback
      };

    default:
      return null;
  }
};

export default teamsFooterDetails;
