import React from 'react';
import NBA from 'assets/images/billing/companyLogos/NBA.svg';
import Shopify from 'assets/images/billing/companyLogos/Shopify.svg';
import Uber from 'assets/images/billing/companyLogos/Uber.svg';
import quora from 'assets/images/billing/companyLogos/quora.svg';
import OLX from 'assets/images/billing/companyLogos/OLX.svg';
import EASports from 'assets/images/billing/companyLogos/ea-sports.svg';
import DoorDash from 'assets/images/billing/companyLogos/door-dash.svg';
import diamond from 'assets/images/billing/diamond.svg';
import styles from './styles.module.scss';

const CompanySection = () => (
  <div className={styles.companySectionWrapper}>
    <div className={styles.premiumDiamond}>
      <img src={diamond} alt='Diamond' />
    </div>
    <div className={styles.companyHeaderWrapper}>
      <div className={styles.companyHeader}>Join 30,000+ teams using trivia.</div>
      <div className={styles.companyLogosWrapper}>
        <img className={(styles.Logos, styles.quora)} src={Shopify} alt='Shopify' />
        <img className={styles.Logos} src={Uber} alt='Company Logo' />
        <img className={styles.Logos} src={NBA} alt='Company Logo' />
        <img className={(styles.Logos, styles.quora)} src={quora} alt='Company Logo' />
        <img className={styles.Logos} src={OLX} alt='Company Logo' />
        <img className={styles.Logos} src={EASports} alt='Company Logo' />
        <img className={(styles.Logos, styles.doorDash)} src={DoorDash} alt='Company Logo' />
      </div>
    </div>
  </div>
);

export default CompanySection;
