import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from 'utils/hooks';
import { Button } from '@springrole/trivia-games';
import history from 'createHistory';
import localStorage from 'utils/localStorage';
import { ReactComponent as Triangle } from '../../assets/images/dashboard/triangle.svg';
import styles from './styles.module.scss';

const LimitReached = (props) => {
  const { width } = useWindowSize();
  const platform = localStorage.getPlatform();
  const { message, homeTrend } = props;
  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <Triangle
          className='triangle-svg'
          height={width < 750 ? 45 : 30}
          width={width < 750 ? 45 : 30}
          data-testid='triangle_svg'
        />

        <div className={styles.text}>{message}</div>
      </div>

      <Button
        style={{
          fontWeight: '600',
          fontSize: '14px',
          padding: width < 650 ? '8px 40px' : '13px 40px'
        }}
        onClick={
          platform === 'teams' && homeTrend
            ? () => {
                window.open(process.env.REACT_APP_TEAMS_APP_SOURCE_URL);
              }
            : () => {
                history.push('/billing');
              }
        }
      >
        Upgrade now
      </Button>
    </div>
  );
};

LimitReached.propTypes = {
  message: PropTypes.string.isRequired,
  homeTrend: PropTypes.bool
};

LimitReached.defaultProps = {
  homeTrend: false
};
export default LimitReached;
