import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import SearchAutocomplete from 'components/Autocomplete/SearchAutoComplete';
import classNames from 'classnames';
import Avatar from 'assets/images/avatar.svg';
import Switch from 'components/Switch';
import { Grid } from '@mantine/core';
import history from '../../../createHistory';
import styles from './styles.module.scss';

const TriviaAccessView = (props) => {
  const {
    allowedToView,
    availableUsers,
    handleAccessToggle,
    handleSelection,
    handleRemove,
    triviaAccess,
    allowedUsers
  } = props;
  return (
    <div className={styles.accessSection}>
      <div className={styles.header}>Control access to Trivia</div>
      {allowedToView ? (
        <div className={styles.switchContainer}>
          <Grid>
            <Grid.Col span={11} className={classNames(styles.typeTableColumn, styles.subHeader)}>
              Limit access to launch Games and manage VirtualCoffee to specific people.
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch checked={triviaAccess.restricted} handleChange={handleAccessToggle} />
            </Grid.Col>
          </Grid>
        </div>
      ) : (
        <>
          <div className={classNames(styles.switchContainer)}>
            <Grid>
              <Grid.Col
                span={11}
                style={{ opacity: 0.4 }}
                className={classNames(styles.typeTableColumn, styles.subHeader)}
              >
                Limit access to launch Games and manage VirtualCoffee to specific people.
              </Grid.Col>
              <Grid.Col span={1} className={classNames(styles.typeTableColumn, styles.subHeader)}>
                <Switch
                  checked={triviaAccess.restricted}
                  handleChange={handleAccessToggle}
                  disabled
                />
              </Grid.Col>
            </Grid>
          </div>
          <div className={styles.upgradePlan} onClick={() => history.push('/billing')}>
            Upgrade to paid plan to configure this.
          </div>
        </>
      )}
      {allowedToView && triviaAccess.restricted && (
        <>
          <SearchAutocomplete
            placeholder='Search for a person to give access'
            onSelect={handleSelection}
            showIcon={false}
            items={availableUsers}
            styling={{
              borderRadius: '4px'
            }}
            className={styles.searchBar}
          />
          <div className={styles.accessWorkspaceWrapper}>
            {allowedUsers.length > 0 ? (
              <>
                <p>These people can control Trivia from your workspace.</p>
                <div className={styles.accessWorkspace}>
                  <div className={styles.users}>
                    {allowedUsers.map((user) => (
                      <div key={user.id} className={styles.accessPerson}>
                        <img src={user.photo || Avatar} alt='user' />
                        <label htmlFor='name'>{user.name}</label>
                        <span className={styles.close}>
                          <CloseIcon width='8px' height='8px' />
                        </span>
                        <span className={styles.closeHover}>
                          <CloseIcon
                            width='8px'
                            height='8px'
                            onClick={() => handleRemove(user.id)}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.noUserWrapper}>
                <div className={styles.noUsers}>
                  This is where you will find the list of people who have access to control Trivia.
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
TriviaAccessView.propTypes = {
  availableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string
    })
  ).isRequired,
  handleAccessToggle: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  triviaAccess: PropTypes.shape({
    restricted: PropTypes.bool.isRequired,
    selectedUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        photo: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  allowedToView: PropTypes.bool.isRequired,
  allowedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string
    })
  ).isRequired
};

export default TriviaAccessView;
