import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { useSelector } from 'react-redux';
import localStorage from 'utils/localStorage';
import RestrictedAccess from 'components/RestrictedAccess';
import { identify, track } from 'utils/segment';
import PropTypes from 'prop-types';
import Contact from './Contact';
import AdminAccess from './Adminaccess/AdminAccess';
import TriviaAccess from './TriviaAccess';
import Notification from './Notification';
import styles from './styles.module.scss';

const AdminView = ({ isAdmin, platform, isTenSpot }) =>
  isAdmin ? (
    <div className={styles.adminView}>
      <Contact />
      <AdminAccess />
      {platform === 'slack' && <TriviaAccess />}
      <Notification isTenspot={isTenSpot} />
    </div>
  ) : (
    <RestrictedAccess message='Caution! This page is cordoned off' adminRestriction />
  );

AdminView.propTypes = {
  isAdmin: PropTypes.bool,
  isTenSpot: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired
};
AdminView.defaultProps = {
  isAdmin: false
};

const Settings = () => {
  const platform = localStorage.getPlatform();
  const [isAdmin, setIsAdmin] = useState(localStorage.getAccessLevel() === 'admin');
  const userDetails = useSelector((state) => state.user);
  const isTenSpot = userDetails?.tenspot ?? false;

  useEffect(() => {
    if (!userDetails.loading) {
      const { _id, loggedUsersCount = 0 } = userDetails;
      if (_id) {
        identify(_id, { 'Unique Logged In Users': loggedUsersCount });
        track('Visit Dashboard', {
          distinct_id: _id,
          Page: 'Settings',
          Date: new Date(),
          'Unique Logged In Users': loggedUsersCount
        });
      }
    }
    setIsAdmin(userDetails.isAdmin);
  }, [userDetails]);

  return (
    <Layout name='Settings'>
      <div className={styles.settingsWrapper}>
        <div className={styles.title}>⚙️ Settings</div>
        <AdminView isAdmin={isAdmin} isTenSpot={isTenSpot} platform={platform} />
      </div>
    </Layout>
  );
};

export default Settings;
