import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from './api';

export const initialState = {
  overview: {
    engagementRate: 0,
    gamesPlayed: 0,
    weeklyEngagement: 0,
    uniquePlayers: 0,
    automations: 0
  },
  knowYourTeam: { shares: 0, teammates: 0, quizzes: 0 },
  virtualCoffee: {
    channels: [],
    teammates: 0,
    leftOut: 0,
    rounds: 0,
    lastMatch: true
  },
  trend: {
    limit: 0,
    players: [],
    leftOut: 0
  },
  automations: [],
  stats: {
    participantLeftOut: 0
  }
};

export const homeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET /analytics/overview endpoint
    getAnalyticsOverview: builder.query({
      query: (params) => ({
        url: '/analytics/overview',
        params
      }),
      // transformResponse is used to transform the response data to the format that is required by the reducer/component
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.overview;
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response?.schedules?.length) {
            dispatch(api.util.upsertQueryData('getAutomations', undefined, response.schedules));
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to fetch overview analytics');
          }
          return Promise.resolve();
        }
      }
    }),

    // GET /analytics/know-your-team endpoint
    getKnowYourTeamAnalytics: builder.query({
      query: () => ({
        url: '/analytics/know-your-team'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.knowYourTeam;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch know your team analytics');
        }
        return initialState.knowYourTeam;
      }
    }),

    // GET /analytics/virtualcoffee endpoint
    getVirtualCoffeeAnalytics: builder.query({
      query: () => ({
        url: '/analytics/virtualcoffee'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.virtualCoffee;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch virtual coffee analytics');
        }
        return initialState.virtualCoffee;
      }
    }),

    // POST /virtual-coffee
    addVirtualCoffee: builder.mutation({
      query: (formData) => ({
        url: `/virtualcoffee`,
        method: 'POST',
        body: formData
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getVirtualCoffeeAnalytics', undefined, (draft) => {
                Object.assign(draft, response.data);
              })
            );
            dispatch(
              api.util.updateQueryData('getVcAnalyticsOverview', undefined, (draft) => {
                Object.assign(draft, {
                  lastMatch: response?.data?.lastMatch,
                  nextMatch: response?.data?.nextMatch,
                  usersMatched: response?.data?.usersMatched
                });
              })
            );
            toast.success('VirtualCoffee added successfully');
          } else {
            toast.error('Failed to add VirtualCoffee');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to create virtual coffee');
          }
          return Promise.resolve();
        }
      }
    }),

    // GET /analytics/virtualcoffee/overview endpoint
    getTrends: builder.query({
      query: () => ({
        url: '/analytics/engagement/trend'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.trend;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch trends data');
        }
        return initialState.trend;
      }
    }),

    // GET /automations endpoint
    getAutomations: builder.query({
      query: () => ({
        url: '/automations'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.automations;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch automations');
        }
        return initialState.automations;
      }
    }),

    // POST /automations endpoint
    addAutomation: builder.mutation({
      query: (formData) => ({
        url: `/automations`,
        method: 'POST',
        body: formData
      }),
      // Manually update the cache to add the new automation
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getAutomations', undefined, (draft) => {
                draft.push(response.data);
              })
            );
            dispatch(
              api.util.updateQueryData('getAnalyticsOverview', undefined, (draft) => {
                const overview = current(draft);
                return { ...overview, automations: overview.automations + 1 };
              })
            );
            toast.success('Automation added successfully');
          } else {
            toast.error('Failed to add automation');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to create automation');
          }
          return Promise.resolve();
        }
      }
    }),

    // PUT /automations endpoint
    updateAutomation: builder.mutation({
      query: (formDate) => ({
        url: `/automations`,
        method: 'PUT',
        body: formDate
      }),
      // Manually update the cache to remove the update automation
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getAutomations', undefined, (draft) => {
                let automations = current(draft);
                automations = automations.map((automation) => {
                  if (automation.id === formData.id) {
                    return response.data;
                  }
                  return automation;
                });
                return automations;
              })
            );
            toast.success('Automation updated successfully');
          } else {
            toast.error('Failed to update automation');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update automation');
          }
          return Promise.resolve();
        }
      }
    }),

    // DELETE /automations endpoint
    deleteAutomation: builder.mutation({
      query: (id) => ({
        url: `/automations?id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['AnalyticsOverview'],
      // Manually update the cache to remove the deleted automation
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getAutomations', undefined, (draft) => {
                const automations = current(draft);
                return automations.filter((automation) => automation.id !== id);
              })
            );
            dispatch(
              api.util.updateQueryData('getAnalyticsOverview', undefined, (draft) => {
                const overview = current(draft);
                return { ...overview, automations: overview.automations - 1 };
              })
            );
            toast.success('Automation deleted successfully');
          } else {
            toast.error('Failed to delete automation');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to delete automation');
          }
          return Promise.resolve();
        }
      }
    }),

    // GET /stats endpoint
    getKpiData: builder.query({
      query: () => ({
        url: `/stats`
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.stats;
        }
        toast.error(response.message);
        return initialState.stats;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch KPI data.');
        }
        return initialState.stats;
      }
    })
  })
});

export const {
  useGetAnalyticsOverviewQuery,
  useGetKnowYourTeamAnalyticsQuery,
  useGetVirtualCoffeeAnalyticsQuery,
  useAddVirtualCoffeeMutation,
  useGetTrendsQuery,
  useGetAutomationsQuery,
  useAddAutomationMutation,
  useUpdateAutomationMutation,
  useDeleteAutomationMutation,
  useGetKpiDataQuery
} = homeApi;
