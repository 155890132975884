import { AES, enc } from 'crypto-js';

const FLAG = 'flag';

const uuid4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

// eslint-disable-next-line
export default {
  decryptFlag: (flag) => {
    try {
      const encryptionKey = process.env.REACT_APP_DEFAULT_KEY;
      const data = AES.decrypt(decodeURIComponent(flag), encryptionKey);
      const [status, key, token, isAdmin, platform] = data.toString(enc.Utf8).split('|');
      return { status, key, token, isAdmin, platform };
    } catch (error) {
      return { status: 'INVALID', token: null };
    }
  },
  setFlag: (flag) => localStorage.setItem(FLAG, decodeURIComponent(flag)),
  deleteFlag: () => localStorage.removeItem(FLAG),
  clearStorage: () => localStorage.clear(),
  getToken: () => {
    try {
      const encryptionKey = process.env.REACT_APP_DEFAULT_KEY;
      let flag;
      if (typeof localStorage !== 'undefined' && localStorage) {
        flag = localStorage.getItem(FLAG);
      }
      let data;
      if (!flag) {
        const params = new URLSearchParams(window.location.search);
        flag = params.get('flag');
        if (!flag) {
          return null;
        }
        data = AES.decrypt(decodeURIComponent(flag), encryptionKey);
      } else {
        data = AES.decrypt(flag, encryptionKey);
      }
      const [status, key, token] = data.toString(enc.Utf8).split('|');
      if (status === 'VALID' && key === process.env.REACT_APP_DEFAULT_KEY) {
        return token;
      }
      return null;
    } catch (error) {
      return null;
    }
  },
  getAccessLevel: () => {
    try {
      const flag = localStorage.getItem(FLAG);
      if (!flag) {
        return null;
      }
      const encryptionKey = process.env.REACT_APP_DEFAULT_KEY;
      const data = AES.decrypt(flag, encryptionKey);
      const [status, key, , isAdmin] = data.toString(enc.Utf8).split('|');
      if (status === 'VALID' && key === process.env.REACT_APP_DEFAULT_KEY) {
        return isAdmin === 'true' ? 'admin' : 'user';
      }
      return null;
    } catch (error) {
      return null;
    }
  },
  hasFlag: () => localStorage.getItem(FLAG),
  getPlatform: () => {
    try {
      const flag = localStorage.getItem(FLAG);
      if (!flag) {
        return null;
      }
      const encryptionKey = process.env.REACT_APP_DEFAULT_KEY;
      const data = AES.decrypt(flag, encryptionKey);
      const [status, key, , , platform] = data.toString(enc.Utf8).split('|');
      if (status === 'VALID' && key === process.env.REACT_APP_DEFAULT_KEY) {
        return platform;
      }
      return null;
    } catch (error) {
      return null;
    }
  },
  setTabState: () => {
    const state = uuid4();
    localStorage.setItem('session.state', state);
    return state;
  },
  getTabState: () => localStorage.getItem('session.state'),
  setError: (error) => {
    localStorage.setItem('session.error', JSON.stringify(error));
  },
  clearError: () => {
    localStorage.removeItem('session.error');
  },
  set: (key, value, expiry) => {
    if (expiry) {
      localStorage.setItem(key, JSON.stringify({ value, expiry }));
    } else {
      localStorage.setItem(key, value);
    }
  },
  get: (key) => localStorage.getItem(key),
  clear: (key) => {
    localStorage.removeItem(key);
  }
};
