import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  user: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserInfo: (state, action) =>
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      ({ ...action.payload, loading: false }),
    updateUserInfo: (state, action) => {
      const emailKey =
        (state.user?.alternateEmail && 'alternateEmail') ?? (state.user?.email && 'email');
      state.user[`${emailKey}`] = action.payload.email;
      state.user.phone = action.payload.phone;
    }
  }
});

// Action creators are generated for each case reducer function
export const { saveUserInfo, updateUserInfo } = userSlice.actions;

export default userSlice.reducer;
