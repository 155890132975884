import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Loading } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import { serializeSlackChannels, serializeTeamsChannels } from 'utils/serialization';
import { useGetLeaderboardQuery, leaderboardApi } from 'app/services/leaderboard';
import { useGetChannelsQuery } from 'app/services/channels';
import { useGetTeamsQuery } from 'app/services/teams';
import Section from 'components/Section';
import { getPageData, setLeaderboardLoading } from 'app/features/leaderboardSlice';
import RestrictedAccess from 'components/RestrictedAccess';
import { ReactComponent as Calendar } from 'assets/images/leaderboard/calendar.svg';
import Avatar from 'assets/images/avatar.svg';
import { ReactComponent as Sad } from 'assets/images/sad.svg';
import { Pagination, Row, Header } from 'components/Table';
import localStorage from 'utils/localStorage';
import UserRank from 'components/UserRank';
import AutoComplete from 'components/Autocomplete';
import { getMedalWithRank } from 'utils/utilities';
import { useDisclosure } from '@mantine/hooks';
import DatePicker from 'components/DatePicker';
import Search from 'components/SearchField';
import moment from 'moment';
import CustomTooltip from 'components/Tooltip';
import Cards from './Card';
import history from '../../createHistory';
import styles from './styles.module.scss';

const NO_TRIVIA_MSG = {
  WEEK: ' in the past week',
  MONTH: ' last month',
  ALL_TIME: ' ever',
  custom: ' in this date range'
};

const NO_PARTICIPATION_MSG = {
  WEEK: ' this week',
  MONTH: ' last month',
  ALL_TIME: ' ever',
  custom: ' between the selected dates'
};

const GAME_FORMAT_FOR_SLACK = [
  { label: 'All Games', value: null },
  { label: 'Self-paced Quizzes', value: 'selfPacedQuiz' },
  { label: 'Instant Quizzes', value: 'instantQuiz' },
  { label: 'Image Quizzes', value: 'imageQuiz' },
  { label: 'Custom Quiz', value: 'customQuiz' },
  { label: 'GIF Charades', value: 'charades' },
  { label: 'Emoji Rebus', value: 'emojiRebus' },
  { label: 'True or False', value: 'trueOrFalse' },
  { label: 'Gotcha!', value: 'gotcha' },
  { label: 'Pictionary', value: 'pictionary' },
  { label: 'Hangman', value: 'hangman' },
  { label: 'Riddles!', value: 'riddles' }
];

const GAME_FORMAT_FOR_TEAMS = [
  { label: 'All Games', value: null },
  { label: 'Self-paced Quizzes', value: 'selfPacedQuiz' },
  { label: 'Instant Quizzes', value: 'instantQuiz' },
  { label: 'Image Quizzes', value: 'imageQuiz' },
  { label: 'Custom Quiz', value: 'customQuiz' },
  { label: 'True or False', value: 'trueOrFalse' },
  { label: 'Gotcha!', value: 'gotcha' },
  { label: 'Pictionary', value: 'pictionary' }
];

const TIME_SPAN = [
  { label: 'Last 7 Days', value: 'WEEK' },
  { label: 'Last 30 Days', value: 'MONTH' },
  { label: 'All Time', value: 'ALL_TIME' },
  { label: 'Custom', value: 'custom' }
];

const Standings = (props) => {
  const dispatch = useDispatch();
  const {
    workspace,
    invalidWorkspace,
    allUsers,
    allowedToView,
    isPublic,
    noTrivia,
    isTab,
    pages,
    currentUserData,
    noParticipation,
    isFetching
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [channelOptions, setChannelOptions] = useState([]);
  const [filterState, setFilterState] = useState({
    channel: 'all',
    timespan: 'WEEK',
    date: [null, null],
    gameType: null
  });

  const params = { timespan: 'WEEK' };
  if (isPublic) {
    params.workspaceId = workspace;
  }
  useGetLeaderboardQuery(params);

  const [opened, { close, open }] = useDisclosure(false);

  const platform = localStorage.getPlatform();
  const gameFormatOptions = platform === 'slack' ? GAME_FORMAT_FOR_SLACK : GAME_FORMAT_FOR_TEAMS;
  const { data: channels = [], isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: !platform || platform === 'teams'
  });
  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery(undefined, {
    skip: !platform || platform === 'slack'
  });
  const [search, setSearch] = React.useState('');
  useEffect(() => {
    if (platform === 'slack') {
      setChannelOptions(serializeSlackChannels(channels, true));
    } else {
      setChannelOptions(serializeTeamsChannels(teams, true));
    }
  }, [channelLoading, teamsLoading]);

  useEffect(() => {
    dispatch(getPageData(currentPage));
  }, [currentPage]);

  const convertDateFormat = (value) => {
    let dateRange;
    if (value.length === 2 && value[0] && value[1]) {
      dateRange = {
        start: moment(value[0]).format('MM/DD/YYYY'),
        end: moment(value[1]).format('MM/DD/YYYY')
      };
    }

    return JSON.stringify(dateRange);
  };
  const handleFilterChange = (label, value) => {
    setFilterState((prevFilterState) => ({ ...prevFilterState, [label]: value }));
    const tempParams = {
      ...filterState,
      [label]: value
    };
    if (filterState.timespan === 'custom') {
      tempParams.timespan = filterState.timespan;
    }
    tempParams[label] = value;

    if (!isEmpty(tempParams.date)) {
      tempParams.dateRange = convertDateFormat(tempParams.date);
    }

    if (tempParams.channel === 'all') {
      delete tempParams.channel;
    }
    if (!tempParams.gameType) {
      delete tempParams.gameType;
    }
    if (tempParams.timespan === 'custom') {
      delete tempParams.timespan;
    }
    delete tempParams.date;

    // Not caching the filtered leaderboard query key because
    // we already adding the leaderboard data in the redux store
    dispatch(setLeaderboardLoading(true));
    dispatch(
      leaderboardApi.endpoints.getLeaderboard.initiate(tempParams, {
        subscribe: false,
        forceRefetch: true
      })
    );
  };

  const handleDropdownOnpen = () => {
    if (filterState.timespan === 'custom') {
      setFilterState({ ...filterState, timespan: '', dateRange: null, date: [null, null] });
    }
  };

  const isDatePresent =
    filterState.date.length === 2 && filterState.date[0] !== null && filterState.date[1] !== null;

  const dateRangeValue = `${isDatePresent && moment(filterState.date[0]).format('MMM-DD-YYYY')} -
    ${isDatePresent && moment(filterState.date[1]).format('MMM-DD-YYYY')}`;

  if (invalidWorkspace) {
    history.replace('/public/404');
    return <div />;
  }

  let leaderboard = !search
    ? // eslint-disable-next-line react/destructuring-assignment
      props.leaderboard
    : allUsers?.filter((user) => user?.name?.toLowerCase().includes(search.toLowerCase()));
  const topThree = leaderboard.slice(0, 3);
  leaderboard = !isPublic && !search && currentPage <= 1 ? leaderboard.slice(3) : leaderboard;
  if (!allowedToView) {
    return (
      <Section className={styles.standingsSection} style={{ marginBottom: '5rem' }}>
        <RestrictedAccess withoutButton />
      </Section>
    );
  }
  return (
    <>
      <div className={styles.filterParent} style={{ marginLeft: isPublic ? '0%' : '' }}>
        {!isPublic && !(channelLoading || teamsLoading) && (
          <div className={styles.filtersSection}>
            <div className={styles.filters}>
              <div className={styles.channelsDropdown}>
                <CustomTooltip
                  label={
                    channelOptions.find((channel) => channel.value === filterState.channel)?.label
                  }
                >
                  {/* Only Element type can be passed as a children here not plain function component */}

                  <CustomSelect
                    options={channelOptions}
                    value={filterState.channel}
                    setValue={(value) => handleFilterChange('channel', value)}
                  />
                </CustomTooltip>
              </div>
              <div className={styles.mobileTimespanSwitch}>
                <AutoComplete
                  clearable={false}
                  searchable={false}
                  defaultSearchValue={
                    (isDatePresent &&
                      filterState.timespan === 'custom' && {
                        label: dateRangeValue,
                        value: 'custom'
                      }) ||
                    TIME_SPAN.find((timeSpan) => timeSpan.value === filterState.timespan)
                  }
                  options={TIME_SPAN}
                  onSelect={(value) => {
                    handleFilterChange('timespan', value);
                    open();
                  }}
                  placeholder={TIME_SPAN[0].label}
                  onDropdownOpen={handleDropdownOnpen}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                />

                {filterState.timespan === 'custom' && (
                  <DatePicker
                    date={filterState.date}
                    onChange={(value) => handleFilterChange('date', value)}
                    hidden={opened}
                    close={close}
                  />
                )}
              </div>
              <div className={styles.channelsDropdown}>
                <CustomTooltip label='select the Format you want to find'>
                  <CustomSelect
                    options={gameFormatOptions}
                    value={filterState.gameType}
                    setValue={(value) => handleFilterChange('gameType', value)}
                  />
                </CustomTooltip>
              </div>
            </div>
            <div className={styles.searchBar}>
              <Search
                size='square'
                placeholder='Search for a person'
                value={search}
                className={styles.searchContainer}
                handleChange={({ target: { value } }) => setSearch(value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.leaderboardContentWrapper}>
        {isFetching ? (
          <Loading height={100} width={100} />
        ) : (
          noTrivia && <NoTrivia noTrivia={noTrivia} timespan={filterState.timespan} />
        )}
        {!noTrivia && !isFetching && (
          <div className={styles.leaderboardTableContainer}>
            {!isPublic && currentPage <= 1 && !search && topThree.length > 0 && (
              <Cards users={topThree} />
            )}
            {!isPublic && !isTab && search === '' && (
              <UserRank
                userData={{
                  name: currentUserData.name,
                  photo: currentUserData.photo,
                  rank: currentUserData.rank,
                  data: {
                    played: currentUserData.played || 0,
                    score: currentUserData.score || 0
                  },
                  error: noParticipation && (
                    <>
                      <Sad />
                      <div className={styles.errorMessage}>
                        You did not participate in any of the Trivia&apos;s held{' '}
                        {NO_PARTICIPATION_MSG[filterState.timespan]}
                      </div>
                    </>
                  )
                }}
              />
            )}
            {(leaderboard?.length > 0 && (
              <>
                <div className={styles.leaderboardTable}>
                  <Header
                    className={styles.columnHeaderContainer}
                    columns={[
                      { label: 'Rank', size: 2, center: true },
                      { label: 'Name', size: 6 },
                      { label: 'Played', size: 2, center: true },
                      { label: 'Score', size: 2, center: true }
                    ]}
                  />
                  {leaderboard.map((user) => (
                    <Row
                      key={user.userId}
                      rank={currentUserData.rank}
                      className={styles.rowContainer}
                      columns={[
                        {
                          label: <div>{getMedalWithRank(user.rank)}</div>,
                          size: 2,
                          center: true,
                          bold: true
                        },
                        {
                          label: (
                            <div className={styles.userPhoneName}>
                              <img src={user.photo || Avatar} alt='user' />
                              <div>{user.name}</div>
                            </div>
                          ),
                          size: 6
                        },
                        { label: user.played, size: 2, center: true, bold: true },
                        { label: user.score.toFixed(2), size: 2, center: true, bold: true }
                      ]}
                    />
                  ))}
                </div>

                <Pagination
                  pages={pages}
                  currentPage={currentPage}
                  pagesToShow={3}
                  onNext={() => setCurrentPage((prev) => prev + 1)}
                  onPrev={() => setCurrentPage((prev) => prev - 1)}
                  onPageClick={(page) => setCurrentPage(page)}
                />
              </>
            )) ||
              (noTrivia && (
                <NoTrivia noTriviaData={noTrivia} search={search} noTrivia={!search && !noTrivia} />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

const NoTrivia = ({ noTriviaData, search, noTrivia, timespan, isPublic }) =>
  !noTriviaData &&
  search === '' && (
    <div className={styles.noTrivia}>
      <Calendar />
      <div className={styles.message}>
        {noTrivia
          ? `I couldn’t find you having fun with Trivia ${NO_TRIVIA_MSG[timespan] || ''}
            ${isPublic ? '. Log in to view more data.' : ''}`
          : "Couldn't find anyone with that name."}
      </div>
    </div>
  );

Standings.propTypes = {
  workspace: PropTypes.string.isRequired,
  invalidWorkspace: PropTypes.bool,
  isPublic: PropTypes.bool,
  noTrivia: PropTypes.bool.isRequired,
  isTab: PropTypes.bool,
  pages: PropTypes.arrayOf(PropTypes.number),
  currentUserData: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
    rank: PropTypes.number,
    played: PropTypes.number,
    score: PropTypes.number,
    userId: PropTypes.string
  }).isRequired,
  noParticipation: PropTypes.bool.isRequired,
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.string,
      played: PropTypes.number,
      rank: PropTypes.number,
      score: PropTypes.number,
      userId: PropTypes.string
    })
  ).isRequired,
  leaderboard: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.string,
      played: PropTypes.number,
      rank: PropTypes.number,
      score: PropTypes.number,
      userId: PropTypes.string
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  allowedToView: PropTypes.bool.isRequired
};

Standings.defaultProps = {
  invalidWorkspace: false,
  isPublic: false,
  isTab: false,
  pages: [1]
};

NoTrivia.propTypes = {
  timespan: PropTypes.string,
  noTrivia: PropTypes.bool,
  noTriviaData: PropTypes.bool,
  search: PropTypes.string,
  isPublic: PropTypes.bool
};

NoTrivia.defaultProps = {
  timespan: '',
  noTrivia: false,
  noTriviaData: false,
  search: '',
  isPublic: false
};

const mapStateToProps = ({ leaderboard, user: { user: userDetails } }) => {
  const totalPages = leaderboard?.leaderboard && Math.ceil(leaderboard.leaderboard.length / 10);
  const currentUserId = userDetails?.userId || null;
  const currentUserData = leaderboard?.leaderboard?.find((user) => user.userId === currentUserId);

  return {
    photo: userDetails?.image,
    allUsers: leaderboard?.leaderboard,
    leaderboard: leaderboard?.currentPage,
    currentUserId,
    pages: Array.from(Array(totalPages), (_, i) => i + 1),
    currentUserData: currentUserData || {},
    noTrivia: leaderboard?.leaderboard.length === 0,
    noParticipation: !currentUserData,
    invalidWorkspace: leaderboard.invalidWorkspace,
    allowedToView: leaderboard?.allowedToView,
    isFetching: leaderboard.loading
  };
};

export default connect(mapStateToProps)(Standings);
