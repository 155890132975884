import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { ReactComponent as Bulb } from 'assets/images/bulb.svg';
import EmptyIcon from 'assets/images/empty-state.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import Tag from 'components/Tag';
import {
  useGetQuizzesQuery,
  customQuizApi,
  useCreateQuizMutation,
  useDeleteQuizMutation
} from 'app/services/custom-quiz';
import { Loading, Section } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import RestrictedAccess from 'components/RestrictedAccess';
import { identify, track } from 'utils/segment';
import localStorage from 'utils/localStorage';
import AccessRestrictedModal from 'components/AccessRestrictedModal';
import QuizModal from './QuizModal';
import Quiz from './Quiz';
import styles from './styles.module.scss';

const CustomQuizView = (props) => {
  const {
    quizzes,
    onCreateClick,
    isPaidUser,
    handleSchedule,
    handleDelete,
    plan,
    loading,
    isFreeTrail
  } = props;

  if (!isFreeTrail && (plan === 'BASIC' || plan === 'STANDARD')) {
    return (
      <Section>
        <RestrictedAccess message='This page is not accessible due to your current payment plan' />
      </Section>
    );
  }

  return quizzes.length === 0 ? (
    <div className={styles.noCustomQuizzes}>
      <img src={EmptyIcon} alt='Empty' height={135} width={135} />
      <div className={styles.text}>
        Create quizzes with your very own questions, MCQ choices and run them for your teammates!
      </div>
      <Button onClick={onCreateClick} className={styles.noQuizCta}>
        + Create a Quiz now
      </Button>
    </div>
  ) : (
    <div className={styles.customQuizzesWrapper}>
      <div className={styles.createQuizContainer}>
        <Button onClick={onCreateClick}>+ Create new quiz</Button>
      </div>
      <div className={styles.customQuizListContainer}>
        <div className={styles.customQuizList}>
          {quizzes.map((quiz, ind) => (
            <Quiz
              num={ind + 1}
              id={quiz?.id}
              key={quiz?.id}
              isPaidUser={isPaidUser}
              name={quiz.catName}
              incomplete={quiz.incomplete}
              creator={quiz.createdBy}
              hasSchedules={quiz.schedules.length > 0}
              schedules={quiz.schedules}
              handleSchedule={(create) =>
                handleSchedule(quiz?.id, quiz.catName, quiz.schedules, create)
              }
              onDelete={() => handleDelete(quiz?.id, quiz.catName, quiz.incomplete)}
              contentLoading={loading}
            />
          ))}
        </div>
        <div className={styles.listFooter}>
          <div className={styles.note}>
            <span className={styles.note}>Note:</span>
            <Tag type='red' text='Incomplete' />
          </div>
          <div className={styles.text}>
            <span className={styles.bolder}>Incomplete quizzes won&apos;t go live.</span>
            <span className={styles.normal}>
              &nbsp;Please complete all the questions in the quiz to launch/schedule it.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomQuizView.propTypes = {
  quizzes: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  onCreateClick: PropTypes.func.isRequired,
  isPaidUser: PropTypes.bool,
  handleSchedule: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  plan: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  isFreeTrail: PropTypes.bool.isRequired
};

CustomQuizView.defaultProps = {
  isPaidUser: false,
  plan: 'BASIC'
};

const CustomQuiz = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('new-quiz');
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [catName, setCatName] = useState('');
  const [quizId, setQuizId] = useState('');
  const [qCount, setQCount] = useState(0);
  const [questionState, setQuestionState] = useState([]);
  const platform = localStorage.getPlatform();
  const userDetails = useSelector((state) => state.user);
  const isAllowedToStart = userDetails.user?.isAllowedToStart ?? true;
  const { data: quizzes = [], isFetching } = useGetQuizzesQuery();
  const [createQuiz, { isLoading: createQuizLoading }] = useCreateQuizMutation();
  const [deleteQuiz, { isLoading: deleteQuizLoading }] = useDeleteQuizMutation();

  const isPaidUser =
    userDetails.freeTrial ||
    (userDetails.plan && !['STANDARD', 'BASIC'].includes(userDetails?.plan));

  useEffect(() => {
    if (!userDetails.loading) {
      const { _id, loggedUsersCount = 0 } = userDetails;
      if (_id) {
        identify(_id, { 'Unique Logged In Users': loggedUsersCount });
        track('Visit Dashboard', {
          distinct_id: _id,
          Page: 'CustomQuiz',
          Date: new Date(),
          'Unique Logged In Users': loggedUsersCount
        });
      }
    }
  }, [userDetails]);

  const handleSchedule = (value) => {
    setCatName(value);
  };

  const onCreateClick = () => {
    setShowQuizModal(true);
  };

  const handleDelete = (id, name, incomplete) => {
    deleteQuiz({ name, id, isIncomplete: incomplete });
  };

  return (
    <Layout name='Custom Quiz'>
      <div className={styles.header}>
        <Bulb />
        Custom Quiz
      </div>
      <Section className={styles.customQuizSection}>
        {isFetching || userDetails.loading ? (
          <Loading height={100} width={100} />
        ) : (
          <CustomQuizView
            onCreateClick={onCreateClick}
            handleSchedule={handleSchedule}
            handleDelete={handleDelete}
            quizzes={quizzes}
            isPaidUser={isPaidUser}
            plan={userDetails.plan}
            loading={createQuizLoading || deleteQuizLoading}
            isFreeTrail={userDetails.freeTrial}
          />
        )}
        {showQuizModal &&
          (isAllowedToStart || platform === 'teams' ? (
            <QuizModal
              open={showQuizModal}
              size='sm'
              onCancel={() => {
                setShowQuizModal(false);
                setType('new-quiz');
              }}
              type={type}
              isPaidUser={isPaidUser}
              catName={catName}
              qCount={qCount}
              questions={questionState}
              contentLoading={createQuizLoading || deleteQuizLoading}
              onQuestionClick={() => setType('question')}
              onQuestionAdded={(questions) => {
                setQuestionState(questions);
                setType('questions');
              }}
              id={quizId}
              onConfirm={async (name, questionCount) => {
                if (name.trim().length <= 25) {
                  const data = await createQuiz({ name: name.trim(), questionCount }).unwrap();

                  if (data.quiz) {
                    const response = await dispatch(
                      customQuizApi.endpoints.getIncompleteQuiz.initiate({ id: data.quiz?._id })
                    );
                    setType('questions');
                    setCatName(name);
                    setQCount(questionCount);
                    setQuestionState(response.data);
                    setQuizId(data.quiz?._id);
                  }
                }
              }}
            />
          ) : (
            <AccessRestrictedModal
              open={showQuizModal}
              automationRestriction={false}
              onCancel={() => setShowQuizModal(false)}
            />
          ))}
      </Section>
    </Layout>
  );
};

export default CustomQuiz;
