import { toast } from 'react-toastify';
import api from './api';

export const initialState = { teams: [] };

export const teamsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET teams endpoint
    getTeams: builder.query({
      query: () => ({
        url: '/teams'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error(response.message);
        return initialState.teams;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch teams info');
        }
        return initialState.teams;
      }
    })
  })
});

export const { useGetTeamsQuery } = teamsApi;
