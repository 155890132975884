const isBetween = (value, min, max) => value >= min && value <= max;
const pricingDetails = [
  {
    minUser: 0,
    maxUser: 25,
    standard: 29,
    pro: 49
  },
  {
    minUser: 26,
    maxUser: 50,
    standard: 49,
    pro: 79
  },
  {
    minUser: 51,
    maxUser: 100,
    standard: 99,
    pro: 129
  },
  {
    minUser: 101,
    maxUser: 250,
    standard: 149,
    pro: 199
  },
  {
    minUser: 251,
    maxUser: 499,
    standard: 249,
    pro: 349
  },
  {
    minUser: 500,
    maxUser: 999,
    standard: 499,
    pro: 599
  },
  {
    minUser: 1000,
    maxUser: 1999,
    standard: 799,
    pro: 999
  }
];

const pricing = (plan, activeUsers) => {
  if (plan === 'basic') {
    return { price: 'FREE', total: 0 };
  }
  if (plan === 'enterprise') {
    return { price: 'TBD', total: 0 };
  }

  let price;
  let priceDetail;
  let activeUsersCopy;
  if (isBetween(activeUsers, 0, 1999)) {
    priceDetail = pricingDetails.find((prices) =>
      isBetween(activeUsers, prices.minUser, prices.maxUser)
    );
    price = priceDetail[plan];
  } else {
    activeUsersCopy = activeUsers - 1999;
    price =
      (plan === 'standard' ? 799 : 999) +
      activeUsersCopy * ((plan === 'standard' ? 99 : 199) / 500);
  }
  return { price: `$${price}`, total: price };
};

export default pricing;
