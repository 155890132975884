/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Link from 'components/Link';
import { Section, Loading } from '@springrole/trivia-games';
import { ReactComponent as Lightning } from 'assets/images/lightning.svg';
import { ReactComponent as Bulb } from 'assets/images/bulb.svg';
import { ReactComponent as Participant } from 'assets/images/participant.svg';
import { ReactComponent as Question } from 'assets/images/question.svg';
import { ReactComponent as Submissions } from 'assets/images/submissions.svg';
import { ReactComponent as Completion } from 'assets/images/subscribed.svg';
import { ReactComponent as Empty } from 'assets/images/empty-state.svg';
import { Grid } from '@mantine/core';
import { useGetCustomQuizInfoQuery } from 'app/services/custom-quiz-results';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuestionResult from './QuestionResult';
import styles from '../styles.module.scss';

export const CustomQuizInsightsView = ({ standings, insights }) => {
  if (insights?.participants < 1) {
    return (
      <div className={styles.noParticipants}>
        <Empty />
        <div className={styles.text}>Seems like no one participated</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <Lightning />
        {insights?.name}
        <Lightning className={styles.reverse} />
      </div>
      <div className={styles.insightsWrapper}>
        <div className={styles.left}>
          <Grid className={styles.columnHeader}>
            <Grid.Col span={1} />
            <Grid.Col span={6}>Name</Grid.Col>
            <Grid.Col span={3} className={styles.center}>
              Questions Attempted
            </Grid.Col>
            <Grid.Col span={2} className={styles.center}>
              Correct attempts
            </Grid.Col>
          </Grid>

          {standings.map((user, index) => (
            <Grid key={user._id} className={styles.row}>
              <Grid.Col span={1}>{index + 1}</Grid.Col>
              <Grid.Col span={6}>{user.name}</Grid.Col>
              <Grid.Col span={3} className={styles.center}>
                {user.totalSubmissions}
              </Grid.Col>
              <Grid.Col span={2} className={styles.center}>
                {user.correctSubmissions}
              </Grid.Col>
            </Grid>
          ))}
        </div>
        <div className={styles.right}>
          <div className={styles.title}>Insights</div>
          <Grid className={styles.insights}>
            <Grid.Col span={6} className={classNames(styles.stat, styles.top, styles.leftcell)}>
              <div className={styles.value}>
                <Participant />
                {insights?.participants}
              </div>
              <div className={styles.text}>Total Participants</div>
            </Grid.Col>
            <Grid.Col span={6} className={classNames(styles.stat, styles.top, styles.rightcell)}>
              <div className={styles.value}>
                <Question />
                {insights?.questions}
              </div>
              <div className={styles.text}>Total questions</div>
            </Grid.Col>
            <Grid.Col span={6} className={classNames(styles.stat, styles.bottom, styles.leftcell)}>
              <div className={styles.value}>
                <Submissions />
                {insights?.answers}
              </div>
              <div className={styles.text}>Total answers</div>
            </Grid.Col>
            <Grid.Col span={6} className={classNames(styles.stat, styles.bottom, styles.rightcell)}>
              <div className={styles.value}>
                <Completion />
                {parseInt(insights?.completionRate, 10).toFixed(2)}%
              </div>
              <div className={styles.text}>Completion rate</div>
            </Grid.Col>
          </Grid>
          <div className={styles.footer}>
            <div className={styles.row1}>
              <div className={styles.by}>
                Hosted by <span>{insights?.organizer}</span>
              </div>
              <div className={styles.time}>{insights?.conductedAt}</div>
            </div>
            <div className={styles.row2}>
              <div className={styles.by}>
                Created by <span>{insights?.curator}</span>
              </div>
              <div className={styles.date}>{insights?.conductedOn}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Result = (props) => {
  const { id, onBack } = props;
  const { data, isLoading } = useGetCustomQuizInfoQuery(id);
  const [questions, setQuestions] = useState([]);
  const [standings, setStandings] = useState([]);
  const [insights, setInsights] = useState({});
  useEffect(() => {
    if (!isLoading) {
      setQuestions(data?.questionsList);
      setStandings(data.usersList?.submissionCounts || []);
      setInsights(
        data.usersList
          ? {
              participants: data.usersList.numberOfParticipants,
              questions: data.usersList.numberOfQuestions,
              answers: data.usersList.totalAnswers,
              completionRate: data.usersList.completedPercentage,
              curator: data.usersList.curatedBy,
              organizer: data.usersList.organisedBy,
              conductedAt: moment(data.usersList.conductedOn).format('h:mm A'),
              conductedOn: moment(data.usersList.conductedOn).format('DD MMM YYYY'),
              name: data.usersList.quizName
            }
          : null
      );
    }
  }, [data]);

  return (
    <div className={styles.quizResultContainer}>
      {!isLoading && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link style={{ marginLeft: '1rem' }} onClick={onBack}>
          {'< go back to custom quiz results'}
        </Link>
      )}
      <Section className={styles.customQuizInsights} style={{ marginBottom: '2rem' }}>
        {isLoading ? (
          <Loading height={100} width={100} />
        ) : (
          <CustomQuizInsightsView standings={standings} insights={insights} />
        )}
      </Section>
      <Section className={styles.customQuizResultsWrapper} style={{ marginBottom: '5rem' }}>
        {isLoading ? (
          <Loading width={100} height={100} />
        ) : (
          <>
            <div className={styles.header}>
              <Bulb />
              Results
            </div>
            <div className={styles.questionResultsContainer}>
              {questions?.map((question, index) => (
                <QuestionResult
                  key={index}
                  number={index + 1}
                  question={question.question}
                  options={question.answers}
                  count={question.count}
                />
              ))}
            </div>
          </>
        )}
      </Section>
    </div>
  );
};

CustomQuizInsightsView.propTypes = {
  standings: PropTypes.arrayOf(
    PropTypes.shape({
      correctSubmissions: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      totalSubmissions: PropTypes.number.isRequired,
      _id: PropTypes.string.isRequired
    })
  ).isRequired,
  insights: PropTypes.shape({
    answers: PropTypes.number,
    completionRate: PropTypes.number,
    conductedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    conductedOn: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    curator: PropTypes.string,
    name: PropTypes.string,
    organizer: PropTypes.string,
    participants: PropTypes.number,
    questions: PropTypes.number
  }).isRequired
};

Result.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBack: PropTypes.func.isRequired
};

export default Result;
