/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'createHistory';
import Layout from 'components/Layout';
import { useGetKnowYourTeamQuery } from 'app/services/KYT-responses';
import { useGetKnowYourTeamAnalyticsQuery } from 'app/services/home';
import { useGetPlanQuery } from 'app/services/payment';
import { Section, Loading } from '@springrole/trivia-games';
import { ReactComponent as BackIcon } from 'assets/images/home/backIcon.svg';
import { Pagination } from 'components/Table';
import RestrictedAccess from 'components/RestrictedAccess';
import { ReactComponent as ArrowIcon } from 'assets/images/home/arrowRight.svg';
import { ReactComponent as DownArrow } from 'assets/images/down-purple.svg';
import { useDisclosure } from '@mantine/hooks';
import { Drawer } from '@mantine/core';
import Button from 'components/Button';
import localStorage from 'utils/localStorage';
import Overview from './Overview';
import styles from './styles.module.scss';

const CustomDrawer = ({ opened, close, title, children }) => (
  <Drawer
    opened={opened}
    onClose={close}
    title={title}
    position='bottom'
    size='md'
    radius='md'
    transition='slide-up'
    transitionProps={{ duration: 300, timingFunction: 'ease' }}
    classNames={{
      content: styles.customContent,
      inner: styles.customInner,
      header: styles.customHeader,
      body: styles.customBody,
      close: styles.customClose
    }}
  >
    {children}
  </Drawer>
);

const KnowYourTeamAnalysis = ({ pages }) => {
  const [fetchingCriteria, setFetchingCriteria] = useState('questions');
  const [currentPage, setCurrentPage] = useState(1);
  const { data: planDetails } = useGetPlanQuery();
  const params = { page: currentPage, fetchingCriteria };
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({ question: null, index: -1 });
  const [responseDetails, setResponseDetails] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showQuestions, setShowQuestions] = useState(true);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const userDetails = useSelector((state) => state.user);
  const { isAdmin } = userDetails;
  const [opened, { open, close }] = useDisclosure(false);
  const platform = localStorage.getPlatform();
  const isMobileWidth = windowWidth < 1000;

  const { data: kytData = { shares: 0, teammates: 0 } } = useGetKnowYourTeamAnalyticsQuery();
  const {
    data: KnowYourTeamResponses = {},
    isLoading: fetching,
    refetch
  } = useGetKnowYourTeamQuery(params, { skip: !isAdmin });
  const responses = KnowYourTeamResponses?.data?.responses;

  useEffect(() => {
    if (isAdmin) refetch();
  }, [fetchingCriteria]);

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (responses && responses.length > 0 && !isMobileWidth) {
      if (fetchingCriteria === 'questions') {
        setSelectedQuestion({ question: responses[0].question, index: 0 });
        setResponseDetails(responses[0]);
      } else {
        setSelectedUser(responses[0].userName);
        setResponseDetails(responses[0]);
      }
    }
  }, [responses]);

  const renderPagination = () => (
    <Pagination
      pages={pages}
      currentPage={currentPage}
      pagesToShow={3}
      onNext={() => setCurrentPage((prev) => prev + 1)}
      onPrev={() => setCurrentPage((prev) => prev - 1)}
      onPageClick={(page) => setCurrentPage(page)}
    />
  );

  const handleQuestionClick = (question, index) => {
    if (isMobileWidth) {
      open(true);
    }
    setSelectedQuestion({ question, index });
    const selectedQuestionDetails = responses?.find((response) => response.question === question);
    setResponseDetails(selectedQuestionDetails);
  };

  const handleUserClick = (userName) => {
    if (isMobileWidth) {
      open(true);
    }
    setSelectedUser(userName);
    const selectedUserDetails = responses.find((user) => user.userName === userName);
    setResponseDetails(selectedUserDetails);
  };

  const handleQuestionsButtonClick = () => {
    setShowQuestions(true);
    setFetchingCriteria('questions');
    setResponseDetails(null);
    setCurrentPage(1);
  };

  const handleUsersButtonClick = async () => {
    setShowQuestions(false);
    setFetchingCriteria('users');
    setResponseDetails(null);
    setCurrentPage(1);
    setIsLoading(true);
    try {
      await new Promise((resolve) => {
        setTimeout(() => {
          setIsLoading(false);
          resolve();
        }, 300);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderQuestionDetails = () =>
    showQuestions ? (
      <div className={styles.questionDetails}>
        {!isMobileWidth && (
          <div className={styles.selectedQuestion}>
            {(currentPage - 1) * 5 + selectedQuestion.index + 1}
            {'. '}
            {selectedQuestion.question}
          </div>
        )}
        {responseDetails && (
          <>
            <div className={styles.responseDetailsCount}>
              {responseDetails.users.length}{' '}
              {responseDetails.users.length <= 1 ? 'response' : 'responses'}
            </div>
            <div className={styles.selectedQuestionResults}>
              {responseDetails.users.map((user, index) => (
                <div key={`${user.userName}_${index}`} className={styles.userDetails}>
                  <div>{user.userName || 'user'}</div>
                  <div>{user.answer}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    ) : (
      <div className={styles.usersDetails}>
        {responseDetails && (
          <>
            {!isMobileWidth && (
              <div className={styles.responseDetailsCount}>
                {responseDetails.questions.length} Questions
              </div>
            )}
            <div className={styles.selectedUserQuestions}>
              {responseDetails.questions.map((question, index) => (
                <div key={index} className={styles.question}>
                  <div className={styles.questionTitle}>
                    <span>{`Q${index + 1}.`}</span> {question.question}
                  </div>
                  <div className={styles.answer}>
                    {!isMobileWidth && <span>Answer:</span>}
                    {` `} {question.answer}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );

  if (planDetails?.plan === 'BASIC' || !isAdmin) {
    return (
      <Layout name='Know-Your-Team Analytics'>
        <div className={styles.goBackButton} onClick={() => history.push('/analytics')}>
          <BackIcon /> Go back
        </div>
        <Section className={styles.restrictedAccessSection}>
          <RestrictedAccess
            message={
              !isAdmin
                ? 'Caution! This page is cordoned off'
                : 'This page is not accessible due to your current payment plan'
            }
            adminRestriction={!isAdmin}
          />
        </Section>
      </Layout>
    );
  }
  return (
    <Layout name='Know-Your-Team Analytics' className={styles.knowYourTeamContentWrapper}>
      <div className={styles.goBackButton} onClick={() => history.push('/analytics')}>
        <BackIcon /> Go back
      </div>
      <div className={styles.heading}>Know your team Analysis</div>

      <Section className={styles.topContainer}>
        <div className={styles.knowYourTeamAnalyticsHeader}>
          <div className={styles.welcomeText}>Overview</div>
        </div>
        <Overview {...kytData} />
      </Section>
      {fetching ? (
        <Loading />
      ) : kytData.teammates > 0 ? (
        <div className={styles.knowYourTeamContentInsights}>
          <Section>
            {!fetching && (
              <div>
                <div className={styles.heading}>
                  Know Your Team {isMobileWidth ? '' : '(KYT)'} Questions and Answers
                </div>
                <div className={styles.knowyourTeamDataCount}>
                  <div
                    className={`${styles.button} ${showQuestions ? styles.selected : ''}`}
                    onClick={handleQuestionsButtonClick}
                  >
                    Questions
                  </div>
                  <div
                    className={`${styles.button} ${!showQuestions ? styles.selected : ''}`}
                    onClick={handleUsersButtonClick}
                  >
                    Users
                  </div>
                </div>
                {showQuestions ? (
                  <div className={styles.knowyourTeamDataContent}>
                    <div className={styles.knowyourTeamDataQuestions}>
                      <div className={styles.questionsContent}>
                        {responses?.map((questionData, index) => (
                          <div
                            key={`${questionData.question}_${index}`}
                            onClick={() => handleQuestionClick(questionData.question, index)}
                            className={`${styles.questionList} ${
                              selectedQuestion.index === index ? styles.selectedQuestion : ''
                            }`}
                          >
                            <div className={styles.fullQuestion}>
                              {(currentPage - 1) * 5 + index + 1}. {questionData.question}
                            </div>
                            <div className={styles.responsesCount}>
                              {isMobileWidth && (
                                <span className={styles.responsesCount}>
                                  {`${questionData.users.length} ${
                                    questionData.users.length < 1 ? 'response' : 'responses'
                                  }`}{' '}
                                  &nbsp; <DownArrow />
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      {renderPagination(pages, currentPage)}
                    </div>
                    {windowWidth < 1200 ? (
                      <CustomDrawer
                        opened={opened}
                        close={close}
                        title={
                          <div className={styles.selectedQuestionTitle}>
                            Q{(currentPage - 1) * 5 + selectedQuestion.index + 1}
                            <div>.&nbsp;{selectedQuestion.question}</div>
                          </div>
                        }
                      >
                        {renderQuestionDetails()}
                      </CustomDrawer>
                    ) : (
                      renderQuestionDetails()
                    )}
                  </div>
                ) : isLoading ? (
                  <Loading />
                ) : (
                  <div className={styles.knowyourTeamDataUsersContent}>
                    <div className={styles.knowyourTeamDataUsers}>
                      <div className={styles.usersContent}>
                        {responses.map((user, index) => (
                          <div
                            key={index}
                            className={`${styles.user} ${
                              selectedUser === user.userName ? styles.selectedUser : ''
                            }`}
                            onClick={() => handleUserClick(user.userName)}
                          >
                            {isMobileWidth && `${(currentPage - 1) * 5 + index + 1}. `}
                            {user.userName || 'user'}
                            <span>{isMobileWidth && <ArrowIcon />}</span>
                          </div>
                        ))}
                      </div>
                      {renderPagination(pages, currentPage)}
                    </div>
                    {windowWidth < 1200 ? (
                      <CustomDrawer
                        opened={opened}
                        close={close}
                        title={`${responseDetails?.questions.length} Questions`}
                      >
                        {renderQuestionDetails()}
                      </CustomDrawer>
                    ) : (
                      renderQuestionDetails()
                    )}
                  </div>
                )}
              </div>
            )}
          </Section>
        </div>
      ) : (
        <div className={styles.emptyKnowYourTeamSection}>
          <section>
            <div>No one has shared anything..!</div>
            <div>Be the first to share interests & favorites</div>
            <Button
              onClick={() => {
                let redirectUrl =
                  'https://support.springworks.in/portal/en/kb/articles/how-does-the-know-your-team-quiz-work-on-ms-teams';
                if (platform === 'slack') {
                  redirectUrl = `${
                    process.env.REACT_APP_TRIVIA_SLACK_API
                  }/slack-deeplink?page=kytPage&key=${localStorage.getToken()}`;
                }
                window.open(redirectUrl, '_blank');
              }}
            >
              Get Started
            </Button>
          </section>
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = ({ KnowYourTeam }) => {
  const totalResponses = KnowYourTeam?.responses?.total || 0;
  const totalPages = KnowYourTeam?.responses && Math.ceil(totalResponses / 5);
  return {
    pages: Array.from(Array(totalPages), (_, i) => i + 1)
  };
};

KnowYourTeamAnalysis.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.number).isRequired
};

CustomDrawer.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps)(KnowYourTeamAnalysis);
