const faq = {
  slack: [
    {
      id: 1,
      Question: 'What is Stripe? How are payments made?',
      Answer:
        "Stripe is a PCI compliant payment processor used by Trivia. Stripe saves the credit card and banking information securely. Any payment for Trivia's subscription from our users is processed and collected through Stripe. Payments are automatically collected by Stripe every month via the credit card filled in by users while purchasing their subscription."
    },
    {
      id: 2,
      Question: 'What is an active user?',
      Answer:
        'An active user is any user who interacts or engages with Trivia. Basically an active user is anyone who: - Plays a Trivia Game, - Gets paired via VirtualCoffee, - Responds to Know Your Team questions If a member in your team makes any of the above actions, they will be regarded as an active user.',
      QuestionChannelBased: 'Who are unique users, and how are they billed?',
      AnswerChannelBased:
        'Unique users refer to individuals who are part of any channels where trivia is enabled. Billing for these users is done on a per-user basis, meaning that we charge only once for a user, regardless of how many channels they are part of where trivia is active.'
    },
    {
      id: 3,
      Question: 'How will the monthly bill be calculated?',
      Answer:
        "You will be billed according to the number of active users in the month. This means, if your company size is 50, but only 25 members participate in Games or use Trivia's features like Know Your Team, Celebrations, VirtualCoffee in the current month, then you will be billed for these 25 users alone. Your team can play unlimited Games and has access to unlimited participation but the monthly bill is only based on the number of active users."
    },
    {
      id: 4,
      Question: 'How to get customer support?',
      Answer:
        'Reach out to us on <a href="mailto:trivia@springworks.in" target="blank">trivia@springworks.in.</a>  or talk to us on Slack Connect.'
    }
  ],
  teams: [
    {
      id: 1,
      Question: 'How are payments made?',
      Answer:
        'Our billing is deeply integrated with Microsoft Billing. This means you will now be billed for Trivia on the same payment method used by your organization to pay for all Microsoft services.'
    },
    {
      id: 2,
      Question: 'How do Licenses work? What do I get per License?',
      Answer:
        'You can buy Licenses from either of the plAnswer for the number of teammates you want to grant Trivia access to. Each License can be given to only one user and only those with Licenses will be able to play & engage with Trivia. The admins will have the ability to assign and unassign Licenses at any point from the total number of available Licenses.'
    },
    {
      id: 3,
      Question: 'How will the monthly bill be calculated?',
      Answer:
        'You will only be charged monthly/annually based on the number of Licenses you have bought. Admins will be allowed to buy more or deactivate Licenses which will be reflected in the bill amount.'
    },
    {
      id: 4,
      Question: 'How to get customer support?',
      Answer:
        "Need help? Write to us at <a href='mailto:trivia@springworks.in' target='blank'>trivia@springworks.in</a> or start a chat with us <a href='https://www.springworks.in/trivia/' target='blank'>here</a>.Or grab a cup of coffee and <a href='https://calendly.com/trivia-1/trivia-demo/?month=2023-02' target='blank'>let's connect 1v1 </a>✌️."
    }
  ]
};

export default faq;
