import React from 'react';
import { Button } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import Step1 from 'assets/images/steplicenses/step1.svg';
import Step2 from 'assets/images/steplicenses/step2.svg';
import Step3 from 'assets/images/steplicenses/step3.svg';
import styles from './styles.module.scss';
import AssignLicenses from './AssignLicenses';

const LicenseSteps = (props) => {
  const { assignLicenses, setAssignLicenses, teamsSubscription, handleClick } = props;
  const onStartAssignLicenses = () => {
    setAssignLicenses(true);
  };

  const assignLicensesState =
    assignLicenses || teamsSubscription?.licensedCount > 0 ? (
      <AssignLicenses
        teamsSubscription={teamsSubscription}
        handleClick={handleClick}
        data-testid='assign-licenses'
        checkOverview={0}
      />
    ) : (
      <div className={styles.steps} data-testid='license-steps'>
        <div className={styles.rowLicenses}>
          <div className={styles.threeStepsText}>Assign Trivia licenses in 3 easy steps </div>{' '}
          <div className={styles.stepOneAndTwo}>
            <img className={styles.stepOne} src={Step1} alt='' data-testid='step1' />

            <img className={styles.stepTwo} src={Step2} alt='' data-testid='step2' />
          </div>
          <img className={styles.stepThree} src={Step3} alt='' data-testid='step3' />
        </div>

        <div style={{ marginTop: '30px' }}>
          <Button onClick={onStartAssignLicenses} gameCenter>
            Start Assigning Licenses
          </Button>
        </div>
      </div>
    );

  return <div className={styles.stepsContainer}>{assignLicensesState}</div>;
};

LicenseSteps.propTypes = {
  assignLicenses: PropTypes.bool.isRequired,
  setAssignLicenses: PropTypes.func.isRequired,
  teamsSubscription: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    licensedCount: PropTypes.number.isRequired
  }).isRequired,
  handleClick: PropTypes.func.isRequired
};

export default LicenseSteps;
