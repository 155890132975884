import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import localStorage from 'utils/localStorage';
import moment from 'moment';
import { Modal } from '@springrole/trivia-games';
import Footer from 'components/Footer';
import classNames from 'classnames';
import { useGetPlanQuery } from 'app/services/payment';
import { useLogoutMutation } from 'app/services/user';
import { useGetAdminsQuery } from 'app/services/settings';
import TeamsFooter from 'components/TeamsFooter';
import history from 'createHistory';
import teamsFooterDetails from './teamsFooterDetails';
import getTabs from '../../data/sidebarTabs';
import Sidebar from '../Sidebar';
import Header from '../Header';
import styles from './styles.module.scss';

const Layout = (props) => {
  const {
    isFreeTrial,
    plan,
    daysLeft,
    isAdmin,
    children,
    isPublic,
    name,
    isTenSpot,
    game,
    className,
    isUserLicensed
  } = props;
  const [isRequestLicenseClicked, setIsRequestLicenseClicked] = useState(false);
  const isStandardOrBasicPlan = plan && (plan === 'BASIC' || plan === 'STANDARD');
  const platform = localStorage.getPlatform();
  const tabs = getTabs(platform, isAdmin, isStandardOrBasicPlan, isTenSpot);
  const { data: adminsData = { admins: [] } } = useGetAdminsQuery(false);
  const { data: planResponse = {} } = useGetPlanQuery(undefined, { skip: isPublic });
  const teamsSubscription = planResponse?.teamsSubscription;
  const isMonetizedWorkspace = !!teamsSubscription;
  const [logoutUser] = useLogoutMutation();
  const licenseCount = useSelector((state) => state.monetization.licenseCount);
  const [currentLicenseCount, setCurrentLicenseCount] = useState(licenseCount);
  const licensedCount = teamsSubscription?.licensedCount;

  useEffect(() => {
    document.title = `Trivia | ${name}`;
  }, []);

  const getTeamsFooterDetails = () => {
    if (isFreeTrial) {
      return false;
    }

    if (
      teamsSubscription &&
      ['Unsubscribed', 'NoSubscription'].includes(teamsSubscription?.status)
    ) {
      return teamsFooterDetails('buy', () =>
        window.open(`${process.env.REACT_APP_TEAMS_APP_SOURCE_URL}`)
      );
    }

    if (isAdmin && isMonetizedWorkspace && currentLicenseCount === 0 && !licensedCount) {
      return teamsFooterDetails('assign', () => history.push('/billing'), teamsSubscription);
    }

    if (!isAdmin && isMonetizedWorkspace && !isUserLicensed) {
      return teamsFooterDetails('request', () => setIsRequestLicenseClicked(true));
    }
    return null;
  };

  useEffect(() => {
    setCurrentLicenseCount(licenseCount || 0);
  }, [licenseCount, licensedCount]);

  return (
    <div className={styles.layoutContainer}>
      {game ? (
        <div className={classNames(styles.contentWrapper, styles.gameContainer)}>
          <div className={styles.content}>{children}</div>
        </div>
      ) : (
        <>
          <Sidebar
            plan={plan}
            tabs={tabs}
            name={name}
            logout={logoutUser}
            isPublic={isPublic}
            isAdmin={isAdmin}
          />
          <div className={styles.contentWrapper}>
            {!isPublic && (
              <Header name={name} tabs={tabs} logout={logoutUser} isPublic={isPublic} />
            )}
            <div
              className={classNames(styles.content, className, {
                [styles.dashboardContent]: name === 'Home'
              })}
            >
              {children}
            </div>
            <div className={styles.footerBanner}>
              {platform === 'slack' && isFreeTrial && name !== 'Billing' && (
                <Footer plan={plan} daysLeft={daysLeft} />
              )}

              {platform === 'teams' && getTeamsFooterDetails() && (
                <TeamsFooter
                  footerDetails={getTeamsFooterDetails()}
                  onClick={() => getTeamsFooterDetails().buttonAction()}
                />
              )}
            </div>
            {isRequestLicenseClicked && (
              <Modal
                title='Request License'
                open={isRequestLicenseClicked}
                onCancel={() => setIsRequestLicenseClicked(false)}
                showConfirm
                showCancelButton
                cancelText='Close'
                confirmText={false}
              >
                <div className={styles.requestLicenseContainer}>
                  <div className={styles.body}>
                    Reach out to your admins and ask them to assign a License to you.
                  </div>

                  <div className={styles.adminContainer}>
                    Admins:&nbsp;
                    <b className={styles.adminList}>
                      {adminsData.admins.map((admin, i) => (
                        <div key={admin.id} className={styles.admin}>
                          {i === adminsData.admins.length - 1 ? `${admin.name}` : `${admin.name}, `}
                        </div>
                      ))}
                    </b>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Layout.propTypes = {
  name: PropTypes.string.isRequired,
  isFreeTrial: PropTypes.bool,
  daysLeft: PropTypes.number,
  plan: PropTypes.string,
  isAdmin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isPublic: PropTypes.bool,
  isTenSpot: PropTypes.bool,
  game: PropTypes.bool,
  className: PropTypes.string,
  isUserLicensed: PropTypes.bool
};

Layout.defaultProps = {
  isFreeTrial: false,
  daysLeft: 0,
  plan: 'Basic',
  isAdmin: false,
  isPublic: false,
  isTenSpot: false,
  game: false,
  className: null,
  isUserLicensed: false
};

const mapStateToProps = ({ user }) => {
  let trialDaysLeft = moment(user.trialExpiresAt).diff(moment(), 'days', false);
  trialDaysLeft = trialDaysLeft >= 0 ? trialDaysLeft : 0;
  return {
    isAdmin: user.user?.isAdmin,
    isTenSpot: user.tenspot === true,
    paymentPlan: user?.plan,
    isFreeTrial: user?.freeTrial,
    plan: user.freeTrial ? 'Pro' : user?.plan,
    daysLeft: user.freeTrial ? trialDaysLeft : 0,
    isUserLicensed: user?.user?.licensed
  };
};

export default connect(mapStateToProps)(Layout);
