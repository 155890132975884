import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mantine/core';
import { ReactComponent as InstallToWorkspace } from 'assets/images/install.svg';
import LoginViaSlack from 'assets/images/usingSlack.svg';
import LoginViaTeams from 'assets/images/usingTeams.svg';
import styles from './styles.module.scss';

const getData = (queryParams) => {
  const params = new URLSearchParams(queryParams);
  let queryString = {};
  const redirect = params.get('redirect');
  if (!redirect) {
    return {};
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of params.keys()) {
    if (!['redirect', 'platform'].includes(key)) {
      queryString[key] = params.get(key);
    }
  }

  queryString = new URLSearchParams(queryString);
  queryString = queryString.toString();

  return {
    redirect: `?redirect=${redirect}&${queryString}`,
    platform: params.get('platform') || 'all'
  };
};

const Login = (props) => {
  const {
    location: { search }
  } = props;
  const { redirect = '', platform = 'all' } = getData(search);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.image}>
        <InstallToWorkspace />
      </div>
      <div data-testid='login' className={styles.header}>
        Login to Trivia
      </div>
      <div className={styles.wrapper}>
        <Grid justify='center'>
          {['all', 'slack'].includes(platform) && (
            <Grid.Col
              span='auto'
              className={styles.wrapperSlack}
              onClick={() => window.open(process.env.REACT_APP_SLACK_LOGIN + redirect, '_self')}
            >
              <img className={styles.img} src={LoginViaSlack} alt='Login with Slack' />
            </Grid.Col>
          )}
          {['all', 'teams'].includes(platform) && (
            <Grid.Col
              span='auto'
              className={styles.wrapperTeams}
              onClick={() => window.open(process.env.REACT_APP_TEAMS_LOGIN + redirect, '_self')}
            >
              <img className={styles.img} src={LoginViaTeams} alt='Login with Teams' />
            </Grid.Col>
          )}
        </Grid>
      </div>
    </div>
  );
};
Login.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired
};

export default Login;
