import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  useGetDmsNudgeSettingsQuery,
  useGetAtHereNotificationStatusQuery,
  useUpdateDmsNudgeSettingsMutation,
  useUpdateAtHereNotificationMutation
} from 'app/services/settings';
import { useSelector } from 'react-redux';
import localStorage from 'utils/localStorage';
import NotificationView from './View';

const Notification = ({ isTenspot = false }) => {
  const currentUser = useSelector((state) => state.user);
  const platform = localStorage.getPlatform();
  const [announcements, setAnnouncements] = useState({
    notifyAll: false
  });
  const [dmsNotifications, setDmsNotifications] = useState(false);
  const { data: notifyAll = false } = useGetAtHereNotificationStatusQuery(undefined, {
    skip: platform !== 'slack'
  });
  const { data: nudgeNotification = false } = useGetDmsNudgeSettingsQuery();
  const allowedToView = currentUser.plan !== 'BASIC';

  useEffect(() => {
    if (platform === 'slack') {
      setAnnouncements({
        notifyAll
      });
    }
  }, [platform, notifyAll]);

  useEffect(() => {
    setDmsNotifications(nudgeNotification);
  }, [nudgeNotification]);

  const [setAtHereNotifications] = useUpdateAtHereNotificationMutation();
  const [setNudgeNotifications] = useUpdateDmsNudgeSettingsMutation();

  const handleSettingsChange = (e) => {
    setAtHereNotifications(e.target.checked);
  };

  const handleDmsNotification = async (e) => {
    setNudgeNotifications(e.target.checked);
  };

  return (
    <NotificationView
      announcements={announcements}
      handleSettingsChange={handleSettingsChange}
      handleDmsNotification={handleDmsNotification}
      isTenspot={isTenspot}
      dmsNotifications={dmsNotifications}
      allowedToView={allowedToView}
      platform={platform}
    />
  );
};

Notification.propTypes = {
  isTenspot: PropTypes.bool
};

Notification.defaultProps = {
  isTenspot: false
};

export default Notification;
