import React from 'react';
import PropTypes from 'prop-types';
import { Modal, TimePicker } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import localStorage from 'utils/localStorage';
import styles from './styles.module.scss';

const Form = (props) => {
  const {
    open,
    timezones,
    days,
    channels,
    frequencies,
    formData,
    handleConfirm,
    handleCancel,
    handleChange,
    disableConfirm
  } = props;
  const platform = localStorage.getPlatform();

  const getChannelPlaceholder = () => {
    if (platform === 'slack') {
      return channels.length ? 'Select a channel' : 'No channels are integrated with Trivia.';
    }

    return channels.length ? 'Select a conversation' : `Trivia is not a member of any team.`;
  };

  const getNoChannelIntegratedNote = () => {
    if (platform === 'slack') {
      return (
        <span className={styles.noChannelIntegratedNote}>
          You can only view integrated channels here. To integrate Trivia with a channel, submit{' '}
          <b>/invite @Trivia</b> on that channel.
        </span>
      );
    }

    return (
      <span className={styles.noChannelIntegratedNote}>
        To view teams here, add Trivia to one from MS Teams.
      </span>
    );
  };

  return (
    <Modal
      open={open}
      title='Enable VirtualCoffee'
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      disableConfirmButton={disableConfirm}
      confirmText='Enable'
    >
      <div className={styles.formContainer}>
        <CustomSelect
          label='CHANNEL'
          value={formData.channel}
          options={channels}
          setValue={(value) => handleChange('channel', value)}
          placeholder={getChannelPlaceholder()}
        />
        {!channels.length && getNoChannelIntegratedNote()}
        <CustomSelect
          label='WHICH DAY OF THE WEEK DO YOU WANT TO MEET?'
          value={formData.day}
          options={days}
          setValue={(value) => handleChange('day', value)}
        />
        <CustomSelect
          label='HOW OFTEN DO YOU WANT TO PAIR YOUR COLLEAGUES'
          value={formData.frequency}
          options={frequencies}
          setValue={(value) => handleChange('frequency', value)}
        />
        <div className={styles.dateAndTimezoneContainer}>
          <TimePicker
            label='SELECT TIME'
            value={formData.time}
            onChange={(value) => handleChange('time', value)}
          />
          <CustomSelect
            label='SELECT TIMEZONE'
            value={formData.timezone}
            options={timezones}
            setValue={(value) => handleChange('timezone', value)}
          />
        </div>
      </div>
    </Modal>
  );
};

Form.propTypes = {
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      length: PropTypes.func
    })
  ).isRequired,
  frequencies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  formData: PropTypes.shape({
    channel: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    time: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired
  }).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired
};

export default Form;
