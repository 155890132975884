export const track = (eventName, eventProperties) => {
  window?.mixpanel?.track(eventName, eventProperties);
  if (['production'].includes(process.env.REACT_APP_ENV)) {
    window?.cio?.track(eventName, eventProperties);
  }
};

export const identify = (userId, traits = {}) => {
  window?.mixpanel?.people.set(userId, traits);
  if (['production'].includes(process.env.REACT_APP_ENV)) {
    window?.cio?.identify({ id: userId, ...traits });
  }
};
