/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Route, Redirect, Switch, Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from 'pages/Home';
import Automations from 'pages/Home/Automations';
import Payment from 'pages/Payment';
import Analytics from 'pages/Analytics';
import VirtualCoffee from 'pages/Analytics/VirtualCoffee';
import Leaderboard from 'pages/Leaderboard';
import CustomQuiz from 'pages/CustomQuiz';
import CustomQuizResults from 'pages/CustomQuizResults';
import PictionaryPage from 'pages/GameZone/Pictionary';
import pollResults from 'pages/PollResults';
import Settings from 'pages/Settings';
import ErrorPage from 'pages/public/Error';
import Install from 'pages/public/Install';
import localStorage from 'utils/localStorage';
import GameZone from 'pages/GameZone';
import PlatformLogin from 'pages/public/Login';
import VirtualCoffeeRoom from 'pages/VirtualCoffee';
import { useGetUserQuery } from 'app/services/user';
import KnowYourTeamAnalysis from 'pages/Analytics/KnowYourTeam/KnowYourTeamAnalysis';
import history from 'createHistory';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  useGetUserQuery();

  return (
    <Route
      {...rest}
      render={(props) => {
        const params = new URLSearchParams(props.location.search);
        const flag = params.get('flag');
        if (!flag) {
          const hasFlag = localStorage.hasFlag();
          const token = localStorage.getToken();
          const accessLevel = localStorage.getAccessLevel();
          const platform = localStorage.getPlatform();
          if (hasFlag && token && accessLevel) {
            if (['/terms'].includes(props.location.pathname)) {
              return <Redirect to='/' />;
            }
            return <Component {...props} />;
          }
          let queryParams = {};
          for (const k of params.keys()) {
            queryParams[k] = params.get(k);
          }
          queryParams.redirect = props.location.pathname.substring(1);
          if (platform) {
            queryParams.platform = platform;
          }
          queryParams = new URLSearchParams(queryParams);
          window.open(`${process.env.REACT_APP_BASE_URL}/login?${queryParams.toString()}`, '_self');
          return null;
        }
        const { status, key } = localStorage.decryptFlag(flag);
        if (status === 'VALID' && key === process.env.REACT_APP_DEFAULT_KEY) {
          localStorage.setFlag(flag);
          return <Component {...props} />;
        }
        return null;
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string
  })
};

AuthenticatedRoute.defaultProps = {
  location: {
    hash: '',
    pathname: '',
    search: ''
  }
};

const redirectLoggedInUser = () => {
  if (window.location.pathname.includes('leaderboard')) {
    return <Redirect to='/leaderboard' />;
  }
  return <Redirect to='/' />;
};

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const hasFlag = localStorage.hasFlag();
      const { workspace, id } = props.match.params;
      const params = new URLSearchParams(props.location.search);

      if (hasFlag && !params.get('taskmodule')) {
        return redirectLoggedInUser();
      }

      // Workspace used for leaderboard
      if (!workspace && !id) {
        return <Redirect to='/public/404' />;
      }
      return (
        <Component workspace={workspace} id={id} taskModule={!!params.get('taskmodule')} isPublic />
      );
    }}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      workspace: PropTypes.string,
      id: PropTypes.string
    })
  }),
  location: PropTypes.objectOf(PropTypes.shape({}))
};

PublicRoute.defaultProps = {
  match: {},
  location: null
};

const Routes = () => (
  <Router history={history}>
    <Switch>
      <AuthenticatedRoute exact path='/' component={Home} />
      <AuthenticatedRoute exact path='/home/automations' component={Automations} />
      <AuthenticatedRoute exact path='/billing' component={Payment} />
      <Redirect exact from='/payment' to='/billing' />
      <AuthenticatedRoute exact path='/analytics' component={Analytics} />
      <AuthenticatedRoute exact path='/analytics/virtual-coffee' component={VirtualCoffee} />
      <AuthenticatedRoute exact path='/leaderboard' component={Leaderboard} />
      <AuthenticatedRoute exact path='/custom-quiz' component={CustomQuiz} />
      <AuthenticatedRoute exact path='/custom-quiz-results' component={CustomQuizResults} />
      <AuthenticatedRoute exact path='/custom-poll-analytics' component={pollResults} />
      <AuthenticatedRoute exact path='/knowYourTeam/responses' component={KnowYourTeamAnalysis} />
      <AuthenticatedRoute exact path='/settings' component={Settings} />
      <AuthenticatedRoute exact path='/game-zone' component={GameZone} />
      <AuthenticatedRoute exact path='/pictionary' component={PictionaryPage} />
      <AuthenticatedRoute
        exact
        path='/virtualcoffee/:eventId/:roomId'
        component={VirtualCoffeeRoom}
      />

      <PublicRoute exact path='/public/leaderboard/:workspace' component={Leaderboard} />

      <Route exact path='/error' render={(props) => <ErrorPage {...props} />} />
      <Route
        exact
        path='/login'
        render={(props) =>
          localStorage.hasFlag() ? <Redirect to='/' /> : <PlatformLogin {...props} />
        }
      />
      <Route
        exact
        path='/install'
        render={(props) => (localStorage.hasFlag() ? <Redirect to='/' /> : <Install {...props} />)}
      />
      <Route
        exact
        path='/policy'
        component={() => {
          window.location.replace(`${process.env.REACT_APP_LANDER_BASE}/privacy-policy`);
          return null;
        }}
      />
      <Route
        exact
        path='/cookie-policy'
        component={() => {
          window.location.replace(`${process.env.REACT_APP_LANDER_BASE}/cookie-policy`);
          return null;
        }}
      />
      <Route
        exact
        path='/tnc'
        component={() => {
          window.location.replace(`${process.env.REACT_APP_LANDER_BASE}/tnc`);
          return null;
        }}
      />
      <Route exact path='*' render={(props) => <ErrorPage notFound {...props} />} />
    </Switch>
  </Router>
);

export default Routes;
