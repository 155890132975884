import React from 'react';
import { ReactComponent as DashboardIcon } from 'assets/images/dashboard.svg';
import { ReactComponent as BillingIcon } from 'assets/images/billing.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/images/analytics.svg';
import { ReactComponent as LeaderboardIcon } from 'assets/images/leaderboard.svg';
import { ReactComponent as GameZoneIcon } from 'assets/images/gamezone.svg';
import { ReactComponent as CustomQuizzesIcon } from 'assets/images/custom-quizzes.svg';
import { ReactComponent as CustomQuizResultsIcon } from 'assets/images/custom-quiz-results.svg';
import { ReactComponent as PollResultsIcon } from 'assets/images/poll-results.svg';

const sidebarTabs = (platform, isAdmin, isStandardOrBasicPlan, isTenSpot = false) => {
  const tabs = [{ path: '/', name: 'Home', id: 'home-link', image: <DashboardIcon /> }];
  switch (platform) {
    case 'slack':
      tabs.push(
        {
          path: '/billing',
          name: 'Billing',
          isTenSpot,
          id: 'billing-link',
          image: <BillingIcon />
        },
        {
          path: '/analytics',
          name: 'Analytics',
          id: 'analytics-link',
          image: <AnalyticsIcon />
        },
        {
          path: '/leaderboard',
          name: 'Leaderboard',
          id: 'trivia-leaderboard-link',
          image: <LeaderboardIcon />
        },
        {
          path: '/custom-quiz',
          name: 'Custom Quiz',
          id: 'custom-quiz-link',
          restricted: isStandardOrBasicPlan,
          image: <CustomQuizzesIcon />
        },
        {
          path: '/custom-quiz-results',
          name: 'Custom Quiz Results',
          restricted: !isAdmin || isStandardOrBasicPlan,
          id: 'custom-quiz-results-link',
          image: <CustomQuizResultsIcon />
        },
        {
          path: '/custom-poll-analytics',
          name: 'Custom Poll Analytics',
          restricted: !isAdmin || isStandardOrBasicPlan,
          id: 'Polls-results-link',
          image: <PollResultsIcon />
        },
        {
          path: '/game-zone',
          name: 'Game Zone',
          id: 'game-zone-link',
          image: <GameZoneIcon />
        }
      );
      break;
    case 'teams':
      tabs.push(
        {
          path: '/billing',
          name: 'Billing',
          id: 'billing-link',
          isTenSpot,
          image: <BillingIcon />
        },
        {
          path: '/analytics',
          name: 'Analytics',
          id: 'analytics-link',
          image: <AnalyticsIcon />
        },
        {
          path: '/leaderboard',
          name: 'Leaderboard',
          id: 'trivia-leaderboard-link',
          image: <LeaderboardIcon />
        },
        {
          path: '/custom-quiz',
          name: 'Custom Quiz',
          restricted: isStandardOrBasicPlan,
          id: 'custom-quiz-link',
          image: <CustomQuizzesIcon />
        },
        {
          path: '/custom-quiz-results',
          name: 'Custom Quiz Results',
          restricted: !isAdmin || isStandardOrBasicPlan,
          id: 'custom-quiz-results-link',
          image: <CustomQuizResultsIcon />
        },
        {
          path: '/custom-poll-analytics',
          name: 'Custom Poll Analytics',
          restricted: !isAdmin || isStandardOrBasicPlan,
          id: 'Polls-results-link',
          image: <PollResultsIcon />
        },
        {
          path: '/game-zone',
          name: 'Game Zone',
          id: 'game-zone-link',
          image: <GameZoneIcon />
        }
      );
      break;
    default:
      break;
  }
  return tabs;
};

export default sidebarTabs;
