import React from 'react';
import { Grid } from '@mantine/core';
import { ReactComponent as AlarmIcon } from 'assets/images/home/alarmIcon.svg';
import { ReactComponent as ListIcon } from 'assets/images/home/listIcon.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/home/peopleIcon.svg';
import styles from './styles.module.scss';

const Overview = (kytData) => {
  const { shares, quizzes, teammates } = kytData;
  const analyticsData = [
    {
      index: 1,
      icon: <AlarmIcon className={styles.icon} />,
      value: shares,
      label: 'KYT answers'
    },
    {
      index: 2,
      icon: <ListIcon className={styles.icon} />,
      value: quizzes,
      label: 'Quizzes played'
    },
    {
      index: 3,
      icon: <PeopleIcon className={styles.icon} />,
      value: teammates,
      label: 'Unique Players'
    }
  ];

  return (
    <div className={styles.overviewWrapper}>
      <Grid className={styles.gridRoot} grow>
        {analyticsData.map((item) => (
          <Grid.Col key={item.index} span={3}>
            <div className={styles.analyticsItem}>
              <div className={styles.icon}>{item.icon}</div>
              <div className={styles.overviewContent}>
                {' '}
                <div className={styles.value}>{item.value}</div>
                <div className={styles.label}>{item.label}</div>
              </div>
            </div>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

export default Overview;
