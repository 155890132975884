import React, { useEffect, useState } from 'react';
import localStorage from 'utils/localStorage';
import { serializeSlackChannels, serializeTeamsChannels } from 'utils/serialization';
import { useGetChannelsQuery } from 'app/services/channels';
import { useGetTeamsQuery } from 'app/services/teams';
import { useGetAutomationsQuery } from 'app/services/home';
import Automations from './ManageAutomations';

const ManageAutomations = () => {
  const [filteredAutomations, setFilteredAutomations] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({
    search: '',
    channel: 'all'
  });
  const [showAutomationModals, setShowAutomationModals] = useState({
    add: false,
    edit: false,
    delete: false
  });
  const platform = localStorage.getPlatform();
  const { data: automationsData = [], isFetching: automationsLoading } = useGetAutomationsQuery();
  const { data: channels = [], isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: !platform || platform === 'teams'
  });
  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery(undefined, {
    skip: !platform || platform === 'slack'
  });

  useEffect(() => {
    if (platform === 'slack') {
      setChannelOptions(serializeSlackChannels(channels, true));
    } else {
      setChannelOptions(serializeTeamsChannels(teams, true));
    }
  }, [channelLoading, teamsLoading]);

  useEffect(() => {
    setFilteredAutomations(automationsData);
  }, [JSON.stringify(automationsData)]);

  const handleChange = (label, value) => {
    let filteredAutomationsTemp;
    if (label === 'search') {
      const searchValue = value.currentTarget.value;
      if (searchValue === '') {
        filteredAutomationsTemp = automationsData;
      } else {
        filteredAutomationsTemp = automationsData.filter((automation) => {
          const gameTypes = automation.types.map((type) => type.toLowerCase());
          const channel = automation.channel.name.toLowerCase();
          return (
            gameTypes.some((gameType) => gameType.includes(searchValue.toLowerCase())) ||
            channel.includes(searchValue.toLowerCase())
          );
        });
      }
      setSelectedDropdownValues((prevValue) => ({
        ...prevValue,
        search: searchValue
      }));
    }
    if (label === 'channel') {
      if (value === 'all') {
        filteredAutomationsTemp = automationsData;
      } else {
        filteredAutomationsTemp = automationsData.filter(
          (automation) => automation.channel.id === value
        );
      }
      setSelectedDropdownValues((prevValue) => ({ ...prevValue, channel: value }));
    }
    setFilteredAutomations(filteredAutomationsTemp);
  };

  const handleAutomationModalOpen = (type, id) => {
    setFormValues(automationsData.find((automation) => automation.id === id));
    setShowAutomationModals((prevValue) => ({ ...prevValue, [type]: true }));
  };

  return (
    <Automations
      loading={automationsLoading}
      automations={filteredAutomations}
      channels={channelOptions}
      selectedDropdownValues={selectedDropdownValues}
      handleChange={handleChange}
      formValues={formValues}
      handleAutomationModalOpen={handleAutomationModalOpen}
      showAutomationModals={showAutomationModals}
      setShowAutomationModals={setShowAutomationModals}
    />
  );
};

export default ManageAutomations;
