import _ from 'lodash';
import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  plan: {
    customerInfo: {
      name: '',
      email: ''
    },
    plan: '',
    showNewBillingPage: false,
    createdAt: new Date()
  },
  checkoutSubscription: ''
};

export const paymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /billing/plan
    getPlan: build.query({
      query: () => ({
        url: '/billing/plan'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return _.omit(response, ['ok']);
        }
        toast.error(response.message);
        return initialState.plan;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch plan info');
        }
        return initialState.plan;
      },
      providesTags: ['plan']
    }),

    // POST /billing/customer-portal
    openCustomerPortal: build.mutation({
      query: () => ({
        url: '/billing/customer-portal',
        method: 'POST'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return window.open(response.url, '_self');
        }
        toast.error(response.message);
        return {};
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to open customer portal');
        }
        return {};
      }
    }),

    // POST /billing/checkout-subscription
    openCheckoutSession: build.mutation({
      query: (body) => ({
        url: '/billing/checkout-subscription',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return window.open(response.url, '_self');
        }
        toast.error(response.message);
        return initialState.checkoutSubscription;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch checkout-subscription');
        }
        return initialState.checkoutSubscription;
      }
    }),

    // PATCH /billing/channels
    updateChannelList: build.mutation({
      query: (body) => ({
        url: '/billing/channels',
        method: 'PATCH',
        body
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to update channl-list');
        }
        return {};
      }
    })
  })
});

export const {
  useGetPlanQuery,
  useOpenCustomerPortalMutation,
  useOpenCheckoutSessionMutation,
  useUpdateChannelListMutation
} = paymentApi;
