import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import localStorage from 'utils/localStorage';
import { serializeSlackChannels, serializeTeamsChannels } from 'utils/serialization';
import { timespanOptions } from 'data/dropdown';
import { useDispatch } from 'react-redux';
import { useGetAnalyticsOverviewQuery, initialState, homeApi } from 'app/services/home';
import { useGetChannelsQuery } from 'app/services/channels';
import { useGetTeamsQuery } from 'app/services/teams';
import { useGetPlanQuery } from 'app/services/payment';
import Overview from './GamesOverview';

const GamesOverview = (props) => {
  const { withDiveDeeperFooter } = props;
  const { data: planDetails = { plan: '' } } = useGetPlanQuery();
  const dispatch = useDispatch();
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({
    timespan: '3',
    channel: 'all'
  });
  const [loading, setLoading] = useState(false);
  const [channelOptions, setChannelOptions] = useState([]);
  const [overview, setOverview] = useState(initialState.overview);
  const platform = localStorage.getPlatform();
  const { data: overviewData = initialState.overview, isLoading: overviewLoading } =
    useGetAnalyticsOverviewQuery({ timespan: '3' });
  const { data: channels = [], isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: !platform || platform === 'teams'
  });
  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery(undefined, {
    skip: !platform || platform === 'slack'
  });

  useEffect(() => {
    setOverview(overviewData);
  }, [overviewLoading]);

  useEffect(() => {
    if (platform === 'slack') {
      setChannelOptions(serializeSlackChannels(channels, true));
    } else {
      setChannelOptions(serializeTeamsChannels(teams, true));
    }
  }, [channelLoading, teamsLoading]);

  const handleDropdownValuesChange = async (label, value) => {
    setSelectedDropdownValues((prevValues) => ({ ...prevValues, [label]: value }));

    const tempParams = { ...selectedDropdownValues };
    tempParams[label] = value;
    if (tempParams?.channel === 'all') {
      delete tempParams.channel;
      delete tempParams.teamId;
    }
    if (tempParams?.timespan === 'all') {
      delete tempParams.timespan;
    }
    if (platform === 'teams' && tempParams?.channel) {
      tempParams.teamId = channelOptions.find(
        (channel) => channel.value === tempParams.channel
      )?.teamId;
    }
    setLoading(true);
    const response = await dispatch(homeApi.endpoints.getAnalyticsOverview.initiate(tempParams));
    setLoading(false);
    setOverview(response.data);
  };

  return (
    <Overview
      planDetails={planDetails}
      loading={overviewLoading || loading}
      overview={overviewLoading ? initialState.overview : overview}
      channelOptions={channelOptions}
      dateRangeOptions={timespanOptions}
      selectedDropdownValues={selectedDropdownValues}
      handleDropdownValuesChange={handleDropdownValuesChange}
      withDiveDeeperFooter={withDiveDeeperFooter}
    />
  );
};

GamesOverview.propTypes = {
  withDiveDeeperFooter: PropTypes.bool
};

GamesOverview.defaultProps = {
  withDiveDeeperFooter: true
};

export default GamesOverview;
