import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import reviews from 'data/reviews';
import { Section } from '@springrole/trivia-games';
import Avatar from 'assets/images/avatar.svg';
import styles from './styles.module.scss';

function getItemsToShow() {
  const screenWidth = window.innerWidth;

  if (screenWidth < 500) {
    return { itemsToShow: 1, showArrows: false, enableAutoPlay: true };
  }
  if (screenWidth < 1000) {
    return { itemsToShow: 1.5, showArrows: true };
  }

  return { itemsToShow: 2.3, showArrows: true };
}

const ReviewSection = () => {
  const [carouselSettings, setCarouselSettings] = useState(getItemsToShow());

  useEffect(() => {
    function handleResize() {
      setCarouselSettings(getItemsToShow());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Section>
      <div className={styles.reviewSectionWrapper}>
        <h4>Don&apos;t take our word for it, listen to what our users have to say.</h4>
        <Carousel
          itemsToShow={carouselSettings.itemsToShow}
          showArrows={carouselSettings.showArrows}
          enableAutoPlay={carouselSettings.enableAutoPlay}
        >
          {reviews.map((item) => (
            <div className={styles.reviewSection} key={item.id}>
              <div>
                <div className={styles.comment}>
                  <div className={styles.commentTitle}>{item.quote}</div>
                  <p className={styles.commentText}>{item.content}</p>
                </div>
                <div className={styles.userInfo}>
                  <div className={styles.personImage}>
                    <div className={styles.picture}>
                      <img src={item.image || Avatar} alt={item.person} />
                    </div>
                  </div>
                  <div className={styles.details}>
                    <div className={styles.name}>{item.person}</div>
                    <div className={styles.description}>{item.designation}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </Section>
  );
};

export default ReviewSection;
