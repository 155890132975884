import localStorage from 'utils/localStorage';

const baseUrl = process.env.REACT_APP_TRIVIA_WEB_API;

const getToken = () => {
  const params = new URLSearchParams(window.location.search);
  const flag = params.get('flag');
  if (flag) {
    const { token } = localStorage.decryptFlag(flag);
    return token;
  }
  return localStorage.getToken();
};

export const generateInviteCode = async (gameType) => {
  const data = await fetch(`${baseUrl}/games/pictionary/generate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: getToken()
    },
    body: JSON.stringify({ gameType: 'pictionary' })
  });

  const response = await data.json();

  if (response.ok) {
    return response.inviteCode;
  }

  return {
    ok: false,
    message: `Failed to create invite code for ${gameType}. Reason: ${response.message}`
  };
};

export const verifyInviteCode = async (inviteCode) => {
  const data = await fetch(`${baseUrl}/games/pictionary/verify?inviteCode=${inviteCode}`, {
    method: 'GET',
    headers: { authorization: getToken() }
  });

  const response = await data.json();

  if (response.ok) {
    return response;
  }

  return { ok: false, message: response.msg };
};
