import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as SwapIcon } from 'assets/VirtualCoffee/swap.svg';
import { useSpring, animated } from 'react-spring';
import styles from './QuestionCard.module.scss';

const QuestionCard = ({ question, shuffleQuestion }) => {
  const [topCard, setTopCard] = useSpring(() => ({
    from: {
      y: -Math.floor(Math.random() * 60) - 30,
      x: Math.floor(Math.random() * 60) - 30,
      opacity: 0,
      transform: 'rotate(-6.21deg)'
    },
    to: {
      x: 0,
      y: 0,
      zIndex: 0,
      opacity: 1,
      transform: 'rotate(0deg)'
    },
    config: {
      duration: 500
    }
  }));
  const [stackCard, setStackCard] = useSpring(() => ({
    from: {
      x: 0,
      y: 0,
      opacity: 0.1,
      transform: 'rotate(0deg)'
    },
    to: {
      x: 0,
      y: 0,
      zIndex: -1,
      opacity: 1,
      transform: `rotate(${Math.floor(Math.random() * 16) - 8}deg)`
    },
    config: {
      duration: 700
    }
  }));
  const handleClick = () => {
    shuffleQuestion();
    setTopCard({
      from: {
        y: -Math.floor(Math.random() * 100) - 50,
        x: Math.floor(Math.random() * 100) - 50,
        opacity: 0,
        transform: `rotate(${Math.floor(Math.random() * 16) - 8}deg)`
      },
      to: {
        y: 0,
        x: 0,
        opacity: 1,
        zIndex: 0,
        transform: 'rotate(0deg)'
      },
      config: {
        duration: 700
      }
    });
    setStackCard({
      from: {
        opacity: 0.6,
        y: Math.floor(Math.random() * 20) - 10,
        x: Math.floor(Math.random() * 20) - 10
      },
      to: {
        x: 0,
        y: 0,
        opacity: 1,
        transform: `rotate(${Math.floor(Math.random() * -8) - 2}deg)`,
        zIndex: -1
      }
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.componentWrapper}>
        {question === '' ? (
          <div className={styles.card} data-testid='placeholder-card'>
            <div className={styles.bars}>
              {[...Array(4)].map((index) => (
                <div className={styles.bar} key={index} />
              ))}
            </div>
          </div>
        ) : (
          <animated.div
            style={{
              ...topCard
            }}
            className={styles.card}
            data-testid='question-card'
          >
            <div className={styles.question}>{question}</div>
          </animated.div>
        )}
        <animated.div
          style={{ ...stackCard }}
          className={classNames(styles.card, { [styles.cardStack]: true })}
          data-testid='stacked-card'
        />
        <animated.div
          style={{
            ...stackCard,
            transform: `rotate(6deg)`
          }}
          className={classNames(styles.card, { [styles.cardStack]: true })}
        />
        <div className={styles.swapButton}>
          <div className={styles.button} onClick={() => handleClick()} data-testid='swap-button'>
            <SwapIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.string,
  shuffleQuestion: PropTypes.func
};

QuestionCard.defaultProps = {
  question: '',
  shuffleQuestion: () => null
};

export default QuestionCard;
