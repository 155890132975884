import React from 'react';
import { ReactComponent as Spinner } from 'assets/images/spinner.svg';
import CallControls from '../CallControls';
import styles from './LostConnection.module.scss';

const LostConnection = () => (
  <div className={styles.contentWrapper}>
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <Spinner className={styles.loader} />
        <div className={styles.text}>You lost your network connection. Trying to reconnect.</div>
      </div>

      <CallControls
        cameraOff={false}
        isMuted={false}
        isConnectionLost
        onCall
        socketMessage='Parts of this page cant be displayed, and some things might not work properly.Refresh the page and try again.'
      />
    </div>
  </div>
);

export default LostConnection;
