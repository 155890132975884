/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from '@springrole/trivia-games';

const VirtualCoffeeTooltipCustomStyles = {
  backgroundColor: '#FFFFFF',
  color: '#333333'
};

export const VirtualCoffeeTooltip = ({ label, children }) => (
  <Tooltip
    position='top'
    arrowSize={6}
    label={label}
    data-testid='plan-details-tooltip'
    style={{
      '--tooltip-bg': VirtualCoffeeTooltipCustomStyles.backgroundColor,
      '--tooltip-color': VirtualCoffeeTooltipCustomStyles.color,
      ...VirtualCoffeeTooltipCustomStyles
    }}
  >
    {children}
  </Tooltip>
);

const toolTipCustomStyles = {
  backgroundColor: 'rgba(21, 20, 20, 0.7)',
  color: '#FFFFFF',
  width: 'fit-content'
};

const CustomTooltip = ({ label, children }) => (
  <Tooltip
    position='bottom'
    arrowSize={6}
    label={label}
    style={{
      '--tooltip-bg': toolTipCustomStyles.backgroundColor,
      '--tooltip-color': toolTipCustomStyles.color,
      ...toolTipCustomStyles
    }}
  >
    {children}
  </Tooltip>
);

const PlanCardTooltipCustomStyles = {
  backgroundColor: '#534f75',
  color: '#FFFFFF',
  borderRadius: '7px',
  whiteSpace: 'pre'
};

export const PlanCardTooltip = ({ label, multiline, children }) => (
  <Tooltip
    position='top'
    multiline={multiline}
    arrowSize={8}
    label={label}
    data-testid='plan-details-tooltip'
    style={{
      '--tooltip-bg': PlanCardTooltipCustomStyles.backgroundColor,
      '--tooltip-color': PlanCardTooltipCustomStyles.color,
      ...PlanCardTooltipCustomStyles
    }}
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
