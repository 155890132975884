import styled from 'styled-components';

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '0.25rem';
    case 'large':
      return '0.5rem';
    default:
      return '0.25rem';
  }
};

const getMargin = (size) => {
  switch (size) {
    case 'small':
      return '0.5rem';
    case 'large':
      return '1rem';
    default:
      return '0.25rem';
  }
};

const DotSeparator = styled.div`
  width: ${(props) => getSize(props.size)};
  height: ${(props) => getSize(props.size)};
  background: #c4c4c4;
  border-radius: 50%;
  margin: 0 ${(props) => getMargin(props.size)};
`;

export default DotSeparator;
