import React, { useState } from 'react';
import { Tooltip, Section } from '@springrole/trivia-games';
import history from 'createHistory';
import localStorage from 'utils/localStorage';
import { ReactComponent as PeopleIcon } from 'assets/images/Analytics/kyt.svg';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/home/arrowRight.svg';
import Form from 'pages/Home/Automations/Modals/Form';
import EnableKnowYourTeamModal from 'components/EnableKnowYourTeam';
import classNames from 'classnames';
import { useGetPlanQuery } from 'app/services/payment';
import { useGetKnowYourTeamAnalyticsQuery } from 'app/services/home';
import styles from './styles.module.scss';
import DisableContainer from '../DisableContainer';

const KnowYourTeamOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAutomationModal, setOpenAutomationModal] = useState(false);
  const { data: planDetails = { plan: '' } } = useGetPlanQuery();
  const { data: kytData = { shares: 0, teammates: 0, quizzes: 0 } } =
    useGetKnowYourTeamAnalyticsQuery();
  const platform = localStorage.getPlatform();
  const isPlatformTeams = platform === 'teams';
  const emptyStateFooterText = isPlatformTeams
    ? 'No interest and favorites shared. Be the first one to share'
    : 'Be the first to share interests & favorites';
  const emptyStateButtonText = isPlatformTeams ? 'Read more' : 'Get started';

  const isBasicPlan = planDetails?.plan === 'BASIC';
  const handleAddAutomation = () => {
    setIsModalOpen(false);
    setOpenAutomationModal(true);
  };

  const handleButtonClick = () => {
    let redirectUrl = '';
    if (isPlatformTeams) {
      redirectUrl =
        'https://support.springworks.in/portal/en/kb/articles/how-does-the-know-your-team-quiz-work-on-ms-teams';
    } else {
      redirectUrl = `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=kyt&key=${localStorage.getToken()}`;
    }
    window.open(redirectUrl, '_self');
  };

  return (
    <Section
      title='Know Your Team (KYT)'
      className={styles.knowYourTeamOverviewContainer}
      headerClass={styles.headerClass}
      action
      label={
        <span
          className={styles.knowYourTeamAnalyticsbutton}
          onClick={() => history.push('/knowYourTeam/responses')}
        >
          View more data
        </span>
      }
    >
      <div
        className={classNames(styles.cardContentWrapper, {
          [styles.teamsCardContentWrapper]: isPlatformTeams
        })}
      >
        <div
          className={classNames(styles.teamOverview, {
            [styles.teamsTeamOverview]: isPlatformTeams
          })}
        >
          <div
            className={classNames(styles.teamOverviewLabels, {
              [styles.teamsTeamOverviewLabels]: isPlatformTeams
            })}
          >
            <div className={styles.dataNumbers}>
              <span>{kytData.shares}</span>
              <Tooltip
                multiline
                width={220}
                label='These are the number of teammates shared their interests & favorites with Trivia in its home tab.'
                position='top'
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <span className={styles.dataText}>Interests &amp; favourites shared</span>
          </div>

          <div
            className={classNames(styles.teamOverviewLabels, {
              [styles.teamsTeamOverviewLabels]: isPlatformTeams
            })}
          >
            <div className={styles.dataNumbers}>
              <span>{kytData.quizzes}</span>
              <Tooltip
                multiline
                width={220}
                label='Trivia used interests & favorites to form Quizzes. This is the number of teammates that competed against each other in these KYT Quizzes.'
                position='top'
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <span className={styles.dataText}>KYT Quizzes played</span>
          </div>

          <div
            className={classNames(styles.teamOverviewLabels, {
              [styles.teamsTeamOverviewLabels]: isPlatformTeams
            })}
          >
            <div className={styles.dataNumbers}>
              <span>{kytData.teammates}</span>
              <Tooltip
                multiline
                width={220}
                label='This is the number of teammates that got to know each other better while playing KYT Quizzes.'
                position='top'
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <span className={styles.dataText}>Teammates got to know each other</span>
          </div>
        </div>
        <div className={styles.peopleIconContainer}>
          <PeopleIcon
            className={classNames(styles.peopleIcon, {
              [styles.reduceWidth]: !isPlatformTeams && !kytData.shares
            })}
          />
        </div>
      </div>

      {isBasicPlan && (
        <DisableContainer
          disableText='You are in Basic plan, please Upgrade plan to access this section'
          disableButtonText='View Plans'
          handleButtonClick={() => history.push('/billing')}
        />
      )}
      {isModalOpen && (
        <EnableKnowYourTeamModal
          open={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
          handleClick={handleAddAutomation}
        />
      )}
      {openAutomationModal && (
        <Form
          open={openAutomationModal}
          isKytForm
          handleCancel={() => setOpenAutomationModal(false)}
        />
      )}
      {!kytData.shares && (
        <div className={styles.emptyStateFooter}>
          <div className={styles.footerDescription}>{emptyStateFooterText}</div>
          <div className={styles.playGamesButton} onClick={handleButtonClick}>
            {emptyStateButtonText} <ArrowIcon />
          </div>
        </div>
      )}
    </Section>
  );
};

export default KnowYourTeamOverview;
