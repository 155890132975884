import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mantine/core';
import { Loading, Button } from '@springrole/trivia-games';
import styles from './styles.module.scss';

const MantineModal = (props) => {
  const {
    open,
    title,
    radius,
    size,
    onCancel,
    children,
    confirmText,
    onConfirm,
    loading,
    disabled
  } = props;

  return (
    <Modal
      opened={open}
      centered
      radius={radius}
      size={size}
      padding={30}
      title={title}
      closeOnClickOutside={false}
      classNames={{
        content: styles.modalWrapper,
        header: styles.header,
        title: styles.title,
        close: styles.close,
        body: styles.body
      }}
      onClose={onCancel}
      overlayColor='#000000'
      overlayOpacity={0.5}
    >
      {loading ? (
        <Loading data-testid='loading-component' height='100px' width='100px' />
      ) : (
        children
      )}
      {confirmText && !loading && (
        <Button
          disabled={disabled}
          classNames={{
            root: styles.buttonRoot,
            label: styles.buttonLabel
          }}
          radius='md'
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      )}
    </Modal>
  );
};

MantineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  confirmText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

MantineModal.defaultProps = {
  radius: 8,
  size: 'md',
  confirmText: 'Confirm',
  title: null,
  loading: false,
  disabled: false
};

export default MantineModal;
