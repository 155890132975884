/* eslint-disable  jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  selectLocalMediaSettings,
  selectPeerAudioByID,
  selectVideoTrackByPeerID,
  useHMSActions,
  useHMSStore
} from '@100mslive/hms-video-react';
import { Modal } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import { ReactComponent as MicOn } from 'assets/VirtualCoffee/microphone-on.svg';
import { ReactComponent as SpeakerIcon } from 'assets/VirtualCoffee/speaker-icon.svg';
import classNames from 'classnames';
import styles from './SettingsModal.module.scss';

const TEST_AUDIO_URL = 'https://100ms.live/test-audio.wav';

const SettingsModal = (props) => {
  const { open, onClose, cameras, microphones, speakers, peer, isVideoEnabled, isAudioEnabled } =
    props;
  const [camera, setCamera] = useState(null);
  const [microphone, setMicrophone] = useState(null);
  const [speaker, setSpeaker] = useState(null);
  const [videoRef, setVideoRef] = useState();
  const audioLevel = useHMSStore(selectPeerAudioByID(peer?.id));

  const handleRef = useCallback((node) => {
    setVideoRef(node);
  }, []);

  const hmsActions = useHMSActions();
  const videoTrack = useHMSStore(selectVideoTrackByPeerID(peer.id));
  const { audioInputDeviceId, audioOutputDeviceId, videoInputDeviceId } =
    useHMSStore(selectLocalMediaSettings);
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const cameraIndex = cameras.findIndex((device) => device.id === videoInputDeviceId);
    if (cameraIndex > 0) {
      setCamera(cameras[cameraIndex]);
    } else {
      setCamera(cameras[0]);
    }

    const microphoneIndex = microphones.findIndex((device) => device.id === audioInputDeviceId);
    if (microphoneIndex > 0) {
      setMicrophone(microphones[microphoneIndex]);
    } else {
      setMicrophone(microphones[0]);
    }

    const speakerIndex = speakers.findIndex((device) => device.id === audioOutputDeviceId);
    if (speakerIndex > 0) {
      setSpeaker(speakers[speakerIndex]);
    } else {
      setSpeaker(speakers[0]);
    }
  }, [cameras, speakers, microphones]);

  useEffect(() => {
    if (videoRef && videoTrack) {
      if (videoTrack.enabled) {
        hmsActions.attachVideo(videoTrack.id, videoRef);
      } else {
        hmsActions.detachVideo(videoTrack.id, videoRef);
      }
    }
  }, [videoRef, videoTrack, hmsActions]);

  useEffect(() => {
    if (audioRef.current && audioOutputDeviceId) {
      audioRef.current.setSinkId(audioOutputDeviceId);
    }
  }, [audioRef.current, audioOutputDeviceId]);

  const content = (
    <div className={styles.settingsModalContainer}>
      <div className={styles.rowContainer}>
        <div className={styles.dropdownContainer}>
          <CustomSelect
            label='CAMERA'
            className={styles.dropdown}
            value={camera?.name}
            options={cameras}
            setValue={(value) => {
              const deviceIndex = cameras.findIndex((device) => device.value === value);
              setCamera(cameras[deviceIndex]);
              hmsActions.setVideoSettings({ deviceId: cameras[deviceIndex]?.id });
            }}
          />
        </div>
        <div className={styles.actionContainer}>
          {isVideoEnabled ? (
            <video
              ref={handleRef}
              className={styles.videoStream}
              autoPlay
              muted
              playsInline
              data-testid='video-element'
            />
          ) : (
            <div className={styles.offState}>Camera is off</div>
          )}
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.dropdownContainer}>
          <CustomSelect
            label='MICROPHONE'
            className={styles.dropdown}
            value={microphone?.name}
            options={microphones}
            setValue={(value) => {
              const deviceIndex = microphones.findIndex((device) => device.value === value);
              setMicrophone(microphones[deviceIndex]);
              hmsActions.setAudioSettings({ deviceId: microphones[deviceIndex]?.id });
            }}
          />
        </div>
        <div className={styles.actionContainer}>
          {isAudioEnabled ? (
            <div className={styles.micWrapper}>
              <MicOn />
              <div className={styles.speakingIndicator}>
                <div className={classNames(styles.dot, { [styles.speaking]: audioLevel > 1 })} />
              </div>
            </div>
          ) : (
            <div className={styles.offState}>Microphone is off</div>
          )}
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.dropdownContainer}>
          <CustomSelect
            label='SPEAKERS'
            className={styles.dropdown}
            value={speaker?.name}
            options={speakers}
            setValue={(value) => {
              const deviceIndex = speakers.findIndex((device) => device.value === value);
              setSpeaker(speakers[deviceIndex]);
              hmsActions.setAudioOutputDevice(speakers[deviceIndex]?.id);
            }}
          />
        </div>
        <div className={styles.actionContainer}>
          <div
            className={styles.playButton}
            onClick={() => audioRef.current?.play()}
            disabled={playing}
            data-testid='play-button-element'
          >
            <audio
              ref={audioRef}
              src={TEST_AUDIO_URL}
              onEnded={() => setPlaying(false)}
              onPlay={() => setPlaying(true)}
              data-testid='audio-element'
            />
            <SpeakerIcon />
            {playing ? 'Playing' : 'Play'}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      showActions={false}
      gameCenter
      title='Settings'
      onCancel={onClose}
      content={content}
    />
  );
};

SettingsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  cameras: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, value: PropTypes.string })
  ),
  microphones: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, value: PropTypes.string })
  ),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, value: PropTypes.string })
  ),
  peer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
  }),
  isAudioEnabled: PropTypes.bool,
  isVideoEnabled: PropTypes.bool
};

SettingsModal.defaultProps = {
  open: false,
  onClose: () => null,
  cameras: [],
  microphones: [],
  speakers: [],
  peer: {},
  isAudioEnabled: false,
  isVideoEnabled: false
};

export default SettingsModal;
