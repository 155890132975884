const formats = [
  {
    value: 'selfPacedQuiz',
    label: 'Self-paced Quizzes'
  },
  {
    value: 'riddles',
    label: 'Riddles!'
  },
  {
    value: 'hangman',
    label: 'Hangman'
  },
  {
    value: 'kyt',
    label: 'Know Your Team Quiz'
  },
  {
    value: 'trivia',
    label: 'Instant Quizzes'
  },
  {
    value: 'charades',
    label: 'GIF Charades'
  },
  {
    value: 'unpopularOpinion',
    label: 'UnPopular Opinions'
  },
  {
    value: 'emojiRebus',
    label: 'Emoji Rebus'
  },
  {
    value: 'waterCooler',
    label: 'Water Cooler'
  },
  {
    value: 'trueOrFalse',
    label: 'True or False'
  },
  {
    value: 'gotcha',
    label: 'Gotcha!'
  },
  {
    value: 'imageQuiz',
    label: 'Image Quizzes'
  },
  {
    value: 'taleTrail',
    label: 'Tale Trail'
  }
];

export default formats;

export const selectGameOptions = [
  {
    value: 'random',
    label: '💫 Surprise Me!'
  },
  {
    value: 'selectAFormat',
    label: '🎮 Select Formats'
  }
];
