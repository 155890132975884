import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/Button';
import { ReactComponent as EmptyIcon } from 'assets/images/empty-state.svg';
import QuizInfo from './QuizInfo';
import styles from '../styles.module.scss';

const QuizzesList = (props) => {
  const {
    noQuizzes,
    recentQuiz,
    handleSelect,
    length,
    listReference,
    quizzes,
    getMore,
    currentPage,
    goToTop,
    hasMore
  } = props;
  if (noQuizzes) {
    return (
      <div className={styles.noCustomQuizzes} data-testid='empty-icon'>
        <EmptyIcon />
        <div className={styles.text}>You haven&apos;t organized any custom quizzes yet...</div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.mostRecentResult}>
        <div className={styles.title}>Most recent</div>

        {recentQuiz && (
          <QuizInfo
            id={recentQuiz._id}
            number={`0${recentQuiz.quizNumber}`.slice(-2)}
            name={recentQuiz.name}
            organizer={recentQuiz.host}
            team={null} // For MS teams
            channel={recentQuiz.channelname}
            participants={recentQuiz.participants}
            questions={recentQuiz.questions}
            date={moment(recentQuiz.startTime).format('DD MMM YYYY')}
            time={moment(recentQuiz.startTime).format('h:mm A')}
            onSelect={handleSelect}
          />
        )}
      </div>
      {length > 1 && (
        <div className={styles.pastResults}>
          <div className={styles.title}>Past</div>
          <div className={styles.resultsList} ref={listReference}>
            {quizzes.map((quiz) => (
              <QuizInfo
                key={quiz._id}
                id={quiz._id}
                number={`0${quiz.quizNumber}`.slice(-2)}
                name={quiz.name}
                organizer={quiz.host}
                channel={quiz.channelname}
                team={null} // For Ms teams
                participants={quiz.participants}
                questions={quiz.questions}
                date={moment(quiz.startTime).format('DD MMM YYYY')}
                time={moment(quiz.startTime).format('h:mm A')}
                onSelect={handleSelect}
              />
            ))}
            {!hasMore && currentPage > 1 && (
              <Button className={styles.loadMore} secondary onClick={goToTop}>
                Go to top
              </Button>
            )}
          </div>
          {hasMore && (
            <Button className={styles.loadMore} secondary onClick={getMore}>
              Load More
            </Button>
          )}
        </div>
      )}
    </>
  );
};

QuizzesList.propTypes = {
  noQuizzes: PropTypes.bool.isRequired,
  recentQuiz: PropTypes.shape().isRequired,
  handleSelect: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  listReference: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  quizzes: PropTypes.arrayOf(
    PropTypes.shape({
      channelname: PropTypes.string,
      host: PropTypes.string,
      name: PropTypes.string,
      participants: PropTypes.number,
      questions: PropTypes.number,
      quizNumber: PropTypes.number,
      startTime: PropTypes.string,
      _id: PropTypes.string
    })
  ).isRequired,
  getMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  goToTop: PropTypes.func.isRequired
};

export default QuizzesList;
