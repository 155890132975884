import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { toast } from 'react-toastify';
import history from './createHistory';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 2500,
  draggable: false,
  theme: 'colored',
  position: toast.POSITION.BOTTOM_LEFT
});

if (['production'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: 'https://d41c510f6c874c5f9d101fb64616d0da@o271082.ingest.sentry.io/6088571',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    tracesSampleRate: 0.5,
    environment: process.env.REACT_APP_ENV
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
