import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ThreeDots } from 'assets/images/steplicenses/threeDots.svg';
import { Menu } from '@mantine/core';
import styles from './styles.module.scss';

const MenuItem = (props) => {
  const { handleOnClick, content, withArrow } = props;
  return (
    <Menu classNames={{ dropdown: styles.dropdown, item: styles.item }} withArrow={withArrow}>
      <Menu.Target data-testid='menu-target'>
        <ThreeDots width={20} />
      </Menu.Target>

      <Menu.Dropdown>
        {content.map((item) => (
          <Menu.Item key={item} onClick={() => handleOnClick(item)}>
            {item}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

MenuItem.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  withArrow: PropTypes.bool
};

MenuItem.defaultProps = {
  withArrow: false
};

export default MenuItem;
