import React from 'react';
import { Section } from '@springrole/trivia-games';
import AmericasHighPerformer from 'assets/images/billing/awards/americas-highperformer.svg';
import MidMarketHighPerformer from 'assets/images/billing/awards/mid-market-highperformer.svg';
import MidMarketLeader from 'assets/images/billing/awards/best-mid-market.svg';
import WinterHighPerformer from 'assets/images/billing/awards/winter-highperformer.svg';
import TeamBuildingLeader from 'assets/images/billing/awards/teambuilding-leader.svg';
import styles from './styles.module.scss';

const AwardSection = () => (
  <Section>
    <div className={styles.awardSectionWrapper}>
      <div className={styles.awardContent}>
        Trivia in G2&apos;s Grid Report
        <br /> for Productivity Bots.
      </div>
      <div className={styles.awardsImagesWrapper}>
        <img className={styles.awards} src={MidMarketHighPerformer} alt='Awards' />
        <img className={styles.awards} src={MidMarketLeader} alt='Awards' />
        <img className={styles.awards} src={TeamBuildingLeader} alt='Awards' />
        <img className={styles.awards} src={WinterHighPerformer} alt='Awards' />
        <img
          className={`${styles.awards} ${styles.americanAward}`}
          src={AmericasHighPerformer}
          alt='Awards'
        />
      </div>
    </div>
  </Section>
);

export default AwardSection;
