import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const ZohoContextProvider = ({ children }) => {
  const [showChat, setShowChat] = useState(false);

  const toggle = () => {
    window.$zoho?.salesiq?.floatwindow?.visible(showChat ? 'hide' : 'show');
    setShowChat((show) => !show);
  };

  const zohoProviderValue = useMemo(() => ({ toggle }), []);

  return <ZohoContext.Provider value={zohoProviderValue}>{children}</ZohoContext.Provider>;
};

ZohoContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

export function useZohoWidget() {
  // eslint-disable-next-line no-use-before-define
  return useContext(ZohoContext);
}

export const ZohoContext = React.createContext();
