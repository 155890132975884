import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const TextField = ({
  multiline,
  name,
  value,
  onChange,
  placeholder,
  gameCenter,
  className,
  ...props
}) => {
  if (multiline === 'on') {
    return (
      <textarea
        className={classNames(styles.textareaField, className)}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      />
    );
  }

  return (
    <input
      className={classNames(styles.inputField, className)}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      autoComplete='off'
      {...props}
    />
  );
};

TextField.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  multiline: PropTypes.oneOf(['on', 'off']),
  disabled: PropTypes.bool,
  gameCenter: PropTypes.bool,
  className: PropTypes.string
};

TextField.defaultProps = {
  multiline: 'off',
  placeholder: 'Enter value here',
  name: 'input',
  disabled: false,
  gameCenter: false,
  className: null
};

export default TextField;
