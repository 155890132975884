import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'pages/Leaderboard/Badge';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const LeaderboardCard = ({ users, position }) => (
  <div className={styles.leaderboardData}>
    <div>
      <span style={{ display: 'flex' }}>
        <span className={styles.desktopViewText}>Times </span>
        Played
      </span>
      <div>{users[position]?.played}</div>
    </div>
    <div>
      <span style={{ textAlign: 'center', marginLeft: 10, display: 'flex' }}>
        <span className={styles.desktopViewText}>Total </span>
        Score
      </span>
      <div>{users[position]?.score}</div>
    </div>
  </div>
);

const Card = ({ users }) => (
  <div className={styles.leaderboardCardContainer}>
    {users[1] && (
      <div
        className={classNames(styles.leaderboardSecond, { [styles.length2]: users.length === 2 })}
      >
        <Badge position='second' image={users[1]?.photo} />
        <p className={styles.leaderboardName}>{users[1]?.name}</p>
        <LeaderboardCard users={users} position={1} />
      </div>
    )}
    <div className={styles.leaderboardFirst}>
      <Badge position='first' image={users[0]?.photo} />
      <p className={styles.leaderboardName}>{users[0]?.name}</p>
      <LeaderboardCard users={users} position={0} />
    </div>
    {users[2] && (
      <div className={styles.leaderboardThird}>
        <Badge position='third' image={users[2]?.photo} />
        <p className={styles.leaderboardName}>{users[2]?.name}</p>
        <LeaderboardCard users={users} position={2} />
      </div>
    )}
  </div>
);

Card.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.string,
      played: PropTypes.number,
      rank: PropTypes.number,
      score: PropTypes.number,
      userId: PropTypes.string
    })
  ).isRequired
};

LeaderboardCard.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.string,
      played: PropTypes.number,
      rank: PropTypes.number,
      score: PropTypes.number,
      userId: PropTypes.string
    })
  ).isRequired,
  position: PropTypes.number.isRequired
};

export default Card;
