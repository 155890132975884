import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectLocalPeer,
  useHMSStore,
  selectDevices,
  selectIsConnectedToRoom,
  useHMSActions,
  selectIsLocalVideoEnabled,
  selectIsLocalAudioEnabled,
  selectPeers,
  useHMSNotifications,
  HMSNotificationTypes
} from '@100mslive/hms-video-react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import { useValidateVirtualCoffeeUserMutation } from 'app/services/virtual-coffee';
import localStorage from 'utils/localStorage';
import styles from './VirtualCoffee.module.scss';
import Header from './Header';
import Peers from './Peers';
import CallControls from './CallControls';
import JoinNowAction from './JoinNowAction';
import QuestionCard from './QuestionCard';
import SettingsModal from './SettingsModal';
import Feedback from './Feedback';
import LostConnection from './LostConnection';

const VirtualCoffee = (props) => {
  const { eventId, roomId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [left, setLeft] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const { questions, fullname, userId } = props;
  const localPeer = useHMSStore(selectLocalPeer);
  const peers = useHMSStore(selectPeers);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const notification = useHMSNotifications();
  const [reconnecting, setReconnecting] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [currentNotification, setCurrentNotification] = useState('');
  const socket = useRef(null);
  const [validateVirtualCoffeeUser] = useValidateVirtualCoffeeUserMutation();

  useEffect(() => {
    if (!notification) {
      return;
    }

    switch (notification.type) {
      case HMSNotificationTypes.PEER_JOINED:
        setCurrentNotification(`${notification.data.name} joined`);
        break;
      case HMSNotificationTypes.PEER_LEFT:
        setCurrentNotification(`${notification.data.name} left`);
        break;
      case HMSNotificationTypes.RECONNECTING:
        setReconnecting(true);
        break;
      case HMSNotificationTypes.RECONNECTED:
        setReconnecting(false);
        break;
      default:
        break;
    }
  }, [notification]);

  const handleLeft = () => {
    socket?.current?.emit('virtualCoffee.user.leave', { roomId, userId });
  };
  const shuffleQuestion = () => {
    socket?.current?.emit('virtualCoffee.shuffle', { roomId, userId });
  };
  const setupSocketConnection = async () => {
    socket.current = io(process.env.REACT_APP_SOCKET_SERVER, {
      auth: { token: localStorage.getToken() },
      autoConnect: false,
      transports: ['websocket'],
      reconnectionDelay: 100,
      reconnectionDelayMax: 1000
    });

    socket.current.on('virtualCoffee.user.joined', ({ prompt }) => {
      setCurrentPrompt(prompt);
    });
    socket.current.on('virtualCoffee.user.left');
    socket.current.on('virtualCoffee.get.prompt', ({ prompt, user }) => {
      setCurrentPrompt(prompt);
      setCurrentNotification(`${user?.userName || 'Peer'} changed icebreaker`);
    });
    socket.current.connect();
  };

  const { audioInput, audioOutput, videoInput } = useHMSStore(selectDevices);
  const formattedAudioInput = audioInput.map((device) => ({
    id: device.deviceId,
    name: device.label,
    value: device.label
  }));
  const formattedAudioOutput = audioOutput.map((device) => ({
    id: device.deviceId,
    value: device.label,
    name: device.label
  }));
  const formattedVideoInput = videoInput.map((device) => ({
    id: device.deviceId,
    name: device.label,
    value: device.label
  }));

  const isLocalVideoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);

  const toggleVideo = async () => {
    await hmsActions.setLocalVideoEnabled(!isLocalVideoEnabled);
  };
  const toggleAudio = async () => {
    await hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled);
  };

  const joinRoom = () => {
    hmsActions.join({
      userName: fullname,
      authToken: token,
      settings: { isAudioMuted: true, isVideoMuted: false },
      rememberDeviceSelection: true
    });
    socket.current.emit('virtualCoffee.user.join', { roomId, eventId, userId, fullname });
  };

  const leaveRoom = async () => {
    await hmsActions.leave();
    setLeft(true);
  };

  const checkPreview = async () => {
    await hmsActions.preview({
      userName: fullname,
      authToken: token,
      settings: {
        isAudioMuted: true,
        isVideoMuted: true
      },
      rememberDeviceSelection: true
    });
    setLoading(false);
  };
  useEffect(() => {
    if (token && fullname !== 'User') {
      checkPreview();
    }

    // eslint-disable-next-line
  }, [loading, token, fullname, left]);

  useEffect(() => {
    setLoading(true);
    (async function validateUser() {
      const { token: resToken } = await validateVirtualCoffeeUser({
        eventId,
        room: roomId
      }).unwrap();
      setToken(resToken);
    })();

    if (!socket.current && !socket.current?.connected) setupSocketConnection();
    return () => {
      handleLeft();
      if (socket.current && socket.current.connected) socket.current.disconnect();
    };

    // eslint-disable-next-line
  }, []);

  const onRejoin = () => {
    setLeft(false);
  };

  if (left) return <Feedback onRejoin={onRejoin} />;

  if (reconnecting) return <LostConnection />;

  return (
    <div className={styles.virtualCoffeeContainer}>
      <Header onCall={isConnected} />
      <div className={styles.virtualCoffeeWrapper}>
        <div className={styles.peersAndActionsWrapper}>
          <div className={styles.videoWrapper}>
            <Peers peer1={peers[1] || localPeer} peer2={peers[1] ? peers[0] : null} />
            {!isConnected && (
              <CallControls
                onCall={isConnected}
                isLocalVideoEnabled={isLocalVideoEnabled}
                toggleVideo={toggleVideo}
                isMuted={!isLocalAudioEnabled}
                toggleAudio={toggleAudio}
                leaveRoom={leaveRoom}
                openSettings={() => setIsOpen(true)}
              />
            )}
          </div>
          <div className={styles.actionsWrapper}>
            {!isConnected ? (
              <JoinNowAction joinRoom={joinRoom} loading={loading} />
            ) : (
              <QuestionCard
                questions={questions}
                shuffleQuestion={shuffleQuestion}
                question={currentPrompt}
              />
            )}
          </div>
        </div>
        <div className={styles.callControlsWrapper}>
          {isConnected && (
            <CallControls
              onCall={isConnected}
              isLocalVideoEnabled={isLocalVideoEnabled}
              toggleVideo={toggleVideo}
              isMuted={!isLocalAudioEnabled}
              toggleAudio={toggleAudio}
              notification={currentNotification}
              setNotification={setCurrentNotification}
              leaveRoom={leaveRoom}
              openSettings={() => setIsOpen(true)}
            />
          )}
        </div>
      </div>

      <SettingsModal
        key={0}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        cameras={formattedVideoInput}
        microphones={formattedAudioInput}
        speakers={formattedAudioOutput}
        peer={localPeer}
        onPlay={() => null}
        isPlaying={false}
        isAudioEnabled={isLocalAudioEnabled}
        isVideoEnabled={isLocalVideoEnabled}
      />
    </div>
  );
};

VirtualCoffee.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.string),
  fullname: PropTypes.string,
  userId: PropTypes.string
};

VirtualCoffee.defaultProps = {
  fullname: 'User',
  questions: ['How do you stay productive and motivated working remotely?'],
  userId: ''
};

const mapStateToProps = ({ user: { user } }) => ({
  fullname: user?.fullname?.trim().split(' ')[0] || 'User',
  userId: user?.userId || ''
});

export default connect(mapStateToProps)(VirtualCoffee);
