/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table as MantineTable } from '@mantine/core';
import { Loading } from '@springrole/trivia-games';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as Pencil } from 'assets/images/pencil.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Table = (props) => {
  const [hoveredId, setHoveredId] = useState(null);
  const { columns, elements, handleAutomationModalOpen, loading, noHover, isAdmin } = props;

  const rows = elements.map((element, index) => (
    <tr
      onMouseEnter={() => setHoveredId(index)}
      className={classNames(styles.row, { [styles.noHover]: noHover })}
      key={element.id.value}
    >
      {Object.keys(element).map((item, i) => {
        if (typeof element[item] === 'object' && !element[item].display) {
          return null;
        }

        return (
          <td key={i} className={styles.rowElement}>
            {element[item]}
          </td>
        );
      })}

      {!noHover && isAdmin && (
        <td className={classNames(styles.actions, { [styles.render]: hoveredId === index })}>
          <div
            onClick={() => handleAutomationModalOpen('delete', element.id.value)}
            className={styles.action}
            data-testid={`delete-button-${element.id.value}-container`}
          >
            <Delete />
          </div>
          <div
            onClick={() => handleAutomationModalOpen('edit', element.id.value)}
            className={classNames(styles.action, styles.pencil)}
            data-testid={`edit-button-${element.id.value}-container`}
          >
            <Pencil style={{ marginLeft: '4px' }} />
          </div>
        </td>
      )}
    </tr>
  ));

  if (loading) {
    return <Loading />;
  }

  return (
    <MantineTable>
      <thead>
        <tr>
          {columns.map((item) => (
            <th key={item} className={styles.header}>
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody onMouseLeave={() => setHoveredId(null)} className={styles.content}>
        {rows}
      </tbody>
    </MantineTable>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAutomationModalOpen: PropTypes.func,
  loading: PropTypes.bool,
  noHover: PropTypes.bool,
  isAdmin: PropTypes.bool
};

Table.defaultProps = {
  loading: false,
  noHover: false,
  isAdmin: true,
  handleAutomationModalOpen: () => null
};

export default Table;
