import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const Questions = (props) => {
  let { questions } = props;
  const { totalQuestions, onQuestionClick } = props;
  let completedQ = 0;
  questions = questions.map((question) => {
    if (question.question !== 'null') {
      completedQ += 1;
    }
    return question.question === 'null'
      ? { value: '+ Add Question ', dummy: true }
      : { value: question.question };
  });

  return (
    <div className={styles.questionsContainer}>
      <div className={styles.status}>
        Completed {completedQ} of {totalQuestions}
      </div>
      <div className={styles.questionsList}>
        {questions.map((question, ind) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.question} key={ind} onClick={() => onQuestionClick(ind + 1)}>
            {question.value}
          </div>
        ))}
      </div>
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          correct: PropTypes.bool
        })
      )
    })
  ).isRequired,
  totalQuestions: PropTypes.number.isRequired,
  onQuestionClick: PropTypes.func.isRequired
};

export default Questions;
