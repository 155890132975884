import { uniqBy, orderBy, startsWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'assets/images/avatar.svg';
import {
  useAddUserAccessMutation,
  useGetAccessDataQuery,
  useRemoveUserAccessMutation,
  useUpdateAccessMutation
} from 'app/services/settings';
import TriviaAccessView from './View';

const initialState = {
  access: {
    allowedUsers: [],
    users: [],
    restricted: true
  }
};

const TriviaAccess = () => {
  const [triviaAccess, setTriviaAccess] = useState({
    restricted: false,
    selectedUsers: []
  });
  const currentUser = useSelector((state) => state.user);
  const { data = {}, isFetching } = useGetAccessDataQuery();
  let { allowedUsers = [], users = [] } = !isFetching ? data : initialState.access;
  const { restricted } = !isFetching ? data : initialState.access;
  const allowedToView = currentUser.plan !== 'BASIC';
  const [addUser, { isLoading: isUserAdding }] = useAddUserAccessMutation();
  const [removeUser, { isLoading: isUserRemoving }] = useRemoveUserAccessMutation();
  const [toggleAccess] = useUpdateAccessMutation();
  useEffect(() => {
    setTriviaAccess(() => ({
      restricted,
      selectedUsers: allowedUsers
    }));
  }, [data]);
  allowedUsers = uniqBy([...allowedUsers, ...triviaAccess.selectedUsers], 'id');
  users = users.filter((usr) => usr.id !== currentUser.user.userId);
  const availableUsers = users.filter(
    ({ id: id1 }) => !allowedUsers.some(({ id: id2 }) => id2 === id1)
  );

  const handleAccessToggle = ({ target: { checked } }) => {
    setTriviaAccess((prevState) => ({
      ...prevState,
      restricted: checked
    }));
    toggleAccess(checked);
  };

  const handleSelection = (user) => {
    setTriviaAccess((prevState) => ({
      ...prevState,
      selectedUsers: [...prevState.selectedUsers, user]
    }));
    addUser(user.id);
  };

  const handleRemove = (user) => {
    setTriviaAccess((prevState) => ({
      ...prevState,
      selectedUsers: triviaAccess.selectedUsers.filter((usr) => usr.id !== user)
    }));
    removeUser(user);
  };

  const filterOptions = (options, inputValue) =>
    orderBy(
      options
        .filter((opt) => {
          const [firstName, ...other] = opt.name.split(/[ ]+/);
          let lastName;
          const { selectedUsers } = triviaAccess;
          const otherFirstIdx = other[0];
          if (other && other.length > 1) lastName = other.join(' ');
          else if (otherFirstIdx) lastName = otherFirstIdx;
          return (
            !selectedUsers.find((_user) => _user.id === opt.id) &&
            (startsWith(firstName?.toLowerCase(), inputValue.toLowerCase()) ||
              startsWith(lastName?.toLowerCase(), inputValue.toLowerCase()) ||
              startsWith(opt.name?.toLowerCase(), inputValue.toLowerCase()))
          );
        })
        .slice(0, 4),
      'name',
      'asc'
    );

  const renderOption = (option) => (
    <>
      <img src={option.photo || Avatar} alt='user' />
      {option.name}{' '}
    </>
  );

  return (
    <TriviaAccessView
      allowedToView={allowedToView}
      isUserAdding={isUserAdding}
      isUserRemoving={isUserRemoving}
      availableUsers={availableUsers}
      handleAccessToggle={handleAccessToggle}
      handleSelection={handleSelection}
      handleRemove={handleRemove}
      filterOptions={filterOptions}
      renderOption={renderOption}
      triviaAccess={triviaAccess}
      allowedUsers={allowedUsers}
      isFetching={isFetching}
    />
  );
};

export default TriviaAccess;
