import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  access: {
    allowedUsers: [],
    users: []
  },
  user: {
    id: '',
    name: '',
    photo: ''
  },
  admin: {
    admins: [],
    members: []
  },
  nudgeNotification: { nudgeStatus: false },
  notifyAll: false
};

const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /settings/access
    getAccessData: build.query({
      query: () => ({
        url: '/settings/access'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          const { ok, ...restResponse } = response;
          return restResponse;
        }
        toast.error(response.message);
        return initialState.access;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch members');
        }
        return initialState.access;
      },
      providesTags: ['ACCESS']
    }),

    // PUT /settings/access
    updateAccess: build.mutation({
      query: (restricted) => ({
        url: '/settings/access',
        method: 'PUT',
        body: { restricted }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response;
        }
        toast.error(response.message);
        return initialState.access;
      },
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          const { ok, restricted } = response;
          if (ok) {
            dispatch(
              api.util.updateQueryData('getAccessData', undefined, (draft) => {
                const cache = current(draft);
                return { ...cache, restricted };
              })
            );
          } else {
            toast.error('Failed to Update access');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update settings access');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /settings/access/add
    addUserAccess: build.mutation({
      query: (user) => ({
        url: '/settings/access/add',
        method: 'PATCH',
        body: { user }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response;
        }
        toast.error(response.message);
        return initialState.user;
      },
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          const { ok, ...responseAllowedUsers } = response;
          if (ok) {
            dispatch(
              api.util.updateQueryData('getAccessData', undefined, (draft) => {
                const cache = current(draft);
                return { ...cache, ...responseAllowedUsers };
              })
            );
            toast.success('Successfully added trivia access');
          } else {
            toast.error('Failed to add this user as trivia admin');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to give user access');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /settings/access/remove
    removeUserAccess: build.mutation({
      query: (user) => ({
        url: '/settings/access/remove',
        method: 'PATCH',
        body: { user }
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          const { ok, ...responseAllowedUsers } = response;
          if (ok) {
            dispatch(
              api.util.updateQueryData('getAccessData', undefined, (draft) => {
                const cache = current(draft);
                return { ...cache, ...responseAllowedUsers };
              })
            );
            toast.success('Successfully removed trivia access');
          } else {
            toast.error('Failed to remove user');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to remove user access');
          }
          return Promise.resolve();
        }
      }
    }),

    // GET /settings/notification/at-here-notification-status
    getAtHereNotificationStatus: build.query({
      query: () => ({
        url: '/settings/at-here-notification-status'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.notifyAll;
        }
        toast.error(response.message);
        return initialState.notifyAll;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch at here notification status');
        }
        return initialState.notifyAll;
      }
    }),

    // GET /settings/nudge-notification-status
    getDmsNudgeSettings: build.query({
      query: () => ({
        url: '/settings/nudge-notification-status'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.nudgeStatus;
        }
        toast.error(response.message);
        return initialState.nudgeNotification;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch nudge notification status');
        }
        return initialState.nudgeNotification;
      }
    }),

    // PATCH /settings/nudge-notification-status
    updateDmsNudgeSettings: build.mutation({
      query: (status) => ({
        url: '/settings/nudge-notification-status',
        method: 'PATCH',
        body: { status }
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getDmsNudgeSettings', undefined, () => response.nudgeStatus)
            );
          } else {
            toast.error('Failed to update Nudge settings');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update nudge notification status');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /settings/notification/at here notifications
    updateAtHereNotification: build.mutation({
      query: (notifyAll) => ({
        url: '/settings/at-here-notification-status',
        method: 'PATCH',
        body: { notifyAll }
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData(
                'getAtHereNotificationStatus',
                undefined,
                () => response.notifyAll
              )
            );
          } else {
            toast.error('Failed to update at-here-notification-status');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update at-here-notification-status');
          }
          return Promise.resolve();
        }
      }
    }),

    // GET /settings/admin?all=
    getAdmins: build.query({
      query: (all = false) => ({
        url: `/settings/admin`,
        params: { all }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          const { admins } = response;
          const transformedResponse = admins.map((admin) => ({
            id: admin.userId,
            name: admin.name,
            photo: admin.photo
          }));
          return { ...response, admins: transformedResponse };
        }
        toast.error(response.message);
        return initialState.admin;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch admins');
        }
        return initialState.admin;
      }
    }),

    // PUT /settings/admin
    updateAdminStatus: build.mutation({
      query: ({ user, isAdmin, photo = null }) => ({
        url: '/settings/admin',
        method: 'PUT',
        body: { id: user.id, isAdmin, photo }
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getAdmins', false, (draft) => {
                const cache = current(draft);
                const { user, isAdmin } = Data;
                const { admins } = cache;
                if (isAdmin) {
                  const addedAdmin = cache.members.find((member) => member.id === user.id);
                  return { ...cache, admins: [...admins, addedAdmin] };
                }
                const removeAdmin = admins.filter((admin) => admin.id !== user.id);
                return { ...cache, admins: removeAdmin };
              })
            );
            const updateStatus = Data.isAdmin ? 'added' : 'removed';
            toast.success(`Successfully ${updateStatus} ${Data.user.name} as an admin`);
          } else {
            const updateStatus = Data.isAdmin ? 'added' : 'removed';
            toast.error(`Failed to ${updateStatus} ${Data.user.name} as Admin`);
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update admin');
          }
          return Promise.resolve();
        }
      }
    })
  })
});

export const {
  useGetAccessDataQuery,
  useGetAdminsQuery,
  useGetDmsNudgeSettingsQuery,
  useGetAtHereNotificationStatusQuery,
  useAddUserAccessMutation,
  useRemoveUserAccessMutation,
  useUpdateDmsNudgeSettingsMutation,
  useUpdateAtHereNotificationMutation,
  useUpdateAccessMutation,
  useUpdateAdminStatusMutation
} = settingsApi;

export default settingsApi;
