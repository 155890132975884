import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localStorage from 'utils/localStorage';

const getFetchBaseQuery = () => {
  const params = new URLSearchParams(window.location.search);
  const flag = params.get('flag');
  let authToken;
  let isPlatformSlack;

  if (flag) {
    const { token, platform } = localStorage.decryptFlag(flag);
    authToken = token;
    isPlatformSlack = platform === 'slack';
  } else {
    isPlatformSlack = localStorage.getPlatform() === 'slack';
    authToken = localStorage.getToken();
  }
  const baseUrl = isPlatformSlack
    ? process.env.REACT_APP_TRIVIA_SLACK_API
    : process.env.REACT_APP_TRIVIA_TEAMS_API;

  return {
    baseUrl,
    prepareHeaders: (header) => {
      header.set('authorization', authToken);
      return header;
    }
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery(getFetchBaseQuery()),
  tagTypes: ['VirtualCoffeeOverview', 'User', 'Quizzes'],
  keepUnusedDataFor: 300, // setting the cache time to 5 minutes for all the endpoints under this base url
  endpoints: () => ({})
});

export default api;
