import styled from 'styled-components';
import colors from 'utils/stylesheet/colors';

const Link = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.purple};
  cursor: pointer;
  text-transform: capitalize;
  :hover {
    text-decoration: underline;
  }
  :active {
    transform: translateY(-1px);
  }
  margin-bottom: 0.5rem;
`;

export default Link;
