/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Search, Button, Modal } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import TextField from 'components/TextField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useUpdateUserMutation } from 'app/services/user';
import { updateButtonType } from 'app/features/monetizationSlice';
import { useGetTeamsQuery } from 'app/services/teams';
import { useGetAllUsersQuery, useUpdateLicensesMutation } from 'app/services/monetization';
import { serializeTeamsChannels } from 'utils/serialization';
import { useSelector, useDispatch } from 'react-redux';
import ArrowRight from 'assets/images/steplicenses/ArrowRight';
import ArrowLeft from 'assets/images/steplicenses/ArrowLeft';
import licenseCertificate from 'assets/images/steplicenses/licenseCertificate.svg';
import history from 'createHistory';
import { SimpleGrid } from '@mantine/core';
import MantineCheckBox from 'components/MantineCheckBox';
import { maybePluralize } from 'utils/utilities';
import localStorage from 'utils/localStorage';
import MenuItem from 'components/MenuItem';
import ManageLicense from '../ManageLicense';
import styles from './styles.module.scss';

let redirectTo;
const columnNames = [
  { id: 1, label: 'NAME' },
  { id: 2, label: 'EMAIL' },
  { id: 3, label: 'LICENSE STATUS' }
];

const licenseStatus = [
  { label: 'All', value: 1 },
  { label: 'Assigned', value: 2 },
  { label: 'Unassigned', value: 3 }
];

const getAssignOrUnassignText = (selectedUserDetails) => {
  const unassignedUsers = selectedUserDetails.filter((selectedUser) => !selectedUser.licensed);
  const assignedUsers = selectedUserDetails.filter((selectedUser) => selectedUser.licensed);

  if (assignedUsers.length > unassignedUsers.length) {
    return 'Unassign License';
  }

  if (unassignedUsers.length > assignedUsers.length) {
    return 'Assign License';
  }

  // if unassigned and assigned licenses are equal in length
  return 'Assign License';
};

const AssignLicenses = (props) => {
  const {
    teamsSubscription,
    checkOverview,
    assignLicenseClicked,
    setAssignLicenseClicked,
    handleClick
  } = props;
  const user = useSelector((state) => state.user);
  const buttonType = useSelector((state) => state.monetization.buttonType);
  const usersList = useSelector((state) => state.monetization.allUsers);
  const hasMore = useSelector((state) => state.monetization.hasMore);
  const totalPages = useSelector((state) => state.monetization.pageCount);
  const licenseCount = useSelector((state) => state.monetization.licenseCount);
  const dispatch = useDispatch();
  const [updateLicenses] = useUpdateLicensesMutation();
  const [updateUser] = useUpdateUserMutation();
  const { data = [] } = useGetTeamsQuery({ type: 'all' });
  const channels = serializeTeamsChannels(data, true, true);
  const [selectedTeam, setSelectedTeam] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onBeforeLeave, setOnBeforeLeave] = useState(false);
  const [isAssignLicense, setIsAssignLicense] = useState(false);
  const [disableTeamsFilter, setDisableTeamsFilter] = useState(false);
  // sortByOrder -> true -> 'asc', sortByOrder -> false -> 'desc'
  const [filter, setFilter] = useState({ sortByOrder: true, sortBy: 'name' });
  const unassignedUsersSelected =
    selectedUserDetails.filter((selectedUser) => !selectedUser.licensed) || [];

  const licenseRemaining =
    Number(teamsSubscription?.quantity) - Number(licenseCount ?? teamsSubscription?.licensedCount);
  const isLicenseExpired =
    Number(teamsSubscription?.quantity) ===
    Number(licenseCount ?? teamsSubscription?.licensedCount);

  const selectedTeamId = channels.find((channel) => channel.value === selectedTeam).teamId || 'all';

  const handleReset = () => {
    setSelectedUserDetails([]);
  };
  const unassignedUsers = usersList.filter((item) => !item.licensed);
  const filteredUsers = unassignedUsers.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useGetAllUsersQuery(
    {
      page: currentPage,
      search: searchTerm,
      teamId: selectedTeamId !== 'all' ? selectedTeamId : undefined,
      ...filter,
      sortByOrder: filter.sortByOrder ? 'asc' : 'desc'
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const unblock = history.block((location) => {
      redirectTo = location.pathname;

      if (selectedUserDetails.length > 0) {
        setOnBeforeLeave(true);
        setIsModalOpen(true);
        return false;
      }

      return true;
    });

    if (selectedUserDetails.length === 0 && onBeforeLeave) {
      history.push(redirectTo);
      redirectTo = '';
    }

    return () => {
      unblock();
    };
  }, [selectedUserDetails]);

  useEffect(() => {
    if (assignLicenseClicked) {
      setAssignLicenseClicked(true);
      assignLicenses(null, true);
    }
  }, [assignLicenseClicked]);

  useEffect(() => {
    if (!user?.email && user?.purchaser) {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    setSelectedUserDetails([]);
    setCurrentPage(1);
  }, [searchTerm, filter, selectedTeam]);

  useEffect(() => {
    setSearchTerm('');
  }, [selectedTeam]);

  const handleTeamChange = (value) => {
    if (!user.globalAdmin) {
      setDisableTeamsFilter(true);
      return setIsModalOpen(true);
    }
    return setSelectedTeam(value);
  };

  const goToNextPage = () => {
    if (!hasMore) {
      return;
    }
    setCurrentPage((prev) => prev + 1);
  };

  const goToPrevPage = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage((prev) => prev - 1);
  };

  const assignLicenses = async (e, isMenuClick, userDetail = null) => {
    if (!userDetail && unassignedUsersSelected.length > licenseRemaining && !isLicenseExpired) {
      setIsModalOpen(true);
      return;
    }

    const tempUser = (userDetail || selectedUserDetails).map((obj) => ({
      ...obj,
      licensed: !obj.licensed
    }));

    if (!isMenuClick) {
      setSelectedUserDetails(tempUser);
    }

    await updateLicenses({ users: tempUser });

    setAssignLicenseClicked(false);
    setIsAssignLicense(true);

    if (!userDetail) {
      setIsModalOpen(true);
    }
  };

  const addUserDetails = (currentUser, menuClicked = false) => {
    if (isLicenseExpired && !currentUser.licensed) {
      setIsModalOpen(true);
      return;
    }

    let tempUser = [];

    if (!menuClicked) {
      tempUser = [...selectedUserDetails];
    }

    if (tempUser.find((item) => item.id === currentUser.id)) {
      const removeUserIndex = tempUser.indexOf(tempUser.find((item) => item.id === currentUser.id));
      tempUser.splice(removeUserIndex, 1);
    } else {
      tempUser.push(currentUser);
    }

    if (menuClicked) {
      assignLicenses(null, true, tempUser);
    } else {
      setSelectedUserDetails(tempUser);
    }
  };

  useEffect(() => {
    if (!isModalOpen && isAssignLicense) {
      setSelectedUserDetails([]);
      setIsAssignLicense(false);
    }

    if (!isModalOpen && disableTeamsFilter) {
      setDisableTeamsFilter(false);
    }
  }, [isModalOpen]);

  const onConfirmClick = async () => {
    if (disableTeamsFilter) {
      window.open(
        `${process.env.REACT_APP_TEAMS_GLOBAL_ADMIN_ACCESS + localStorage.getToken()}|billing`,
        '_self'
      );
    }

    if (!user?.email && user?.purchaser) {
      await updateUser({ email, phone: undefined, forceUpdate: true });
      setIsModalOpen(false);
      return;
    }

    if (isLicenseExpired) {
      dispatch(updateButtonType('Buy Licenses'));
      setIsModalOpen(false);
      return;
    }

    setSelectedUserDetails([]);
    setIsModalOpen(false);
  };

  const onModalCrossClick = () => {
    if (onBeforeLeave) {
      setOnBeforeLeave(false);
    }

    setIsModalOpen(false);
  };

  const handleEmailInfo = (e) => {
    setEmail(e.target.value);
  };

  const getModalDetails = () => {
    if (disableTeamsFilter) {
      return (
        <div className={classNames(styles.modalText, styles.updatedModalText, styles.modalPadding)}>
          To filter teammates by team, you need to sign in with a Microsoft Teams admin account.
          <br />
          <br />
          If you don&apos;t have an admin account, ask your admin to sign in on your behalf.
          <br />
          <br />
          Once granted, you can filter your teammates by team.
          <br />
          <br />
          Note: reach out to your IT admin if you don&apos;t know who your Microsoft Teams admins
          are.
        </div>
      );
    }

    if (!user?.email && user?.purchaser) {
      return (
        <div>
          <div className={styles.modalText}>
            To manage your subscription & licenses, enter your email address.
          </div>
          <TextField
            className={styles.modalTextField}
            placeholder='Type here'
            name='email'
            value={email}
            onChange={handleEmailInfo}
          />
        </div>
      );
    }

    if (onBeforeLeave) {
      const action = getAssignOrUnassignText(selectedUserDetails).split(' ')[0].toLowerCase();
      return (
        <div className={classNames(styles.modalText, styles.updatedModalText)}>
          You have not {`${action}ed`} {maybePluralize(selectedUserDetails.length, 'License')}{' '}
          {action === 'assign' ? 'to' : 'from'} selected{' '}
          {maybePluralize(selectedUserDetails.length, 'teammate')}. If you continue, no changes will
          be saved.
        </div>
      );
    }

    if (unassignedUsersSelected.length > licenseRemaining) {
      return (
        <>
          <div className={styles.modalText}>
            Uh-oh, you have {licenseRemaining} {maybePluralize(licenseRemaining, 'License')}{' '}
            available but selected {unassignedUsersSelected?.length}&nbsp;
            {maybePluralize(unassignedUsersSelected?.length, 'user')}. Deselect&nbsp;
            {unassignedUsersSelected.length - licenseRemaining}&nbsp;
            {maybePluralize(unassignedUsersSelected.length - licenseRemaining, 'teammate')} or buy
            Licenses.
          </div>

          <div className={styles.deselectBtnContainer}>
            <Button onClick={onModalCrossClick} style={{ backgroundColor: 'transparent' }}>
              Go back & deselect
            </Button>
            <Button onClick={() => dispatch(updateButtonType('Buy Licenses'))}>Buy Licenses</Button>
          </div>
        </>
      );
    }

    if (
      selectedUserDetails.length > 0 &&
      selectedUserDetails.every((selectedUser) => !selectedUser.licensed)
    ) {
      return (
        <div className={styles.modalText}>
          You&apos;ve successfully Unassigned {selectedUserDetails.length}&nbsp;
          {maybePluralize(selectedUserDetails.length, 'License')}
        </div>
      );
    }

    if (isLicenseExpired) {
      return (
        <div className={styles.modalText}>
          You successfully assigned all Licenses to your teammates. To assign more Licenses, buy
          them now.
        </div>
      );
    }

    return (
      <>
        <img className={styles.certificateImage} src={licenseCertificate} alt='' />

        <div className={styles.modalText}>
          Great work! You have successfully assigned&nbsp;
          {licenseCount ?? teamsSubscription?.licensedCount}&nbsp;
          {maybePluralize(licenseCount ?? teamsSubscription?.licensedCount, 'License')} and
          have&nbsp;
          {licenseRemaining} {maybePluralize(licenseRemaining, 'License')} to be assigned.
        </div>
      </>
    );
  };

  const getModalButtonText = () => {
    if (disableTeamsFilter) {
      return 'Sign in with admin account';
    }

    if (!user?.email && user?.purchaser) {
      return 'Save';
    }

    if (onBeforeLeave) {
      return 'Continue';
    }

    if (isLicenseExpired) {
      return 'Buy licenses';
    }

    return 'Close';
  };

  const getModalTitle = () => {
    if (onBeforeLeave) {
      return 'You have unsaved changes';
    }

    if (disableTeamsFilter) {
      return 'Sign in with a Teams admin account';
    }

    return null;
  };

  const handleFilter = (item, type) => {
    if (item === 'checkbox') {
      if (selectedUserDetails?.length === usersList?.length) {
        setSelectedUserDetails([]);
        return;
      }

      setSelectedUserDetails(usersList);
      return;
    }

    if (type === 'dropdown') {
      if (item === 1) {
        // show both assigned and unassigned
        setFilter({ ...filter, licenseStatus: undefined });
        return;
      }

      if (item === 2) {
        // filter based on assigned
        setFilter({ ...filter, licenseStatus: true });
        return;
      }

      // filter based on unassigned
      setFilter({ ...filter, licenseStatus: false });
      return;
    }

    const label = item.id === 3 ? 'licensed' : item.label.toLowerCase();

    if (filter.sortBy === label) {
      setFilter({ ...filter, sortByOrder: !filter.sortByOrder, sortBy: label });
    } else {
      setFilter({ ...filter, sortByOrder: true, sortBy: label });
    }
  };

  return (
    <div className={checkOverview === 1 ? styles.overviewModal : styles.assignLicensesContainer}>
      {['manage licenses', 'buy licenses'].includes(buttonType?.toLowerCase()) && (
        <ManageLicense
          teamsSubscription={teamsSubscription}
          handleCancelClick={handleClick}
          licensesAssigned={Number(licenseCount ?? teamsSubscription?.licensedCount)}
          licensesRemaining={licenseRemaining}
          buttonType={buttonType}
          checkOverview={0}
        />
      )}

      <Modal
        title={getModalTitle()}
        open={isModalOpen}
        onCancel={onModalCrossClick}
        confirmText={getModalButtonText()}
        onConfirm={onConfirmClick}
        showConfirm={unassignedUsersSelected.length <= licenseRemaining || disableTeamsFilter}
        showCancelButton={onBeforeLeave}
        cancelText='Cancel'
        customButtonRoot={classNames(styles.customButton, {
          [styles.transparendBtn]: disableTeamsFilter
        })}
        closeOnClickOutside={onBeforeLeave}
      >
        {isModalOpen && getModalDetails()}
      </Modal>
      {checkOverview === 0 && (
        <div className={styles.topText}>
          <div className={styles.teammates}>Teammates</div>
          <div className={styles.totalLicenses}>
            Total number of Licenses: {teamsSubscription?.quantity}
          </div>
        </div>
      )}

      <div className={styles.filterSection}>
        {checkOverview === 0 && (
          <div className={styles.selectDropdown}>
            <div className={styles.selectDropdownItem}>
              <CustomSelect
                placeholder='Teammate or select Team'
                testId='select-team'
                options={channels}
                value={selectedTeam}
                setValue={handleTeamChange}
              />

              <div className={styles.licenseStatusDropdown}>
                <CustomSelect
                  placeholder='Filter by status'
                  testId='select'
                  options={licenseStatus}
                  setValue={(value) => handleFilter(value, 'dropdown')}
                />
              </div>
            </div>
          </div>
        )}
        <div className={checkOverview === 0 ? styles.rightSection : styles.overviewSearch}>
          <Search
            placeholder='Search for teammates'
            size='tiny'
            fullWidth
            value={searchTerm}
            handleChange={(event) => setSearchTerm(event.currentTarget.value)}
          />
          {checkOverview === 0
            ? selectedUserDetails?.length > 0 && (
                <div className={styles.button}>
                  <Button style={{ width: '160px' }} onClick={assignLicenses}>
                    {getAssignOrUnassignText(selectedUserDetails)}
                  </Button>
                </div>
              )
            : assignLicenseClicked && getAssignOrUnassignText(selectedUserDetails)}
        </div>
      </div>

      {licenseRemaining > 0 && !checkOverview && (
        <div className={styles.licenseRemainingBanner}>
          ⚠️ You have <span>{licenseRemaining} unassigned Licenses</span>. Select teammates to
          assign them below.
        </div>
      )}
      {checkOverview === 1 && (
        <div onClick={handleReset} className={styles.ResetButton}>
          Reset
        </div>
      )}
      {checkOverview === 0 ? (
        <div className={styles.usersList}>
          <SimpleGrid className={styles.columnNameContainer} cols={3} spacing={0}>
            {columnNames.map((item) => (
              <div className={styles.columnName} key={item.id}>
                {item.id === 1 ? (
                  <div style={{ display: 'flex' }}>
                    <div
                      onClick={() => handleFilter('checkbox')}
                      className={styles.checkboxWrapper}
                    >
                      <MantineCheckBox
                        checked={selectedUserDetails?.length === usersList?.length}
                      />
                    </div>
                    <div onClick={() => handleFilter(item, 'column')}>{item.label}</div>
                  </div>
                ) : (
                  <div onClick={() => handleFilter(item, 'column')}>{item.label}</div>
                )}
              </div>
            ))}
            {usersList?.map((item) => (
              <>
                <div className={styles.detailCheckboxText}>
                  <div className={styles.checkboxWrapper}>
                    <MantineCheckBox
                      checked={selectedUserDetails.some(
                        (selectedUser) => selectedUser.id === item.id
                      )}
                      handleChange={() => addUserDetails(item)}
                    />
                  </div>
                  {item.name}
                </div>
                <div className={styles.detailText}>{item.email}</div>
                <div className={styles.detailStatusText}>
                  <div className={styles.detailStatus}>
                    <div
                      className={classNames(styles.statusText, {
                        [styles.statusTextBackground]: item.licensed
                      })}
                    >
                      {item.licensed ? 'Assigned' : 'Unassigned'}
                    </div>

                    <MenuItem
                      content={item.licensed ? ['Unassign License'] : ['Assign License']}
                      handleOnClick={() => addUserDetails(item, true)}
                    />
                  </div>
                </div>
              </>
            ))}
          </SimpleGrid>
        </div>
      ) : (
        filteredUsers.map((item) => (
          <div>
            <div className={styles.overviewDetailCheckboxText}>
              <div className={styles.overviewCheckboxWrapper}>
                <MantineCheckBox
                  checked={selectedUserDetails.some((selectedUser) => selectedUser.id === item.id)}
                  handleChange={() => addUserDetails(item)}
                />
              </div>
              {item.name}
            </div>
          </div>
        ))
      )}

      {checkOverview === 0 && (
        <div className={styles.paginateSection}>
          <div className={styles.paginateText}>Rows per page: 10</div>
          <div className={styles.paginateText}>
            {totalPages === 0 ? '0' : currentPage} of {totalPages}
          </div>

          <div onClick={goToPrevPage} className={styles.arrowLeft}>
            <ArrowLeft />
          </div>
          <div onClick={goToNextPage} className={styles.arrowRight}>
            <ArrowRight />
          </div>
        </div>
      )}
    </div>
  );
};

AssignLicenses.propTypes = {
  teamsSubscription: PropTypes.bool.isRequired,
  checkOverview: PropTypes.number.isRequired,
  assignLicenseClicked: PropTypes.bool,
  setAssignLicenseClicked: PropTypes.func,
  handleClick: PropTypes.func.isRequired
};

AssignLicenses.defaultProps = {
  assignLicenseClicked: false,
  setAssignLicenseClicked: () => {}
};

export default AssignLicenses;
