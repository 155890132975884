import { toast } from 'react-toastify';
import api from './api';

export const virtualCoffeeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // POST /virtualcoffee/feedback endpoint
    submitFeedback: builder.mutation({
      query: (body) => ({
        url: '/virtualcoffee/feedback',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response;
        }
        return { ok: false };
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error(response.message);
        }
        return { ok: false };
      }
    }),

    // POST /virtualcoffee/validate endpoint
    validateVirtualCoffeeUser: builder.mutation({
      query: (body) => ({
        url: '/virtualcoffee/validate',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response;
        }
        return { ok: false, token: null };
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error(response.message);
        }
        return { ok: false, token: null };
      }
    })
  })
});

export const { useSubmitFeedbackMutation, useValidateVirtualCoffeeUserMutation } = virtualCoffeeApi;
