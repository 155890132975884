import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import GamesOverview from 'components/GamesOverview';
import { identify, track } from 'utils/segment';
import GamesEngagement from './GamesEngagement';
import Cards from './Cards';
import styles from './styles.module.scss';

const Analytics = (props) => {
  const { _id, loggedUsersCount } = props;

  useEffect(() => {
    if (_id) {
      identify(_id, { 'Unique Logged In Users': loggedUsersCount });
      track('Visit Dashboard', {
        distinct_id: _id,
        Page: 'Analytics',
        Date: new Date(),
        'Unique Logged In Users': loggedUsersCount
      });
    }
  }, [_id]);

  return (
    <Layout name='Analytics' className={styles.analyticsContentWrapper}>
      <div className={styles.welcomeText}>📊 Analytics</div>
      <GamesOverview withDiveDeeperFooter={false} />
      <Cards />
      <GamesEngagement />
    </Layout>
  );
};

Analytics.propTypes = {
  loggedUsersCount: PropTypes.number.isRequired,
  _id: PropTypes.string
};
Analytics.defaultProps = {
  _id: ''
};

const mapStateToProps = ({ user }) => ({
  loggedUsersCount: user.loggedUsersCount || 0,
  _id: user._id
});

export default connect(mapStateToProps, {})(Analytics);
