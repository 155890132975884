/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Grid } from '@mantine/core';
import { Link as LinkTag, DatePicker, Select } from '@springrole/trivia-games';
import React, { useState, useRef } from 'react';
import { TimeInput } from '@mantine/dates';
import localStorage from 'utils/localStorage';
import SearchAutocomplete from 'components/Autocomplete/SearchAutoComplete';
import timezones from 'data/timezones';
import PropTypes from 'prop-types';
import { ReactComponent as DownArrow } from 'assets/images/down.svg';
import styles from '../styles.module.scss';

const CustomSelect = ({ label, options, value, setValue, placeholder, customInput, ...rest }) => (
  <Select
    size='md'
    radius='md'
    label={label}
    value={value}
    onChange={setValue}
    data={options}
    placeholder={placeholder}
    rightSection={<DownArrow />}
    rightSectionWidth={30}
    transition='pop-top-left'
    transitionDuration={80}
    transitionTimingFunction='ease'
    classNames={{
      input: styles.customInput,
      root: styles.root
    }}
    {...rest}
  />
);

const Schedule = (props) => {
  const {
    goBack,
    schedule,
    date,
    time,
    handleChange,
    selectedTeam,
    selectedChannel,
    selectedTimezone,
    edit,
    channels: channelsProps,
    hasNewSchedules
  } = props;
  const formattedDate = new Date(date);
  const showTeams = localStorage.getPlatform() === 'teams';
  const getPlatformSpecificContext = (_team, slack) => {
    if (showTeams) {
      return _team || '';
    }
    return slack;
  };

  const transformDataFormat = (data) =>
    data.map((item) => ({
      label: item.name,
      value: item.id
    }));
  const transformTimeFormat = (data) => data.map((item) => item.name);

  const formatTime = (formatedTime) => {
    if (!formatedTime) return '';
    const currentDate = new Date(formatedTime);
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const ref = useRef(null);

  const handleTimeInputClick = () => {
    ref.current?.showPicker();
  };

  const [team, setTeam] = useState(showTeams ? selectedTeam : null);
  let teams = [];
  let channels = [];
  if (showTeams) {
    teams = channelsProps;
  } else {
    channels = channelsProps;
  }
  if (team) {
    channels = channelsProps.find((t) => t?.id === team?.id)?.channels || [];
  }
  if (team && channelsProps?.length === 1) {
    channels = channelsProps[0].channels;
  }

  return (
    <div className={styles.scheduleContainer}>
      {!hasNewSchedules && <LinkTag onClick={goBack}>{'< Go back to schedule details'}</LinkTag>}
      <Grid>
        <Grid.Col span={6}>
          <div className={styles.label}>Date</div>
          <DatePicker
            value={schedule?.time || formattedDate}
            onChange={(value) => handleChange('date', value)}
            classNames={{
              root: styles.root,
              input: styles.input,
              day: styles.cell
            }}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <div className={styles.label}>Time</div>
          <TimeInput
            size='lg'
            onChange={(event) => handleChange('time', event.target.value)}
            value={formatTime(schedule?.time || time)}
            radius='md'
            classNames={{
              root: styles.timeInputRoot,
              wrapper: styles.timeInputWrapper,
              input: styles.timeInputInput,
              section: styles.timeInputSection
            }}
            format='12'
            ref={ref}
            onClick={handleTimeInputClick}
            rightSection={<DownArrow />}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <div className={styles.label}>{getPlatformSpecificContext('Team', 'Channel')}</div>
          <div>
            <CustomSelect
              options={transformDataFormat(getPlatformSpecificContext(teams, channels))}
              value={
                edit
                  ? showTeams
                    ? selectedTeam.id ?? selectedTeam
                    : selectedChannel.id ?? selectedChannel
                  : showTeams
                  ? selectedTeam
                  : selectedChannel ?? ''
              }
              placeholder={`Search for a ${getPlatformSpecificContext('Team', 'Channel')}`}
              setValue={(value) => {
                if (showTeams) setTeam(value);
                handleChange(getPlatformSpecificContext('selectedTeam', 'selectedChannel'), value);
              }}
              searchable
            />
          </div>
        </Grid.Col>
        <Grid.Col span={6}>
          <div className={styles.label}>Timezone</div>
          <div>
            <CustomSelect
              options={transformTimeFormat(timezones)}
              value={selectedTimezone}
              setValue={(value) => handleChange('selectedTimezone', value)}
              placeholder='Select a timezone'
              searchable
            />
          </div>
        </Grid.Col>
        {showTeams && (
          <Grid.Col span={6}>
            <div className={styles.label}>Channel</div>
            <div>
              <SearchAutocomplete
                items={channels}
                placeholder={selectedChannel ? selectedChannel?.name : ' Select channel'}
                onSelect={(value) => handleChange('selectedChannel', value)}
                showIcon={false}
                className={styles.autocompletePlaceholder}
              />
            </div>
          </Grid.Col>
        )}
      </Grid>
    </div>
  );
};

Schedule.propTypes = {
  goBack: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  }),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  // time is getting instance of moment() date object
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedTeam: PropTypes.shape(),
  selectedChannel: PropTypes.shape(),
  selectedTimezone: PropTypes.shape(),
  edit: PropTypes.bool.isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  hasNewSchedules: PropTypes.bool.isRequired
};

Schedule.defaultProps = {
  selectedTimezone: '',
  schedule: null,
  selectedChannel: null,
  selectedTeam: null
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  customInput: PropTypes.bool.isRequired
};

export default Schedule;
