/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@springrole/trivia-games';
import mindBlownIcon from 'assets/images/billing/mind-blown-icon.svg';
import ManageLicense from '../LicenseSteps/ManageLicense';
import AssignLicenses from '../LicenseSteps/AssignLicenses';
import styles from './styles.module.scss';

const BasicOverViewSection = (props) => {
  const {
    platform,
    gamesPlayed,
    plan,
    teamsSubscription,
    handleClick,
    isModalOpen,
    handleBuySubscription,
    participantLeftOut
  } = props;

  const { licenseCount, licensedCount, quantity } = teamsSubscription || {};
  const moreSpanRef = useRef(null);
  const names =
    platform === 'teams'
      ? participantLeftOut.map((participant) => participant.fullname)
      : participantLeftOut.map((participant) => participant.name);

  const [showMore, setShowMore] = useState(false);
  const [assignLicenseClicked, setAssignLicenseClicked] = useState(false);
  const userCount = plan === 'BASIC' ? (platform === 'slack' ? 3 : 10) : null;
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const handleAssignLicenseConfirm = () => {
    setAssignLicenseClicked(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreSpanRef.current && !moreSpanRef.current.contains(event.target)) {
        setShowMore(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMore]);

  const licenseRemaining =
    Number(teamsSubscription?.quantity) - Number(licenseCount ?? teamsSubscription?.licensedCount);

  return (
    <>
      <div className={styles.basicRestricted}>
        <div className={styles.mindBlownIcon}>
          <img src={mindBlownIcon} alt='mindBlown Icon' />
        </div>
        <div className={styles.basicOverviewWrapper}>
          <div className={styles.infoContainer}>
            <div className={styles.restrictedPlayers}>
              {showMore && (
                <div className={styles.additionalNames}>
                  <ul>
                    {names.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {plan !== 'BASIC' && platform === 'teams' && (
              <>
                <span>users can not play as they do not have the Licences</span>
                <div className={styles.warning}>
                  ⚠️{' '}
                  <span className={styles.userLimit}>
                    Assigned Licenses on your current plan : {quantity - licenseRemaining}
                  </span>
                  {userCount !== null && <p>{userCount} users per game</p>}
                </div>
              </>
            )}
            {plan === 'BASIC' && (
              <>
                <div>
                  {' '}
                  <span
                    ref={moreSpanRef}
                    className={styles.restrictedPlayers}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleShowMore}
                  >
                    {names.length} {names.length > 1 ? 'users' : 'user'}
                  </span>
                  {'  '}
                  could not play in the last {gamesPlayed > 20 ? '20' : gamesPlayed} games
                </div>
                <div className={styles.warning}>
                  ⚠️ <span className={styles.userLimit}>User limit on your plan:</span> 3 users per
                  Game
                </div>
              </>
            )}
          </div>
          <div className={styles.actionContainer}>
            {platform === 'teams' && (
              <Button className={styles.basicUpgradeplanButton}>
                {plan === 'BASIC' ? (
                  <span onClick={() => handleBuySubscription('buy')}>Upgrade Plan</span>
                ) : (
                  <span onClick={handleClick}>
                    {licenseRemaining > 0 ? 'Assign Licences' : 'Buy Licences'}
                  </span>
                )}
              </Button>
            )}

            <div className={styles.description}>
              {plan === 'BASIC' ? (
                <span>Buy plan to remove all user limits on your plan and</span>
              ) : (
                <span>Assign Licenses to your teammates and</span>
              )}
              <br /> increase your engagement rate to at least 50%
            </div>
          </div>
        </div>
      </div>
      {isModalOpen &&
        (licenseRemaining > 0 ? (
          <Modal
            open={isModalOpen}
            onCancel={handleClick}
            title='Manage Licences'
            cancelText='Do Later'
            confirmText='Assign Licences'
            onConfirm={() => handleAssignLicenseConfirm()}
            className={styles.assignLicenseModalWidth}
          >
            <div className={styles.assignLicenseModal}>
              <div className={styles.upperPart}>
                <div>
                  <div>{licensedCount}</div>
                  <span>Total assigned Licences</span>
                </div>
                <div>
                  <div>{licenseRemaining}</div>
                  <span>Unassigned Licences</span>
                </div>
              </div>
              <hr style={{ color: '#FFFFFF66', height: '1px', margin: '1rem 0rem' }} />
              <div className={styles.couldNotPlayNote}>
                You have {licenseRemaining} Licenses unassigned, Do you want to assign who couldn’t
                play?
              </div>
              <div className={styles.searchBar}>
                <AssignLicenses
                  teamsSubscription={teamsSubscription}
                  checkOverview={1}
                  handleClick={handleClick}
                  setAssignLicenseClicked={setAssignLicenseClicked}
                  assignLicenseClicked={assignLicenseClicked}
                />
              </div>
            </div>
          </Modal>
        ) : (
          <ManageLicense
            teamsSubscription={teamsSubscription}
            handleCancelClick={handleClick}
            licensesAssigned={Number(licenseCount ?? teamsSubscription?.licensedCount)}
            licensesRemaining={licenseRemaining}
            buttonType='Buy Licenses'
            teamMataesUnableToPlay={names.length}
            checkOverview={1}
          />
        ))}
      ;
    </>
  );
};

BasicOverViewSection.propTypes = {
  platform: PropTypes.string.isRequired,
  gamesPlayed: PropTypes.number.isRequired,
  plan: PropTypes.string.isRequired,
  teamsSubscription: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    licenseCount: PropTypes.number.isRequired,
    licensedCount: PropTypes.number.isRequired,
    licensesAssigned: PropTypes.number.isRequired
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleBuySubscription: PropTypes.func.isRequired,
  participantLeftOut: PropTypes.arrayOf(
    PropTypes.shape({
      fullname: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BasicOverViewSection;
