/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlanCardTooltip } from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import StandardProSection from './StandardProOverviewSection';
import BasicOverViewSection from './BasicOverViewSection';
import styles from './styles.module.scss';

const OverviewSection = ({
  platform,
  gamesPlayed,
  planResponse,
  userData,
  channels,
  uniqueUsersCount,
  selectedChannels,
  activeUsers,
  setSelectedChannels,
  dropDownChannelOptions,
  participantLeftOut,
  teamsSubscription,
  handleBuySubscription,
  channelBasedBilling,
  handleManageChannelClick,
  unassignedUsers,
  usersCount,
  isCalculatingUniqueUsersCount,
  isModalOpen,
  setIsModalOpen,
  updateChannelListLoading,
  setUpdateChannelListLoading,
  toggleStandardProModal,
  openCustomerPortal
}) => {
  const { plan, planPrices } = planResponse;
  const [hasChanges, setHasChanges] = useState(false);
  const { freeTrial } = userData;
  const user = useSelector((state) => state.user);
  const { trialExpiresAt } = user;
  const trialDaysLeft = moment(trialExpiresAt).diff(moment(), 'days', false);
  const restrictedPlayers = participantLeftOut?.length;
  const noRestrictedPlayersInBasicPlan = restrictedPlayers < 1 && plan === 'BASIC';
  const handleClickInChannelDropdown = (selectedChannel) => {
    setSelectedChannels(selectedChannel);
  };
  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={(plan === 'BASIC' || freeTrial) && styles.mainOverviewWrapper}>
      {freeTrial || noRestrictedPlayersInBasicPlan ? (
        <div className={styles.freeTrailWrapper}>
          <div className={styles.overviewHeading}>Overview</div>
          <div className={styles.freeTrailBody}>
            <div className={styles.planDetails}>
              <div className={styles.planInfo}>
                Free plan{noRestrictedPlayersInBasicPlan ? `- Basic` : null}
                <span className={styles.termInfo}>
                  {noRestrictedPlayersInBasicPlan ? `(${planPrices?.TERM || 'month'}ly)` : `(Pro)`}
                </span>
                <div className={styles.currentPlan}>Current Plan</div>
              </div>
              {noRestrictedPlayersInBasicPlan ? (
                <div>
                  <div>
                    {activeUsers}
                    <span>
                      {' '}
                      <PlanCardTooltip
                        label={
                          channelBasedBilling
                            ? `Unique users are the individuals of the channels where Trivia \n is enabled.Billing is per user means it's charged once, regardless\nof their participation in multiple Trivia-enabled channels.`
                            : `Any user who plays a game or uses other\n Trivia features are called Trivia Users.`
                        }
                      >
                        <InfoIcon />
                      </PlanCardTooltip>
                    </span>
                  </div>
                  <div className={styles.currentPlan}>
                    {channelBasedBilling ? 'Unique users ' : 'Active users'}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {trialDaysLeft >= 0 ? trialDaysLeft : 'no'} days left
                    <span>
                      {' '}
                      <PlanCardTooltip
                        label={`${trialDaysLeft} days left for your free plan to complete.\nUpgrade now to continue playing with Trivia.`}
                      >
                        <InfoIcon />
                      </PlanCardTooltip>
                    </span>
                  </div>
                  <div className={styles.currentPlan}>Of Free Trail</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : plan === 'BASIC' || platform === 'teams' ? (
        <BasicOverViewSection
          plan={plan}
          gamesPlayed={gamesPlayed}
          handleBuySubscription={handleBuySubscription}
          platform={platform}
          planResponse={planResponse}
          teamsSubscription={teamsSubscription}
          handleClick={handleClick}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          participantLeftOut={participantLeftOut}
          unassignedUsers={unassignedUsers}
        />
      ) : (
        plan !== null && (
          <StandardProSection
            plan={plan}
            term={planResponse.planPrices?.TERM || 'month'}
            channelBasedBilling={channelBasedBilling}
            handleClickInChannelDropdown={handleClickInChannelDropdown}
            usersCount={usersCount}
            handleClick={handleClick}
            dropDownChannelOptions={dropDownChannelOptions}
            uniqueUsersCount={uniqueUsersCount}
            channels={channels}
            isModalOpen={isModalOpen}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            selectedChannels={selectedChannels}
            setSelectedChannels={setSelectedChannels}
            handleManageChannelClick={handleManageChannelClick}
            isCalculatingUniqueUsersCount={isCalculatingUniqueUsersCount}
            updateChannelListLoading={updateChannelListLoading}
            setUpdateChannelListLoading={setUpdateChannelListLoading}
            toggleStandardProModal={toggleStandardProModal}
            openCustomerPortal={openCustomerPortal}
          />
        )
      )}
    </div>
  );
};

OverviewSection.propTypes = {
  platform: PropTypes.string.isRequired,
  gamesPlayed: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
  planResponse: PropTypes.shape({
    plan: PropTypes.string.isRequired,
    channelBasedBilling: PropTypes.bool.isRequired,
    planPrices: PropTypes.shape({
      STANDARD: PropTypes.number,
      PRO: PropTypes.number,
      TERM: PropTypes.string
    })
  }),
  userData: PropTypes.shape({
    freeTrial: PropTypes.bool.isRequired,
    usersCount: PropTypes.number.isRequired
  }).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  activeUsers: PropTypes.number.isRequired,
  uniqueUsersCount: PropTypes.number.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedChannels: PropTypes.func.isRequired,
  dropDownChannelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  teamsSubscription: PropTypes.shape({}),
  channelBasedBilling: PropTypes.bool.isRequired,
  handleBuySubscription: PropTypes.func.isRequired,
  participantLeftOut: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleManageChannelClick: PropTypes.func.isRequired,
  isCalculatingUniqueUsersCount: PropTypes.bool.isRequired,
  updateChannelListLoading: PropTypes.bool.isRequired,
  setUpdateChannelListLoading: PropTypes.func.isRequired,
  toggleStandardProModal: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  openCustomerPortal: PropTypes.func.isRequired,
  unassignedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

OverviewSection.defaultProps = {
  teamsSubscription: {},
  planResponse: {
    plan: '',
    channelBasedBilling: false,
    planPrices: {}
  }
};

export default OverviewSection;
