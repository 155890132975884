/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Switch = ({ disabled, checked, handleChange }) => (
  <label className={styles.switchWrapper} style={{ opacity: disabled ? '0.4' : '1' }}>
    <input
      data-testid='Switch-id'
      type='checkbox'
      disabled={disabled}
      checked={!disabled && checked}
      onChange={handleChange}
    />
    <span className={styles.slider} />
  </label>
);

Switch.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
Switch.defaultProps = {
  disabled: false,
  checked: false
};

export default Switch;
