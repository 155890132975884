import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import api from './services/api';
import { categoriesApi } from './services/categories';
import { leaderboardApi } from './services/leaderboard';
import { KnowYourTeamApi } from './services/KYT-responses';
import authenticateResponse from './middleware/authenticateResponse';
import userReducer from './features/userSlice';
import leaderboardReducer from './features/leaderboardSlice';
import monetizationReducer from './features/monetizationSlice';
import knowYourTeamReducer from './features/KnowYourTeamSlice';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [leaderboardApi.reducerPath]: leaderboardApi.reducer,
    [KnowYourTeamApi.reducerPath]: KnowYourTeamApi.reducer,
    user: userReducer,
    leaderboard: leaderboardReducer,
    monetization: monetizationReducer,
    KnowYourTeam: knowYourTeamReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authenticateResponse,
      api.middleware,
      categoriesApi.middleware,
      leaderboardApi.middleware,
      KnowYourTeamApi.middleware
    ])
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
