import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@springrole/trivia-games';

const CustomSelect = (props) => {
  const { options, value, setValue, placeholder, ...rest } = props;

  return (
    <Select
      data={options}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      {...rest}
    />
  );
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired
};

CustomSelect.defaultProps = {
  disabled: false,
  placeholder: 'Pick One'
};

export default CustomSelect;
