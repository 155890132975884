import React from 'react';
import PropTypes from 'prop-types';
import DotSeparator from 'components/DotSeparator';
import MenuItem from 'components/MenuItem';
import styles from './styles.module.scss';

const MobileAutomationRow = (props) => {
  const { automation, handleAutomationModalOpen } = props;
  return (
    <>
      {automation.map((item) => (
        <div key={item.id.value} className={styles.container}>
          <div className={styles.detailContainer}>
            <div className={styles.topDetail}>
              <b className={styles.gameType}>{item.game}</b>
            </div>
            <div className={styles.bottomDetail}>{item.channel}</div>
            <div className={styles.bottomDetail}>
              {item.time} <DotSeparator width='4px' height='4px' /> {item.frequency}
            </div>
          </div>
          <MenuItem
            content={['delete', 'edit']}
            handleOnClick={(key) => handleAutomationModalOpen(key, item.id.value)}
          />
        </div>
      ))}
    </>
  );
};

MobileAutomationRow.propTypes = {
  automation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.shape({
        value: PropTypes.string,
        display: PropTypes.bool
      }),
      type: PropTypes.string,
      channel: PropTypes.string,
      team: PropTypes.string,
      frequency: PropTypes.string,
      time: PropTypes.string
    })
  ).isRequired,
  handleAutomationModalOpen: PropTypes.func.isRequired
};

export default MobileAutomationRow;
