import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Empty } from 'assets/images/empty-state.svg';
import { useGetCustomPollInfoQuery } from 'app/services/poll-results';
import { Section, Loading } from '@springrole/trivia-games';
import { Accordion } from '@mantine/core';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const PollInfo = ({ id, handlePollSelect }) => {
  const [selectedPollInfo, setSelectedPollInfo] = useState({
    selectedOption: null,
    opinion: '',
    options: [],
    participants: [],
    host: '',
    startTime: ''
  });

  const { selectedOption, opinion, options, participants, host, startTime } = selectedPollInfo;
  const { data: responseData, isLoading } = useGetCustomPollInfoQuery(id);

  useEffect(() => {
    if (!isLoading && responseData) {
      const { data } = responseData;
      setSelectedPollInfo({
        selectedOption: null,
        opinion: data?.opinion,
        options: data?.options,
        participants: data?.participants,
        host: data?.host,
        startTime: data?.startTime
      });
    }
  }, [responseData, isLoading]);

  const handleOptionClick = (option) => {
    setSelectedPollInfo({ ...selectedPollInfo, selectedOption: option });
  };

  const participantsCountByOption = {};

  participants?.forEach((participant) => {
    const { answer } = participant;
    participantsCountByOption[answer] = (participantsCountByOption[answer] || 0) + 1;
  });

  const overviewData = [
    { item: host, label: 'Host' },
    { item: participants?.length, label: 'People Participated' },
    { item: options?.length, label: 'Choices' },
    { item: dayjs(startTime).format('DD-MMM-YYYY [,] HH:mm A'), label: 'Start Date and Time' }
  ];

  const getOptionAlphabet = (ind) => {
    const alphabetList = ['A', 'B', 'C', 'D', 'E'];
    return alphabetList[ind];
  };

  const renderParticipantsByOption = (option) => {
    if (option === selectedOption) {
      const filteredParticipants = participants.filter(
        (participant) => participant.answer === option
      );
      return (
        <div className={styles.participants}>
          {filteredParticipants?.length > 0 ? (
            <div className={styles.participantsDeatils}>
              {filteredParticipants?.map((participant) => (
                <span key={participant.id} className={styles.participantsDetailsContent}>
                  {participant.name || 'name'}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.noParticipants}>
              <Empty />
              <div className={styles.text}>No one chose this option</div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setSelectedPollInfo((prev) => ({
      ...prev,
      selectedOption: options?.length > 0 ? options[0] : null
    }));
  }, [options]);

  if (isLoading) {
    return (
      <div className={styles.pollInfoWrapper}>
        <div className={styles.goBackButton} onClick={() => handlePollSelect(null)}>
          {'< Go Back'}
        </div>
        <Section>
          <Loading height={100} width={100} />
        </Section>
      </div>
    );
  }
  return (
    <div className={styles.pollInfoWrapper}>
      <div className={styles.goBackButton} onClick={() => handlePollSelect(null)}>
        {'< Go Back'}
      </div>
      {!isLoading && (
        <div className={styles.pollOverviewSection}>
          <Section>
            <div className={styles.overviewHeading}>Overview</div>
            <div className={styles.overviewContent}>
              {overviewData?.map(({ item, label }) => (
                <div key={label} className={styles.overviewData}>
                  <div>{item}</div>
                  <div className={styles.labels}>{label}</div>
                </div>
              ))}
            </div>
          </Section>
          <Section className={styles.pollInfoSection}>
            <div className={styles.header}>💡Results</div>
            <div className={styles.opinion}>Poll Opinion: {opinion}</div>
            <Accordion
              classNames={{
                root: styles.customRoot,
                item: styles.customItem,
                control: styles.customControl,
                chevron: styles.customChevron,
                icon: styles.customIcon,
                content: styles.customContent,
                label: styles.customLabel
              }}
            >
              {responseData &&
                options?.map((option, index) => (
                  <div key={option} className={styles.optionData}>
                    <div className={styles.alphabet}>{getOptionAlphabet(index)}</div>
                    <div className={styles.data}>
                      <Accordion.Item key={option} value={option}>
                        <Accordion.Control onClick={() => handleOptionClick(option)}>
                          <div className={styles.option}>
                            <div className={styles.optionPercentage}>
                              {participants?.length > 0
                                ? `${Math.round(
                                    ((participantsCountByOption[option] || 0) /
                                      participants.length) *
                                      100
                                  )}`
                                : 0}
                              %
                            </div>
                            <div className={styles.optionText}>{option}</div>
                          </div>
                          <div className={styles.optionChosenPeople}>
                            {participantsCountByOption[option] || 0} People
                          </div>
                        </Accordion.Control>
                        <Accordion.Panel>{renderParticipantsByOption(option)}</Accordion.Panel>
                      </Accordion.Item>
                    </div>
                  </div>
                ))}
            </Accordion>
          </Section>
        </div>
      )}
    </div>
  );
};

PollInfo.propTypes = {
  id: PropTypes.string.isRequired,
  handlePollSelect: PropTypes.func.isRequired
};

export default PollInfo;
