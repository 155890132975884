import { Loading, Modal } from '@springrole/trivia-games';
import React, { useState } from 'react';
import _ from 'lodash';
import { useAddQuestionMutation, useCompleteQuizMutation } from 'app/services/custom-quiz';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Questions from './modal-content/Questions';
import { Create, Question } from './modal-content';
import getQuestionData from './getQuestionData';
import styles from './styles.module.scss';

const QuizModal = ({
  showActions,
  isPaidUser,
  catName,
  contentLoading,
  qCount,
  onQuestionClick,
  onQuestionAdded,
  ResetQuizData,
  AddQuestion,
  CompleteQuiz,
  ...props
}) => {
  const [formData, setFormData] = useState({
    name: '',
    questionsCount: '5',
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    correct: '1',
    num: 1
  });
  const [error, setError] = useState(null);
  const [addQuestion] = useAddQuestionMutation();
  const [completeQuiz] = useCompleteQuizMutation();

  const onAddQuestion = async () => {
    const { id, questions } = props;
    const { num } = formData;
    const data = getQuestionData(questions, formData);
    if (
      data.question === 'null' ||
      _.isEmpty(data.question?.trim()) ||
      _.isEmpty(data.option1?.trim()) ||
      _.isEmpty(data.option2?.trim())
    ) {
      toast.error(
        'Please add question and all answers before saving and make sure none of them are empty white spaces2.'
      );
    } else if (_.uniq(data.optionsArray).length !== data.optionsArray.length) {
      toast.error('One more options are same. Please try giving different option values.');
    } else if (_.isEmpty(data.option3) && data.correct === '3') {
      toast.error('Option 3 Cannot be empty if that is the correct option.');
    } else if (
      (data.correct === '4' && _.isEmpty(data.option4)) ||
      (_.isEmpty(data.option3) && !_.isEmpty(data.option4))
    ) {
      if (_.isEmpty(data.option3)) {
        toast.error('Please provide all the preceeding options first!');
      } else {
        toast.error('Option 4 Cannot be empty if that is the correct option.');
      }
    } else if (
      (data.correct === '5' && _.isEmpty(data.option5)) ||
      (!_.isEmpty(data.option5) && (_.isEmpty(data.option3) || _.isEmpty(data.option4)))
    ) {
      if (_.isEmpty(data.option3) || _.isEmpty(data.option4)) {
        toast.error('Please provide all the preceeding options first!');
      } else {
        toast.error('Option 5 Cannot be empty if that is the correct option.');
      }
    } else {
      const answers = [
        { value: data.option1.trim(), correct: data.correct === '1' },
        { value: data.option2.trim(), correct: data.correct === '2' }
      ];
      if (data.option3 && !_.isEmpty(data.option3.trim())) {
        answers.push({ value: data.option3.trim(), correct: data.correct === '3' });
      }
      if (data.option4 && !_.isEmpty(data.option4.trim())) {
        answers.push({ value: data.option4.trim(), correct: data.correct === '4' });
      }
      if (data.option5 && !_.isEmpty(data.option5.trim())) {
        answers.push({ value: data.option5.trim(), correct: data.correct === '5' });
      }
      const response = await addQuestion({
        question: data.question.trim(),
        answers,
        num,
        id,
        complete: false
      }).unwrap();
      if (response?.ok) {
        const questionsTemp = [...questions];
        questionsTemp[num - 1] = { question: data.question.trim(), answers };
        onQuestionAdded(questionsTemp);
        setFormData((form) => ({
          ...form,
          question: '',
          option1: '',
          option2: '',
          option3: '',
          option4: '',
          option5: '',
          correct: '0'
        }));
      }
    }
  };

  const onConfirmCreation = async () => {
    const { questions, id, onCancel } = props;

    if (questions.every((q) => q.question !== 'null')) {
      const response = await completeQuiz({ quizId: id }).unwrap();
      if (response.ok) {
        toast.success('Successfully created custom quiz.');
        onCancel();
      }
    } else {
      toast.error('Please add all questions.');
    }
  };

  const getModalProps = () => {
    const { type, questions, onConfirm } = props;
    const { name, questionsCount } = formData;
    const modalProps = {
      title: 'Modal title',
      children: 'Modal content',
      classNames: {
        modal: styles.customModalClass,
        header: styles.customHeaderClass
      },
      ...props
    };
    if (!showActions && !type === 'new-quiz') {
      modalProps.showConfirm = false;
      modalProps.showCancelButton = false;
    }
    if (type === 'new-quiz') {
      modalProps.title = 'Create Custom quiz';
      modalProps.confirmText = 'Save';
      modalProps.children = (
        <Create
          name={name}
          questionsCount={questionsCount}
          handleChange={(field, value) => {
            if (
              field === 'name' &&
              (value.length > 25 || value.replace(/\s+/g, ' ').trim().length < 5)
            ) {
              setError(
                value.length > 25
                  ? 'Quiz name cannot exceed 25 characters'
                  : 'You must enter atleast 5 characters'
              );
            } else {
              setError(null);
            }
            setFormData((form) => ({ ...form, [field]: value }));
          }}
          error={error}
          isPaidUser={isPaidUser}
        />
      );
      modalProps.onConfirm = () => onConfirm(name, parseInt(questionsCount, 10));
    } else if (type === 'questions') {
      modalProps.title = catName;
      modalProps.children = (
        <Questions
          questions={questions}
          totalQuestions={qCount}
          onQuestionClick={(num) => {
            setFormData((form) => ({ ...form, num }));
            onQuestionClick();
          }}
        />
      );
      modalProps.confirmText = 'Submit';
      modalProps.onConfirm = onConfirmCreation;
    } else if (type === 'question') {
      const data = getQuestionData(questions, formData);
      modalProps.title = catName;
      modalProps.children = (
        <Question
          question={data.question}
          option1={data.option1}
          option2={data.option2}
          option3={data.option3}
          option4={data.option4}
          option5={data.option5}
          correct={data.correct}
          showFifth={isPaidUser || !_.isEmpty(data.option5)}
          onChange={(key, value) => setFormData((form) => ({ ...form, [key]: value }))}
        />
      );
      modalProps.confirmText = 'Save';
      modalProps.onConfirm = onAddQuestion;
      modalProps.onCancel = () => {
        setFormData((form) => ({
          ...form,
          question: '',
          option1: '',
          option2: '',
          option3: '',
          option4: '',
          option5: '',
          correct: '0'
        }));
        return onQuestionAdded(questions);
      };
      modalProps.size = 'md';
    }

    if (contentLoading) {
      modalProps.children = <Loading height={50} width={50} />;
      modalProps.loading = true;
    }
    return modalProps;
  };

  return (
    <Modal
      {...getModalProps()}
      classNames={{
        modal: styles.modalWrapper,
        header: styles.header,
        title: styles.title,
        close: styles.close,
        inner: styles.inner
      }}
      error={error}
    />
  );
};

QuizModal.propTypes = {
  AddQuestion: PropTypes.func,
  id: PropTypes.string,
  onQuestionAdded: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      value: PropTypes.string
    })
  ),
  CompleteQuiz: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  ResetQuizData: PropTypes.func,
  type: PropTypes.string,
  catName: PropTypes.string,
  qCount: PropTypes.number,
  contentLoading: PropTypes.bool,
  onQuestionClick: PropTypes.func,
  isPaidUser: PropTypes.bool,
  onConfirm: PropTypes.func,
  showActions: PropTypes.bool
};
QuizModal.defaultProps = {
  id: '',
  isPaidUser: false,
  catName: '',
  qCount: 0,
  contentLoading: false,
  type: '',
  questions: [],
  AddQuestion: () => null,
  CompleteQuiz: () => null,
  ResetQuizData: () => null,
  onQuestionAdded: () => null,
  onQuestionClick: () => null,
  onConfirm: () => null,
  showActions: false
};

export default QuizModal;
