import React from 'react';
import PropTypes from 'prop-types';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import styles from '../styles.module.scss';

const SearchAutocomplete = ({ placeholder, onSelect, items, showIcon, ...restProps }) => (
  <ReactSearchAutocomplete
    placeholder={placeholder}
    onSelect={onSelect}
    showIcon={showIcon}
    items={items}
    styling={{
      height: '3rem',
      borderRadius: '8px',
      border: 'none',
      cursor: 'pointer',
      zIndex: 2,
      fontSize: '14px',
      fontFamily: 'poppins'
    }}
    className={styles.searchAutocomplete}
    {...restProps}
  />
);

SearchAutocomplete.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default SearchAutocomplete;
