import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Pagination = ({ pages, currentPage, onNext, onPrev, pagesToShow, onPageClick }) => {
  let shownPages =
    currentPage > pages.length - pagesToShow
      ? pages.slice(pages.length - pagesToShow, pages.length)
      : pages.slice(currentPage - 1, currentPage - 1 + pagesToShow);
  if (pages.length <= pagesToShow) {
    shownPages = pages;
  }
  return (
    <div className={styles.paginationContainer}>
      <div
        className={classNames(styles.prevPage, styles.arrow, {
          [styles.disabled]: currentPage === 1
        })}
        onClick={onPrev}
      />
      <div className={styles.pages}>
        {shownPages.map((page) => (
          <div
            className={classNames(styles.page, { [styles.active]: currentPage === page })}
            key={page}
            onClick={() => onPageClick(page)}
          >
            {page}
          </div>
        ))}
      </div>
      <div
        className={classNames(styles.nextPage, styles.arrow, {
          [styles.disabled]: currentPage > pages.length - 1
        })}
        onClick={onNext}
      />
    </div>
  );
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  pagesToShow: PropTypes.number,
  onPageClick: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  pages: [1],
  currentPage: 1,
  pagesToShow: 5
};

export default Pagination;
