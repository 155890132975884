/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { Section, MultiSelectDropdown } from '@springrole/trivia-games';
import { PlanCardTooltip } from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import popular from 'assets/images/billing/popular.svg';
import calculateTieredPricing from 'utils/utilities';
import MantineModal from 'components/Modal/MantineModal';
import TextField from 'components/TextField';
import styles from '../styles.module.scss';

const PlanCardComponent = ({
  index,
  btnPlan,
  handleButtonClick,
  isCalculatingUniqueUsersCount,
  handleClickInChannelDropdown,
  username,
  useremail,
  dropDownChannelOptions,
  selectedChannels,
  open,
  setOpen,
  billingInfo,
  setBillingInfo,
  handleStripeRedirect,
  data,
  platform,
  selectedchannelLength,
  billingIntervalState,
  freeTrial,
  activeUsers,
  uniqueUsersCount,
  channelBasedBilling
}) => {
  const usersCount = channelBasedBilling ? uniqueUsersCount : activeUsers;
  const price =
    platform === 'slack'
      ? data.plan === 'Standard'
        ? billingIntervalState === 'month'
          ? '$29.'
          : '$279.'
        : billingIntervalState === 'month'
        ? '$49.'
        : '$479.'
      : data.plan === 'Standard'
      ? '29'
      : '25';
  const slackStandardCosts = calculateTieredPricing(usersCount, 'STANDARD', billingIntervalState);
  const slackProCosts = calculateTieredPricing(usersCount, 'PRO', billingIntervalState);
  const formatPrice = (value) => Number(value.toFixed(2)).toLocaleString();

  const handleBillingInfo = (e) => {
    setBillingInfo({ ...billingInfo, [e.target.name]: e.target.value });
  };

  const handleConfirmClick = () => {
    handleStripeRedirect(btnPlan);
  };
  return (
    <>
      <MantineModal
        open={open}
        onCancel={() => setBillingInfo({ username, useremail }, setOpen(false))}
        title='Billing Information'
        onConfirm={() => handleConfirmClick()}
        loading={isCalculatingUniqueUsersCount}
        disabled={selectedChannels.length === 0}
      >
        <div>
          {channelBasedBilling && (
            <>
              <div className={styles.billingText}>CHANNELS TO ENABLE</div>
              <MultiSelectDropdown
                value={selectedChannels}
                data={dropDownChannelOptions}
                onChange={handleClickInChannelDropdown}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
              <div className={styles.note}>
                <span className={styles.note}>
                  Note: Only unique users from the above selected channels will be billed and
                  allowed to play Trivia
                </span>
              </div>
              <br />
              <div className={styles.uniqueUsersText}>
                👥 Total Unique users - {uniqueUsersCount}
              </div>
              <hr className={styles.line} />
            </>
          )}
          <div className={styles.billingText}>BILLING NAME</div>
          <TextField
            className={styles.billingTextField}
            placeholder='Type here'
            name='name'
            value={billingInfo?.name ?? username}
            onChange={handleBillingInfo}
          />

          <div className={styles.billingText}>BILLING EMAIL</div>
          <TextField
            className={styles.billingTextField}
            placeholder='Type here'
            name='email'
            value={billingInfo?.email ?? useremail}
            onChange={handleBillingInfo}
          />
        </div>
        {selectedChannels < 1 && channelBasedBilling ? (
          <span className={styles.error}>Select at least one channel</span>
        ) : null}
      </MantineModal>
      <Section
        className={`${styles.Plan} ${
          index === 1 ? styles.larger : index === 0 && styles.basicCard
        }`}
      >
        <aside>
          {index === 1 && (
            <img
              src={popular}
              alt=''
              className={`${styles.popularTag} ${
                billingIntervalState === 'year' && styles.yearlyStyle
              }
            `}
            />
          )}
        </aside>
        <div>
          <div
            className={`${styles.planInfo} ${
              selectedchannelLength === 0 && platform === 'slack' && channelBasedBilling
                ? styles.noChannels
                : styles.withChannels
            }`}
          >
            <div className={styles.userPlan}>
              {data.plan}
              {selectedchannelLength === 0 &&
                platform === 'slack' &&
                channelBasedBilling &&
                billingIntervalState !== 'month' && (
                  <div className={styles.offPercent}>20% off</div>
                )}
            </div>
            <div className={styles.planDetails}>
              <span>${data.plan === 'Pro' ? 2 : data.plan === 'Basic' ? 0 : 1}</span>/
              {platform === 'slack' ? 'user' : 'Licenses'}
              /month
            </div>
          </div>
          {selectedchannelLength > 0 || !channelBasedBilling ? (
            platform === 'teams' ? (
              billingIntervalState === 'month' ? (
                <div className={styles.someThing}>
                  <div className={styles.priceDetails}>
                    <span className={styles.price}>
                      ${data.plan === 'Basic' ? 0 : data.plan === 'Pro' ? 2 : 1}
                    </span>
                    /License/{billingIntervalState}{' '}
                    <span>
                      {data.plan !== 'Basic' && (
                        <PlanCardTooltip
                          label={`${usersCount} users * $${data.plan === 'Pro' ? 2 : 1}`}
                        >
                          <InfoIcon />
                        </PlanCardTooltip>
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={styles.yearlyPlan}>
                  <div className={styles.totalCost}>
                    <span className={styles.crossed}>
                      {data.plan !== 'Basic' && (
                        <>
                          ${data.plan === 'Basic' ? 0 : data.plan === 'Pro' ? 2 * 12 : 1 * 12}
                          /License/
                          {billingIntervalState}{' '}
                        </>
                      )}
                    </span>
                    <span className={styles.offPercent}>{data.plan !== 'Basic' && '20% off'}</span>
                  </div>
                  <div className={data.plan !== 'Basic' ? styles.payingCost : styles.basicCrossed}>
                    $
                    {data.plan === 'Basic'
                      ? 0
                      : data.plan === 'Pro'
                      ? formatPrice(2 * 12 * 0.8)
                      : formatPrice(1 * 12 * 0.8)}
                    <span className={styles.year}>/License/{billingIntervalState}</span>{' '}
                    <span>
                      {data.plan !== 'Basic' && (
                        <PlanCardTooltip
                          label={`(${usersCount} users x $${
                            data.plan === 'Pro' ? 2 : 1
                          } x 12 months) * 20% discount
                          `}
                        >
                          <InfoIcon />
                        </PlanCardTooltip>
                      )}
                    </span>
                  </div>
                </div>
              )
            ) : billingIntervalState === 'month' ? (
              <div className={styles.priceDetails}>
                <span className={styles.price}>
                  $
                  {data.plan === 'Pro'
                    ? slackProCosts.totalCost
                    : data.plan === 'Basic'
                    ? 0
                    : slackStandardCosts.totalCost}
                </span>
                /{billingIntervalState}{' '}
                <span>
                  {data.plan !== 'Basic' && (
                    <PlanCardTooltip
                      label={`${usersCount} trivia Active Users \n${
                        data.plan === 'Pro'
                          ? slackProCosts.tierDetails
                              .map(
                                (tier, ind) =>
                                  `${tier.tierName}: ${
                                    ind === 0
                                      ? `min. billing of $49`
                                      : `${tier.applicableQty} users x $${tier.unitPrice} = $${tier.tierCost}`
                                  }`
                              )
                              .join('\n')
                          : slackStandardCosts.tierDetails
                              .map(
                                (tier, ind) =>
                                  `${tier.tierName}: ${
                                    ind === 0
                                      ? `min. billing of $29`
                                      : `${tier.applicableQty} users x $${tier.unitPrice} = $${tier.tierCost}`
                                  }`
                              )
                              .join('\n')
                      }\n\nTotal: $${
                        data.plan === 'Pro' ? slackProCosts.totalCost : slackStandardCosts.totalCost
                      }`}
                    >
                      <InfoIcon />
                    </PlanCardTooltip>
                  )}
                </span>
              </div>
            ) : (
              <div className={styles.yearlyPlan}>
                <div className={styles.totalCost}>
                  <span className={styles.crossed}>
                    {data.plan !== 'Basic' && (
                      <>
                        $
                        {data.plan === 'Pro'
                          ? slackProCosts.totalCostWithoutDiscount
                          : data.plan === 'Basic'
                          ? null
                          : slackStandardCosts.totalCostWithoutDiscount}
                        /{billingIntervalState}{' '}
                      </>
                    )}
                  </span>
                  <span className={styles.offPercent}>{data.plan !== 'Basic' && '20% off'}</span>
                </div>
                <div className={styles.payingCostWrapper}>
                  <div className={data.plan !== 'Basic' ? styles.payingCost : styles.basicCrossed}>
                    $
                    {data.plan === 'Pro'
                      ? slackProCosts.totalCost
                      : data.plan === 'Basic'
                      ? 0
                      : slackStandardCosts.totalCost}
                    <span className={styles.year}>/{billingIntervalState}</span>{' '}
                    <span>
                      {data.plan !== 'Basic' && (
                        <PlanCardTooltip
                          label={`${usersCount} trivia Active Users \n${
                            data.plan === 'Pro'
                              ? slackProCosts.tierDetails
                                  .map(
                                    (tier, ind) =>
                                      `${tier.tierName}:   (${tier.applicableQty} users x $${
                                        ind === 0 ? '2' : tier.unitPrice
                                      }  ${ind === 0 ? `x 12` : ''}) ${
                                        ind === 0 ? 'x 20% off' : ''
                                      } = $${tier.tierCost}`
                                  )
                                  .join('\n')
                              : slackStandardCosts.tierDetails
                                  .map(
                                    (tier, ind) =>
                                      `${tier.tierName}:   (${tier.applicableQty} users x $${
                                        ind === 0 ? '1' : tier.unitPrice
                                      }  ${ind === 0 ? `x 12` : ''}) ${
                                        ind === 0 ? 'x 20% off' : ''
                                      } = $${tier.tierCost}`
                                  )
                                  .join('\n')
                          }\n\nTotal: $${
                            data.plan === 'Pro'
                              ? slackProCosts.totalCost
                              : slackStandardCosts.totalCost
                          }`}
                        >
                          <InfoIcon />
                        </PlanCardTooltip>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </div>
        {data.plan !== 'Basic' && (
          <div className={styles.minimumPricing}>
            {platform === 'slack' ? `Min. billing price is ` : `Min. license quantity is `}
            <span>{price}</span>
          </div>
        )}
      </Section>
      {freeTrial && data.plan.toUpperCase() === 'Basic' ? null : data.plan.toUpperCase() ===
        'BASIC' ? (
        <button type='button' className={styles.currentPlanBtn}>
          Current Plan
        </button>
      ) : (
        <button
          type='button'
          onClick={() => handleButtonClick(data.plan.toUpperCase())}
          className={styles.planBtn}
        >
          {' '}
          Choose Plan{' '}
        </button>
      )}
      <ul>
        {data.allFeatures.map((feature, i) => (
          <li key={i}>{feature}.</li>
        ))}
      </ul>
    </>
  );
};

export default PlanCardComponent;

PlanCardComponent.propTypes = {
  index: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired,
  selectedchannelLength: PropTypes.number.isRequired,
  billingIntervalState: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  useremail: PropTypes.string.isRequired,
  channelBasedBilling: PropTypes.number.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  dropDownChannelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  handleClickInChannelDropdown: PropTypes.func.isRequired,
  btnPlan: PropTypes.string.isRequired,
  uniqueUsersCount: PropTypes.number.isRequired,
  activeUsers: PropTypes.number.isRequired,
  freeTrial: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleStripeRedirect: PropTypes.func.isRequired,
  isCalculatingUniqueUsersCount: PropTypes.bool.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  setBillingInfo: PropTypes.func.isRequired,
  data: PropTypes.shape({
    plan: PropTypes.string,
    featuresList: PropTypes.arrayOf(PropTypes.string).isRequired,
    allFeatures: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};
