import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import colors from '../../utils/stylesheet/colors';
import styles from './styles.module.scss';

const SectionWrapper = styled.div`
  display: block;
  background-color: ${colors.trivia_section_bg};
  border-radius: 4px;
  ${({ disablePadding }) => (disablePadding ? 'padding: 0;' : 'padding: 3rem;')}
  overflow: auto;
  width: 100%;
  @media only screen and (max-width: 560px) {
    padding: 1rem;
    border-radius: 8px;
    width: unset !important;
    ${({ disableMargin }) => (disableMargin ? 'margin-bottom: 0' : 'margin: 1rem 0rem 6rem;')}
  }
  ${({ disableMargin }) => (disableMargin ? 'margin: 0' : 'margin: auto;')}
  ${({ size }) => (size === 'small' ? 'width: 60%;' : '')}
`;

const Section = (props) => {
  const {
    disableRightPadding,
    id,
    size,
    disableMargin,
    disablePadding,
    style,
    className,
    children
  } = props;
  return (
    <SectionWrapper
      id={id}
      size={size}
      disableMargin={disableMargin}
      disablePadding={disablePadding}
      style={style}
    >
      <div
        className={classNames(
          styles.sectionContainer,
          {
            [styles.noPadding]: disablePadding,
            [styles.noRightPadding]: disableRightPadding
          },
          className
        )}
      >
        {children}
      </div>
    </SectionWrapper>
  );
};

Section.propTypes = {
  disableMargin: PropTypes.bool,
  disablePadding: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  disableRightPadding: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.shape()
};

Section.defaultProps = {
  disableMargin: false,
  disablePadding: false,
  id: null,
  className: null,
  disableRightPadding: false,
  size: 'large',
  style: {}
};

export default Section;
