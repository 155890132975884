import React, { useState } from 'react';
import history from 'createHistory';
import { DotSeparator, Section } from '@springrole/trivia-games';
import SetupVirtualCoffeeModal from 'components/SetupVirtualCoffeeModal';
import { useGetVcAnalyticsOverviewQuery } from 'app/services/analytics';
import { Progress } from '@mantine/core';
import DisableContainer from '../DisableContainer';
import styles from './styles.module.scss';

const VirtualCoffee = () => {
  const [openVcModal, setOpenVcModal] = useState(false);
  const { data: virtualCoffeeOverview = {} } = useGetVcAnalyticsOverviewQuery();
  const { lastMatch, nextMatch, usersMatched, virtualcoffees } = virtualCoffeeOverview;
  const lastMatchEnagagementRate = lastMatch?.channel.engagementRate;

  return (
    <Section
      className={styles.virtualCoffeeOverviewContainer}
      title='VirtualCoffee overview'
      action
      label={
        <span
          className={styles.virtualCoffeeOverviewAllMatches}
          onClick={() => history.push('/analytics/virtual-coffee')}
        >
          View more data
        </span>
      }
    >
      <div className={styles.virtualCoffeeOverviewContent}>
        <div className={styles.virtualCoffeeMatch}>
          <div className={styles.virtualCoffeeTop}>{lastMatch ? lastMatch.date : '-'}</div>
          <div className={styles.virtualCoffeeChannels}>
            <span className={styles.virtualCoffeePreviousMatchHeading}>Previous Match</span>
            {lastMatch && (
              <div className={styles.virtualCoffeeChannelName}>
                <DotSeparator size='small' className={styles.dotSeparator} />
                <span>{`${lastMatch.channel.isPrivate ? '🔒' : '#'}${
                  lastMatch.channel.name
                }`}</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.virtualCoffeeMatch}>
          <div className={styles.virtualCoffeeTop}>{nextMatch ? nextMatch.date : '-'}</div>
          <div className={styles.virtualCoffeeChannels}>
            <span className={styles.virtualCoffeePreviousMatchHeading}>Next Match</span>
            {nextMatch && (
              <div className={styles.virtualCoffeeChannelName}>
                <DotSeparator size='small' className={styles.dotSeparator} />
                <span>{`${nextMatch.channel.isPrivate ? '🔒' : '#'}${
                  nextMatch.channel.name
                }`}</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.virtualCoffeeUsers}>
          <div className={styles.virtualCoffeeTop}>{usersMatched}</div>
          <div className={styles.virtualCoffeeChannels}>
            <span>{lastMatch ? 'Teammates matched' : 'Teammates to be matched'}</span>
          </div>
        </div>
      </div>
      <div className={styles.engagementRateContainer}>
        <div className={styles.engagementRateHeader}>
          <span className={styles.lastRoundEngagement}>Last round engagement rate</span>
        </div>
        <div className={styles.engagementRateProgressBar}>
          <Progress radius='lg' size='lg' color='#9277ff' value={lastMatchEnagagementRate} />
          <div className={styles.lastRoundEngagement}>{lastMatchEnagagementRate}%</div>
        </div>
      </div>
      {!virtualcoffees && (
        <DisableContainer
          disableText="Enable VirtualCoffee, see who's grabbing coffee with whom & understand your team better."
          disableButtonText='Enable VirtualCoffee'
          handleButtonClick={() => setOpenVcModal(true)}
        />
      )}
      {openVcModal && (
        <SetupVirtualCoffeeModal open={openVcModal} handleCancel={() => setOpenVcModal(false)} />
      )}
    </Section>
  );
};

export default VirtualCoffee;
