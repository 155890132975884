import React from 'react';
import Button from 'components/Button';
import { ReactComponent as PartyIcon } from 'assets/images/party-icon.svg';
import { maybePluralize } from 'utils/utilities';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Footer = (props) => {
  const { daysLeft, plan } = props;
  return (
    <div className={styles.footerCard}>
      <div className={styles.svgAndText}>
        <PartyIcon className={styles.svg} />
        <div className={styles.text}>
          <div className={styles.plans}>
            Your plan: {plan} (Free Trial): <b>{daysLeft}</b> {maybePluralize(daysLeft, 'day')} left
          </div>
          <div className={styles.details}>
            After your trial expires, you will be downgraded to our Basic (free) plan. Continue
            enjoying the benefits of your trial with our Pro plan starting at{' '}
            <span className={styles.plans}>&nbsp;$2/user/month.</span>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Link to='/billing'>
          <Button className={styles.plansBtn}>View other plans</Button>
        </Link>
        <Link to={{ pathname: '/billing', search: '?getPro=true' }}>
          <Button dark className={styles.getStartedBtn}>
            Get Pro
          </Button>
        </Link>
      </div>
    </div>
  );
};

Footer.propTypes = {
  daysLeft: PropTypes.number.isRequired,
  plan: PropTypes.string.isRequired
};

export default Footer;
