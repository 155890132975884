import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HazardIcon } from 'assets/images/hazard.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Snackbar = ({ type, textOnly, text, subtext, actionText, performAction }) => (
  <div
    className={classNames(styles.snackbar, {
      [styles.info]: type === 'info',
      [styles.textOnly]: textOnly
    })}
  >
    <div className={styles.left}>
      {type === 'warning' && !textOnly && (
        <div className={styles.imgcontainer}>
          <HazardIcon />
        </div>
      )}
      <div data-testid='SnackbarText' className={styles.textWrapper}>
        <div className={styles.text}>{text}</div>
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </div>
    {!textOnly && (
      <div className={styles.right}>
        <div className={styles.action} onClick={performAction}>
          {actionText}
        </div>
      </div>
    )}
  </div>
);

Snackbar.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  actionText: PropTypes.string,
  performAction: PropTypes.func,
  type: PropTypes.oneOf(['warning', 'info']),
  textOnly: PropTypes.bool
};

Snackbar.defaultProps = {
  type: 'warning',
  textOnly: false,
  subtext: null,
  actionText: null,
  performAction: () => null
};

export default Snackbar;
