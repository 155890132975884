import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TriviaSpringworks } from 'assets/images/trivia-branding.svg';
import { ReactComponent as Whatsapp } from 'assets/images/whatsapp.svg';
import { ReactComponent as LinkedIn } from 'assets/images/linkedin.svg';
import { ReactComponent as Facebook } from 'assets/images/facebook.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter.svg';
import { ReactComponent as Dice } from 'assets/images/dice.svg';
import { ReactComponent as Slack } from 'assets/images/slack-logo.svg';
import { ReactComponent as Teams } from 'assets/images/teams-logo.svg';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from 'react-share';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomButton from 'components/Button';
import classNames from 'classnames';
import styles from './styles.module.scss';

class Sidebar extends React.PureComponent {
  render() {
    const { tabs, name, loading, isPublic } = this.props;
    const url = process.env.REACT_APP_HOME_PAGE;
    return (
      <div className={styles.sidebarContainer}>
        {!isPublic ? (
          <div className={styles.headerItemsContainer}>
            <div className={styles.springworksBranding}>
              <TriviaSpringworks />
            </div>
            <div className={styles.menuItems}>
              {tabs.map((tab) => (
                <Link to={tab.path} key={tab.id} id={tab.id}>
                  <div
                    className={classNames(styles.item, { [styles.selected]: name === tab.name })}
                  >
                    {tab.image}
                    <div className={styles.text}>
                      <span>{tab.name}</span>
                      {(tab.restricted || tab.isTenSpot) && !loading && (
                        <Lock className={styles.locked} />
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.publicSidebar}>
            <div className={styles.branding}>
              <TriviaSpringworks />
            </div>
            <div className={styles.tryTriviaWrapper}>
              <div className={styles.heading}>
                Come together as a happier, healthier remote team
              </div>
              <Dice />
              <ul>
                <li>Seamless integration</li>
                <li>Multiple game types</li>
                <li>Leaderboards</li>
              </ul>
              <CustomButton
                onClick={() => {
                  window.location.href = process.env.REACT_APP_HOME_PAGE;
                }}
              >
                Try Trivia for Free
              </CustomButton>
              <div className={styles.availability}>
                <div className={styles.text}>Available on</div>
                <div className={styles.platforms}>
                  <div
                    data-testid='slack-button'
                    className={styles.platformWrapper}
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_HOME_PAGE}slack/`;
                    }}
                    aria-hidden='true'
                  >
                    <Slack />
                  </div>
                  <div
                    data-testid='teams-button'
                    className={styles.platformWrapper}
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_HOME_PAGE}microsoft-teams/`;
                    }}
                    aria-hidden='true'
                  >
                    <Teams />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.socialLinks}>
              <div className={styles.text}>Share with friends</div>
              <div className={styles.socialIcons}>
                <WhatsappShareButton
                  windowHeight={600}
                  windowWidth={800}
                  url={url}
                  title={
                    `For team engagement, I recommend Trivia - an app for Slack, MS Teams & Web.` +
                    `\n\nWe've been using it at work to engage with colleagues in real-time.There are quizzes,` +
                    ` puzzles & mini-games that run right inside our channels.\n\nIt's super fun! Check it out here: `
                  }
                >
                  <Whatsapp />
                </WhatsappShareButton>
                <LinkedinShareButton url={url} windowHeight={768} windowWidth={1280}>
                  <LinkedIn />
                </LinkedinShareButton>
                <FacebookShareButton
                  url={url}
                  quote={
                    `Shoutout to Trivia - an app we've been using at work to engage with colleagues in real-time.` +
                    `\n\nThere are quizzes, puzzles & mini-games that run right inside our channels. It's super fun!` +
                    `\n\nCheck it out!`
                  }
                  hashtag='#trivia'
                >
                  <Facebook />
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  title={
                    `Shoutout to Trivia (by @springroleinc)\n\nWe've been using Trivia at work to engage with` +
                    ` colleagues in real-time.\n\nThere are quizzes, puzzles & mini-games that run right inside` +
                    ` our channels.\n\nIt's super fun!\n\nCheck it out!`
                  }
                  hashtags={['trivia']}
                >
                  <Twitter />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Sidebar.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool,
  isPublic: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({}))
};

Sidebar.defaultProps = {
  name: '',
  loading: false,
  isPublic: false,
  tabs: []
};

// TODO: Remove this when api is ported
const mapStateToProps = () => ({
  fullname: 'Admin',
  photo: null,
  loading: false
});

export default connect(mapStateToProps)(Sidebar);
