/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getPlans from 'data/plans';
import { MultiSelectDropdown } from '@springrole/trivia-games';
import { capitalizeFirstLetter } from 'utils/utilities';
import PlanCardComponent from './PlanCardComponent';
import styles from '../styles.module.scss';

const BasicAndFreeTrialComponent = ({
  btnPlan,
  handleButtonClick,
  billingInfo,
  setBillingInfo,
  username,
  useremail,
  open,
  setOpen,
  handleStripeRedirect,
  userPlan,
  plans,
  freeTrial,
  activeUsers,
  platform,
  channelBasedBilling,
  dropDownChannelOptions,
  setSelectedChannels,
  selectedChannels,
  uniqueUsersCount,
  isCalculatingUniqueUsersCount
}) => {
  const intervals = ['month', 'year'];
  const [billingIntervalState, setBillingInterval] = useState(plans?.TERM || 'month');
  const planWiseFeatures = getPlans(null, activeUsers, platform);
  const term = plans?.TERM || 'month';

  const handleClickInChannelDropdown = (selectedChannel) => {
    setSelectedChannels(selectedChannel);
  };

  const selectedchannelLength = selectedChannels.length;

  return (
    <div className={styles.comparePlanBasic}>
      {channelBasedBilling && userPlan !== null && platform === 'slack' ? (
        <div className={styles.newUserBasic}>
          <div className={styles.heading}>
            {term === 'year' || selectedchannelLength === 0
              ? 'Compare and upgrade your plan'
              : 'Upgrade your plan'}
          </div>
        </div>
      ) : (
        <div className={styles.heading}>Compare Plans</div>
      )}
      <div className={styles.billingIntervalsWrapper}>
        {channelBasedBilling && platform === 'slack' ? (
          <div className={styles.channelsWrapper}>
            <div>
              <div className={styles.para}>
                Select channels to enable them to play trivia, compare the prices and upgrade your
                plan.
              </div>
              <div style={{ ...(isCalculatingUniqueUsersCount ? { pointerEvents: 'none' } : {}) }}>
                <MultiSelectDropdown
                  placeholder='search or choose channels'
                  value={selectedChannels}
                  data={dropDownChannelOptions}
                  onChange={handleClickInChannelDropdown}
                  className={styles.dropDownStyles}
                  styles={{
                    rightSection: { pointerEvents: 'none' }
                  }}
                />
                <div className={styles.selectOptions}>
                  <span>👥 Total Unique users - {uniqueUsersCount}.</span> Only unique users will be
                  billed.
                </div>
              </div>
            </div>
            <div className={styles.billingIntervals}>
              {intervals.includes('month') && (
                <button
                  onClick={() => setBillingInterval('month')}
                  type='button'
                  className={billingIntervalState === 'month' ? styles.selected : ''}
                >
                  Monthly
                </button>
              )}
              {intervals.includes('year') && (
                <button
                  onClick={() => setBillingInterval('year')}
                  type='button'
                  className={billingIntervalState === 'year' ? styles.selected : ''}
                >
                  Yearly <span className={styles.offPercent}>20% off</span>
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.billingIntervals}>
              {intervals.includes('month') && (
                <button
                  onClick={() => setBillingInterval('month')}
                  type='button'
                  className={billingIntervalState === 'month' ? styles.selected : ''}
                >
                  Monthly
                </button>
              )}
              {intervals.includes('year') && (
                <button
                  onClick={() => setBillingInterval('year')}
                  type='button'
                  className={billingIntervalState === 'year' ? styles.selected : ''}
                >
                  Yearly <span className={styles.offPercent}>20% off</span>
                </button>
              )}
            </div>
            <div className={styles.activeUserCount}>
              <span>{activeUsers}</span> users in your {capitalizeFirstLetter(platform)} workspace
              interact with Trivia
            </div>
          </>
        )}
      </div>
      <div className={styles.plans}>
        {planWiseFeatures?.length > 0 ? (
          planWiseFeatures.map((data, index) => (
            <div key={index} className={styles.Cards}>
              <PlanCardComponent
                billingInfo={billingInfo}
                btnPlan={btnPlan}
                setBillingInfo={setBillingInfo}
                handleStripeRedirect={handleStripeRedirect}
                handleButtonClick={handleButtonClick}
                isCalculatingUniqueUsersCount={isCalculatingUniqueUsersCount}
                selectedChannels={selectedChannels}
                handleClickInChannelDropdown={handleClickInChannelDropdown}
                dropDownChannelOptions={dropDownChannelOptions}
                open={open}
                setOpen={setOpen}
                index={index}
                username={username}
                useremail={useremail}
                freeTrial={freeTrial}
                data={data}
                platform={platform}
                selectedchannelLength={selectedchannelLength}
                billingIntervalState={billingIntervalState}
                plans={plans}
                userPlan={userPlan}
                activeUsers={activeUsers}
                uniqueUsersCount={uniqueUsersCount}
                channelBasedBilling={channelBasedBilling}
              />
            </div>
          ))
        ) : (
          <div>No plans available</div>
        )}
      </div>
      <div className={styles.exploreText}>
        Explore customizations, enterprise support & more features{' '}
        <button
          type='button'
          onClick={() =>
            window.open('https://support.springworks.in/portal/en/kb/trivia', '_blank')
          }
          className={styles.stripeButton}
        >
          here
        </button>
        .
      </div>
    </div>
  );
};

BasicAndFreeTrialComponent.propTypes = {
  userPlan: PropTypes.string.isRequired,
  plans: PropTypes.shape({
    PRO: PropTypes.number,
    STANDARD: PropTypes.number,
    TERM: PropTypes.string
  }).isRequired,
  activeUsers: PropTypes.number.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  setBillingInfo: PropTypes.func.isRequired,
  handleStripeRedirect: PropTypes.func.isRequired,
  isCalculatingUniqueUsersCount: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  useremail: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  freeTrial: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  channelBasedBilling: PropTypes.bool.isRequired,
  btnPlan: PropTypes.string.isRequired,
  dropDownChannelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  setSelectedChannels: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  uniqueUsersCount: PropTypes.number.isRequired
};

export default BasicAndFreeTrialComponent;
