import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import Section from 'components/Section';
import games from 'data/games';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loading } from '@springrole/trivia-games';
import withHmsHooks from 'components/withHmsHooks';
import history from 'createHistory';
import Game from './Game';
import styles from './styles.module.scss';

const GameZone = () => {
  const [loading] = useState(false);
  const loadingUser = useSelector((state) => state.user.loading);
  return (
    <Layout name='Game Zone'>
      <div className={styles.heading}>🎮GameZone</div>
      <div className={styles.gameZoneWrapper}>
        <Section disableRightPadding>
          {(loading || loadingUser) && <Loading height={100} width={100} />}
          {!loading && !loadingUser && (
            <>
              {games().map((currentGame) => (
                <Game
                  key={currentGame.id}
                  description={currentGame.description}
                  criteria={currentGame.criteria}
                  rules={currentGame.rules}
                  name={currentGame.name}
                  image={currentGame.image}
                  onStart={() => history.push('/pictionary')}
                />
              ))}
              <Game loading />
            </>
          )}
        </Section>
      </div>
    </Layout>
  );
};

GameZone.propTypes = {
  history: PropTypes.shape({ listen: PropTypes.func }).isRequired
};

export default withRouter(withHmsHooks(GameZone));
