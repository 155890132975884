import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/Switch';
import { Grid } from '@mantine/core';
import classNames from 'classnames';
import history from '../../../createHistory';
import styles from './styles.module.scss';

const NotificationView = (props) => {
  const {
    announcements,
    handleSettingsChange,
    isTenspot,
    dmsNotifications,
    handleDmsNotification,
    allowedToView,
    platform
  } = props;

  return (
    <div className={styles.notificationSection}>
      <div className={styles.header}>
        <div className={styles.title}>Notifications</div>
        <div className={styles.subTitle}>Control notifications and messages from Trivia.</div>
      </div>
      <div className={styles.notificationsWrapper}>
        {platform === 'slack' && (
          <div className={styles.notificationtext}>
            <Grid>
              <Grid.Col
                span={11}
                className={classNames(
                  styles.typeTableColumn,
                  styles.subHeader,
                  !allowedToView && styles.disableText
                )}
              >
                @here notifications inside Game launch messages.
              </Grid.Col>
              <Grid.Col span={1}>
                <Switch
                  data-testid='Switch-id-platform-slack'
                  disabled={isTenspot || !allowedToView}
                  checked={announcements.notifyAll}
                  handleChange={handleSettingsChange}
                />
              </Grid.Col>
            </Grid>
          </div>
        )}
        <div className={styles.annnouncementText}>
          <Grid>
            <Grid.Col
              span={11}
              className={classNames(
                styles.typeTableColumn,
                styles.subHeader,
                !allowedToView && styles.disableText
              )}
            >
              DMs to Trivia Admin for New games, features and surprises Announcements.
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch
                data-testid='Switch-id'
                disabled={isTenspot || !allowedToView}
                checked={dmsNotifications}
                handleChange={handleDmsNotification}
              />
            </Grid.Col>
          </Grid>
        </div>
        {!allowedToView && (
          <div className={styles.upgradePlan} onClick={() => history.push('/billing')}>
            Upgrade to paid plan to configure this.
          </div>
        )}
      </div>
    </div>
  );
};

NotificationView.propTypes = {
  isTenspot: PropTypes.bool,
  announcements: PropTypes.shape({
    notifyAll: PropTypes.bool
  }).isRequired,
  dmsNotifications: PropTypes.bool,
  handleDmsNotification: PropTypes.func.isRequired,
  handleSettingsChange: PropTypes.func.isRequired,
  allowedToView: PropTypes.bool,
  platform: PropTypes.string.isRequired
};

NotificationView.defaultProps = {
  isTenspot: false,
  dmsNotifications: false,
  allowedToView: true
};

export default NotificationView;
