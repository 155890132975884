import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'components/PhoneInput';
import TextField from 'components/TextField';
import Button from 'components/Button';
import InlineStatus from 'components/InlineStatus';
import styles from './styles.module.scss';

const ContactView = (props) => {
  const {
    handleSave,
    handleEmailChange,
    isEmailValid,
    savePhone,
    validatePhone,
    contactInfo,
    isUserUpdating,
    userErrorStatus
  } = props;

  const getErrorMessage = (info) => {
    if (info.invalidPhone) {
      return 'Invalid phone number';
    }
    if (info.invalidEmail && info.invalidPhone) {
      return 'Invalid email address and phone number.';
    }
    return 'Invalid email address';
  };

  return (
    <div className={styles.contactSection}>
      <div className={styles.header}>Contact Information</div>
      <div className={styles.fieldsWrapper}>
        <TextField
          data-testid='email'
          name='email'
          value={contactInfo.email}
          onChange={handleEmailChange}
          placeholder='E.g   myemail@email.com'
          onBlur={isEmailValid}
          className={styles.inputWrapper}
          disabled={isUserUpdating}
        />
        <PhoneInput
          value={contactInfo.phone}
          savePhone={savePhone}
          validate={validatePhone}
          disabled={isUserUpdating}
        />
        <Button onClick={handleSave}>Save</Button>
      </div>
      {(contactInfo.invalidEmail || contactInfo.invalidPhone) && (
        <InlineStatus status='error' message={getErrorMessage(contactInfo)} />
      )}
      {contactInfo.saved &&
        !contactInfo.invalidEmail &&
        !contactInfo.invalidPhone &&
        userErrorStatus === 400 && (
          <InlineStatus status='success' message='Details saved successfully.' />
        )}
    </div>
  );
};

export default ContactView;

ContactView.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  isEmailValid: PropTypes.func.isRequired,
  savePhone: PropTypes.func.isRequired,
  validatePhone: PropTypes.func.isRequired,
  isUserUpdating: PropTypes.bool,
  contactInfo: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    invalidEmail: PropTypes.bool,
    emailTouched: PropTypes.bool,
    invalidPhone: PropTypes.bool,
    phoneTouched: PropTypes.bool,
    saved: PropTypes.bool
  }).isRequired,
  userErrorStatus: PropTypes.number
};

ContactView.defaultProps = {
  isUserUpdating: false,
  userErrorStatus: 200
};
