import React from 'react';
import PropTypes from 'prop-types';
import Create from './CreateQuiz';
import Questions from './Questions';

const CompleteQuiz = (props) => {
  const { name, questionsCount, questions, onQuestionClick } = props;
  return (
    <>
      <Create disabled name={name} questionsCount={String(questionsCount)} />
      <Questions
        totalQuestions={questionsCount}
        questions={questions}
        onQuestionClick={onQuestionClick}
      />
    </>
  );
};

CompleteQuiz.propTypes = {
  name: PropTypes.string.isRequired,
  questionsCount: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  onQuestionClick: PropTypes.func.isRequired
};

export default CompleteQuiz;
