import _ from 'lodash';
import { updateLicenses, getAllUsers } from 'app/features/monetizationSlice';
import { toast } from 'react-toastify';
import api from './api';

export const monetizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /user/all
    getAllUsers: build.query({
      query: (params) => ({
        url: '/user/all',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(getAllUsers(_.omit(response, 'ok')));
          } else {
            toast.error('Failed to fetch all users');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to get all users');
          }
          return Promise.resolve();
        }
      }
    }),

    // POST user/licenses
    updateLicenses: build.mutation({
      query: (body) => ({
        url: '/user/licenses',
        method: 'POST',
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.ok) {
            dispatch(updateLicenses(response));
          } else {
            toast.error('Failed to fetch all users');
          }
          return Promise.resolve();
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error?.data?.message ?? 'Failed to update licenses');
          }
          return Promise.resolve();
        }
      }
    }),

    // PATCH /subscription/update-quantity?secret=
    updateLicensesQuantity: build.mutation({
      query: (body) => ({
        url: `/subscription/update-quantity`,
        method: 'PATCH',
        body
      })
    }),

    // DELETE /subscription/cancel
    cancelSubscription: build.mutation({
      query: (params) => ({
        url: '/subscription/cancel',
        method: 'DELETE',
        params
      })
    })
  })
});

export const {
  useGetAllUsersQuery,
  useUpdateLicensesMutation,
  useUpdateLicensesQuantityMutation,
  useCancelSubscriptionMutation
} = monetizationApi;
