import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@springrole/trivia-games';
import styles from './styles.module.scss';

const DisableContainer = (props) => {
  const { disableText, disableButtonText, handleButtonClick } = props;
  return (
    <div className={styles.disableContainer}>
      <span className={styles.disableLabel}>{disableText}</span>
      <Button className={styles.disableButton} onClick={handleButtonClick}>
        <span>{disableButtonText}</span>
      </Button>
    </div>
  );
};

DisableContainer.propTypes = {
  disableText: PropTypes.string.isRequired,
  disableButtonText: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default DisableContainer;
