import React from 'react';
import PropTypes from 'prop-types';
import NoAccess from 'assets/images/errortape.svg';
import Button from 'components/Button';
import history from 'createHistory';
import { useGetAdminsQuery } from 'app/services/settings';
import styles from './styles.module.scss';

const RestrictedAccess = ({
  message = 'You are on the basic plan',
  adminRestriction = false,
  isTenSpot = false,
  withoutButton,
  subtitle = 'Contact your workspace admins for access to this page'
}) => {
  const { data: adminsData = { admins: [] } } = useGetAdminsQuery(true, {
    skip: !adminRestriction
  });

  return (
    <div className={styles.restrictedAccessContainer}>
      <img src={NoAccess} alt='no-access' className={styles.noAccessImg} />
      <div className={styles.messageWrapper}>
        <div className={styles.title}>{message}</div>
        {!isTenSpot &&
          (!adminRestriction ? (
            <div className={styles.subtitle}>Upgrade plan to access this section</div>
          ) : (
            <>
              <div className={styles.subtitle}>{subtitle}</div>
              <div className={styles.adminContainer}>
                Admins:&nbsp;
                <b className={styles.adminList}>
                  {adminsData.admins.map((admin, i) =>
                    i === adminsData.admins.length - 1 ? `${admin.name}` : `${admin.name}, `
                  )}
                </b>
              </div>
            </>
          ))}
      </div>
      {!adminRestriction && !isTenSpot && !withoutButton && (
        <Button onClick={() => history.push('/billing')}>View plans</Button>
      )}
    </div>
  );
};

RestrictedAccess.propTypes = {
  message: PropTypes.string,
  adminRestriction: PropTypes.bool,
  isTenSpot: PropTypes.bool,
  withoutButton: PropTypes.bool,
  subtitle: PropTypes.string
};

RestrictedAccess.defaultProps = {
  message: 'You are on the basic plan',
  adminRestriction: false,
  isTenSpot: false,
  withoutButton: false,
  subtitle: 'Contact your workspace admins for access to this page'
};

export default RestrictedAccess;
