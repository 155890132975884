import _ from 'lodash';
import { toast } from 'react-toastify';
import api from './api';

const initialState = {
  polls: {
    hasMore: false,
    data: []
  },
  pollInfo: {
    gameId: '',
    data: []
  }
};

export const pollsResultsApi = api.injectEndpoints({
  endpoints: (build) => ({
    // GET /custom-poll/list
    getpolls: build.query({
      query: (page) => ({
        url: '/custom-poll/list',
        params: { page, limit: 5 }
      }),

      transformResponse: (response) => {
        if (response) {
          return { polls: response?.data, hasMore: response.hasMore };
        }
        toast.error(response.message);
        return initialState.polls;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch polls');
        }
        return initialState.polls;
      }
    }),
    // GET /custom-poll/info
    getCustomPollInfo: build.query({
      query: (gameId) => ({
        url: '/custom-poll/info',
        params: { gameId }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return _.omit(response, 'ok');
        }
        toast.error(response.message);
        return initialState.pollInfo;
      },
      transformErrorResponse: (response) => {
        if (![401, 403].includes(response.status)) {
          toast.error('Failed to fetch custom poll info');
        }
        return initialState.pollInfo;
      }
    })
  })
});

export const { useGetpollsQuery, useGetCustomPollInfoQuery } = pollsResultsApi;
