import React, { useState } from 'react';
import localStorage from 'utils/localStorage';
import history from 'createHistory';
import {
  useGetAnalyticsOverviewQuery,
  useGetKnowYourTeamAnalyticsQuery,
  useGetAutomationsQuery
} from 'app/services/home';
import { Button } from '@springrole/trivia-games';
import classNames from 'classnames';
import EnableKnowYourTeamModal from 'components/EnableKnowYourTeam';
import styles from './styles.module.scss';
import Form from '../Automations/Modals/Form';

const KytCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAutomationModal, setOpenAutomationModal] = useState(false);
  const platform = localStorage.getPlatform();

  const { data: overview = { automations: 0 } } = useGetAnalyticsOverviewQuery();
  const { data: automationsData = [] } = useGetAutomationsQuery();
  const { data: kytData = { shares: 0, teammates: 0 } } = useGetKnowYourTeamAnalyticsQuery();

  const slackButtonText = kytData.teammates > 0 ? `See what everyone's sharing` : 'Get Started';
  const buttonText = platform === 'slack' ? slackButtonText : `See what everyone's sharing`;

  const hasSurpriseMeOrKYT = automationsData.some((automation) => {
    const { types } = automation;
    return types.includes('Surprise Me!') || types.includes('Know Your Team Quiz');
  });

  const handleButtonClick = () => {
    let redirectUrl =
      'https://support.springworks.in/portal/en/kb/articles/how-does-the-know-your-team-quiz-work-on-ms-teams';

    if (platform === 'slack') {
      redirectUrl = `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=kytPage&key=${localStorage.getToken()}`;
    }

    if (buttonText === 'Get Started') {
      window.open(redirectUrl, '_blank');
    } else if (overview.automations > 0 && hasSurpriseMeOrKYT) {
      history.push('/knowYourTeam/responses');
    } else {
      setIsModalOpen(true);
    }
  };

  const handleAddAutomation = () => {
    setIsModalOpen(false);
    setOpenAutomationModal(true);
  };

  const subTitleText =
    kytData.shares > 0
      ? `${kytData.shares} interests & favorites have been shared so far.`
      : 'You will see Know Your Team insights here soon';

  const titleText =
    kytData.shares > 0 ? (
      <>
        {kytData.teammates} teammates <span className={styles.highlightText}>know each other</span>{' '}
        better
      </>
    ) : (
      'Be the first to share interests & favorites'
    );

  return overview.automations > 0 && hasSurpriseMeOrKYT ? (
    <div className={classNames(styles.cardWraper, styles.kytCard)}>
      <div className={classNames(styles.cardSubTitle, styles.subTitleNonEmptyState)}>
        {subTitleText}
      </div>
      <div className={classNames(styles.cardTitle, styles.titleNonEmptyState)}>{titleText}</div>
      <Button onClick={handleButtonClick} className={styles.commonButtonStyles}>
        {buttonText}
      </Button>
    </div>
  ) : (
    <div className={classNames(styles.cardWraper, styles.kytCard)}>
      {isModalOpen && (
        <EnableKnowYourTeamModal
          open={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
          handleClick={handleAddAutomation}
        />
      )}
      {openAutomationModal && (
        <Form
          isKytForm
          open={openAutomationModal}
          handleCancel={() => setOpenAutomationModal(false)}
        />
      )}
      <div className={styles.cardTitle}>
        Get to <span className={styles.highlightText}>Know Your Team</span> better
      </div>
      <div className={styles.cardSubTitle}>
        Help teammates bond over shared interests & make friends at work.
      </div>
      <Button className={styles.commonButtonStyles} onClick={handleButtonClick}>
        Enable Know Your Team
      </Button>
    </div>
  );
};

export default KytCard;
