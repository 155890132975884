import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { virtualCoffeeFrequencyOptions } from 'data/dropdown';
import momentTz from 'moment-timezone';
import moment from 'moment';
import days from 'data/days';
import localStorage from 'utils/localStorage';
import { timezonesForMantineComponent as timezones, toTzValue, toTzString } from 'data/timezones';
import { useGetChannelsQuery } from 'app/services/channels';
import { useSelector } from 'react-redux';
import AccessRestrictedModal from 'components/AccessRestrictedModal';
import { serializeSlackChannels } from 'utils/serialization';
import { useAddVirtualCoffeeMutation } from 'app/services/home';
import Modal from './Form';

const SetupVirtualCoffeeModal = (props) => {
  const { open, handleCancel } = props;
  const platform = localStorage.getPlatform();
  const isAllowedToEnableVirtualCoffee = useSelector((state) => state.user.user.isAllowedToStart);
  const { data, isFetching } = useGetChannelsQuery();
  const [addVirtualCoffee, { data: virtualCoffeeResponse, isLoading: isAdding }] =
    useAddVirtualCoffeeMutation();
  const channelOptions = isFetching ? [] : serializeSlackChannels(data);

  const [formData, setFormData] = useState({
    // showing the first channel in the array for pre-filled state
    // in most cases it is #random or #general channel
    channel: channelOptions[0]?.value ?? null,
    day: 'Friday',
    time: moment('16:30', 'hh:mm')
      .add(5 * Math.floor(Math.random() * 13), 'minutes')
      .toDate(),
    timezone: toTzValue(momentTz.tz.guess()),
    frequency: '2'
  });

  const handleChange = (field, value) => {
    setFormData((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleConfirm = async () => {
    const form = {
      time: moment(formData.time).format('hh:mm a'),
      timezone: toTzString(formData.timezone),
      dayOfTheWeek: days.findIndex((day) => day.value === formData.day) + 1,
      frequency: Number(formData.frequency),
      channel: formData.channel
    };
    addVirtualCoffee(form);
  };

  if (platform === 'slack' && !isAllowedToEnableVirtualCoffee) {
    return <AccessRestrictedModal open={open} onCancel={handleCancel} />;
  }

  if (!isAdding && virtualCoffeeResponse?.ok) {
    handleCancel();
  }

  return (
    <Modal
      open={open}
      disableConfirm={isAdding}
      timezones={timezones}
      days={days}
      formData={formData}
      frequencies={virtualCoffeeFrequencyOptions}
      channels={channelOptions}
      handleChange={handleChange}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
    />
  );
};

SetupVirtualCoffeeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default SetupVirtualCoffeeModal;
