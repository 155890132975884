/* eslint-disable no-nested-ternary */
import Table from 'components/MantineTable';
import PropTypes from 'prop-types';
import Pagination from 'components/Pagination';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const VirtualCoffeeListView = (props) => {
  const { selectedChannel, VcListData = [], date, rate, lastMatch } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const isComingSoon = process.env.REACT_APP_COMING_SOON === 'true';
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex - dataPerPage;
  const paginatedVcListData = VcListData?.slice(firstIndex, lastIndex).map((item) => ({
    ...item,
    id: item.id.value,
    messages: `${item.messages}%`
  }));
  const setPage = (number) => {
    setCurrentPage(number);
  };

  if (!lastMatch) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.center]: selectedChannel === 'all' || paginatedVcListData.length === 0
      })}
    >
      {selectedChannel === 'all' ? (
        <div className={styles.emptyList}>Choose a channel to view pairings</div>
      ) : paginatedVcListData.length === 0 && !rate ? (
        <div className={styles.emptyList}>No Engagement since the team mates matched are 0</div>
      ) : (
        <>
          <div className={styles.text}>
            {isComingSoon ? `Latest round (${date})` : date}
            &nbsp;| Engagement rate (based on only message exchange):{' '}
            {isComingSoon ? 'Coming soon' : `${rate}%`}
          </div>
          <div className={styles.listContainer}>
            <Table
              columns={['', 'VIRTUALCOFFEE GROUP', 'Messages Sent']}
              elements={paginatedVcListData}
              noHover
            />

            <Pagination
              activePage={currentPage}
              setPage={setPage}
              total={Math.ceil(VcListData.length / dataPerPage)}
            />
          </div>
        </>
      )}
    </div>
  );
};

VirtualCoffeeListView.propTypes = {
  selectedChannel: PropTypes.string.isRequired,
  date: PropTypes.string,
  rate: PropTypes.string,
  VcListData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.shape({ value: PropTypes.number, display: PropTypes.bool }),
      messages: PropTypes.string,
      users: PropTypes.string
    })
  ),
  lastMatch: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    channel: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isPrivate: PropTypes.bool
    })
  })
};

VirtualCoffeeListView.defaultProps = {
  date: '',
  rate: '',
  VcListData: [],
  lastMatch: {}
};

export default VirtualCoffeeListView;
