import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from 'assets/images/home/arrowRight.svg';
import styles from './styles.module.scss';

const EmptyStateFooter = (props) => {
  const { content, action, handleClick } = props;
  return (
    <div className={styles.emptyStateFooter}>
      <div className={styles.footerDescription}>{content}</div>
      <div className={styles.playGamesButton} onClick={handleClick}>
        {action} <ArrowIcon />
      </div>
    </div>
  );
};

EmptyStateFooter.propTypes = {
  content: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default EmptyStateFooter;
