import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@springrole/trivia-games';
import styles from './styles.module.scss';

const Delete = (props) => {
  const { open, handleConfirm, handleCancel, disableConfirm } = props;
  return (
    <Modal
      open={open}
      title='Delete Automation'
      showWarning
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText='Delete'
      disableConfirmButton={disableConfirm}
    >
      <div className={styles.contentWrapper}>Are you sure want to delete this Automation?</div>
    </Modal>
  );
};

Delete.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool.isRequired
};

export default Delete;
