import { Grid } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import localStorage from 'utils/localStorage';
import { ReactComponent as Participant } from 'assets/images/participant.svg';
import { ReactComponent as Question } from 'assets/images/question.svg';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const QuizInfo = ({
  number,
  name,
  organizer,
  team,
  channel,
  participants,
  questions,
  time,
  date,
  onSelect,
  id
}) => {
  const showHash = localStorage.getPlatform() === 'slack';
  const [gridColumnSpan, setGridColumnSpan] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 568) {
        setGridColumnSpan(10);
      } else {
        setGridColumnSpan(6);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid className={styles.listItem} justify='center' key={id} onClick={() => onSelect(id)}>
      <Grid.Col span={1} className={styles.listItemCell}>
        {number}
      </Grid.Col>
      <Grid.Col span={gridColumnSpan} className={styles.listItemCell}>
        <div style={{ lineHeight: '2.75rem' }}>
          <div className={styles.name}>{name}</div>
          <div className={styles.organized}>
            Organized by <span>{organizer}</span>{' '}
            {team && (
              <>
                for <span>{team}</span>
              </>
            )}{' '}
            on {showHash && '#'}
            <span>{channel}</span>
          </div>
        </div>
      </Grid.Col>
      <Grid.Col span={5} className={styles.listItemCell}>
        <div className={styles.statsWrapper}>
          <div className={styles.stat}>
            <Participant />
            {participants}
          </div>
          <div className={styles.text}>Participants</div>
        </div>
        <div className={styles.statsWrapper}>
          <div className={styles.stat}>
            <Question />
            {questions}
          </div>
          <div className={styles.text}>Questions</div>
        </div>
        <div className={styles.statsWrapper}>
          <div className={styles.time}>{time}</div>
          <div className={styles.date}>{date}</div>
        </div>
      </Grid.Col>
    </Grid>
  );
};

QuizInfo.propTypes = {
  number: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizer: PropTypes.string.isRequired,
  team: PropTypes.string,
  channel: PropTypes.string,
  participants: PropTypes.number.isRequired,
  questions: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

QuizInfo.defaultProps = {
  team: '',
  channel: ''
};

export default QuizInfo;
