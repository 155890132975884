import React, { useState } from 'react';
import { uniqBy, orderBy, startsWith } from 'lodash';
import { useSelector } from 'react-redux';
import { useGetAdminsQuery, useUpdateAdminStatusMutation } from 'app/services/settings';
import Avatar from 'assets/images/avatar.svg';
import AdminAccessView from './View';

const InitialState = {
  admin: {
    admins: [],
    members: []
  }
};

const AdminAccess = () => {
  const [usersAccess, setUsersAccess] = useState([]);
  const { data, isFetching: isFetchingAdmins = false } = useGetAdminsQuery(false);
  let users = !isFetchingAdmins ? data?.members : InitialState.admin?.members;
  const { user: currentUser } = useSelector((state) => state.user);
  let admins = !isFetchingAdmins ? data?.admins : InitialState.admin?.admins;
  // order admins by id
  admins = uniqBy([...admins, ...usersAccess], 'id');
  // remove current user option from selection menu
  users = users.filter((usr) => currentUser.userId !== usr.id);
  const availableUsers = users.filter(({ id: id1 }) => !admins.some(({ id: id2 }) => id2 === id1));
  const [updateAdminStatus, { isLoading: updatingAdmin = false }] = useUpdateAdminStatusMutation();

  const handleSelection = (user) => {
    updateAdminStatus({ user, isAdmin: true });
    setUsersAccess((prevState) => [...prevState, user]);
  };

  const handleRemove = async (user) => {
    let selectedUsers = uniqBy([...admins, ...usersAccess], 'id');
    selectedUsers = selectedUsers.filter((usr) => usr.id !== user.id);
    setUsersAccess(selectedUsers);
    updateAdminStatus({ user, isAdmin: false });
  };

  const filterOptions = (options, inputValue) =>
    orderBy(
      options
        .filter((opt) => {
          const [firstName, ...other] = opt.name.split(/[ ]+/);
          let lastName;
          const otherFirstIdx = other[0];
          if (other && other.length > 1) lastName = other.join(' ');
          else if (otherFirstIdx) lastName = otherFirstIdx;
          return (
            !usersAccess.find((_user) => _user.id === opt.id) &&
            (startsWith(firstName?.toLowerCase(), inputValue.toLowerCase()) ||
              startsWith(lastName?.toLowerCase(), inputValue.toLowerCase()) ||
              startsWith(opt.name?.toLowerCase(), inputValue.toLowerCase()))
          );
        })
        .slice(0, 4),
      'name',
      'asc'
    );

  const renderOption = (option) => (
    <>
      <img src={option.photo || Avatar} alt='user' />
      {option.name}{' '}
    </>
  );

  return (
    <AdminAccessView
      availableUsers={availableUsers}
      handleSelection={handleSelection}
      handleRemove={handleRemove}
      filterOptions={filterOptions}
      renderOption={renderOption}
      updatingAdmin={updatingAdmin}
      isFetchingAdmins={isFetchingAdmins}
      admins={admins}
    />
  );
};

export default AdminAccess;
