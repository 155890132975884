import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'assets/images/avatar.svg';
import SearchAutocomplete from 'components/Autocomplete/SearchAutoComplete';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import styles from './styles.module.scss';

export const AccessWorkSpaceWrapper = ({ handleRemove, admins }) => (
  <div className={styles.accessWorkspaceWrapper} data-testid='admin-component'>
    {admins.length > 0 ? (
      <div className={styles.accessWorkspace}>
        <div className={styles.users}>
          {admins.map((user) => (
            <div key={user.id} className={styles.accessPerson}>
              <img src={user.photo || Avatar} alt='user' />
              <label htmlFor={user.name}>{user.name}</label>
              <span className={styles.close}>
                <CloseIcon width='8px' height='8px' />
              </span>
              <span className={styles.closeHover}>
                <CloseIcon
                  data-testid='close-icon'
                  width='8px'
                  height='8px'
                  onClick={() => handleRemove(user)}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className={styles.noUserWrapper}>
        <div className={styles.noUsers}>
          This is where you will find the list of people who have admin access to Trivia.
        </div>
      </div>
    )}
  </div>
);

AccessWorkSpaceWrapper.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
      email: PropTypes.string
    })
  )
};
AccessWorkSpaceWrapper.defaultProps = {
  admins: []
};

const AdminAccessView = (props) => {
  const { availableUsers, handleSelection, handleRemove, admins } = props;

  return (
    <div className={styles.accessSection}>
      <div className={styles.header}>Add Admin</div>
      <SearchAutocomplete
        data-testid='auto-complete'
        items={availableUsers}
        placeholder='Search for a person to give access.'
        onSelect={handleSelection}
        showIcon={false}
        styling={{
          borderRadius: '4px'
        }}
        className={styles.searchBar}
      />
      <AccessWorkSpaceWrapper handleRemove={handleRemove} admins={admins} />
    </div>
  );
};

AdminAccessView.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  availableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string
    })
  ),
  handleSelection: PropTypes.func.isRequired,

  admins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
      email: PropTypes.string
    })
  )
};

AdminAccessView.defaultProps = {
  admins: [],
  availableUsers: []
};

export default AdminAccessView;
