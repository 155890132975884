import React from 'react';
import { Button } from '@springrole/trivia-games';
import { useGetAnalyticsOverviewQuery } from 'app/services/home';
import localStorage from 'utils/localStorage';
import classNames from 'classnames';
import styles from './styles.module.scss';

const PlayGameCard = () => {
  const { data: overview = { gamesPlayed: 0 } } = useGetAnalyticsOverviewQuery();

  const handleButtonClick = () => {
    const platform = localStorage.getPlatform();
    if (platform === 'teams') {
      window.open(
        `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/homeTab`,
        '_blank'
      );
    }

    window.open(
      `${
        process.env.REACT_APP_TRIVIA_SLACK_API
      }/slack-deeplink?page=gamesPage&key=${localStorage.getToken()}`,
      '_blank'
    );
  };

  return overview.gamesPlayed > 0 ? (
    <div className={classNames(styles.cardWraper, styles.gameCard)}>
      <div className={classNames(styles.cardSubTitle, styles.subTitleNonEmptyState)}>
        Bored of playing the same Games?
      </div>
      <div className={classNames(styles.cardTitle, styles.titleNonEmptyState)}>
        Explore other <span className={styles.highlightText}>games</span> on Trivia!
      </div>
      <Button className={styles.commonButtonStyles} onClick={handleButtonClick}>
        View all Games
      </Button>
    </div>
  ) : (
    <div className={classNames(styles.cardWraper, styles.gameCard)}>
      <div className={styles.cardTitle}>Play 10+ games with your team!</div>
      <div className={styles.cardSubTitle}>
        Explore Trivia Quizzes, Customizable Quizzes, Charades & more
      </div>
      <Button className={styles.commonButtonStyles} onClick={handleButtonClick}>
        Explore Games
      </Button>
    </div>
  );
};

export default PlayGameCard;
