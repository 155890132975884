const DAYS = [
  { label: 'Mon', value: 'Monday' },
  { label: 'Tue', value: 'Tuesday' },
  { label: 'Wed', value: 'Wednesday' },
  { label: 'Thu', value: 'Thursday' },
  { label: 'Fri', value: 'Friday' },
  { label: 'Sat', value: 'Saturday' },
  { label: 'Sun', value: 'Sunday' }
];

export const daysToNaturalLanguage = (daysArray) => {
  let days = daysArray.map((day) => day.substring(0, 3)).join(', ');
  const index = days.lastIndexOf(', ');
  days = `${days.slice(0, index)}${days.slice(index)}`;
  return days;
};

export default DAYS;
