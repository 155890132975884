import React from 'react';
import { useHMSActions, selectIsConnectedToRoom, useHMSStore } from '@100mslive/hms-video-react';

const withHmsHooks = (Component) => (props) => {
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  return <Component {...props} hmsActions={hmsActions} isConnected={isConnected} />;
};

export default withHmsHooks;
