import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@springrole/trivia-games';
import styles from './styles.module.scss';

const Overview = (props) => {
  const { virtualCoffeeOverview } = props;
  const { lastMatch, nextMatch, usersMatched } = virtualCoffeeOverview;
  return (
    <Section className={styles.withoutComingSoonContainer} title='VirtualCoffee Overview'>
      <div className={styles.stats}>
        <span className={styles.header}>{lastMatch ? lastMatch.date : '-'}</span>
        <div className={styles.content}>
          <span>Last round of VirtualCoffee</span>
          <span>
            {lastMatch && `(${lastMatch.channel.isPrivate ? '🔒' : '#'}${lastMatch.channel.name})`}
          </span>
        </div>
      </div>
      <div className={styles.stats}>
        <span className={styles.header}>{nextMatch ? nextMatch.date : '-'}</span>
        <div className={styles.content}>
          <span>Next round of VirtualCoffee</span>
          <span>
            {nextMatch && `(${nextMatch.channel.isPrivate ? '🔒' : '#'}${nextMatch.channel.name})`}
          </span>
        </div>
      </div>
      <div className={styles.stats}>
        <span className={styles.header}>{usersMatched}</span>
        <div className={styles.content}>
          <span>{lastMatch ? 'Teammates matched' : 'Teammates to be matched'}</span>
        </div>
      </div>
    </Section>
  );
};

export default Overview;

Overview.propTypes = {
  virtualCoffeeOverview: PropTypes.shape({
    lastMatch: PropTypes.shape({
      date: PropTypes.string,
      channel: PropTypes.shape({
        isPrivate: PropTypes.bool,
        name: PropTypes.string
      })
    }),
    nextMatch: PropTypes.shape({
      date: PropTypes.string,
      channel: PropTypes.shape({
        isPrivate: PropTypes.bool,
        name: PropTypes.string
      })
    }),
    usersMatched: PropTypes.number
  })
};

Overview.defaultProps = {
  virtualCoffeeOverview: {}
};
