/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mantine/core';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ColumnHeader = ({ columns, className }) => (
  <Grid className={classNames(styles.columnHeaderContainer, className)} justify='center'>
    {columns.map((col, ind) => (
      <Grid.Col
        key={ind}
        span={col.size || 3}
        style={{ textAlign: col.center ? 'center' : 'initial' }}
      >
        {col.label}
      </Grid.Col>
    ))}
  </Grid>
);

ColumnHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  className: PropTypes.string
};

ColumnHeader.defaultProps = {
  className: null
};

export default ColumnHeader;
