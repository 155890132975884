export const planDetails = {
  ok: true,
  plan: 'STANDARD',
  customerInfo: {
    name: 'Anuj Kapoor',
    email: 'anuj.kapoor@springworks.in'
  }
};

const userInfo = {
  info: {
    user: {
      _id: '634e556e76b3ab84fb3b9fc9',
      userId: 'U02HDUZ6WK0',
      workspace: '634e556ec821fe269ba08b2c',
      __v: 0,
      celebrations: {
        active: true
      },
      createdAt: '2022-10-18T07:27:42.850Z',
      deleted: false,
      email: 'anuj.kapoor@springworks.in',
      enterpriseOwner: false,
      fullname: 'Anuj Kapoor',
      globalAdmin: false,
      image:
        'https://secure.gravatar.com/avatar/e58207bea6278158d341d38a1d46c2b3.jpg?s=512&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0016-512.png',
      isAdmin: true,
      licensed: false,
      settings: {
        promotionalNudge: true,
        transactionalNudge: true
      },
      teamId: 'T02JAB69HH6',
      updatedAt: '2022-11-02T05:48:53.144Z',
      username: 'anuj.kapoor',
      isAllowedToStart: true
    },
    freeTrial: false,
    trialExpiresAt: '2022-10-19T16:27:42.791Z',
    plan: 'BASIC',
    isAdmin: true,
    tenspot: false,
    installedAt: '2022-10-18T07:27:42.820Z',
    _id: '634e556ec821fe269ba08b2c',
    team: {
      name: 'Anuj Kapoor',
      id: 'T02JAB69HH6'
    },
    usersCount: 2,
    promotions: {
      pictionary: true
    }
  },
  ok: true
};

export const userDetails = {
  ...userInfo
};

const gamesOverviewDataForTimespan3 = {
  ok: true,
  data: {
    engagementRate: 95,
    gamesPlayed: 23,
    weeklyEngagement: 53,
    uniquePlayers: 34,
    automations: 2
  }
};

const gamesOverviewDataForTimespan6 = {
  ok: true,
  data: {
    engagementRate: 0,
    gamesPlayed: 0,
    weeklyEngagement: 0,
    uniquePlayers: 0,
    automations: 0
  }
};

export function getAnalyticsOverview(schema, request) {
  const { timespan, channel } = request.queryParams;
  // for allTime
  if (!timespan && !channel) {
    return gamesOverviewDataForTimespan3;
  }
  if ((timespan && timespan === '3') || channel) {
    return gamesOverviewDataForTimespan3;
  }
  return gamesOverviewDataForTimespan6;
}

export const channels = {
  ok: true,
  data: [
    { id: 'C123123123', name: 'random', isPrivate: false },
    { id: 'C123123124', name: 'general', isPrivate: false },
    { id: 'C123123125', name: 'fun', isPrivate: false },
    { id: 'C123123126', name: 'office', isPrivate: true }
  ]
};

export const teams = {
  ok: true,
  data: [
    {
      id: '123123123',
      name: 'Springrole India',
      channels: [{ id: 'C123123123', name: 'General' }],
      isGroupChat: false
    },
    {
      id: '123123124',
      name: 'Trivia Anuj',
      channels: [{ id: 'C123123124', name: 'Random' }],
      isGroupChat: false
    },
    {
      id: 'C123123125',
      name: 'Avinash, Sandeep, and 3 others',
      isGroupChat: true
    }
  ]
};

export const trend = {
  ok: true,
  data: {
    limit: 5,
    players: ['PRO', 'STANDARD'].includes(userInfo.info.plan)
      ? [
          { allowed: 5, restricted: 0 },
          { allowed: 7, restricted: 0 },
          { allowed: 9, restricted: 0 },
          { allowed: 12, restricted: 0 },
          { allowed: 8, restricted: 0 },
          { allowed: 6, restricted: 0 },
          { allowed: 4, restricted: 0 },
          { allowed: 14, restricted: 0 },
          { allowed: 11, restricted: 0 },
          { allowed: 10, restricted: 0 },
          { allowed: 9, restricted: 0 },
          { allowed: 7, restricted: 0 },
          { allowed: 10, restricted: 0 },
          { allowed: 11, restricted: 0 },
          { allowed: 14, restricted: 0 },
          { allowed: 7, restricted: 0 },
          { allowed: 9, restricted: 0 },
          { allowed: 10, restricted: 0 },
          { allowed: 11, restricted: 0 },
          { allowed: 6, restricted: 0 }
        ]
      : [
          { allowed: 5, restricted: 6 },
          { allowed: 5, restricted: 12 },
          { allowed: 5, restricted: 15 },
          { allowed: 5, restricted: 6 },
          { allowed: 5, restricted: 4 },
          { allowed: 5, restricted: 10 },
          { allowed: 5, restricted: 8 },
          { allowed: 5, restricted: 14 },
          { allowed: 5, restricted: 12 },
          { allowed: 5, restricted: 10 },
          { allowed: 5, restricted: 7 },
          { allowed: 5, restricted: 2 },
          { allowed: 5, restricted: 5 },
          { allowed: 5, restricted: 6 },
          { allowed: 5, restricted: 5 },
          { allowed: 5, restricted: 3 },
          { allowed: 5, restricted: 8 },
          { allowed: 5, restricted: 5 },
          { allowed: 5, restricted: 6 },
          { allowed: 5, restricted: 4 }
        ],
    leftOut: 33
  }
};

export const knowYourTeamAnalytics = {
  ok: true,
  data: {
    shares: 0,
    teammates: 0,
    quizzes: 0
  }
};

export const virtualcoffeeAnalytics = {
  ok: true,
  data: {
    channels: [
      { id: 'C1231231', name: 'random', isPrivate: false },
      { id: 'C23423423', name: 'social', isPrivate: false }
    ],
    usersMatched: 123,
    leftOut: 0,
    rounds: 0,
    lastMatch: false
  }
};

export const createVirtualCoffee = {
  ok: true,
  data: {
    channels: [
      { id: 'C1231231', name: 'random', isPrivate: false },
      { id: 'C23423423', name: 'social', isPrivate: false },
      { id: 'C23423423', name: 'office', isPrivate: true },
      { id: 'C23423423', name: 'fun', isPrivate: false }
    ],
    usersMatched: 123,
    leftOut: 0,
    rounds: 0,
    lastMatch: false,
    nextMatch: { date: new Date(), channel: { name: 'general', id: 'C4545454', isPrivate: true } }
  }
};

export const automations = {
  ok: true,
  data: [
    {
      id: '1',
      type: 'quiz',
      channel: { id: 'C123123123', name: 'general', isPrivate: false },
      team: { id: '1321231230', name: 'SpringRole India Pvt Ltd' },
      frequency: ['Monday', 'Tuesday', 'Wednesday'],
      time: '12:30 PM',
      timezone: 'Asia/Kolkata',
      category: [{ id: '1', name: 'maths' }],
      difficulty: 'Easy',
      isGroupChat: false
    },
    {
      id: '2',
      type: 'random',
      channel: { id: 'C123123124', name: 'random', isPrivate: false },
      team: { id: '1321231230', name: 'SpringRole India Pvt Ltd' },
      frequency: ['Monday', 'Tuesday', 'Friday'],
      time: '12:30 PM',
      timezone: 'Asia/Kolkata',
      isGroupChat: false
    },
    {
      id: '3',
      type: 'water cooler',
      channel: { id: 'C123123125', name: 'Avinash, Sandeep, and 3 others', isPrivate: true },
      team: { id: '1321231230', name: 'SpringRole India Pvt Ltd' },
      frequency: ['Monday', 'Tuesday', 'Wednesda', 'Thursday', 'Friday'],
      time: '12:30 PM',
      timezone: 'Asia/Kolkata',
      isGroupChat: true
    }
  ]
};

export const categories = {
  ok: true,
  categories: {
    categories: [
      {
        id: '5f993d4b1ee8800012c65d78',
        name: 'Action/Adventure Movies',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/action+%26+adventure+movies.svg'
      },
      {
        id: '5ed7329969debc09a46e7a8f',
        name: 'Actors and Celebrities',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/actors+%26+celebrities.svg'
      },
      {
        id: '5ed7329969debc09a46e7a91',
        name: 'Art',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/art.svg'
      },
      {
        id: '5f993f555156c93c9812f533',
        name: 'Asian Geography',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/asian+geography.svg'
      },
      {
        id: '5ffc5148982a4eed37b956d8',
        name: 'Assorted Qs'
      },
      {
        id: '5ed7329969debc09a46e7a92',
        name: 'Basic Maths',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/basic+maths.svg'
      },
      {
        id: '5f314d5474b2b9a3ba57d980',
        name: 'Bollywood',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/bollywood.svg'
      },
      {
        id: '5ed7329969debc09a46e7a93',
        name: 'Books',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/books.svg'
      },
      {
        id: '5ed7329969debc09a46e7a94',
        name: 'Business and Tech',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/business+and+tech.svg'
      },
      {
        id: '60e5e8ec41dcf34bfd907344',
        name: 'Covid',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/covid.svg'
      },
      {
        id: '5f6474cb7cc1e8174c3ea39b',
        name: 'Cricket',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/cricket.svg'
      },
      {
        id: '5ed7329969debc09a46e7a95',
        name: 'Entertainment',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/entertainment.svg'
      },
      {
        id: '5ed7329969debc09a46e7a96',
        name: 'Food and Drink',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/food+and+drink.svg'
      },
      {
        id: '5ed7329969debc09a46e7a97',
        name: 'Game of Thrones',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/game+of+thrones.svg'
      },
      {
        id: '5ed7329969debc09a46e7a98',
        name: 'General Knowledge',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/general+knowledge.svg'
      },
      {
        id: '5ed7329969debc09a46e7a99',
        name: 'Geography',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/geography.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9a',
        name: 'Grammar',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/grammar.svg'
      },
      {
        id: '5f92c5dfecb99b40f4acfda1',
        name: 'Halloween',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/halloween.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9b',
        name: 'Harry Potter',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/harry+potter.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9c',
        name: 'History',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/history.svg'
      },
      {
        id: '5f993ebe5156c93c9812f397',
        name: 'India General',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/india+general.svg'
      },
      {
        id: '5f993e835156c93c9812eff8',
        name: 'India Science',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/india+science.svg'
      },
      {
        id: '5f993ede5156c93c9812f462',
        name: 'Indian Food',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/indian+food.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9d',
        name: 'Landmarks and Monuments',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/landmarks+%26+monuments.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9e',
        name: 'Lifestyle',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/lifestyle.svg'
      },
      {
        id: '5ed7329969debc09a46e7a9f',
        name: 'Maths Worded Problems',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/math+worded+problems.svg'
      },
      {
        id: '5f993ead5156c93c9812f23e',
        name: 'Money',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/money.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa0',
        name: 'Movies & Films',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/movies+%26+films.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa1',
        name: 'Music',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/music.svg'
      },
      {
        id: '5f993d0b1ee8800012c65b83',
        name: 'Periodic Table',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/periodic+table.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa2',
        name: 'Science and Nature',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/science+%26+nature.svg'
      },
      {
        id: '5f993e965156c93c9812f099',
        name: 'Sherlock Holmes',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/sherlock+holmes.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa3',
        name: 'Spelling',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/spelling.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa4',
        name: 'Spelling & Grammar',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/spelling+%26+grammar.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa5',
        name: 'Sport',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/sports.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa6',
        name: 'TV',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/tv.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa7',
        name: 'The Office',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/the+office.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa8',
        name: 'US President',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/us+president.svg'
      },
      {
        id: '5ed7329969debc09a46e7aa9',
        name: 'US States',
        image: 'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/us+states.svg'
      },
      {
        id: '5ed7329969debc09a46e7aaa',
        name: 'World and Work',
        image:
          'https://trivia-backend-assets.s3.ap-south-1.amazonaws.com/categories/world+%26+work.svg'
      },
      {
        id: '61792f6fc1fc046695da051f',
        name: '🆕 Cryptocurrency'
      },
      {
        id: '60e5e8ec41dcf34bfd907345',
        name: '🆕 Games'
      }
    ],
    specialCategories: [
      {
        id: '637726bcb0e1d2dc08c22a3e',
        name: '⚽ Premier League Quiz'
      },
      {
        id: '6371dee563dc876ecb0d6a99',
        name: "🏏 Men's T20 World Cup 2022 Quiz"
      },
      {
        id: '637b2f1f63dc876ecb0de6cc',
        name: '🦃 Thanksgiving Quiz'
      }
    ]
  }
};

export function addAutomation(schema, request) {
  const automation = JSON.parse(request.requestBody);
  return {
    ok: true,
    data: { ...automation, channel: { name: 'office', id: '12345', isPrivate: true } }
  };
}

export function updateAutomation(schema, request) {
  const automation = JSON.parse(request.requestBody);
  return {
    ok: true,
    data: { ...automation, channel: { name: 'office', id: '12345', isPrivate: true } }
  };
}

export function deleteAutomation(schema, request) {
  const { id } = request.queryParams;
  return {
    ok: true,
    data: { id }
  };
}
