import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@springrole/trivia-games';
import history from 'createHistory';
import classNames from 'classnames';
import { useGetAnalyticsOverviewQuery } from 'app/services/home';
import { connect } from 'react-redux';
import { maybePluralize } from 'utils/utilities';
import Form from '../Automations/Modals/Form';
import styles from './styles.module.scss';

const AutomationCard = (props) => {
  const { isAdmin } = props;
  const [openAutomationModal, setOpenAutomationModal] = useState(false);
  const { data: overview = { automations: 0 } } = useGetAnalyticsOverviewQuery();

  return overview.automations > 0 ? (
    <div className={classNames(styles.cardWraper, styles.automationCard)}>
      <div className={classNames(styles.cardSubTitle, styles.subTitleNonEmptyState)}>
        Trivia is on auto-pilot to spread it&apos;s magic.
      </div>
      <div className={classNames(styles.cardTitle, styles.titleNonEmptyState)}>
        You&apos;re a part of&nbsp;
        <span className={styles.highlightText}>
          {overview.automations} active {maybePluralize(overview.automations, 'Automation')}
        </span>
      </div>
      <Button
        onClick={() => history.push('/home/automations')}
        className={styles.commonButtonStyles}
      >
        {isAdmin ? 'Manage Automations' : 'View Automations'}
      </Button>
    </div>
  ) : (
    <div className={classNames(styles.cardWraper, styles.automationCard)}>
      <div className={styles.cardTitle}>
        <span className={styles.highlightText}>Automate</span> the fun
      </div>
      <div className={styles.cardSubTitle}>
        Put Trivia on auto-pilot & maximize engagement regularly.
      </div>
      {isAdmin && (
        <Button onClick={() => setOpenAutomationModal(true)} className={styles.commonButtonStyles}>
          Automate Games
        </Button>
      )}
      {openAutomationModal && (
        <Form open={openAutomationModal} handleCancel={() => setOpenAutomationModal(false)} />
      )}
    </div>
  );
};

AutomationCard.propTypes = {
  isAdmin: PropTypes.bool
};

AutomationCard.defaultProps = {
  isAdmin: false
};

const mapStateToProps = ({ user }) => ({ isAdmin: user.user?.isAdmin });

export default connect(mapStateToProps)(AutomationCard);
