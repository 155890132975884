import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { selectCameraStreamByPeerID, useHMSActions, useHMSStore } from '@100mslive/hms-video-react';
import { selectPeerAudioByID } from '@100mslive/hms-video-store';
import { ReactComponent as MicOn } from 'assets/VirtualCoffee/microphone-on.svg';
import classNames from 'classnames';
import { ReactComponent as Spinner } from 'assets/images/spinner.svg';
import styles from './Peers.module.scss';

const Peers = ({ peer1, peer2 }) => {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const hmsActions = useHMSActions();
  const peer1AudioLevel = useHMSStore(selectPeerAudioByID(peer1?.id));
  const peer2AudioLevel = useHMSStore(selectPeerAudioByID(peer2?.id));
  const videoTrack1 = useHMSStore(selectCameraStreamByPeerID(peer1?.id));
  const videoTrack2 = useHMSStore(selectCameraStreamByPeerID(peer2?.id));

  useEffect(() => {
    if (videoRef1.current && videoTrack1) {
      if (videoTrack1.enabled) {
        hmsActions.attachVideo(videoTrack1.id, videoRef1.current);
      } else {
        hmsActions.detachVideo(videoTrack1.id, videoRef1.current);
      }
    }

    if (videoRef2.current && videoTrack2) {
      if (videoTrack2.enabled) {
        hmsActions.attachVideo(videoTrack2.id, videoRef2.current);
      } else {
        hmsActions.detachVideo(videoTrack2.id, videoRef2.current);
      }
    }
  }, [videoTrack1, videoTrack2, hmsActions]);

  return (
    <div
      className={classNames(styles.videoContainer, {
        [styles.mainVideoDisabled]: !videoTrack1?.enabled
      })}
    >
      <video
        ref={videoRef1}
        className={classNames(styles.mainVideoWrapper, { [styles.hide]: !videoTrack1?.enabled })}
        autoPlay
        muted
        playsInline
      />
      <MicOn className={classNames(styles.MicOn, { [styles.hide]: peer1AudioLevel < 1 })} />
      <div className={classNames(styles.peerName, { [styles.hide]: !peer2 })}>{peer1?.name}</div>
      {peer1 ? (
        <img
          src={
            peer1?.image || `https://ui-avatars.com/api/?length=1&name=${encodeURI(peer1?.name)}`
          }
          alt='peer1'
          className={classNames(styles.user1Image, { [styles.hide]: videoTrack1?.enabled })}
        />
      ) : (
        <Spinner className={styles.loader} />
      )}
      {peer2 && (
        <div className={styles.userVideoContainer}>
          <div
            className={classNames(styles.userVideoWrapper, {
              [styles.userVideoDisabled]: !videoTrack2?.enabled
            })}
          >
            <video
              ref={videoRef2}
              className={classNames(styles.userVideo, { [styles.hide]: !videoTrack2?.enabled })}
              autoPlay
              muted
              playsInline
            />
            <MicOn
              className={classNames(styles.MicOn, {
                [styles.small]: true,
                [styles.hide]: peer2AudioLevel < 1
              })}
            />
            <img
              src={
                peer2?.image ||
                `https://ui-avatars.com/api/?length=1&name=${encodeURI(peer2?.name)}`
              }
              alt='peer2'
              className={classNames(styles.user2Image, { [styles.hide]: videoTrack2?.enabled })}
            />
            <div className={styles.videoText}>You</div>
          </div>
        </div>
      )}
    </div>
  );
};

Peers.propTypes = {
  peer1: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }),
  peer2: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  })
};

Peers.defaultProps = {
  peer1: null,
  peer2: null
};

export default Peers;
