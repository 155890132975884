// To return the required form of data needed for the mantine select dropdown
export const serializeSlackChannels = (channels, withInitialState) => {
  const channelOptions = [];
  if (withInitialState) {
    channelOptions.push({ label: 'All Channels', value: 'all' });
  }
  channels.forEach((channel) => {
    channelOptions.push({
      label: `${channel.isPrivate ? '🔒' : '#'}${channel.name}`,
      value: channel.id
    });
  });
  return channelOptions;
};

export const serializeTeamsChannels = (teams, withInitialState, onlyTeam = false) => {
  const channelOptions = [];
  if (withInitialState) {
    if (onlyTeam) {
      channelOptions.push({ label: 'All conversations', value: 'all' });
    } else {
      channelOptions.push({
        label: 'All conversations',
        value: 'all',
        group: 'All conversations'
      });
    }
  }
  teams.forEach((team) => {
    if (onlyTeam) {
      if (team.isGroupChat) return;
      channelOptions.push({ label: team.name, value: team.id, teamId: team.id });
      return;
    }

    if (team.isGroupChat) {
      channelOptions.push({ label: team.name, value: team.id, group: 'Group chats', teamId: null });
    } else {
      team.channels.forEach((channel) => {
        channelOptions.push({
          label: channel.name,
          value: channel.id,
          group: team.name,
          teamId: team.id
        });
      });
    }
  });
  return channelOptions;
};
