import React, { useState, useEffect } from 'react';
import {
  useGetVirtualCoffeeInsightsQuery,
  useGetVcAnalyticsOverviewQuery,
  analyticsApi
} from 'app/services/analytics';
import { useDispatch } from 'react-redux';
import { ReactComponent as BackIcon } from 'assets/images/home/backIcon.svg';
import LineChartComingSoon from 'assets/images/Analytics/lineChartComingSoon.png';
import { Section } from '@springrole/trivia-games';
import CustomSelect from 'components/Select/customSelect';
import history from 'createHistory';
import Layout from 'components/Layout';
import Charts from 'components/Charts';
import { serializeSlackChannels } from 'utils/serialization';
import useWindowSize from 'utils/hooks';
import moment from 'moment';
import { useGetVirtualCoffeeChannelsQuery } from 'app/services/channels';
import VirtualCoffeeListView from './VirtualCoffeeListView';
import Overview from './Overview';
import styles from './styles.module.scss';

const getSelectedIndex = (virtualCoffeeInsights, selectedDataPoint) => {
  const selectedListIndex = virtualCoffeeInsights.findIndex(
    (item) => moment(item.eventDate).format('DD/MM/YYYY') === selectedDataPoint
  );

  if (selectedListIndex < 0) return 0;

  return selectedListIndex;
};

const VirtualCoffee = () => {
  const dispatch = useDispatch();
  const [virtualCoffeeInsights, setVirtualCoffeeInsights] = useState([]);
  const [virtualCoffeeOverview, setVirtualCoffeeOverview] = useState({});
  useGetVirtualCoffeeInsightsQuery({});
  useGetVcAnalyticsOverviewQuery();
  const { data: virtualCoffeeChannels = [], isFetching } = useGetVirtualCoffeeChannelsQuery();
  const channels = isFetching ? [] : serializeSlackChannels(virtualCoffeeChannels, true);
  const labels = virtualCoffeeInsights.map((item) =>
    moment(item.eventDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
  );

  const [selectedChannel, setSelectedChannel] = useState('all');
  const [selectedDataPoint, setSelectedDataPoint] = useState(labels[0]);
  const { width } = useWindowSize();

  const VcListData = virtualCoffeeInsights[
    getSelectedIndex(virtualCoffeeInsights, selectedDataPoint)
  ]?.matches?.map((item, index) => ({
    id: { value: index, display: false },
    users: item.users.join(', '),
    messages: item.engagement || 0
  }));
  const data = virtualCoffeeInsights.map((item) => item.engagement);

  const handleDropdownValuesChange = async (value) => {
    setSelectedChannel(value);
    const params = { channel: value };
    if (value === 'all') {
      delete params.channel;
    }
    const insights = await dispatch(
      analyticsApi.endpoints.getVirtualCoffeeInsights.initiate(params)
    );
    setVirtualCoffeeInsights(insights.data);
    const overview = await dispatch(analyticsApi.endpoints.getVcAnalyticsOverview.initiate(params));
    setVirtualCoffeeOverview(overview.data);
  };
  useEffect(() => {
    handleDropdownValuesChange('all');
  }, []);
  return (
    <Layout name='Analytics' className={styles.virtualCoffeeContentWrapper}>
      <div className={styles.goBackButton} onClick={() => history.push('/analytics')}>
        <BackIcon /> Go back
      </div>

      <div className={styles.topContainer}>
        <div className={styles.welcomeText}>VirtualCoffee Insights</div>
        <div className={styles.dropdown}>
          <CustomSelect
            options={channels}
            value={selectedChannel}
            setValue={(value) => handleDropdownValuesChange(value)}
          />
        </div>
      </div>
      <Overview virtualCoffeeOverview={virtualCoffeeOverview} />
      <Section className={styles.container}>
        <div className={styles.title}>
          <b className={styles.engagement}>Engagement rate</b> (Click on graph to view match info)
        </div>
        {process.env.REACT_APP_COMING_SOON === 'true' ? (
          <div className={styles.comingSoonContainer}>
            <img className={styles.comingSoonImg} src={LineChartComingSoon} alt='' />
            <div className={styles.comingSoonText}>Coming soon</div>
          </div>
        ) : (
          <div>
            <Charts
              type='line'
              labels={width < 425 ? labels.slice(-5) : labels}
              datasets={[
                {
                  data: width < 425 ? data.slice(-5) : data,
                  backgroundColor: '#E1DCFF'
                }
              ]}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    xAlign: 'center',
                    yAlign: 'bottom',
                    backgroundColor: '#FFFFFF',
                    bodyColor: '#000',
                    displayColors: false,
                    callbacks: {
                      label(context) {
                        let label = context.label || '';

                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += `${context.parsed.y}%`;
                        }
                        return label;
                      },
                      title: () => null
                    }
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: true,
                      color: '#A192F5',
                      drawTicks: false
                    },
                    border: {
                      dash: [10, 10],
                      display: false
                    },
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.8)',
                      padding: width < 425 ? 10 : 20
                    }
                  },
                  y: {
                    grid: {
                      color: '#A192F5',
                      drawTicks: true
                    },
                    border: {
                      dash: [10, 10],
                      display: false
                    },
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.8)',
                      padding: width < 425 ? 5 : 20,
                      stepSize: 25,
                      callback: (value) => `${value}%`
                    },
                    min: 0,
                    max: 100
                  }
                },
                elements: {
                  line: { borderColor: ' #A192F5' }
                },
                maintainAspectRatio: false
              }}
              setSelectedDataPoint={setSelectedDataPoint}
            />
          </div>
        )}
        <VirtualCoffeeListView
          selectedChannel={selectedChannel}
          VcListData={VcListData}
          date={
            virtualCoffeeInsights[
              getSelectedIndex(
                virtualCoffeeInsights,
                process.env.REACT_APP_COMING_SOON === 'true'
                  ? labels[labels.length - 1]
                  : selectedDataPoint
              )
            ]?.eventDate
          }
          rate={
            virtualCoffeeInsights[getSelectedIndex(virtualCoffeeInsights, selectedDataPoint)]
              ?.engagement
          }
          lastMatch={virtualCoffeeOverview?.lastMatch}
        />
      </Section>
    </Layout>
  );
};

export default VirtualCoffee;
