import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button } from '@springrole/trivia-games';
import PropTypes from 'prop-types';
import { useCancelSubscriptionMutation } from 'app/services/monetization';
import styles from './styles.module.scss';

const CancelSubscriptionModal = ({
  openCancelSubscriptionModal,
  setOpenCancelSubscriptionModal,
  teamsSubscription,
  plan
}) => {
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = async () => {
    setLoading(true);

    try {
      const data = await cancelSubscription({
        subscriptionId: teamsSubscription?.subscriptionId
      }).unwrap();
      setLoading(false);
      if (data.ok) {
        setOpenCancelSubscriptionModal(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.data.message);
    }
  };

  return (
    <Modal
      title='Cancel Subscription'
      open={openCancelSubscriptionModal}
      onCancel={() => setOpenCancelSubscriptionModal(false)}
      showConfirm={false}
    >
      <div className={styles.container}>
        <div className={styles.bodyContainer}>
          <div className={styles.body}>
            Are you sure you want to cancel the <b>{plan}</b> subscription?
          </div>

          <div className={styles.subBody}>
            Subscription cancellation may take up to 10 minutes to process
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => setOpenCancelSubscriptionModal(false)}
            style={{ margin: 0, marginRight: '10px', backgroundColor: 'transparent' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCancelSubscription}
            style={{
              backgroundColor: loading ? '#C6C6C6' : '#EC5959',
              margin: '0px',
              borderColor: 'transparent'
            }}
            disable={loading}
          >
            Cancel subscription
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CancelSubscriptionModal.propTypes = {
  setOpenCancelSubscriptionModal: PropTypes.func.isRequired,
  teamsSubscription: PropTypes.shape({
    subscriptionId: PropTypes.string.isRequired
  }).isRequired,
  plan: PropTypes.string.isRequired,
  openCancelSubscriptionModal: PropTypes.bool.isRequired
};

export default CancelSubscriptionModal;
